import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Footer } from './parts'
import routes from "./routes";
// import {requestPermission} from "./firebase"
// import * as serviceWorker from './serviceWorker';

const loadScript = function (src) {
  var tag = document.createElement("script");
  tag.async = false;
  tag.src = src;
  document.getElementsByTagName("body")[0].appendChild(tag);
};

function App(props) {
  let currentPath = window.location.pathname;
  let isAppBarShow = true;

  if (currentPath === "/login") isAppBarShow = false;

  useEffect(()=>{
    // requestPermission()
    loadScript(`https://assets.mentorbaik.com/js/jquery.js`);
    loadScript(`https://assets.mentorbaik.com/js/plugins.js`);
    loadScript(`https://assets.mentorbaik.com/js/functions.js`);
  },[]);
  
  return (
    <Router>
      <Switch>
        {routes.map((route, index) => (
          // Render more <Route>s with the same paths as
          // above, but different components this time.
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
     
    </Router>
  );
}

// serviceWorker.register();
export default App;