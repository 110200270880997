import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import renderHTML from 'react-render-html';
import { Images } from ".";
import moment from "moment";
import Select from 'react-dropdown-select';
import * as Unicons from '@iconscout/react-unicons';
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import htmlToDraft from 'html-to-draftjs';
import ReactPlayer from "react-player";
import Loader from "react-loader-spinner";
import Comment from "./Timeline/Comment";
import CommentDiscussion from "./Timeline/CommentDiscussion";
import PopupShare from "./PopupShare";
import { Alert } from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from "./ImageModal";
import DeleteModal from "./DeleteModal";
import ReactHlsPlayer from 'react-hls-player';
import FolderModal from "./FolderModal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function truncate(source, size) {
  return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

function splitwords(txt, limit) {
  let chunks = txt.split(" ");
  let str = "";
  chunks.map((item, index) => {
    if (index < limit) str += item + " ";
  });
  return str;
}

function wordcounts(txt, limit) {
  let chunks = txt.split(" ");
  return chunks.length;
}
const CDNPlayer = (props) => {
  const { item } = props;
  const [show, is_show] = useState(false);
  return <>
    {!show && <><img src={item.image_url} style={{ width: '100%', cursor: 'pointer' }} onClick={() => {
      is_show(true);
    }} /><p className="mt-2">Click to play video</p></>}
    {show && <ReactHlsPlayer
      src={item.video_url}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
    />
    }
  </>
}

export default function (props) {
  const {
    progress,
    posts,
    premium,
    events,
    isLogin,
    is_mentor,
    toggle,
    onToggle,
    allUser,
    onRefresh,
    callAction,
    call_action,
    can_post,
    on_toggle_modal,
    toolbar,
    onEdit,
    onReload,
    filter,
    onUserQuestions,
  } = props;
  const navigate = useHistory();
  const sectorsCheckList = useRef([]);
  const brothersCheckList = useRef([]);
  const categoriesCheckList = useRef([]);
  const location = useLocation()
  const [follow, toggleFollow] = useState(false);
  const [items, setItems] = useState(posts ? posts : []);
  const [idInboxNotRead, setIdInboxNotRead] = useState([]);
  const [lastComment, setLastComment] = useState([]);
  const [update, setUpdate] = useState(0);
  const [update_in_progress, toggleProgress] = useState(false);
  const [toggleReply, setToggle] = useState(true);
  const [toggleShare, setToggleShare] = useState(false);
  const [shareItem, setShareItem] = useState(null);
  const [readmore, setReadmore] = useState({});
  const [sharelink, setShareLink] = useState(null);
  const [totalUnread, setTotalUnread] = useState(0);
  const [toggle_image, setToggleImage] = useState(false);
  const [filterTanya, setFilterTanya] = useState({
    pertanyaanSaya: false,
    kodeSaham: false,
  });
  const [selected_image, setSelectedImage] = useState(null);
  const [toggle_delete, setToggleDelete] = useState(false);
  const [deleted_item, setDeletedItem] = useState(null);
  const [delete_status, setDeleteStatus] = useState(0);
  const [selected_item, setSelectedItem] = useState(null);
  const [toggle_save, setToggleSave] = useState(false);
  const [sortNewest, set_sort_newest] = useState(true);
  const [selectedSaham, setSelectedSaham] = useState([])

  const getFullDate = (date) =>{
    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dev"]
    const currDate = new Date(date)
    const day = currDate.getUTCDay() < 10 ? '0' + currDate.getUTCDay() : currDate.getUTCDay()
    const month = currDate.getUTCMonth()
    const year = currDate.getUTCFullYear()
    const hour = currDate.getUTCHours()
    const mins = currDate.getUTCMinutes()
    return `${day} ${months[month-1]} ${year} ${hour}:${mins}`
  }
  
  const clearFilters = (e) => {
    e.preventDefault()
    setSelectedSaham([])
    for (let i = 0; i < sectorsCheckList.current.length; i++) {
      sectorsCheckList.current[i].checked = false;
    }
    for (let i = 0; i < categoriesCheckList.current.length; i++) {
      categoriesCheckList.current[i].checked = false;
    }
    for (let i = 0; i < brothersCheckList.current.length; i++) {
      brothersCheckList.current[i].checked = false;
    }
  }

  const checkboxvalue = (index, section) => {
    if( section == "sectors" ){
      sectorsCheckList.current[index].checked = true;
    }else if( section == "categories" ){
      categoriesCheckList.current[index].checked = true;
    }else if( section == "brothers" ){
      brothersCheckList.current[index].checked = true;
    }
  }

  const follow_user = (item) => {
    try {
      callAction(call_action, "follow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/follow`,
      });
      toggleProgress(true);

      let rs = posts.map((p) => {
        if (p.mentor_id === item.mentor_id) {
          p.is_followed = 1;
        }
        return p;
      });
      setItems(rs);
    } catch (err) {
      console.log(err);
    }
  };

  const unfollow_user = (item) => {
    try {
      callAction(call_action, "unfollow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/unfollow`,
      });
      toggleProgress(true);

      let rs = posts.map((p) => {
        if (p.mentor_id === item.mentor_id) {
          p.is_followed = 0;
        }
        return p;
      });
      setItems(rs);
    } catch (err) {
      console.log(err);
    }
  };

  const onFollowClicked = (item) => {
    if (item.is_followed === 0) follow_user(item);
    else unfollow_user(item);
  };

  const toggleComments = (item) => {
    let updated_items = items.map((i) => {
      if (i.id === item.id) {
        i.toggle_comments = !i.toggle_comments;
      }
      return i;
    });
    setItems(updated_items);
    setUpdate(update + 1);
  };

  const onReadMore = (item, is_subscribed) => {
    let updated_items = items.map((i) => {
      if (!i.toggle_read) i.toggle_read = false;
      if (i.id === item.id) {
        i.toggle_read = !i.toggle_read;
      }
      return i;
    });
    setItems(updated_items);
    setUpdate(update + 1);
  }
  
  const isOwnPost = (item) => {
    if (parseInt(item.mentor_id) === parseInt(localStorage.getItem("mentor_id") && parseInt(item.mentor_id) > 0)) {
      return true;
    }
  }
  
  const deletePost = () => {
    callAction(call_action, "delete_post", {
      endpoint: `/v1/mentor/${deleted_item.mentor_id}/post/${deleted_item.id}/delete`,
    });
  }
  
  const like = (item) => {
    callAction(call_action, "like", {
      endpoint: `/v1/posts/${item.id}/like`,
    });
    item.is_liked = 1;
    item.total_likes++;
  }
  
  const unlike = (item) => {
    callAction(call_action, "unlike", {
      endpoint: `/v1/posts/${item.id}/unlike`,
    });
    item.is_liked = 0;
    item.total_likes--;
  }
  
  const saved = (item) => {
    //open folder popup
    setSelectedItem(item);
    setToggleSave(true);
  }
  
  const unsaved = (item) => {
    callAction(call_action, "unsaved", {
      endpoint: `/v1/folders/remove/${item.id}`,
    });
    item.is_saved = 0;
  }
  
  const bookmark = (item, folder_id) => {
    callAction(call_action, "like", {
      endpoint: `/v1/post/${item.id}/save/${folder_id}`,
    });
  }

  const listMentors = (list) => {
    let result = '';
    for( let idx in list ){
      result += list[idx].username;
      if( idx != list.length-1 ){
        result += ' , ';
      }
    }
    return result ? result : 'All mentors'
  }

  // const handleMarkAsRead = (postId) => {
    // callAction(call_action, "mark_as_read", {
    //   endpoint: `/v2/inbox/${postId}`,
    //   scenario: "patch",
    // });
    // if(!idInbox.includes(postId)){
      // let temp = [...idInbox]
      // temp.push(idInbox)
      // setIdInbox(temp)
    // }
  // }

  const handleMarkAsRead = () => {
    let temp = []
    for( let item of posts ){
      if( item.is_read == 0 ){
        callAction(call_action, "mark_as_read", {
          endpoint: `/v2/inbox/${item.id}`,
          scenario: "patch",
        });
        temp.push(item.id)
      }
    }
    setIdInboxNotRead(temp)
  }

  useEffect(() => {
    posts.map((p) => {
      p.toggle_comments = false;
    });
    premium.map((p) => {
      p.toggle_comments = false;
    });
    setItems(posts);
    setUpdate(update + 1);
    handleMarkAsRead()
  }, []);

  useEffect(() => {
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(true);
    }
  }, [call_action.follow_mentor]);

  useEffect(() => {
    if (call_action.delete_post) {
      setDeleteStatus(0);
      setDeletedItem(null);
      setToggleDelete(false);
      if (onReload) onReload();
    }
  }, [call_action.delete_post]);

  useEffect(() => {
    if(call_action.inbox_count){
      setTotalUnread(call_action.inbox_count.meta.total_is_not_read)
    }
  }, [call_action.inbox_count]);

  useEffect(() => {
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(false);
    }
  }, [call_action.unfollow_mentor]);

  useEffect(() => {
    posts.map((p) => {
      p.toggle_comments = false;
    });
    premium.map((p) => {
      p.toggle_comments = false;
    });
    setItems(posts);
    setUpdate(update + 1);
  }, [posts, premium]);

  function addStyle(string) {
    return string.split("\n").join("\<br/>")
  }

  return (
    
    <>
      <div className="row mt-2">
        <div className="col-10 pt-1">
          <h3 className="mb-0">
            {`Inbox (${totalUnread})`}
          </h3>
        </div>
        <div className="col-2">
          <div className="row ml-2">
            {/* <button className="btn btn-white" data-toggle="modal" data-target={`#filterTanyaModal`}> 
              <p>
                <Unicons.UilFilter size="16" className="inline-icon2"/>
                &nbsp;
                Filter 
              </p>
            </button> */}
            &nbsp;
            &nbsp;
            &nbsp;
            {/* <div class="dropdown">
              <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Urutan
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" href="#" onClick={()=>set_sort_newest(true)}>
                  Pertanyaan terbaru
                  { sortNewest && <Unicons.UilCheck  size="22" className="inline-icon" color="#4ba5f8"/> }
                </a>
                <a class="dropdown-item" href="#" onClick={()=>set_sort_newest(false)}>
                  Aktivitas terbaru
                  { !sortNewest && <Unicons.UilCheck  size="22" className="inline-icon" color="#4ba5f8"/> }
                </a>
              </div>
            </div> */}

            {/* for filter "Tanya" section */}
            <div class="modal fade" id="filterTanyaModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h4 class="modal-title" id="exampleModalLabel">FILTER</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body ml-2 mr-2">
                    <div className="row">
                      <div className="col">
                        <h4 className="mb-0">
                          Message
                        </h4>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div class="form-check">
                          <input class="form-check-input mt-2" type="checkbox" value="" id={"pertanyaanSaya"}  
                          defaultChecked={filterTanya.pertanyaanSaya}
                          onChange={()=>setFilterTanya({...filterTanya, pertanyaanSaya: !filterTanya.pertanyaanSaya})}
                          />
                          <label class="form-check-label" for={"unread"}>
                            {"Unread"}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div class="form-check">
                          <input class="form-check-input mt-2" type="checkbox" value="" id={"kodeSaham"} 
                          defaultChecked={filterTanya.kodeSaham}
                          onChange={()=>setFilterTanya({...filterTanya, kodeSaham: !filterTanya.kodeSaham})}
                          />
                          <label class="form-check-label" for={"Read"}>
                            {"Read"}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div class="form-check">
                          <input class="form-check-input mt-2" type="checkbox" value="" id={"pertanyaanSaya"}  
                          defaultChecked={filterTanya.pertanyaanSaya}
                          onChange={()=>setFilterTanya({...filterTanya, pertanyaanSaya: !filterTanya.pertanyaanSaya})}
                          />
                          <label class="form-check-label" for={"answered"}>
                            {"Answered"}
                          </label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div class="form-check">
                          <input class="form-check-input mt-2" type="checkbox" value="" id={"kodeSaham"} 
                          defaultChecked={filterTanya.kodeSaham}
                          onChange={()=>setFilterTanya({...filterTanya, kodeSaham: !filterTanya.kodeSaham})}
                          />
                          <label class="form-check-label" for={"notAnswered"}>
                            {"Not Answered"}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-blue-mentorbaik">APPLY</button>
                  </div>
                </div>
              </div>
            </div>

            {/* <button className="btn btn-white" > 
              <p>
                Urutan 
                &nbsp;
              </p>
            </button> */}
          </div>
        </div>
      </div>
      { items.length > 0 && toggle !== "event" && toggle !== "Discussion" && (
        <>
          {items.map((item, index) => {
            // { item.is_read == 0 && handleMarkAsRead(item.id) }
            const own_post = isOwnPost(item) ? true : false;
            //item.is_subscribed = 1; //override dulu
            // if (!item.mentor && !item.profile) return null;
            // if (item.symbol === "Tanya" && item.comments === 0 && !is_mentor) return null;
            // console.log('check', ((item.is_subscribed === 1 || is_mentor || own_post) && item.toggle_read));
            // console.log("comment", item.is_subscribed, own_post, is_mentor, (item.is_subscribed === 1 || own_post || is_mentor));
            return (
              <div key={index} className={ !item.is_read ? "widget-box box-inbox mentor-timeline-widget new-inbox" : "widget-box box-inbox mentor-timeline-widget"}>
                <div className="row">
                  {  !item.is_read &&
                    <div className="col-1 notif-inbox">
                      <p className="dot-notif">
                      </p>
                    </div>
                  }
                  <div className="col-11">
                    <div className="timeline-top-user">
                      <div className="user-profile-wrap">
                        <img
                          src={item.mentor ? item.mentor.avatar_url : item.profile.avatar_url}
                          className="user-image"
                          alt="user-image"

                          onClick={() => 
                            item.symbol !== "Tanya" ? 
                            document.location="/mentor/" + item.mentor.username 
                            :
                            ""
                          }
                        />
                        <div className="user-description">
                          <div className="user-name" onClick={() => {
                            // document.location="/mentor/" + item.mentor.username ? item.mentor.username : item.profile.username;
                          }}> 
                            {item.profile ? item.profile.nama : "Mentor Baik"}
                          </div>
                          <div className="user-timestamp">
                            {" "}
                            {moment(item.created_at).format("DD MMM YYYY HH:MM")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col mt-2">
                    <font>
                      {`Question for `}
                    </font>
                    <font className="list-mentors">
                      {listMentors(item.mentor_list)}
                    </font>
                  </div>
                </div>
                <div className="premium-box">
                  <h4 className={`mb-1 clickable-title mt-2`} onClick={() => {
                    if (item.is_subscribed === 0 && !own_post) {
                      document.location = "/subscribe";
                    } else {
                        navigate.push({
                          pathname: `posts/${item.id}`,
                      })
                    }
                  }}>{item.symbol}: {item.title}</h4>
                  {item.post_status === 0 && <span class="badge badge-pill badge-primary">Draft</span>}
                  {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
                    {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown> */}
                    <p>
                      Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
                  </div>}
                  {((item.is_subscribed === 1 || is_mentor || own_post) && !item.toggle_read) && <div>
                    {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown>
                    <br/> */}
                    { 
                      item.styled_excerpt ? 
                        renderHTML(item.styled_excerpt )
                        :
                        item.excerpt 
                    }
                    {/* {handleContent(item.excerpt)} */}
                    <div>
                      <a href="javascript:void(0);" onClick={() => {
                        onReadMore(item);
                      }}>Read More...</a>
                    </div>
                  </div>}
                  
                  {item.toggle_read && <div>
                    {/* <ReactMarkdown>{(item.content)}</ReactMarkdown> */}
                    {/* <div className="text-justify">
                      {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
                    </div> */}
                    <div className="text-justify">
                      {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
                    </div>
                  </div>}

                  <hr/>
                  <div className="row text-center"> 
                    <div className="col-md-2 offset-md-5 mb-3">
                      {item.comments > 0 && 
                        <span onClick={() => {
                            toggleComments(item);
                          }} style={{ color: item.comments > 0 && "#00A8FF", cursor: "pointer"}}>
                          Show answer
                        </span>
                      }
                    </div>
                  </div>

                  <div className="mentor-comment-wrap"> 
                    <Comment
                      show={item.toggle_comments}
                      item={item}
                      setLastComment={setLastComment}
                      can_reply={true}
                      allUser={allUser}
                      {...props}
                      post_id={item.id}
                      onReply={() => {
                        setToggle(true);
                      }}
                      onClose={() => {
                        setToggle(true);
                      }}
                      onReplyDone={() => {
                        toggleComments(item);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
          {progress && <div style={{ textAlign: "center" }}>
            <Loader type="Puff" color="#00BFFF" height={30} width={30} />
          </div>}
        </>
      )}

      { items.length == 0 && (
        <div className="widget-box box-inbox mentor-timeline-widget mb04">
          <p>Belum ada pesan masuk</p>
        </div>
      )}

      <PopupShare
        show={toggleShare}
        sharelink={sharelink}
        shareItem={shareItem}
        onClose={() => {
          setToggleShare(false);
        }}
      />

      <ImageModal show={toggle_image} item={selected_image} onClose={() => {
        setToggleImage(false);
      }} />

      <DeleteModal
        delete_status={delete_status}
        item={deleted_item}
        show={toggle_delete}
        onClose={() => {
          setToggleDelete(false);
          setDeleteStatus(0);
        }} onConfirm={() => {
          //();
          setDeleteStatus(1);
          deletePost();
        }} />

      <FolderModal {...props}
        item={selected_item}
        show={toggle_save}
        onSaved={() => {
          setToggleSave(false);
          selected_item.is_saved = 1;
        }}
        onClose={() => {
          setToggleSave(false);
        }} />
    </>
  );
}
