import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import moment from "moment-timezone";
import TimelineItem from "./TimelineItem";

export default function (props) {
  const { callAction, call_action } = props;
  const { symbol, ts, limit } = props;
  const [items, setItems] = useState([]);
  const [fetching, loading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [item, setItem] = useState(false);
  const load = () => {
    callAction(call_action, "latest_comments", {
      endpoint: "/v1/latest/comments",
      data: {
        ts,
        symbol,
        limit,
      },
    });
    loading(true);
  };
  const onLoad = (payload) => {
    if (!payload) return;

    let data = payload.data ? payload.data : [];
    setItems(data);

    setUpdate(update + 1);
  };
  useEffect(() => {
    setToggle(false);
    load();
  }, [symbol]);

  useEffect(() => {
    if (fetching && call_action.latest_comments !== null) {
      onLoad(call_action.latest_comments);
      loading(false);
    }
  }, [call_action.latest_comments]);

  console.log({ symbol, ts, limit });

  return (
    <div
      className={`modal modal-project-message fade`}
      id="projectMessage2"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ maxWidth: 578 }}>
        <div className="modal-content">
          {fetching && (
            <div className="modal-body">
              <div style={{ textAlign: "center" }}>
                <Loader type="Puff" color="#00BFFF" height={100} width={100} />
              </div>
            </div>
          )}
          {!fetching && (
            <div className="modal-header centered">
              <h4 className="modal-title">{symbol}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                <img src="images/ic-close.png" />
              </button>
            </div>
          )}
          {!fetching && (
            <div className="modal-body">
              {!toggle && (
                <div className="project-message-wrapper has-scrollbar">
                  {items.map((item, index) => {
                    const txt = item.content.replace(/<[^>]+>/g, "");
                    return (
                      <div
                        key={index}
                        className="comment-box clickable p-2"
                        onClick={() => {
                          setToggle(true);
                          setItem(item);
                        }}
                      >
                        <div className="user-profile-wrap pl-2">
                          <img src={item.avatar_url} className="user-image" />
                          <div className="user-description">
                            <div className="user-name">
                              {item.username}
                            </div>
                            <div className="user-statistic">
                              <span>
                                {moment(item.created_at).fromNow(true)}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className={`pl-2`}>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.txt }}
                          />
                          <div class="reply-box">
                            <h4>
                              Replying to <span>{item.title}</span>
                            </h4>
                            <p>
                              {txt.substr(0, 100)}
                              {txt.length > 100 ? `...` : null}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {toggle && <>
                <TimelineItem data={item} 
                callAction={callAction}
                call_action={call_action}
                isMentor={false} canPost={false} isLogin={true} 
                onRefresh={()=>{}} 
                onClose={()=>{
                  setToggle(false);
                }}/>
              </>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
