import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Images, Header,Snackbar } from '../../parts';
import Loader from "react-loader-spinner";
import {Alert} from "react-bootstrap";
import qs from "qs";
const ResetPassword = (props) => {
    const {callAction, call_action, location, match} = props;
    const [progress, setProgress] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [snackbar_text, setSnackbarText] = useState(null);
    const [password_visible, setPasswordVisible] = useState(false);
    const [email, setEmail] = useState(null);
    const [email_sent, setEmailSent] = useState(false);
    const [reset_password, setResetPassword] = useState(false);
    const [reset_password_done, setPasswordResetDone] = useState(false);
    const [password, setPassword] = useState(null);
    const [confirm_password, setConfirm] = useState(null);
    const [reset_token, setResetToken] = useState(null);
      const [snackbar_type, setSnackbarType] = useState('danger');

    const error = (msg) => {
        setSnackbar(true);
        setSnackbarText(msg);
    }
    const send_reset = () => {
       
        callAction(call_action, 'reset_password',{
            endpoint:'/v1/reset_password',
            scenario:'post',
            data:{
                email
            }
        });
         setProgress(true);
    }
    const onSendReset = (payload) => {
        if(!payload) return;
        
        setProgress(false);
        setEmailSent(true);
    }

    const change_password = () => {
         if(!password) return error(`MOHON ISI PASSWORD !`);
        if(!confirm_password) return error(`MOHON ISI ULANG PASSWORD !`);
        if(password.length === 0) return error(`MOHON ISI PASSWORD !`);
        if(confirm_password.length === 0) return error(`MOHON ISI ULANG PASSWORD !`);
        if(password.length < 6) return error(`PASSWORD MINIMAL 6 KARAKTER !`);
        if(password.localeCompare(confirm_password) !== 0) return error(`PASSWORD YANG ANDA MASUKKAN TIDAK SAMA!`);
      
        callAction(call_action, 'change_password',{
            endpoint:'/v1/confirm_reset',
            scenario:'post',
            data:{
                reset_token,
                password,
                confirm_password
            }
        });
        setProgress(true);
    }

    const onChangePassword = (payload) => {
        if(!payload) return;
        
        setProgress(false);
        setPasswordResetDone(true);
    }
     useEffect(() => {
        if(progress && call_action.reset_password !== null){
          onSendReset(call_action.reset_password);
        }
      }, [call_action.reset_password]);

      useEffect(() => {
        if(progress && call_action.change_password !== null){
          onChangePassword(call_action.change_password);
        }
      }, [call_action.change_password]);

     useEffect(()=>{
       
        if(location.search){
            let query = qs.parse(location.search.split("?").join(""));
            if(query.q){
                setResetPassword(true);
                setResetToken(query.q);
            }
        }
     },[])

    return (
        <>
           <Header isLogin={false} {...props} />
            <section id="content">
                <div class="content-wrap">
                    <div class="section nobg nomargin">
                        <div class="login-card-wrapper">
                            <Link to="/login" class="form-back-button"><img src={Images.icArrow} alt="arrow" /> Back</Link>
                          {snackbar && (
                  <div style={{marginTop:15,marginBottom:-15}}>
                     <Alert variant={snackbar_type ? snackbar_type : `danger`}>
                      {snackbar_text}
                        </Alert>
                      </div>
                )}
                            {(!reset_password && email_sent) && <div className="form-login-wrapper text-center">
                                    <h4>Reset Link Sent!</h4>
                                    <p>Please check your email for your reset password instruction.</p>
                                </div>}
                            {(!reset_password && !email_sent) && <form class="form-login-wrapper text-center">
                                <h3 class="form-title">Reset Password</h3>
                                <p class="form-subtitle">Enter your email address and we'll send a link to reset your password.</p>
                                <div class="form-group">
                                    <input type="email" class="sm-form-control" placeholder="Email" onBlur={(evt)=>{
                                        setEmail(evt.target.value);
                                    }}/>
                                </div>
                                 {progress && (
                                      <div style={{ textAlign: "center",marginTop:30 }}>
                                        <Loader
                                          type="Puff"
                                          color="#00BFFF"
                                          height={35}
                                          width={35}
                                        />
                                      </div>
                                    )}
                                {!progress && <div class="form-button">
                                    <Link to="#" class="button" onClick={()=>{
                                        send_reset();
                                    }}>Send reset email</Link>
                                </div>}
                            </form>}
                             {(reset_password && reset_password_done) && <div className="form-login-wrapper text-center">
                                    <h4>Password Changed!</h4>
                                    <p>Your password has been changed successfully!</p>
                                </div>}

                            {(reset_password && !reset_password_done) && <form class="form-login-wrapper text-center">
                                <h3 class="form-title">Change Password</h3>
                                <p class="form-subtitle">Enter the new password.</p>
                                 <div className="form-group">
                          <div className="password-group">
                            <input
                              type={password_visible ? "text" : "password"}
                              className="sm-form-control password-box"
                              placeholder="Password"
                              aria-label="password"
                              name="your-password"
                              id="your-password"
                              onBlur={(evt)=>{
                                setPassword(evt.target.value)
                            }}
                            />
                            <Link to="#" onClick={() => {
                                setPasswordVisible(!password_visible);
                              }} className="password-visibility">
                              <i className="eye-close"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="password-group">
                            <input
                              type={password_visible ? "text" : "password"}
                              className="sm-form-control password-box"
                              placeholder="Confirm Password"
                              aria-label="password"
                              name="your-password"
                              id="your-password"
                              onBlur={(evt)=>{
                                setConfirm(evt.target.value)
                            }}
                            />
                            <Link to="#" onClick={() => {
                                setPasswordVisible(!password_visible);
                              }} className="password-visibility">
                              <i className="eye-close"></i>
                            </Link>
                          </div>
                        </div>
                                 {progress && (
                                      <div style={{ textAlign: "center",marginTop:30 }}>
                                        <Loader
                                          type="Puff"
                                          color="#00BFFF"
                                          height={35}
                                          width={35}
                                        />
                                      </div>
                                    )}
                                {!progress && <div class="form-button">
                                    <Link to="#" class="button" onClick={()=>{
                                        change_password();
                                    }}>Change Password</Link>
                                </div>}
                            </form>}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ResetPassword
