import React, { useEffect, useState } from "react";
import accounting from "accounting";
import { getSubsTypeName } from "../libs/helper";
import { Link } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Loader from "react-loader-spinner";
import styled from "styled-components";
import VideoModal from "./VideoModal";
import moment from "moment";

const VideoContainer = styled.div`
  padding:18px;
  max-height:1000px;
  overflow:auto;
  margin-bottom:30px;
`;
const VideoItem = styled.div`
  cursor:pointer;
  margin-bottom:18px;
  text-align: center;
  margin-top: 3%;
  h4{
    margin:10px 0px;
    font-size:14px;
  }
`
export default function (props) {
  const {items} = props;
  const [progress, setProgress] = useState(false);
  const [data, setData] = useState([]);
  const [toggle_modal, setToggleModal] = useState(false);
  const [selectedItem, setItem] = useState(null);

  // const load = ()=>{
  //   callAction(call_action, 'videos',{
  //     endpoint:'/v1/videos'
  //   });
  //   setProgress(true);
  // } 

  const handleLoad = (payload)=>{
    if(!payload) return;
    setData(payload.data ? payload.data : []);
  }
  useEffect(()=>{
    setData(items)
    setProgress(true);
  },[items]);

  // useEffect(()=>{
  //   if(progress && call_action.videos !== null){
  //     handleLoad(call_action.videos);
  //   }
  // },[call_action.videos]);

  {console.log("data NIH", data)}
  return (
    <>
    {data.length > 0 && 
      <div class="widget-panel widget-panel-dark mentor-ordersummary-widget">
        <VideoContainer className="maxHeightNone">
          {data.map((item, index) => {
            return (
              <div key={index+item.id} className="widget-box box-inbox mentor-timeline-widget">
                <div>
                  {<div className="thumbnail-video-wrap">
                    {(
                      <div className="row">
                        <div className="col-12">
                          <h4 className={`mb-1 clickable-title`}>{item.title}</h4>
                        </div>
                        <br/>
                        
                        <div className="col-12">
                          <div className="user-timestamp">
                            {" "}
                            {moment(item.created_at).format("DD MMM YYYY HH:MM")}
                          </div>
                        </div>
                        {/* { item.images && item.images[0].image_url != '' ?
                          <>
                            {item.images.filter(item => item.image_url).map((item, idx) => {
                              return (
                                <Col className="thumb-video-inner" md={ idx==0 ? 12 : 4 } key={index+item.id}>
                                  <img src={item.image_url} style={{ width: '100%', cursor: 'pointer' }} onClick={() => {
                                    setSelectedImage(item.image_url);
                                    setToggleImage(true);
                                  }} />
                                </Col>)
                            })}
                          </>
                          :
                          item.image_url && !item.images ?
                          <>
                            <Col className="thumb-video-inner">
                              <img src={item.image_url} style={{ width: '100%', cursor: 'pointer' }} onClick={() => {
                                setSelectedImage(item.image_url);
                                setToggleImage(true);
                              }} />
                            </Col>
                          </>
                          :
                          item.image_url && item.images[0].image_url == '' &&
                          <>
                            <Col className="thumb-video-inner">
                              <img src={item.image_url} style={{ width: '100%', cursor: 'pointer' }} onClick={() => {
                                setSelectedImage(item.image_url);
                                setToggleImage(true);
                              }} />
                            </Col>
                          </>
                        } */}
                      </div>
                    )}
                    <VideoItem onClick={()=>{
                      setItem(item);
                      setToggleModal(true);
                    }}>
                      <img className="thumbnailUrl" src={item?.thumbnail_url}/>
                    </VideoItem>
                  </div>}
                </div>
              </div>
            );
          })}
        
        </VideoContainer>
      </div>}
      
      <VideoModal item={selectedItem} show={toggle_modal} onClose={()=>{
        setToggleModal(false);
      }}/>
    </>
  );
}
