/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Timeline from "../../parts/FolderTimeline";
import TimelineItem from "../../parts/TimelineItem";
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalEdit,
  ModalPost,
  CardLeft,
  CardRight,
} from "../../parts";
import { load_notif } from "../../libs/helper";
import PopupNewPosts from "../../parts/PopupNewPosts";
import moment from "moment-timezone";
import { setupNotification } from "../../libs/helper";
import PopupNewComments from "../../parts/PopupNewComments";
import Loader from "react-loader-spinner";
import Notifications from "../../parts/Notifications";


const Folder = (props) => {
  const { callAction, call_action } = props;
  const [ready, setReady] = useState(false);
  const [fetching, loading] = useState(false);
  const [load_recent, setLoadingRecent] = useState(false);
  const [progress, setProgress] = useState(false);
  const [is_post, togglePostModal] = useState(false);
  const [isLogin, setLoginStatus] = useState(false);
  const [is_edit, toggleEditModal] = useState(false);
  const [edit_item, setEditItem] = useState(null);
  const [posts, setPosts] = useState([]);
  const [premium, setPremium] = useState([]);
  const [events, setEvents] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [since_id, setSince_id] = useState(0);
  const [next_id, setNextId] = useState(0);
  const [toggle, setToggle] = useState("public");
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [profile, setProfile] = useState(null);
  const [followings, setFollowings] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [popup_mentor_id, setPopupMentorId] = useState(0);
  const [toggle_popup, setTogglePopup] = useState(false);
  const [popup_reply_symbol, setPopupReplySymbol] = useState("");
  const [popup_reply_limit, setPopupReplyLimit] = useState(0);
  const [popup_symbol, setPopupSymbol] = useState("");
  const [popup_limit, setPopupLimit] = useState(0);
  const [toggle_content, setContent] = useState(false);
  const [toggle_messages, setToggleMessage] = useState(false);
  const [post_item, setPostItem] = useState(null);
  const [notif_loading, setLoadNotif] = useState(false);
  const [load_subspost, setLoadSubspost] = useState(false);
  const [is_subscribed, setIsSubscribed] = useState(false);
  const [folder_name, setFolderName] = useState(localStorage.getItem("folder_name"));
  const [folder_id, setFolderId] = useState(localStorage.getItem("folder_id"));
  const [ts, setTimestamp] = useState(localStorage.getItem("last_ts") || 0);
  const scroll = useRef();
  const [filters, setFilters] = useState({
    code: "",
    mentor_id: 0,
  });
  const [update, setUpdate] = useState(0);


  const load = () => {
    if (toggle === "public") {
      callAction(call_action, "folder_timeline", {
        endpoint: `/v1/folders/${folder_id}/posts`,
        scenario: "get",
        data: {
          since_id,
        },
      });
    }
    

    // if(since_id === 0) loading(true);
  };
  const loadProfile = () => {
    callAction(call_action, "me", {
      endpoint: "/v1/myself",
    });
    setProgress(true);
  };

  const handleTimeline = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    const { status, data, r, next_id } = payload;

    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    if (since_id === 0 && payload.data && payload.data.length === 0) setPosts([]);
    if (since_id === 0 && payload.data && payload.data.length > 0) setPosts(payload.data);
    else if (payload.data && payload.data.length > 0) setPosts(payload.data ? [...posts, ...payload.data] : posts);
    // initNotifs();


    setNextId(next_id);
    setUpdate(update + 1);

    return true;
    //setTimeout(() => loadProfile(), 1000);
  };
  
  const onProfileLoaded = (payload) => {
    // load();
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setIsMentor((payload.data && payload.data.mentor && payload.data.mentor.id > 0) ? true : false)
    setProfile(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setWatchlist(payload.data ? payload.data.watchlists : []);
    setFollowings(payload.data ? payload.data.followed_mentors : []);
    setSubscriptions(payload.data ? payload.data.mentorsubs : []);
    setIsSubscribed(payload.data ? payload.data.is_subscribed : false);
    setReady(true);

    return true;
  };

  useEffect(() => {

    setTimeout(() => {
      load_notif();
      setLoginStatus(localStorage.getItem("token") ? true : false);
      /*setIsMentor(
        parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
      );*/

      loadProfile();
      //load();
      // initNotifs();
      window.scrollTo(0, 0);
    }, 500);
    setFolderName(localStorage.getItem("folder_name"));
    setFolderId(localStorage.getItem("folder_id"));
  }, []);

  useEffect(() => {
    let ok = onProfileLoaded(call_action.me);
    if (ok) {
      setProgress(false);
    }
  }, [call_action.me]);

  useEffect(() => {
    let ok = handleTimeline(call_action.folder_timeline);

    if (ok) loading(false);
    if (!ready) setReady(true);

  }, [call_action.folder_timeline]);

  useEffect(() => {

    if (ready) {
      if (since_id === 0) load();
      else {
        setSince_id(0);
        setNextId(0);
        // scroll.current.pageLoaded = 0;
        // setReady(false);
      }

    }
  }, [toggle]);

  useEffect(() => {
    load();
  }, [since_id]);

  const handleNotifications = (payload) => {
    if (!payload) return;

    return true;
  }

console.log({folder_name})
  return (
    <>
      <Notifications {...props} onLoaded={() => {

      }} />
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {
          setFilters(input);
          setUpdate(update + 1);
        }}
      />
      <section id="content">
        <div className="content-wrap">
          {/* <div className="container">
            <div className="banner-ads">
              <img src={Images.BannerMain} alt="mainbanner" />
            </div>
          </div> */}

          <div className="container">
            <div className="row">
              <div className="col-md-3">
                {ready && <CardLeft
                  {...props}
                  is_mentor={is_mentor}
                  stats={stats}
                  watchlist={watchlist}
                  followings={followings}
                  subs={subscriptions}
                  onProjectClicked={(input) => {
                    const { symbol, limit } = input;
                    setPopupReplySymbol(symbol);
                    setPopupReplyLimit(limit);
                    setToggleMessage(true);
                  }}
                  onFolderClicked={(folder) => {
                    console.log({ folder });
                  }}
                  onSubsClicked={(input) => {
                    const { mentor_id, symbol, limit } = input;
                    setPopupMentorId(mentor_id || 0);
                    setPopupSymbol(symbol || "");
                    setTimestamp(localStorage.getItem("last_ts") || 0);
                    setTogglePopup(true);
                    setPopupLimit(limit);
                  }}
                  onWatchlist={() => {
                    loadProfile();
                  }}
                />}
              </div>

              <div className="col-md-6">

                {(ready && !toggle_content && toggle === "public") && (
                  <InfiniteScroll
                    ref={scroll}
                    dataLength={posts.length} //This is important field to render the next data
                    next={() => {
                      setSince_id(next_id);
                    }}
                    hasMore={since_id !== next_id ? true : false}

                    endMessage={``}
                    // below props only if you need pull down functionality
                    refreshFunction={() => {
                      setSince_id(0);
                    }}
                    pullDownToRefresh
                    pullDownToRefreshThreshold={50}
                    pullDownToRefreshContent={
                      <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                    }
                    releaseToRefreshContent={
                      <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                    }
                  >
                    <div className="widget-box folder">

                      <div className="widget-folder">
                        <h4>My Notes - {folder_name}</h4>
                        <a href="/">Return to Home</a>
                      </div>


                    </div>
                    <Timeline
                      toolbar={false}
                      progress={fetching}
                      isLogin={isLogin}
                      is_mentor={is_mentor}
                      can_post={false}
                      posts={posts}
                      premium={premium}
                      events={[]}
                      toggle={toggle}
                      onToggle={(input) => {
                        setToggle(input);
                      }}
                      callAction={callAction}
                      call_action={call_action}
                      onRefresh={() => {
                        loadProfile();
                      }}
                      onReload={() => {
                        setSince_id(0);

                      }}
                      on_toggle_modal={() => {
                        togglePostModal(true);
                      }}
                      onEdit={(item) => {
                        setEditItem(item);
                        toggleEditModal(true);
                      }}
                      onNext={() => {
                        setSince_id(next_id);
                      }}
                    />
                  </InfiniteScroll>

                )}
                
                {toggle_content && (
                  <TimelineItem
                    isLogin={isLogin}
                    item={post_item}
                    toggle={toggle}
                    onToggle={(input) => {
                      setToggle(input);
                    }}
                    callAction={callAction}
                    call_action={call_action}
                    onRefresh={() => {
                      loadProfile();
                    }}
                  />
                )}
              </div>
              {ready && <CardRight {...props} />}
            </div>
          </div>
        </div>
      </section>
      <ModalEvent />
      <ModalPost
        watchlist={watchlist}
        {...props}
        open={is_post}
        onClose={() => {
          togglePostModal(false);
        }}
        onFinish={() => {
          load();
          togglePostModal(false);
        }}
        is_mentor={is_mentor}
      />

      {(
        <ModalEdit
          watchlist={watchlist}
          {...props}
          data={edit_item}
          open={is_edit}
          is_mentor={is_mentor}
          onClose={() => {
            load();
            toggleEditModal(false);
          }}
          onFinish={() => {
            load();
            toggleEditModal(false);
          }}
        />
      )}

      <ModalHistory subscriptions={[]} {...props} />
      {toggle_popup && (
        <PopupNewPosts
          mentor_id={popup_mentor_id}
          symbol={popup_symbol}
          ts={ts}
          limit={popup_limit || 10}
          fetching={load_recent}
          {...props}
        />
      )}
      {toggle_messages && (
        <PopupNewComments
          symbol={popup_reply_symbol}
          ts={ts}
          limit={popup_reply_limit}
          fetching={false}
          {...props}
        />
      )}
    </>
  );
};

export default Folder;
