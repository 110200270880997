/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Timeline from "../../parts/Timeline";
import TimelineItem from "../../parts/TimelineItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from "react-loading";
import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalEdit,
  ModalPost,
  CardLeft,
  CardRight,
} from "../../parts";
import { load_notif } from "../../libs/helper";
import PopupNewPosts from "../../parts/PopupNewPosts";
import moment from "moment-timezone";
import { setupNotification } from "../../libs/helper";
import PopupNewComments from "../../parts/PopupNewComments";
import Notifications from "../../parts/Notifications";


const HomeDiscussion = (props) => {
  const { callAction, call_action } = props;
  const location = useLocation()
  const [ready, setReady] = useState(false);
  const [fetching, loading] = useState(false);
  const [load_recent, setLoadingRecent] = useState(false);
  const [progress, setProgress] = useState(false);
  const [is_post, togglePostModal] = useState(false);
  const [isLogin, setLoginStatus] = useState(false);
  const [is_edit, toggleEditModal] = useState(false);
  const [edit_item, setEditItem] = useState(null);
  const [posts, setPosts] = useState([]);
  const [premium, setPremium] = useState([]);
  const [events, setEvents] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [since_id, setSince_id] = useState(0);
  const [next_id, setNextId] = useState(0);
  // const [toggle, setToggle] = useState(location.pathname == '/inbox' ? localStorage.setItem('toggle','Inbox') : localStorage.getItem('toggle'));
  const [toggle, setToggle] = useState('Latest');
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [profile, setProfile] = useState(null);
  const [followings, setFollowings] = useState([]);
  const [allSaham, setAllSaham] = useState([]);
  const [allSectors, setAllSectors] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [popup_mentor_id, setPopupMentorId] = useState(0);
  const [toggle_popup, setTogglePopup] = useState(false);
  const [popup_reply_symbol, setPopupReplySymbol] = useState("");
  const [popup_reply_limit, setPopupReplyLimit] = useState(0);
  const [popup_symbol, setPopupSymbol] = useState("");
  const [popup_limit, setPopupLimit] = useState(0);
  const [toggle_content, setContent] = useState(false);
  const [toggle_messages, setToggleMessage] = useState(false);
  const [post_item, setPostItem] = useState(null);
  const [notif_loading, setLoadNotif] = useState(false);
  const [load_subspost, setLoadSubspost] = useState(false);
  const [sort, setSort] = useState('desc');
  const [mentor_ids, setMentorIds] = useState(null);
  const [is_subscribed, setIsSubscribed] = useState(false);
  const [ts, setTimestamp] = useState(localStorage.getItem("last_ts") || 0);
  const scroll = useRef();
  const [filters, setFilters] = useState({
    code: "",
    mentor_id: 0,
  });
  const [update, setUpdate] = useState(0);
  const [filterTanya, setFilterTanya] = useState(false)
  const [filterLatest, setFilterLatest] = useState(false)
  const [triggerFilter, setTriggerFilter] = useState(false)
  const [selSaham, setSelSaham] = useState(null)
  const [selSectors, setSelSectors] = useState(null)
  const [selMentor, setSelMentor] = useState(null)
  const [selCategories, setSelCategories] = useState(null)
  const [account_id, setAccountId] = useState("")
 
  const onUserQuestions = (id) => {
    setAccountId(id)
    setFilterTanya(true)
    setSince_id(0)
  }
 
  const applyFilterTimeline = (filters ,section, sorting) => {
    if(section == "Latest" ){
      let { sahamCheck, broCheck, catCheck } = filters
      setSelSaham(sahamCheck)
      setSelMentor(broCheck)
      setSelCategories(catCheck)
      setFilterLatest(true)
    }else if(section == "Tanya" ){
      if( filters.pertanyaanSaya == filters.kodeSaham ){
        localStorage.setItem('toggle',"Tanya")
        setFilterTanya(false)
        setSince_id(0)
      }else if( filters.pertanyaanSaya ){
        setFilterTanya(true)
        setAccountId(profile.account_id)
        setSince_id(0)
        setTriggerFilter(!triggerFilter)
      }else if( filters.kodeSaham ){
        localStorage.setItem('toggle',"TanyaSaham")
        setTriggerFilter(!triggerFilter)
        setSince_id(0)
      }
    }else if(section == "Discussion" ){
      let { sahamCheck, sectorCheck } = filters
      setSelSaham(sahamCheck)
      setSelSectors(sectorCheck)
      setFilterTanya(true)
      setTriggerFilter(!triggerFilter)
    }
    setSort(sorting)
  }

  const load = () => {
    // load total unread inbox msg
    callAction(call_action, "inbox_count", {
      endpoint: "/v2/inbox",
      scenario: "get",
      data: {
        since_id,
        sort,
        mentor_ids,
        account_tanya: account_id
      },
    });
    // load all sectors
    callAction(call_action, "sectors", {
      endpoint: "/v1/sectors",
      scenario: "get",
      data: {
        since_id,
        sort,
        mentor_ids,
      },
    });
    if (localStorage.getItem('toggle') === "TanyaSaham") {
        callAction(call_action, "timelineDiscussion", {
          endpoint: "/v1/symbol/Emiten/timeline",
          scenario: "get",
          data: {
            since_id,
            sort,
            mentor_ids,
          },
        });
    }
    if (localStorage.getItem('toggle') === "Latest") {
      callAction(call_action, "timelineDiscussion", {
        endpoint: "/v1/recent",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids: selMentor,
          emitens: selSaham,
          categories: selCategories,
        },
      });
    }
    if (localStorage.getItem('toggle') === "Discussion") {
      if(filterTanya) {
        callAction(call_action, "timelineDiscussion", {
        endpoint: "/v1/symbol/Discussion/timeline",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids,
          emiten_code : selSaham,
          sector_id : selSectors,
        },
      });
      } else {
        callAction(call_action, "timelineDiscussion", {
          endpoint: "/v1/symbol/Discussion/timeline",
          scenario: "get",
          data: {
            since_id,
            sort,
            mentor_ids
          },
        });
      }
      // callAction(call_action, "timelineDiscussion", {
      //   endpoint: "/v1/symbol/Discussion/timeline",
      //   scenario: "get",
        // data: {
        //   since_id,
        //   sort,
        //   mentor_ids
        // },
      // });
    }
    if (localStorage.getItem('toggle') === "Inbox") {
        callAction(call_action, "timelineDiscussion", {
        endpoint: "/v2/inbox",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids,
          account_tanya: account_id
        },
      });
    }
    
    if (localStorage.getItem('toggle') === "Tanya") {
      if(filterTanya) {
        callAction(call_action, "timelineDiscussion", {
        endpoint: "/v1/symbol/my-qna/timeline",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids,
          account_tanya: account_id
        },
      });
      } else {
        callAction(call_action, "timelineDiscussion", {
          endpoint: "/v1/symbol/Tanya/timeline",
          scenario: "get",
          data: {
            since_id,
            sort,
            mentor_ids
          },
        });
      }
    }
    if (localStorage.getItem('toggle') === "my-qna") {
      if(filterTanya) {
        callAction(call_action, "timelineDiscussion", {
        endpoint: "/v1/symbol/my-qna/timeline",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids,
          account_tanya: account_id
        },
      });
      } else {
        callAction(call_action, "timelineDiscussion", {
          endpoint: "/v1/symbol/my-qna/timeline",
          scenario: "get",
          data: {
            since_id,
            sort,
            mentor_ids,
          },
        });
      }
    }

     if (localStorage.getItem('toggle') === "Event") {
      callAction(call_action, "timelineDiscussion", {
        endpoint: "/v1/symbol/Event/timeline",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids
        },
      });
    }

     if (localStorage.getItem('toggle') === "Admin") {
      callAction(call_action, "timelineDiscussion", {
        endpoint: "/v1/symbol/Admin/timeline",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids
        },
      });
    }

     if (localStorage.getItem('toggle') === "Emiten") {
      callAction(call_action, "timelineDiscussion", {
        endpoint: "/v1/symbol/Emiten/timeline",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids
        },
      });
    }

   // if(since_id === 0) loading(true);
  };
  
  const loadMentors = () => {
    callAction(call_action, "load_mentors", {
      endpoint: "/v1/mentors",
    });
    setProgress(true);
  };
  
  const loadListSaham = () => {
    callAction(call_action, "load_saham", {
      endpoint: "/v1/emiten",
    });
    setProgress(true);
  };

  const loadProfile = () => {
    callAction(call_action, "me", {
      endpoint: "/v1/myself",
    });
    setProgress(true);
  };

  const handleTimeline = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
  
    const { status, data, r,next_id } = payload;
    
    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    if(since_id === 0 && payload.data && payload.data.length === 0) {
      setPosts([])

    };
    if(since_id === 0 && payload.data && payload.data.length > 0) {
      setPosts(payload.data);
      localStorage.removeItem("prevPosts");
      if(payload.data.length > 0){
        localStorage.setItem("prevPosts", payload.data[0].title);
      }
    }
    else if(payload.data && payload.data.length > 0) {
      setPosts(payload.data ? [...posts,...payload.data] : posts);
      localStorage.removeItem("prevPosts");
      if(payload.data.length > 0){
        localStorage.setItem("prevPosts", payload.data ? [...posts,...payload.data[0].title] : posts[0].title);
      }
    }
   // initNotifs();

   
    setNextId(next_id);
    setUpdate(update + 1);
   
    return true;
    //setTimeout(() => loadProfile(), 1000);
  };

  const handlePremium = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    const { status, data, r } = payload;
    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    setPremium(payload.data ? payload.data : []);
   // initNotifs();
    setUpdate(update + 1);
    //setTimeout(() => loadProfile(), 1000);
  };
  const onProfileLoaded = (payload) => {
   // load();
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setIsMentor((payload.data && payload.data.mentor && payload.data.mentor.id > 0) ? true : false)
    setProfile(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setWatchlist(payload.data ? payload.data.watchlists : []);
    setFollowings(payload.data ? payload.data.followed_mentors : []);
    setSubscriptions(payload.data ? payload.data.mentorsubs : []);
    setIsSubscribed(payload.data ? payload.data.is_subscribed : false);
    setAccountId(payload.data.profile.account_id)
    // setReady(true);
   
    return true;
  };

  // useEffect(() => {
  //   if(location.pathname == '/inbox'){
  //     setToggle("Inbox")
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    setPosts([])
    localStorage.removeItem("prevPosts")
    localStorage.removeItem("toggle")
    
    if( location.pathname == '/inbox' ){
      localStorage.setItem('toggle','Inbox')
      setToggle('Inbox')
    } else if( location.pathname == '/' ){
      localStorage.setItem('toggle','Latest')
      setToggle('Latest')
    } else if( location.pathname == '/tanya' ){
      localStorage.setItem('toggle','Tanya')
      setToggle('Tanya')
    } else if( location.pathname == '/discussion' ){
      localStorage.setItem('toggle','Discussion')
      setToggle('Tanya')
    } else if( !localStorage.getItem('toggle') ){
      localStorage.setItem('toggle','Latest')
    } 
    // if( location.pathname = '/inbox' ){
    // }
    setTimeout(() => {
      load_notif();
      setLoginStatus(localStorage.getItem("token") ? true : false);
      /*setIsMentor(
        parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
      );*/
    
      loadProfile();
      //load();
     // initNotifs();
      window.scrollTo(0, 0);
    }, 500);
    loadMentors()
    loadListSaham()
  }, []);
  
  useEffect(() => {
    if( call_action.me ){
      let ok = onProfileLoaded(call_action.me);
      if(ok){
         setProgress(false);
      }
    }
  }, [call_action.me]);

  useEffect(() => {
    if( call_action.timelineDiscussion) {
      // if( call_action.timelineDiscussion.data.length > 0 ){
        // if( (call_action.timelineDiscussion.data[0].title !== localStorage.getItem("prevPosts")) ){
          // && localStorage.getItem("toggle") != "Inbox"
          let ok = handleTimeline(call_action.timelineDiscussion);
       
          if(ok) loading(false);
          if(!ready) setReady(true);
        // }
      // }else{
        // let ok = handleTimeline(call_action.timelineDiscussion);
     
        // if(ok) loading(false);
        // if(!ready) setReady(true);
      // }
    }else if(since_id != 0){
      let ok = handleTimeline(call_action.timelineDiscussion);
       
      if(ok) loading(false);
      if(!ready) setReady(true);
    }
  }, [call_action.timelineDiscussion]);

  useEffect(() => {
    // let ok = handleTimeline(call_action.timelineDiscussion);
    // localStorage.setItem('toggle', toggle)
    setReady(false)
    // if( location.pathname == '/inbox' ){
    //   setPosts([])
    //   localStorage.setItem('toggle','Inbox')
    //   setToggle('Inbox')
    // } else if( location.pathname == '/' && localStorage.getItem('toggle') == "Inbox" ){
    //   localStorage.setItem('toggle','Latest')
    //   setToggle('Latest')
    // } else if( !localStorage.getItem('toggle') ){
    //   localStorage.setItem('toggle','Latest')
    // } else if( location.pathname == '/tanya' ){
    //   localStorage.removeItem("prevPosts")
    //   setPosts([])
    //   localStorage.setItem('toggle','Tanya')
    //   setToggle('Tanya')
    // } 
    
    if(ready) {
      setPosts([])
      if(since_id === 0) load();
      else{
        setSince_id(0);
        setNextId(0);
       // scroll.current.pageLoaded = 0;
       // setReady(false);
      }
    }

    // if(ok) loading(false);
    // if(!ready) setReady(true);
   
  }, [localStorage.getItem('toggle'), triggerFilter]);

  // useEffect(() => {
  //   if(ready) {
  //     if(since_id === 0) load();
  //     else{
  //       setSince_id(0);
  //       setNextId(0);
  //      // scroll.current.pageLoaded = 0;
  //      // setReady(false);
  //     }
  //   }
  // }, [toggle]);

  useEffect(() => {
    if (fetching && call_action.premium ) {
      handlePremium(call_action.premium);
      loading(false);
    }
  }, [call_action.premium]);

  useEffect(() => {
    if (!fetching && call_action.load_mentors) {
      setMentors(call_action.load_mentors.data);
      loading(false);
    }
  }, [call_action.load_mentors]);

  useEffect(() => {
    if (!fetching && call_action.load_saham) {
      setAllSaham(call_action.load_saham.data);
      loading(false);
    }
  }, [call_action.load_saham]);

  useEffect(() => {
    if (!fetching && call_action.sectors) {
      setAllSectors(call_action.sectors.sector_list);
      loading(false);
    }
  }, [call_action.sectors]);

  useEffect(()=>{
    setSince_id(0);
    load();
  },[sort]);

  useEffect(()=>{
    if( mentor_ids ){
      setSince_id(0);
      load();
    }
  },[mentor_ids]);
  
  useEffect(()=>{
    // setReady(false)
    if(since_id){
      load();
    }
  },[since_id]);

  useEffect(() =>{
    setReady(false)
    setPosts([])
    load()
  },[account_id, filterTanya])

  useEffect(() =>{
    if(filterLatest){
      setReady(false)
      setPosts([])
      load()
      setFilterLatest(false)
    }
  },[filterLatest])

  const handleNotifications = (payload) => {
    if(!payload) return;

    return true;
  }

  return (
    <>
      <Notifications {...props} onLoaded={()=>{
      
      }}/>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {
          setFilters(input);
          setUpdate(update + 1);
        }}
      />
      <section id="content">
        <div className="content-wrap">
          {/* <div className="container">
            <div className="banner-ads">
              <img src={Images.BannerMain} alt="mainbanner" />
            </div>
          </div> */}

          <div className="container">
            <div className="row">
              <div className="col-md-3 p-0">
                {ready && <CardLeft
                  {...props}
                  withFilter={true}
                  is_mentor={is_mentor}
                  stats={stats}
                  watchlist={watchlist}
                  followings={followings}
                  subs={subscriptions}
                  onProjectClicked={(input) => {
                    const { symbol, limit } = input;
                    setPopupReplySymbol(symbol);
                    setPopupReplyLimit(limit);
                    setToggleMessage(true);
                  }}
                  onFolderClicked={(folder)=>{
                    localStorage.setItem("folder_id",folder.id);
                    localStorage.setItem("folder_name",folder.name);
                    document.location="/my-notes";
                  }}
                  onFilter={(filter)=>{
                  
                    const {sort, mentor_ids} = filter;
                    setSort(sort);
                    if(mentor_ids.length > 0) setMentorIds(mentor_ids.join(","));
                    else setMentorIds(null);
                  
                  }}
                  onSubsClicked={(input) => {
                    const { mentor_id, symbol, limit } = input;
                    setPopupMentorId(mentor_id || 0);
                    setPopupSymbol(symbol || "");
                    setTimestamp(localStorage.getItem("last_ts") || 0);
                    setTogglePopup(true);
                    setPopupLimit(limit);
                  }}
                  onWatchlist={() => {
                    loadProfile();
                  }}
                />}
              </div>
              <div className="col-md-9">
                {(ready && !toggle_content && localStorage.getItem('toggle') === "Discussion" && localStorage.getItem('toggle') !== "Inbox") && (
                  <InfiniteScroll
                      style={{'overflow': 'none !important'}}
                      ref={scroll}
                      dataLength={posts.length} //This is important field to render the next data
                      next={()=>{
                        setSince_id(next_id);
                      }}
                      hasMore={since_id !== next_id ? true : false}
                     
                      endMessage={``}
                      // below props only if you need pull down functionality
                      refreshFunction={()=>{
                        setSince_id(0);
                      }}
                      // pullDownToRefresh
                      // pullDownToRefreshThreshold={50}
                      // pullDownToRefreshContent={
                      //   <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
                      // }
                      // releaseToRefreshContent={
                      //   <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
                      // }
                    >
                      <Timeline
                        toolbar={true}
                        progress={fetching}
                        isLogin={isLogin}
                        ready={ready}
                        allSaham={allSaham}
                        allSectors={allSectors}
                        mentors={mentors}
                        applyFilterTimeline={applyFilterTimeline}
                        is_mentor={is_mentor}
                        can_post={false}
                        posts={posts}
                        premium={premium}
                        events={[]}
                        toggle={toggle}
                        onToggle={(input) => {
                          setToggle(input);
                          setFilterTanya(false);
                          setAccountId("");
                          setSince_id(0);
                        }}
                        callAction={callAction}
                        call_action={call_action}
                        onReload={()=>{
                          setSince_id(0);
                          setFilterTanya(false);
                          setAccountId("");
                        }}
                        onRefresh={() => {
                          loadProfile();
                        }}
                        on_toggle_modal={()=>{
                          togglePostModal(true);
                        }}
                          onEdit={(item)=>{
                          setEditItem(item);
                          toggleEditModal(true);
                        }}
                        onNext={()=>{
                          setSince_id(next_id);
                        }}
                        onUserQuestions={onUserQuestions}
                      />
                    </InfiniteScroll>
                  
                )}
                {toggle_content && (
                  <TimelineItem
                    isLogin={isLogin}
                    item={post_item}
                    toggle={toggle}
                    onToggle={(input) => {
                      setToggle(input);
                      setFilterTanya(false);
                      setAccountId("");
                      setSince_id(0);
                    }}
                    callAction={callAction}
                    call_action={call_action}
                    onRefresh={() => {
                      loadProfile();
                    }}
                  />
                )}
                { !ready && (
                  <div style={{ textAlign: "center", margin: 300 }}>
                    <ReactLoading type={"bubbles"} color="#85909ecc" height={80} width={80}/>
                  </div>
                )}
              </div>
             {/* {ready && <CardRight {...props}/>} */}
            </div>
          </div>
        </div>
      </section>
      <ModalEvent />
     <ModalPost
        watchlist={watchlist}
        {...props}
        toggle={toggle}
        open={is_post}
        onClose={()=>{
          togglePostModal(false);
        }}
        onFinish={() => {
          load();
           togglePostModal(false);
        }}
        is_mentor={is_mentor}
      />
      
      {(
         <ModalEdit
        watchlist={watchlist}
        {...props}
        data={edit_item}
        open={is_edit}
        is_mentor={is_mentor}
        onClose={()=>{
          load();
          toggleEditModal(false);
        }}
        onFinish={() => {
          load();
           toggleEditModal(false);
        }}
      />
      )}

      <ModalHistory subscriptions={[]} {...props} />
      {toggle_popup && (
        <PopupNewPosts
          mentor_id={popup_mentor_id}
          symbol={popup_symbol}
          ts={ts}
          limit={popup_limit || 10}
          fetching={load_recent}
          {...props}
        />
      )}
      {toggle_messages && (
        <PopupNewComments
          symbol={popup_reply_symbol}
          ts={ts}
          limit={popup_reply_limit}
          fetching={false}
          {...props}
        />
      )}
    </>
  );
};

export default HomeDiscussion;
