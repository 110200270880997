import React, { useState } from "react";
import Loader from "react-loader-spinner";

import Api from "../Services/Api";
const api = new Api();

export default function LabelAndFilePicker(props) {
  const { label, onChange, value, action, endpoint, filetypes, hint } = props;
  const [progress, setProgress] = useState(false);
  const [fileurl, setFileUrl] = useState("");
  const [file, setFile] = useState(null);

  const [uploadedFiles, setUploadedFiles] = useState(null);

  return (
    <div className={`form-control`}>
      <div style={{padding:10,background:'#e5e5e5'}}>
            <h5 style={{margin:0,marginBottom:5}}>{label}</h5>
            {progress ? (
                 <div style={{ textAlign: "center" }}>
                 <Loader
                   type="Puff"
                   color="#00BFFF"
                   height={100}
                   width={100}
                 />
               </div>
            ) : null}
            {file!==null ? (
                <div><a href={fileurl}>{file.name}</a></div>
            ) : null}
             {file === null && typeof value !== 'undefined'  ? (
              <div>
               <div>{value.length > 0 ? <a href={value}>{"Download File"}</a> : null}</div>
              </div>
            ) : null}
            <input
              type="file"
              name="file"
              accept={filetypes ? filetypes : `application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
              text/plain, application/pdf, image/*,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow`}
              onChange={event => {
               
                setFile(event.target.files[0]);
                setProgress(true);
                api
                  .upload(
                    "/media/upload",
                    {
                      file: event.target.files[0]
                    },
                    true,
                    endpoint
                  )
                  .then(response => {
                    setProgress(false);
                    setFileUrl(response.file_url);
                    onChange(response.file_url);
                  })
                  .catch(err => {
                    setProgress(false);
                    console.log(err.message);
                  });
              }}
            />
            {hint ? <p>{hint}</p> : <p>*) Maximum 2MB allowed</p>}
          </div>
    </div>
  );
}
