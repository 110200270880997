import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../Assets";

const ModalHistory = (props) => {
  const { subscriptions } = props;
  return (
    <div
      className="modal fade modal-history-subscription"
      id="modalHistorySubscription"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ maxWidth: "620px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4>History Subscriptions</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <img src={Images.icClose} alt="close" />
            </button>
          </div>
          <div className="modal-body">
            <div className="main-modal-history has-scrollbar">
              <div className="top-modal-history">
                <span>Status (combo)</span>
                <span>90 hari terakhir (combo)</span>
              </div>
              <div className="table-responsive">
                <table className="table table-history-subscription">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Product</th>
                      <th>Expiring</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriptions.map((d, index) => (
                      <tr key={index}>
                        <td className="text-success">
                          <strong>{d.id}</strong>
                        </td>
                        <td>{d.date}</td>
                        <td>ITMG-Thowilz Rp {d.price}</td>
                        <td>7 days</td>
                        <td>
                          <Link to="#" className="button">
                            Renew
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalHistory;
