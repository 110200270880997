import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { ModalFollowers, ModalSubscribers } from './';
import moment from 'moment';
import { Alert } from 'react-bootstrap';

export default function (props) {
  const {
    is_subscribed,
    expired,
    expiring,
    mentorsubs,
    followers,
    subscribers,
    is_mentor,
    call_action,
    callAction
  } = props;
  const [toggle_subscriptions, setToggleSubscriptions] = useState(false);
  const [toggle_followers, setToggleFollowers] = useState(false);
  const [alert, setAlert] = useState(false);
  const [mentor_id, setMentorId] = useState(0);
  const [username, setUsername] = useState('');
  const oneYearFromNow = moment().subtract(1, 'days').add(10, 'years').unix();
  useEffect(() => {
    setMentorId(localStorage.getItem('mentor_id') || 0);
    setUsername(localStorage.getItem('username'));
  }, [is_mentor]);

  return (
    <>
      <div className="widget-box mentor-status-widget">
        {!is_subscribed && (
          <Link to="/subscribe" className="button btn-block nomargin">
            SUBSCRIBE
          </Link>
        )}

        {is_subscribed && (
          <>
            {expired < oneYearFromNow ? (
              <Link to="/subscribe" className="button btn-block m-0 mb-3">
                EXTEND
              </Link>
            ) : (
              <div
                className="button btn-block m-0 mb-3"
                onClick={() => setAlert(true)}
              >
                EXTEND
              </div>
            )}
          </>
        )}

        {alert && (
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            <Alert variant="danger" onClose={() => setAlert(false)} dismissible>
              Anda hanya bisa extend subscription sekali dalam setahun
            </Alert>
          </div>
        )}
        {is_subscribed && (
          <div className="widget-header">
            <h4>You have</h4>
            {expiring > 0 && (
              <a
                href="#modalHistorySubscription"
                className="text-danger"
                data-toggle="modal"
              >
                <strong>Renew</strong>
              </a>
            )}
          </div>
        )}
        {is_subscribed && (
          <div className="widget-body">
            <ul className="mentor-status-list">
              {expiring > 0 && (
                <li className="text-danger">
                  <strong>{expiring}</strong> expiring subscription
                </li>
              )}
              {expired && (
                <li>
                  Active subscriptions until{' '}
                  <span className="text-primary">
                    <strong>
                      {moment(expired * 1000).format('DD/MM/YYYY')}
                    </strong>
                  </span>{' '}
                </li>
              )}
              {mentorsubs > 0 && (
                <li>
                  <span className="text-primary">
                    <strong>{mentorsubs}</strong>
                  </span>{' '}
                  mentor services
                </li>
              )}
              {is_mentor && (
                <li>
                  <span
                    className="text-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToggleFollowers(true);
                    }}
                  >
                    <strong>{followers}</strong>
                  </span>{' '}
                  followers
                </li>
              )}
              {is_mentor && (
                <li>
                  <span
                    className="text-primary"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setToggleSubscriptions(true);
                    }}
                  >
                    <strong>{subscribers}</strong>
                  </span>{' '}
                  subscribers
                </li>
              )}
            </ul>
            {/*}
            {!is_mentor && (
              <Link to="/become-mentor" className="button btn-block nomargin">
                Become a Mentor
              </Link>
            )}{*/}
          </div>
        )}
      </div>

      <ModalFollowers
        {...props}
        open={toggle_followers}
        username={username}
        mentor_id={mentor_id}
        onClose={() => {
          setToggleFollowers(false);
        }}
      />

      <ModalSubscribers
        {...props}
        open={toggle_subscriptions}
        username={username}
        mentor_id={mentor_id}
        onClose={() => {
          setToggleSubscriptions(false);
        }}
      />
    </>
  );
}
