import { useState } from "react";
import { Header } from "../../parts";
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { isEmptyString, isMobileValid, isSameString, isStringLength } from "../../libs/validator";

export const GoogleProfileForm = (props) => {
    const navigate = useHistory();
    const location = useLocation();
    const API_URL = process.env.REACT_APP_API_URI;
    const [token, setToken] = useState(null)
    const [progress, loading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [submitError, setSubmitError] = useState(false);
    const [error_message, setError_message] = useState("");
    const [snackbar_text, setSnackbarText] = useState("");
    const [user, setUser] = useState({
        username: "",
        name: "",
        email: "",
        phone: "",
    })
    
    useEffect(() => {
        const userState = location.state.user

        if (userState != null) {
            setUser({
                ...user, name: userState.name, email: userState.email
            })
        } else {
            setUser({})
            setToken(null)
        }
    }, [])

    
    async function handleRegister() {
        // Conditional Things
        loading(true)

        let isError = false;

        if (!isStringLength(user.phone, 10, 16) || !isMobileValid(user.phone)) {
            setError_message("Invalid phone number, please input valid phone number");
            setSubmitError(true)
            isError = true;
        }

        if (isError) {
            loading(false);
            setTimeout(() => {
                setSubmitError(false)
            }, 5000)
            return;
        } 

        try {
            const response = await fetch(new URL("/register", API_URL), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({...user, method: "google"})
            })
    
            if (response.status === 200) {
                //Redirect to some endpoint
                const responseUser = await response.json();

                localStorage.setItem("token", responseUser.access_token);
                localStorage.setItem("user_id", responseUser.user.id);
                localStorage.setItem("name", responseUser.user.name);
                localStorage.setItem("username", responseUser.user.username);
                localStorage.setItem("is_profile", responseUser.is_profile);
                localStorage.setItem("is_mentor", responseUser.is_mentor);
                localStorage.setItem("mentor_id", responseUser.mentor_id);
                localStorage.setItem("avatar", responseUser.avatar_url);

                if (typeof responseUser.roles === "string") {
                    localStorage.setItem("roles", responseUser.roles);
                } else {
                    localStorage.setItem("roles", JSON.stringify(responseUser.roles));
                }

                setTimeout(()=>{
                    document.location = "/"
                }, 1000);
    
                return;
            } 
    
            //Error case 
            else {
                const error = await response.json();
                setSubmitError(true)
                setError_message(error.error)
                loading(false);
                setTimeout(() => {
                    setSubmitError(false)
                }, 5000)
            } 
            
        } catch (error) {
            setSubmitError(true)
            setError_message(error.error);
            loading(false);
                setTimeout(() => {
                    setSubmitError(false)
                }, 5000)
        }
    }

    return (
        <>
        <Header isLogin={false} {...props} />
            <section id="content">
                <div className="content-wrap">
                    <div className="container">
                        <div className="row justify-content-center pt-5 mt-3 pb-4">
                            <div className="col-md-7">
                                <div className="widget-box widget-panel main-account-content account-history">
                                    <div className="widget-body">
                                        <form className="form-member-profile">
                                            <div class="alert alert-primary d-flex align-items-center" role="alert">
                                                    <i className="icon-info"></i>
                                                <div>
                                                    Please fill in your profile info to continue.
                                                </div>
                                            </div> 
                                            {
                                                submitError ? 
                                                    <div class="alert alert-danger d-flex align-items-center mb-2" role="alert">
                                                            <i className="icon-info"></i>
                                                        <div>
                                                            {error_message}
                                                        </div>
                                                    </div> 
                                                : null
                                            }
                                            <div className="form-group">
                                                <label className="control-label">Username</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name=""
                                                    onChange={(e) => {
                                                        setUser({
                                                            ...user, username: e.target.value
                                                        })
                                                    }}
                                                    defaultValue={user?.username ?? ""}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name=""
                                                    onChange={(e) => {
                                                        setUser({
                                                            ...user, name: e.target.value
                                                        })
                                                    }}
                                                    defaultValue={user?.name ?? ""}
                                                    disabled={Object.keys(user).length !== 0 ? true : false}
                                                />
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="control-label">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        name=""
                                                        required
                                                        onChange={(e) => {
                                                            setUser({
                                                                ...user, email: e.target.value
                                                            })
                                                        }}
                                                        defaultValue={user?.email ?? ""}
                                                        disabled={Object.keys(user).length !== 0 ? true : false}
                                                        />
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="control-label">Mobile</label>
                                                    <input
                                                    type="text"
                                                    className="form-control"
                                                    name=""
                                                    required
                                                    onChange={(e) => {
                                                        setUser({
                                                            ...user, phone: e.target.value
                                                        })
                                                    }}
                                                    defaultValue={user?.phone ?? ""}
                                                    />
                                                </div>
                                            </div>
                                            {progress && (
                                            <div style={{ textAlign: "center" }}>
                                                <Loader type="Puff" color="#00BFFF" height={100} width={100} />
                                            </div>
                                            )}
                                            {!progress && (
                                                <button
                                                    type="submit"
                                                    className="button button-submit w-100 mx-auto"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleRegister();
                                                    }}
                                                >
                                                    SAVE
                                                </button>
                                            )}
                                            {snackbar && (
                                                <SnackBar
                                                    show={snackbar}
                                                text={snackbar_text}
                                                onClose={() => {
                                                    setSnackbar(false);
                                                }}
                                            />
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};