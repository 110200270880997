import React, { useState, useEffect } from "react";
import withSession from "./withSession";
import Home from "./pages/Home";
import HomeTanya from "./pages/HomeTanya";
import HomeWatchlist from "./pages/HomeWatchlist";
import HomeDiscussion from "./pages/HomeDiscussion";
import HomeThesis from "./pages/HomeThesis";
import HomeVideo from "./pages/HomeVideo";
import Inbox from "./pages/Inbox";
import Folder from "./pages/Folder";
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword'
import AccountMentorHistory from './pages/AccountMentorHistory'
import MentorSubscriptionStatus from './pages/MentorSubscriptionStatus'
import MentorAccountMember from './pages/MentorAccountMember'
import InviteMember from './pages/InviteMember'
import MentorAccountMentor from './pages/MentorAccountMentor'
import MentorAccountPassword from './pages/MentorAccountPassword'
import MentorAccountPrivacy from './pages/MentorAccountPrivacy'
import MentorAccountReferralInfo from './pages/MentorAccountReferralInfo'
import AccountPrivacy from './pages/AccountPrivacy'
import Terms from './pages/Terms'
import Contact from './pages/Contact'
import MentorTerms from './pages/MentorTerms'
import MentorAccountSetPrice from './pages/MentorAccountSetPrice'
import MentorMentor from './pages/MentorMentor'
import Saham from './pages/Saham'
import MentorLending from "./pages/MentorLending";
import Landing from "./pages/Landing";
import Logout from "./pages/Logout";
import Checkout from "./pages/Checkout";
import Purchase from "./pages/Purchase";
import Article from "./pages/Article";
import Activation from "./pages/Activation";
import CheckoutMobile from "./pages/CheckoutMobile";
import Thankyou from "./pages/Thankyou";
import Subscribe from "./pages/Subscribe";
import { GoogleProfileForm } from "./pages/GoogleProfileForm/GoogleProfileForm";

const domain = process.env.REACT_APP_DOMAIN;
let host = window.location.host;
let subdomains = host.split(".");

let routes = [];

routes = [
  {
    path: "/",
    exact: true,
    component: withSession(Home),
  },
  {
    path: "/tanya",
    exact: true,
    component: withSession(HomeTanya),
  },
  {
    path: "/watchlist",
    exact: true,
    component: withSession(HomeWatchlist),
  },
  // {
  //   path: "/discussion",
  //   exact: true,
  //   component: withSession(HomeDiscussion),
  // },
  {
    path: "/video",
    exact: true,
    component: withSession(HomeVideo),
  },
  {
    path: "/thesis",
    exact: true,
    component: withSession(HomeThesis),
  },
  {
    path: "/inbox",
    exact: true,
    component: withSession(Inbox),
  },
  {
    path: "/logout",
    exact: true,
    component: withSession(Logout),
  },
  {
    path: "/landing",
    exact: true,
    component: withSession(Landing),
  },
  {
    path: "/my-notes",
    exact: true,
    component: withSession(Folder),
  },
  {
    path: "/callback",
    exact: true,
    component: (Thankyou),
  },
  {
    path: "/checkout-mobile",
    exact: true,
    component: (CheckoutMobile),
  },
  {
    path: "/account-history",
    exact: true,
    component: withSession(AccountMentorHistory),
  },
  {
    path: "/profile",
    exact: true,
    component: withSession(MentorAccountMember),
  },
  {
    path: "/invite-friend",
    exact: true,
    component: withSession(InviteMember),
  },
  {
    path: "/become-mentor",
    exact: true,
    component: withSession(MentorAccountMentor),
  },
  {
    path: "/set-price",
    exact: true,
    component: withSession(MentorAccountSetPrice),
  },
  {
    path: "/referral-info",
    exact: true,
    component: withSession(MentorAccountReferralInfo),
  },
  {
    path: "/transactions",
    exact: true,
    component: withSession(AccountMentorHistory),
  },
  {
    path: "/subscribers",
    exact: true,
    component: withSession(MentorSubscriptionStatus),
  },
  {
    path: "/contact",
    exact: true,
    component: Contact,
  },
  {
    path: "/privacyPolicy",
    exact: true,
    component: AccountPrivacy,
  },
  {
    path: "/termsOfUse",
    exact: true,
    component: Terms,
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: withSession(MentorAccountPrivacy),
  },
  {
    path: "/terms-of-use",
    exact: true,
    component: withSession(MentorTerms),
  },
  {
    path: "/mentor",
    exact: true,
    component: withSession(MentorMentor),
  },
  {
    path: "/mentor/:username",
    exact: true,
    component: withSession(MentorMentor),
  },
  {
    path: "/posts/:id",
    exact: true,
    component: withSession(Article),
  },
  {
    path: "/checkout",
    exact: true,
    component: withSession(Checkout),
  },
  {
    path: "/purchase",
    exact: true,
    component: withSession(Purchase),
  },
  {
    path: "/password",
    exact: true,
    component: withSession(MentorAccountPassword),
  },
  {
    path: "/symbol/:code",
    exact: true,
    component: withSession(Saham),
  },
  {
    path: "/subscribe",
    exact: true,
    component: withSession(Subscribe),
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/signin",
    exact: true,
    component: Login,
  },
  {
    path: "/register",
    exact: true,
    component: Login,
  },
  {
    path: "/activate",
    exact: true,
    component: Activation,
  },
  {
    path: "/forgot-password",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/reset-password",
    exact: true,
    component: ResetPassword,
  },
  {
    path: "/google-form",
    exact: true,
    component: GoogleProfileForm,
  },
];

export default routes;