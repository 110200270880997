import React, { useEffect, useState } from "react";
import accounting from "accounting";
import { getSubsTypeName } from "../libs/helper";
import { Link } from "react-router-dom";

import Loader from "react-loader-spinner";

export default function (props) {

  const { onAddToCart, onRemoveFromCart,addToCart,onCheckout } = props;
  const [progress, setProgress] = useState(false);
  const [cart, setCart] = useState(
    localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
  );
  const [total_price, setTotalPrice] = useState(0);
  const [update, setUpdate] = useState(0);

  const updateCart = () => {
    if (cart !== null) {
      let total = 0;
      cart.map((item) => {
        let price = item.price ? parseFloat(item.price) : 0;
        total += price;
      });
      setTotalPrice(total);
    }
  };
  const onRemoveItem = (deletedItem) => {
    let c = cart;
    let refresh = [];
    c.map((item) => {
      if (item !== deletedItem) refresh.push(item);
    });
    localStorage.setItem("cart", JSON.stringify(refresh));
    setCart(refresh);
    onRemoveFromCart();
  };
  useEffect(() => {
    updateCart();
  }, [cart]);

  return (
    <div class="widget-box widget-panel widget-panel-dark mentor-ordersummary-widget">
      <div class="widget-header">
        <h4>Order summary</h4>
      </div>
      <div class="widget-body">
        {cart && (
          <div class="table-order-summary">
            <table class="table">
              <tbody>
                {cart.map((item, index) => {
                  console.log({item});
                  return (
                    <tr key={index}>
                    {item.type !== 'subs' &&  <td>
                        {item.mentor.username}
                        <br />
                        {item.type === "ma"
                          ? item.emiten
                          : getSubsTypeName(item.type)}
                      </td>}
                      {item.type === 'subs' &&  <td>
                       {item.name}
                      </td>}
                      <td class="tright">
                        <div className={`cart-action`}>
                          <span className={`price`}>
                            Rp. {accounting.formatNumber(item.price, 0, ".")}
                          </span>
                          <span className={`delete-action`}>
                            <a
                              onClick={() => {
                                if (onRemoveItem) onRemoveItem(item);
                              }}
                            >
                              <i className={`fa fa-trash`}></i>
                            </a>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td>TOTAL</td>
                  <td class="tright">
                    Rp. {accounting.formatNumber(total_price, 0, ".")}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}
        {progress && (
          <div style={{ textAlign: "center" }}>
            <Loader type="Puff" color="#00BFFF" height={60} width={60} />
          </div>
        )}
        <div class="order-summary-action">
        <a
            href="#modal-addtocart"
            class="button btn-block"
            data-toggle="modal"
            onClick={() => {
              if (onAddToCart) onAddToCart();
              setProgress(true);
              setTimeout(() => {
                setCart(
                  localStorage.getItem("cart")
                    ? JSON.parse(localStorage.getItem("cart"))
                    : []
                );
                setProgress(false);
              }, 1000);
            }}
          >
            Add to Cart
          </a>
          <Link
            to={{
              pathname: "/purchase",
            }}
            class="button btn-block button-outline"
          >
            Check Out
          </Link>
          
        </div>
      </div>
    </div>
  );
}
