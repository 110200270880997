import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Images } from "./";
import moment from "moment";
import ReactMarkdown from 'react-markdown';

import ReactPlayer from "react-player";
import Loader from "react-loader-spinner";

import Comment from "./Timeline/Comment";
import { load } from "dotenv";
import ReactHlsPlayer from 'react-hls-player';
import renderHTML from "react-render-html";
import { Col, Row } from "react-bootstrap";
import ImageModal from "./ImageModal";

const TimelineItem = (props) => {
  const {
    progress,
    data,
    premium,
    events,
    isLogin,
    is_mentor,
    readMoreToggle,
    allUser,
    toggle,
    onToggle,
    onRefresh,
    callAction,
    call_action,
    can_post,
    onClose,
  } = props;
  const [item, setPost] = useState(null);
  const [follow, toggleFollow] = useState(false);
  const [update, setUpdate] = useState(0);
  const [latestComment, setLatestComment] = useState([]);
  const [update_in_progress, toggleProgress] = useState(false);
  const [toggleReply, setToggle] = useState(true);
  const [toggleShare, setToggleShare] = useState(false);
  const [shareItem, setShareItem] = useState(null);
  const [readmore, setReadmore] = useState({});
  const [sharelink, setShareLink] = useState(null);
  const [selected_image, setSelectedImage] = useState(null);
  const [toggle_image, setToggleImage] = useState(false);
  
  // const autolink = (str, item) => {
  //   const newStr = str.replace(/(<a href=")?((https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)))(">(.*)<\/a>)?/gi, function () {
  //   return '<a href="' + arguments[2] + '" target="_blank">' + (arguments[7] || arguments[2]) + '</a>'
  //   });
  //   return newStr;
  // }
  const follow_user = (item) => {
    try {
      callAction(call_action, "follow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/follow`,
      });
      toggleProgress(true);

      let i = item;
      i.is_followed = 1;
    } catch (err) {
      console.log(err);
    }
  };
  const unfollow_user = (item) => {
    try {
      callAction(call_action, "unfollow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/unfollow`,
      });
      toggleProgress(true);
       let i = item;
      i.is_followed = 0;
       setPost(i);
      setUpdate(update + 1);
     
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (readMoreToggle) {
      let i = data
      console.log("DATA >>" , data)
      i.toggle_read = true
      setPost(i);
    }

    const last_comment = [];
    if (item && (item.latest_comment !== undefined || item.latest_comment !== null)) {
      last_comment.push(item.latest_comment)
    }

    setLatestComment(last_comment);
    
  }, [item])
 
  const onFollowClicked = (item) => {
    if (item.is_followed === 0) follow_user(item);
    else unfollow_user(item);
  };

  const toggleComments = (item) => {
      let i = item;
      i.toggle_comments = !i.toggle_comments;
     setPost(i);
    setUpdate(update + 1);
  };
  
  useEffect(() => {
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(true);
    }
  }, [call_action.follow_mentor]);

  useEffect(() => {
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(false);
    }
  }, [call_action.unfollow_mentor]);
  
  if (progress)
    return (
      <div style={{ textAlign: "center" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
    if (item === null)
    return (
      <div style={{ textAlign: "center" }}>
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );


  const onReadMore = (item, is_subscribed) => {
     let i = item;
      i.toggle_read = !i.toggle_read;
     setPost(i);
    
    setUpdate(update + 1);
  }

  function addStyle(string) {
    return string.split("\n").join("\<br/>")
  }

  const renderImage = (imageUrl) => (
    <img src={imageUrl} style={{ width: '100%', cursor: 'pointer' }} alt="" onClick={() => {
      setSelectedImage(imageUrl);
      setToggleImage(true);
    }} />
  )

  const isOwnPost = (item) => {
     if(parseInt(item.mentor_id) === parseInt(localStorage.getItem("mentor_id"))){
             return true;
      }
  }
  const own_post = isOwnPost(item) ? true : false;
  if(!item.mentor && !item.profile) return null;
  if(item.symbol==="Tanya" && item.comments === 0 && !is_mentor) return null;
  return (
    <div className="widget-box mentor-timeline-widget">
      <div className="timeline-top-user">
        <div className="user-profile-wrap">
          {console.log("item >> ", item)}
          <img
            src={item?.profile?.avatar_url}
            className="user-image"
            alt="user-image"
          />
          <div className="user-description">
            <div className="user-name"> {item?.profile?.username}</div>
            <div className="user-timestamp">
              {" "}
              {moment(item.created_at).format("DD/MM/YYYY")}
            </div>
          </div>
        </div>
        {onClose && <div className="user-option dropdown">
        
          <Link
            to="#"
            onClick={()=>{
                onClose();
            }}
          >
           <i className={`fa fa-backspace`} style={{fontSize:'1.6em'}}></i>
          </Link>
         
        </div>}
      </div>
      <div className="premium-box">
        <h4 className={`mt-4 mb-1 clickable-title`} onClick={()=>{
          if(item.is_subscribed===0 && !own_post){
            document.location="/subscribe";
          }
        }}>{item.symbol}: {item.title}</h4>
        {item.post_status === 0 && <span class="badge badge-pill badge-primary">Draft</span>}
        {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
          <ReactMarkdown>{(item.excerpt)}</ReactMarkdown>
        <p>
          Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
        </div>}
        
          {(item.is_subscribed === 1 || own_post) &&  <div className="thumbnail-video-wrap">
          {(
            <Row>
            { item.images && item.images[0].image_url !== '' ?
              <>
                {item.images.filter(item => item.image_url).map((img, idx) => {
                  console.log('Item Image: ', idx, img);
                  return (
                    <Col className="thumb-video-inner" md={ idx === 0 ? 12 : 4 } key={idx}>
                      {renderImage(img.image_url)}
                    </Col>)
                })}
              </>
              :
              item.image_url && !item.images ?
              <>
                <Col className="thumb-video-inner">
                  {renderImage(item.image_url)}
                </Col>
              </>
              :
              item.image_url && item.images[0].image_url === '' &&
              <>
                <Col className="thumb-video-inner">
                  {renderImage(item.image_url)}
                </Col>
              </>
            }
          </Row>
          )}
          {item.video_url && (
            <div className="thumb-video-inner">
              {item.is_cdn === 1 &&  
                <ReactHlsPlayer
                  src={item.video_url}
                  autoPlay={false}
                  controls={true}
                  width="100%"
                  height="auto"
                />
              }
              {item.is_cdn === 0 &&  <ReactPlayer url={item.video_url} width={"100%"} />}
            </div>
          )}
        
        </div>}
      

        {((item.is_subscribed === 1 || is_mentor  || own_post) && !item.toggle_read) &&  
          <div>
            { 
              item.styled_excerpt ? 
                renderHTML(item.styled_excerpt)
                :
                item.excerpt 
            }
            <div className="mb-4">
              <a href="javascript:void(0);" onClick={()=>{
                onReadMore(item);
              }}>Read More...</a>
            </div>
            
          </div>
        }

        
        {(item.is_subscribed === 1 || is_mentor || own_post) && item.toggle_read &&  
          <div>
            <div className="text-justify">
              {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
            </div>
            <br/>
            <div className="text-justify mb-4">
              {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
            </div>
          </div>
        }
          
        
        {(item.is_subscribed === 1 || own_post || is_mentor) &&  <div className="mentor-comment-wrap">
          <div class="comment-title">
            <Link
              to={`#`}
              class="iconbox-left linkable"
              onClick={() => {
                toggleComments(item);
              }}
            >
              <i class="far fa-comments"></i>
              <span>{item.comments ? item.comments : 0}</span>
            </Link>
            {parseInt(localStorage.getItem("mentor_id")) !==
              item.mentor_id && (
              <Link
                to={`#`}
                className="iconbox-left linkable"
                onClick={() => {
                  onFollowClicked(item);
                  return;
                }}
              >
                <i
                  class={
                    item.is_followed === 1
                      ? `fas fa-user-check`
                      : `fas fa-user-plus`
                  }
                ></i>
                <span>
                  {item.is_followed === 1 ? `Followed` : `Follow`}
                </span>
              </Link>
            )}

            <a href="#" onClick={()=>{
              setToggleShare(true);
              setShareItem(item);
              setShareLink(item.permalink
                      ? item.permalink
                      : "https://mentorbaik.com/posts/" + item.id);
            }} class="iconbox-left linkable"><i className={`fas fa-share-alt`}/> <span>Share</span></a>
            
            {/*}
            {(item.is_subscribed === 1 && !own_post) && (
              <a className="button btn-subscribed">Subscribed</a>
            )}*/}
          </div>
          
          {item.symbol !== 'Tanya' && <Comment
            show={item.toggle_comments}
            item={item}
            can_reply={true}
            {...props}
            post_id={item.id}
            allUser={allUser}
            onReply={() => {
              setToggle(false);
            }}
            onClose={() => {
              setToggle(true);
            }}
            onReplyDone={() => {
              toggleComments(item);
            }}
          />}
          {item.symbol === 'Tanya' && <Comment
            show={item.toggle_comments}
            item={item}
            can_reply={is_mentor}
            {...props}
            post_id={item.id}
            allUser={allUser}
            onReply={() => {
              setToggle(false);
            }}
            onClose={() => {
              setToggle(true);
            }}
            onReplyDone={() => {
              toggleComments(item);
            }}
          />}
        </div>}
      </div>
      <ImageModal show={toggle_image} item={selected_image} onClose={() => {
        setToggleImage(false);
      }} />
    </div>
  );
}

export default TimelineItem;