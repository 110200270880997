import { getMessaging, getToken } from "firebase/messaging";
import { initializeApp } from "firebase/app";

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.

const firebaseConfig = {
  apiKey: "AIzaSyBm0lII8TJvGu6nxGQwEb-P4yUNr3LDR7g",
  authDomain: "mentor-baik-web.firebaseapp.com",
  projectId: "mentor-baik-web",
  storageBucket: "mentor-baik-web.appspot.com",
  messagingSenderId: "645411261953",
  appId: "1:645411261953:web:ee63c455e6f38ebf203a00"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging();

const askPermission = () => {
  if (Notification.permission !== 'granted') {
    Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
            console.log("GRANTED")
            return
        }
    });
}
}

const getNotif = () => {
  askPermission()
  getToken(messaging,firebaseConfig).then((currentToken) => {
    if (currentToken) {
      console.log("success")
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
}

export default getNotif;