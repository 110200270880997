import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Images } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";
import moment from "moment";
import accounting from "accounting";
const bulan = [
  null,
  "January",
  "February",
  "March",
  "April",
  "Mei",
  "June",
  "July",
  "Augustus",
  "September",
  "October",
  "November",
  "December",
];
const payment_status = ["Pending", "Paid", "Cancelled"];
const MentorSubscriptionStatus = (props) => {
  const { callAction, call_action } = props;
  const [progress, setProgress] = useState(true);
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [status, setStatus] = useState(1);
  const [month, setMonth] = useState(moment().format("M"));
  const [stats, setStats] = useState(null);
  const [since_id, setSince_id] = useState(0);
  const [next_id, setNextId] = useState(0);
  const [total_revenue, setTotalRevenue] = useState(0);

  //history data
  const [rows, setRows] = useState([]);

  //profile data
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );

  const load = () => {
    callAction(call_action, "subscription_status", {
      endpoint: "/v1/subscriptions/status",
      data: {
        since_id,
        //m: month,
        //y: moment().format("YYYY"),
       // status
      },
    });
    setProgress(true);
  };
  const onLoadCompleted = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (!payload.data) return;
    
    setRows(payload.data ? payload.data : []);
    setProgress(false);
    setSince_id(payload.since_id);

  };
  useEffect(() => {

    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
    load();

  }, []);

  useEffect(()=>{
   
    load();

  },[status, month]);

  useEffect(() => {
    if (progress && call_action.subscription_status !== null) {
      onLoadCompleted(call_action.subscription_status);
    }
  }, [call_action.subscription_status]);

  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center  pt-4 pb-4">
              <div className="col-md-3">
                <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={
                    data ? data.avatar_url : localStorage.getItem("avatar")
                  }
                  callAction={callAction}
                  call_action={call_action}
                />

                <SidebarProfile is_mentor={is_mentor} />
              </div>
              <div className="col-md-7">
              <div class="widget-box widget-panel main-account-content account-history">
								<div class="widget-header">
									<h4>Subscription Status</h4>
								</div>
								<div class="widget-body">
									<div class="history-filter">
                    {/*}
										<select class="form-control">
											<option>Maret 2021</option>
											<option>Februari 2021</option>
											<option>Januari 2021</option>
											<option>Desember 2020</option>
											<option>November 2020</option>
                </select>{*/}
									</div>
									<div class="table-responsive">
										<table class="table table-history-transactions">
											<thead>
												<tr>
													<th>Nama</th>
													<th>Status</th>
													<th>Expiry</th>
												
												</tr>
											</thead>
											<tbody>
                        {rows.map((i,index)=>{
                          return <tr key={index}>
													<td>{i.mentor.nama} - {i.subs_name}</td>
													<td class="text-success">{payment_status[i.subs_status]}</td>
													<td>{moment(parseInt(i.valid_until)).format("DD/MM/YYYY")}</td>
												</tr>
                        })}  
												
											</tbody>
										</table>
                    <div className={`center pt-4 pb-4`}>
                        

                       {rows.length > 20 &&  <a
                            href="#"
                            class="button"
                            onClick={() => {
                              load();
                            }}
                          >
                            Next
                          </a>}
                         
                        
                      </div>
									</div>
								</div>
							</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorSubscriptionStatus;
