import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Images } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";
import moment from "moment";
import accounting from "accounting";
const bulan = [
  null,
  "January",
  "February",
  "March",
  "April",
  "Mei",
  "June",
  "July",
  "Augustus",
  "September",
  "October",
  "November",
  "December",
];

const payment_status = ["Pending", "Paid", "Cancelled"];
const AccountMentorHistory = (props) => {
  
  const { callAction, call_action } = props;
  const [progress, setProgress] = useState(true);
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [status, setStatus] = useState(1);
  const [month, setMonth] = useState(moment().format("M"));
  const [stats, setStats] = useState(null);
  const [since_id, setSince_id] = useState(0);
  const [next_id, setNextId] = useState(0);
  const [total_revenue, setTotalRevenue] = useState(0);

  //history data
  const [rows, setRows] = useState([]);

  //profile data
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );

  const load = () => {
    callAction(call_action, "history", {
      endpoint: "/v1/mentor/history",
      data: {
        since_id,
        m: month,
        y: moment().format("YYYY"),
        status
      },
    });
    setProgress(true);
  };
  const onLoadCompleted = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (!payload.data) return;
    
    setRows(payload.data ? payload.data : []);
    setProgress(false);
    setNextId(payload.next_id);
  };
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
    load();
  }, []);

  useEffect(()=>{
   
    load();

  },[status, month])
  useEffect(() => {
    if (progress && call_action.history !== null) {
      onLoadCompleted(call_action.history);
    }
  }, [call_action.history]);

  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center  pt-4 pb-4">
              <div className="col-md-3">
                <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={
                    data ? data.avatar_url : localStorage.getItem("avatar")
                  }
                  callAction={callAction}
                  call_action={call_action}
                />

                <SidebarProfile is_mentor={is_mentor} />
              </div>
              <div className="col-md-7">
                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-header">
                    <h4>Transaction History</h4>
                  </div>
                  <div className="widget-body">
                    <div className="history-filter">
                      <select
                        className="form-control"
                        onChange={(evt) => {
                          setSince_id(0);
                          setNextId(0);
                          setStatus(evt.target.value);
                        }}
                      >
                        <option value="">Status</option>
                        <option value="2">Cancelled</option>
                        <option value="1">Paid</option>
                      </select>
                      <select
                        className="form-control"
                        defaultValue={month}
                        onChange={(evt) => {
                          setSince_id(0);
                          setNextId(0);
                          setMonth(evt.target.value);
                        }}
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
                          (bln, index) => (
                            <option value={bln} key={index}>
                              {bulan[bln]} {moment().format("YYYY")}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                    <div className="total-revenue-status">
                      <span>Total Revenue</span>
                      <span className="amount">Rp.{total_revenue}</span>
                    </div>
                    <div className="table-responsive">
                      <table className="table table-history-transactions">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Product</th>
                            <th>Status</th>
                            <th className="tright">Price</th>
                          </tr>
                        </thead>
                        {/*
                        {
                              number: "23455",
                              date: "15/10/2021",
                              event: "Mentor 6 month Events (1)",
                              status: "Paid",
                              price: 300000,
                            },*/}
                        <tbody>
                          {rows.map((i, index) => (
                            <tr key={index}>
                              <td className="text-success">
                                <strong>#{i.order_no}</strong>
                              </td>
                              <td>
                                {moment(i.created_at).format("DD/MM/YYYY")}
                              </td>
                              <td>{i.product}</td>
                              <td className="text-danger">
                                {payment_status[i.payment_status]}
                              </td>
                              <td className="tright">
                                Rp.{accounting.formatNumber(i.amount, 0, ".")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className={`center pt-4 pb-4`}>
                        {since_id !== next_id && (
                          <a
                            href="#"
                            class="button"
                            onClick={() => {
                              if (since_id > 0) {
                                setSince_id(since_id);
                              }
                            }}
                          >
                            Previous
                          </a>
                        )}

                        {since_id !== next_id && (
                          <a
                            href="#"
                            class="button"
                            onClick={() => {
                              setSince_id(next_id);
                            }}
                          >
                            Next
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AccountMentorHistory;
