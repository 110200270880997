import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Images } from "../Assets";

export default function (props) {
  const { mentor_id, username, callAction, call_action, open, onClose } = props;
  const [loading, isLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [since_id, setSinceId] = useState(0);
  const [is_open, setOpen] = useState(open);
  const load = () => {
    if(username) { 
      callAction(call_action, "load_followers", {
        endpoint: `/v1/mentor/${username}/followers`,
        data:{
            since_id
        }
      });
    }
    
  };
  const onload = (payload) => {
    if (!payload) return;
    const { data, total, next_id } = payload;
    let d = items ? items : [];
    if (data) data.map((item) => d.push(item));
    if(total > 0 && next_id) {
        setSinceId(next_id);
    }
    setItems(d);
  };
  useEffect(() => {
    isLoading(true);
    setItems([]);
    setSinceId(0);
    load();
  }, []);

  useEffect(()=>{
    if(open) load();
    setOpen(open);

  },[open]);

  useEffect(() => {
    if (mentor_id > 0) {
      isLoading(true);
      setItems([]);
      setSinceId(0);
     
    }
  }, [mentor_id]);

  useEffect(() => {
    isLoading(false);
    onload(call_action.load_followers);
  }, [call_action.load_followers]);

  return (
    <>
    <div
      class={`modal fade ${is_open ? 'show' : ''}`}
      id="modal-followers"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" style={{ maxWidth: 578 }}>
        <div class="modal-content">
          <div class="modal-header centered">
            <h4 class="modal-title">@{username} is followed by</h4>
            <button
              type="button"
              class="close"
             
              aria-hidden="true"
              onClick={()=>{
                onClose();
              }}
            >
              <img src={Images.icClose} />
            </button>
          </div>
          <div class="modal-body">
            <div class="mentor-followers-wrapper has-scrollbar">
              {!loading && items.length > 0 && (
                <ul>
                  {items.map((item, index) => {
                    return (
                      <li>
                        <div class="mentor-follower-item">
                          <div class="user-profile-wrap">
                            <img src={item.avatar_url} class="user-image" />
                            <div class="user-description">
                              <div class="user-name">{item.nama}</div>
                              <div class="user-statistic">@{item.username}</div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )}
              {!loading && items.length === 0 && (
                <ul>
                  <li>
                    <div style={{ textAlign: "center",fontSize:14 }}>No Followers Yet</div>
                  </li>
                </ul>
              )}
              {loading && (
                <div style={{ textAlign: "center" }}>
                  <Loader type="Puff" color="#00BFFF" height={50} width={50} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    {is_open && <div class={`modal-backdrop fade show`}></div>}
    </>
  );
}
