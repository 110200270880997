/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalPost,
  CardLeft,
  CardRight,
} from "../../parts";
import Timeline from "../../parts/Timeline";
import accounting from "accounting";
import { getSubsTypeName } from "../../libs/helper";
import OrderConfirmation from "../../parts/OrderConfirmation";
import Loader from "react-loader-spinner";

const Purchase = (props) => {
  const { call_action, callAction, match } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [mentor, setMentor] = useState(null);
  const [update, setUpdate] = useState(0);
  const [fetching, loading] = useState(false);

  const [followings, setFollowings] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.followed_mentors
        : []
      : []
  );
  const [stats, setStats] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.stats
        : null
      : null
  );
  const [watchlist, setWatchlist] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.watchlists
        : []
      : []
  );
  const location = useLocation();

  const submit = () => {
    const cart = localStorage.getItem("cart");
    if(cart === null) loading(false);
    const orders = JSON.parse(cart) || [];
    console.log({orders});
    
    callAction(call_action,'checkout',{
      endpoint:'/v2/checkout',
      scenario:'post',
      data: {
        /*orders: orders.map((item)=>{
          return item.id;
        })*/
        orders
      }
    });
    loading(true);
  }
  
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    if(fetching && call_action.checkout !== null){
      
      handleSubmit(call_action.checkout);
    }
  }, [call_action.checkout]);

  const handleSubmit = (payload) => {
    const {status, payment_page_url} = payload;
    loading(false);
    if(status === 1){
      document.location = payment_page_url;
      localStorage.removeItem("cart");
    }
    
  }
 
  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
        refresh={update}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="banner-ads">
             
            </div>
          </div>
          <div className="container">
            <div className="row">
              <CardLeft
                {...props}
                stats={stats}
                watchlist={watchlist}
                followings={followings}
                subs={[]}
                onWatchlist={() => {
                  //loadProfile();
                }}
              />
              <div className="col-md-6">
               
                  <OrderConfirmation
                  progress={fetching}
                    onRemoveFromCart={() => {}}
                    onConfirm={() => {
                      
                      submit();
                    }}
                  />
               
               
              </div>
              <CardRight
              {...props}
                orders={[1, 2, 3]}
                is_mentor={false}
                subscription={false}
                onAddToCart={() => {}}
                onRemoveFromCart={() => {
                  setUpdate(update + 1);
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Purchase;