import React from 'react'
import Button from '../Button'
import CheckBox from '../CheckBox'
import Dropdown from '../Dropdown'
import * as Unicons from '@iconscout/react-unicons';

const FilterBox = ({ filterData }) => {
    const { id, filterName, isSort, isEmiten, isSector, clearFilters, applyFilter, filters = [], sorting = null, emiten = null, sector = null } = filterData

    const onClearFilter = (e) => {
        clearFilters(e)
    }

    const onApplyFilter = () => {
        applyFilter(filterName, "desc")
    }

  return (
    <div class="modal fade" id={`${id}`} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title" id="exampleModalLabel">FILTER</h4>
            <Button style={"close"} dataDismissed ariaLabel={"Close"}>
                <span aria-hidden="true">&times;</span>
            </Button>
        </div>
        <div class="modal-body ml-2 mr-2">
        {
            isSort ? (
                <div>
                    <h4 className="m-0 mt-3">
                        Sort
                    </h4>
                    <div class="dropdown" style={{width: '100%'}}> 
                        <button style={{width: '100%', backgroundColor: 'white', fontSize: '14px', textAlign: 'left'}} class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Urutan
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{width: '100%'}}>
                        <div class="dropdown-item" onClick={() => sorting.onChange('desc')}>
                            Terbaru
                            { sorting.sortValue === 'desc' && <Unicons.UilCheck  size="22" className="inline-icon" color="#4ba5f8"/> }
                        </div>
                        <div class="dropdown-item" onClick={() => sorting.onChange('asc')}>
                            Terlama
                            { sorting.sortValue === 'asc' && <Unicons.UilCheck  size="22" className="inline-icon" color="#4ba5f8"/> }
                        </div>
                        </div>
                    </div>
                </div>
            ) : null
        }
            {
                filters.length > 0 ? filters.map((item, index) => (
                    <div className="row" key={index}>
                        <div className="col-12">
                        <h4 className="m-0 mt-3">
                            {item.title}
                        </h4>
                        </div>
                        {item.data.map((data,indexSub) => {
                        return (
                        <div className="col-6" key={item.tag+indexSub+'|'+data.label}>
                            <CheckBox 
                                type={item.inputType}
                                value={`${data.id}|${data.label}`}
                                labelId={item.tag+indexSub}
                                selected={item.selected}
                                defaultChecked={data.checked}
                                onChange={(event) => item.onChange(event, indexSub, item.tag)}
                                labelName={data.label}
                            />
                        </div>
                        )})}
                    </div>
                )) : null
            }
            {
                isEmiten ? (
                    <div className="row">
                        <div className="col-12">
                            <h4 className="m-0 mt-3">
                                Kode Saham
                            </h4>
                        </div>
                        <div className="col-12">
                            <Dropdown 
                                placeholder={"cari kode saham"}
                                options={emiten.options}
                                value={emiten.value}
                                valueField={"name"}
                                labelField={"name"}
                                onChange={(values) => emiten.onChange(values, "Saham")}
                            />
                        </div>
                    </div>
                ) : null
            }
            {
                isSector ? (
                    <div className="row">
                        <div className="col-12">
                            <h4 className="m-0 mt-3">
                                Kode Industri
                            </h4>
                        </div>
                        <div className="col-12">
                            <Dropdown 
                                placeholder={"cari kode industri"}
                                options={sector.options}
                                value={sector.value}
                                valueField={"id"}
                                labelField={"name"}
                                onChange={(values) => sector.onChange(values, "Industri")}
                            />
                        </div>
                    </div>
                ) : null
            }
        </div>
        <div class="modal-footer">
            <Button onClick={onClearFilter}>
                CLEAR
            </Button>
            <Button color='btn-blue-mentorbaik' onClick={onApplyFilter} dataDismissed>
                APPLY
            </Button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default FilterBox