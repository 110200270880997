import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import { Images } from "./";
import SnackBar from "./Snackbar";
import { DropdownDate, YearPicker, MonthPicker } from "react-dropdown-date";
import currencyFormatter from "currency-formatter";

const ListReferral = (props) => {
  const { data, setData, setSnacbar, progress, snackbar, snackbar_text, save, data_ref } = props;
  const [list_referral, setListReferral] = useState([]);


  useEffect(() => {
    if(data_ref){
      setListReferral(data_ref.data.list_accounts)
    }
  }, [data_ref]);


  const formatDate = (date) => {
    // formats a JS date to 'yyyy-mm-dd'
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [year, month, day].join("-");
  };

  return (
    <form className="form-member-profile">
      {list_referral.length > 0 ?
        list_referral.map((ref) => {
          return (
            <div className="cardRef">
              <div className="row">
                <div className="col-md-6">
                  <p className={"marginBotThin"}>
                    {ref.last_payment}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p className={"marginBotThin"}>
                    @{ref.username}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 text-left">
                  <p className={"marginBotThin"}>
                    {ref.description}
                  </p>
                </div>
                <div className="col-md-6 text-right">
                  <p className={"marginBotThin"}>
                    {currencyFormatter.format(ref.price, { code: 'IDR' })}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 text-left">
                  <p className={"marginBotThin"}>
                    <strong>Bonus</strong>
                  </p>
                </div>
                <div className="col-md-6 text-right">
                  <p className={"marginBotThin"}>
                    <strong>{currencyFormatter.format(ref.bonus, { code: 'IDR' })}</strong>
                  </p>
                </div>
              </div>
            </div>
          );
        })
        :
        <div className="row">
          <div className="col-md-12 text-center mt-5">
            <img src={Images.empty} alt="close" />
            <p className="emptyReferral">
              You have no friends joined yet. <br/>
              Start sharing your referrals now!
            </p>
          </div>
        </div>
      }
    </form>
  );
};
export default ListReferral;