import React, { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { Images } from "../Assets";
import Folders from "../Folders";
import Filters from "../Filters";

import WidgetStats from "../WidgetStats";

const CardLeft = (props) => {
  const {
    stats,
    watchlist,
    callAction,
    call_action,
    onWatchlist,
    followings,
    subs,
    onSubsClicked,
    onProjectClicked,
    onFolderClicked,
    onFilter,
    is_mentor,
    withFilter
  } = props;


  const path = useRouteMatch("/mentor-mentor");
  const pathSaham = useRouteMatch("/saham");
  //const [is_mentor, toggleMentor] = useState(false);
  const [is_subscribed, setSubscribedStatus] = useState(false);
  const [expiring, setExpiring] = useState(0);
  const [mentorsubs, setMentorSubs] = useState(0);
  const [subscribers, setSubscribers] = useState(0);
  const [projectsubs, setProjectSubs] = useState(0);
  const [followers, setFollowers] = useState(0);
  //const [subscriptions, setSubscriptionStats] = useState(0);
  const [subs_expired, setSubsExpired] = useState(0);
  const [mentors, setMentors] = useState([]);
  const [projects, setProjects] = useState([]);
  const [watchlist_progress, setWatchlistProgress] = useState(false);
  const [symbol_lookup, setSymbolLookup] = useState(false);
  const [results, setResults] = useState([]); //symbol lookup results
  const [symbol_code, setSymbolCode] = useState(null);
  const [update, setUpdate] = useState(0);
  const [toggle_watchlist, setToggleWatchlist] = useState(false);
  
  const addSymbol = (code) => {
    callAction(call_action, "addSymbol", {
      endpoint: "/v1/watchlist/" + code,
      scenario: "get",
    });
    setSymbolLookup(false);
    setWatchlistProgress(true);
  };
  const removeSymbol = (code) => {
    callAction(call_action, "removeSymbol", {
      endpoint: "/v1/watchlist/" + code + "/unfollow",
      scenario: "get",
    });
    setWatchlistProgress(true);
  };
  const searchSymbol = (code) => {
    callAction(call_action, "search", {
      endpoint: "/v1/search",
      scenario: "post",
      data: {
        search: code,
      },
    });
    setSymbolLookup(true);
  };

  const handleSearch = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setResults(payload.symbols ? payload.symbols : []);
    setUpdate(update + 1);
  };

  const getSubscribedPostNotif = (input) => {
    const { mentor_id, emiten_id } = input;
    let sp = localStorage.getItem("sp");
     if(!sp) return 0;
    if (sp) sp = JSON.parse(sp);
    if (!Array.isArray(sp)) return 0;
    for (let i = 0; i < sp.length; i++) {
      if (sp[i].mentor_id === mentor_id && sp[i].emiten_id === emiten_id)
        return sp[i].total;
    }
  };
  const getWatchlistPostNotif = (input) => {
    const { symbol } = input;
    let ep = localStorage.getItem("wl_posts");
    if(!ep) return 0;
    if (ep) ep = JSON.parse(ep);
    if(typeof ep[symbol] === 'undefined') return 0;
    if (!Array.isArray(ep[symbol])) return 0;
    let total = 0;
    for (let i = 0; i < ep[symbol].length; i++) {
      if (ep[symbol][i].is_new === 1) total++;
    }
    return total;
  };
  const getFollowedPostNotif = (input) => {
    const { mentor_id } = input;
    let ep = localStorage.getItem("mentor_posts");
     if(!ep) return 0;
    if (ep) ep = JSON.parse(ep);
    if(!ep[mentor_id]) return 0;
    if (!Array.isArray(ep[mentor_id])) return 0;
    let total = 0;
    for (let i = 0; i < ep[mentor_id].length; i++) {
      if (ep[mentor_id][i].is_new === 1) total++;
    }
    
    return total;
  };

  const getProjectsNotif = () => {
   
    let ep = localStorage.getItem("pr");

    if (ep) ep = JSON.parse(ep);
    if (!Array.isArray(ep)) return [];
    return ep;
  };

  useEffect(() => {
    if (symbol_lookup) {
      handleSearch(call_action.search);
    }
  }, [call_action.search]);

  useEffect(() => {
    if (watchlist_progress && call_action.addSymbol !== null) {
      setWatchlistProgress(false);
      if (onWatchlist) onWatchlist();
    }
  }, [call_action.addSymbol]);

  useEffect(() => {
    if (watchlist_progress && call_action.removeSymbol !== null) {
      setWatchlistProgress(false);
      if (onWatchlist) onWatchlist();
    }
  }, [call_action.removeSymbol]);

  useEffect(() => {
    // let check = parseInt(localStorage.getItem("is_mentor"));
    // toggleMentor(check === 1 ? true : false);

    setProjects(getProjectsNotif());
    if(call_action.me && typeof call_action.me.data !== 'undefined')
    setSubsExpired(call_action.me ? call_action.me.data.subs_expired : 0);
    if(call_action.me && typeof call_action.me.data !== 'undefined')
      setSubscribedStatus(parseInt(call_action.me.data.is_subscribed) === 1 ? true : false );
  }, []);

   useEffect(() => {
    // let check = parseInt(localStorage.getItem("is_mentor"));
    // toggleMentor(check === 1 ? true : false);

   
    if(call_action.me && typeof call_action.me.data !== 'undefined')
    setSubsExpired(call_action.me ? call_action.me.data.subs_expired : 0);
    if(call_action.me && typeof call_action.me.data !== 'undefined')
      setSubscribedStatus(parseInt(call_action.me.data.is_subscribed) === 1 ? true : false );
  }, [call_action.me]);

  useEffect(() => {
    if (stats) {
      setExpiring(stats.expiring);
      setMentorSubs(stats.mentorsubs);
      setProjectSubs(stats.projectsubs);
      setSubscribers(stats.subscribers);
      setFollowers(stats.followers);
      
    }
  }, [stats]);
 
  return (
    <div className="col-md-12">
    
      {(!is_mentor && stats) && (
        <WidgetStats {...props} 
        is_mentor={is_mentor} 
        is_subscribed={is_subscribed}
        expired={subs_expired}
        followers={followers} 
        subscribers={subscribers} 
        expiring={expiring} 
        mentorsubs={mentorsubs}/>
      )}
      <Folders {...props} onFolderClicked={(folder)=>{
        onFolderClicked(folder);
      }}/>
      {/* {withFilter && <Filters {...props} onFilter={(value)=>{
        console.log("onFilter",value);
        if(onFilter) onFilter(value);
      }}/>} */}
       {/*}
      {is_mentor && (
        <div className="widget-box widget-panel mentor-project-widget">
          <div className="widget-header">
            <h4>My Projects</h4>
          </div>
          <div className="widget-body">
            <div className="table-mentor-project has-scrollbar">
              {projects.length > 0 && (
                <table className="table no-border">
                  <tbody>
                    {projects.map((n, index) => (
                      <tr key={index}>
                        <td>{n.symbol}</td>
                        <td className="text-primary" style={{textAlign:'right'}}>
                          <a href="#projectMessage2" 
                        data-toggle="modal" onClick={()=>{
                          if (onProjectClicked)
                          onProjectClicked({
                                symbol: n.symbol || "",
                                limit: n.total,
                              });
                        }}>{n.total} messages</a></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {projects.length === 0 && <span>You have no projects</span>}
            </div>
          </div>
        </div>
      )}
      {*/}
     {/* <div className="widget-box mentor-watchlist-widget">
        <div className="widget-header">
          <h4>WATCHLIST</h4>
        </div>
        <div className="widget-body">
          <form className="add-saham">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Type here to add symbol"
                name=""
                onChange={(evt) => {
                  searchSymbol(evt.target.value);
                }}
                onBlur={(evt) => {
                  setSymbolCode(evt.target.value);
                }}
              />
              <Link
                to="#"
                className="button-add"
                onClick={() => {
                  addSymbol(symbol_code);
                }}
              >
                <img src={Images.icAdd} alt="images" />
              </Link>
            </div>
          </form>
          <div className="mentor-watchlist-wrapper has-scrollbar">
            {symbol_lookup && (
              <ul className={`watchlist-lookup`}>
                {results.map((item, index) => {
                  return (
                    <li key={index}>
                      <a
                        href="javascript:;"
                        onClick={() => {
                          addSymbol(item.code);
                        }}
                      >
                        {item.code} - {item.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            )}
            <ul>
              <li className={`saham-header`}>
                <div>Symbol</div>
                <div>NEW POST</div>
              </li>
              {watchlist.map((n, index) => (
                <li key={index}>
                  <div className={`saham-item-wrap`}>
                    <div className={`saham`}><a href="#" onClick={()=>{
                      document.location = "/symbol/" + n.code;
                    }}>{n.code}</a></div>
                    <div
                      className={`delete-saham`}
                      onClick={() => {
                        removeSymbol(n.code);
                      }}
                    >
                      <img src="/images/ic-delete-saham.png" />
                    </div>
                  </div>
                  <Link
                    to={"#"}
                    onClick={()=>{
                      document.location = "/symbol/" + n.code;
                    }}
                    className={`saham-count`}
                  >
                    {getWatchlistPostNotif({
                      symbol: n.code,
                    }) || 0}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
                  */}
      {/*}
      {path === null && pathSaham === null && (
        <div className="widget-box mentor-follow-widget">
          <div className="widget-header">
            <h4>Followed Mentors</h4>
          </div>
          <div className="widget-body">
            <div className="table-follow has-scrollbar">
              {followings && followings.length > 0 && (
                <table className="table no-border">
                  <thead>
                    <tr>
                      <th>Mentor</th>
                      <th className="tright">NEW POST</th>
                    </tr>
                  </thead>
                  <tbody>
                    {followings.map((i, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            to={`#`}
                            onClick={()=>{
                              document.location="/mentor/" + i.username
                            }}
                            className="mentor-profile-wrap"
                          >
                            <img
                              src={i.avatar_url}
                              className="user-image"
                              alt={i.nama}
                            />
                            <div className="user-description">
                              <div className="user-fullname">{i.nama}</div>
                              <div className="user-nickname">@{i.username}</div>
                            </div>
                          </Link>
                        </td>
                        <td className="tright text-primary">
                          {getFollowedPostNotif({ mentor_id: i.id }) || 0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {followings.length === 0 && (
                <span>You have not following any mentor yet.</span>
              )}
            </div>
          </div>
        </div>
      )}
      */}
      
    </div>
  );
};

export default CardLeft;
