import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Images } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";
import SnackBar from "../../parts/Snackbar";
import {Alert} from "react-bootstrap";
import Loader from "react-loader-spinner";

const MentorAccountPassword = (props) => {
  const { callAction, call_action } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [progress, setProgress] = useState(false);
  const [password, setPassword] = useState(null);
  const [old_password, setOldPassword] = useState(null);
  const [confirm_password, setConfirmPassword] = useState(null);
  const [snackbar_type, setSnackbarType] = useState('alert');
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("");
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
  }, []);

  const submit = () => {
    callAction(call_action,'change_password',{
      endpoint:'/v1/change-password',
      scenario:'post',
      data:{
        new_password: password,
        old_password,
        confirm_password
      }
    });
    setProgress(true);
  }

  const onSubmit = (payload) => {
    if(!payload) return;
    if(payload.status === 0){
      setSnackbar(true);
      setSnackbarType('danger');
      setSnackbarText(payload.error || 'Maaf tidak berhasil mengirimkan permohonan anda. Silahkan coba kembali!');
    }else{
       setSnackbar(true);
       setSnackbarType('success');
       setSnackbarText("Password anda telah berhasil diubah!");
    }

    
    setProgress(false);
  }

  useEffect(() => {
    if (progress && call_action.change_password !== null) {
      onSubmit(call_action.change_password);
    }
  }, [call_action.change_password]);


  return (
    <>
      <Header
        isLogin={true}
        {...props}
        onFilter={(input) => {
          //setFilters(input);
          //setUpdate(update + 1);
        }}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center  pt-4 pb-4">
              <div className="col-md-3">
              <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={data ? data.avatar_url : localStorage.getItem("avatar")}
                  callAction={callAction}
                  call_action={call_action}
                />

                <SidebarProfile is_mentor={is_mentor} current={'password'}/>
              </div>
              <div className="col-md-7">
                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-header">
                    <h4>Change Password</h4>
                  </div>
                  <div className="widget-body">
                    <form className="form-member-password">
                      <div className="form-group">
                        <label className="control-label">
                          Current Password
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          name="old_password"
                          onBlur={(evt)=>{
                            setOldPassword(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">New Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          onBlur={(evt)=>{
                            setPassword(evt.target.value);
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <label className="control-label">Re-enter New Password</label>
                        <input
                          type="password"
                          className="form-control"
                          name="confirm_password"
                          onBlur={(evt)=>{
                            setConfirmPassword(evt.target.value);
                          }}
                        />
                      </div>
                      <button type="submit" className="button button-submit" onClick={(evt)=>{
                        submit();
                        evt.preventDefault();
                      }}>
                        SAVE
                      </button>
                    </form>
                     {progress && (
                        <div style={{ textAlign: "center" }}>
                          <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={100}
                            width={100}
                          />
                        </div>
                      )}
                     {snackbar && (
                          <div style={{marginTop:15,marginBottom:-15}}>
                             <Alert variant={snackbar_type ? snackbar_type : `danger`}>
                              {snackbar_text}
                                </Alert>
                              </div>
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorAccountPassword;
