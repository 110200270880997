import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import moment from "moment-timezone";
import TimelineItem from "./TimelineItem";

export default function (props) {
  const { callAction, call_action } = props;
  const { mentor_id, symbol, ts, limit } = props;
  const [items, setItems] = useState([]);
  const [fetching, loading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [item, setItem] = useState(false);
  const load = () => {
    callAction(call_action, "latest_premium", {
      endpoint: "/v1/latest/premium",
      data: {
        ts,
        symbol,
        mentor_id,
        limit,
      },
    });
    loading(true);
  };
  const onLoad = (payload) => {
    if (!payload) return;
    const { r } = payload;
    let data = payload.data ? payload.data : [];
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    setItems(data);
    loading(false);
    setUpdate(update + 1);
  };
  useEffect(() => {
    setToggle(false);
    load();
  }, [mentor_id, symbol]);

  useEffect(() => {
    if (fetching && call_action.latest_premium !== null) {
      onLoad(call_action.latest_premium);
    }
  }, [call_action.latest_premium]);




  return (
    <div
      className={`modal modal-project-message fade`}
      id="projectMessage"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ maxWidth: 578 }}>
        <div className="modal-content">
          {fetching && (
            <div className="modal-body">
              <div style={{ textAlign: "center" }}>
                <Loader type="Puff" color="#00BFFF" height={100} width={100} />
              </div>
            </div>
          )}
          {!fetching && (
            <div className="modal-header centered">
              <h4 className="modal-title">{symbol}</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                <img src="images/ic-close.png" />
              </button>
            </div>
          )}
          {!fetching && (
            <div className="modal-body">
              {!toggle && <div
                className="project-message-wrapper has-scrollbar"
                
              >
                {items.map((item, index) => {
                  const txt = item.content.replace(/<[^>]+>/g, "");
                  return (
                    <div key={index} 
                    className="comment-box clickable p-2"
                    onClick={()=>{
                      setToggle(true);
                      setItem(item);
                    }}>
                      <div className="user-profile-wrap pl-2">
                        <img
                          src={item.mentor.avatar_url}
                          className="user-image"
                        />
                        <div className="user-description">
                          <div className="user-name">
                            {item.mentor.username}
                          </div>
                          <div className="user-statistic">
                            <span>{moment(item.created_at).tz('Asia/Jakarta').fromNow()}</span>
                          </div>
                        </div>
                      </div>
                      <div className={"pl-2"}>
                        <h4 className={`mt-1 mb-1`}>{item.title}</h4>
                      </div>
                      <div className={`pl-2`}>
                        {`${txt.substr(0, 100)}${
                          txt.length > 100 ? `...` : ``
                        }`}
                      </div>
                     
                    </div>
                  );
                })}
              </div>}

              {toggle && <>
                <TimelineItem data={item} 
                callAction={callAction}
                call_action={call_action}
                isMentor={false} canPost={false} isLogin={true} 
                onRefresh={()=>{}} 
                onClose={()=>{
                  setToggle(false);
                }}/>
              </>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
