import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Footer, Header, Images } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Contact = (props) => {
  const { callAction, call_action } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const style1 ={
    "color" : "#666",
    "fontSize" : "16px"
  }
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
  }, []);
  
  return (
    <>
      <Header
        landing={false}
        isLogin={false}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center pt-4 pb-4">
              
              <div className="col-md-12">
                <div className="widget-box main-account-content account-privacy">
                  <div class="widget-body">
                    <h2>Contact Us</h2>
                    <p className="pl-3">
                      <i>
                        Soho Capital, Central Park, lantai 32, nomor 7 
                        <br/>
                        Jl. Letjen S Parman, kavling 28 - Jakarta 11470
                        <br/>
                        <a href="tel:622157906242">
                          Phone : +62 21 57906242
                        </a>
                        <br/>
                        <a href="https://wa.me/6281317878717">
                          Whatsapp business : +6281317878717
                        </a>
                        <br/>
                        <a href='mailto:cs@gmail.com?subject=Hello&body=Hello!'>
                          Email : cs@gmail.com
                        </a>
                        <br/>
                        Operational hours: 09:00 - 16.30
                      </i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
