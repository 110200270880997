import React from "react";
import { Modal } from "react-bootstrap";

export default function (props) {
  const { show, item, onClose } = props;

  return (
    <Modal
      size="lg"
      fullscreen={true}
      show={show}
      onHide={() => {
        onClose();
      }}
    >
      {item !== null && (
        <Modal.Body>
          <div style={{ position: "relative", paddingTop: "70%" }}>
            <iframe
              src={`${item.video_url}?autoplay=true&preload=true`}
              loading="lazy"
              style={{
                border: "none",
                position: "absolute",
                top: 0,
                height: "100%",
                width: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            ></iframe>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}
