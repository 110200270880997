import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Images } from "../Assets";
import SearchBox from "../SearchBox";
import InfiniteScroll from "react-infinite-scroller";
import { getSubsTypeName } from "../../libs/helper";
import accounting from "accounting";
import moment from "moment";
import Loader from "react-loader-spinner";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Notification from "../../components/Notification";

const Header = (props) => {
  const {
    isLogin,
    location,
    onFilter,
    landing,
    refresh,
    callAction,
    call_action,
  } = props;

  const [cart, setCart] = useState(
    localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : []
  );
  const [n_notif, setNotifCount] = useState(0);
  const [carts, setCartItems] = useState([]);
  const [total_price, setTotalPrice] = useState(0);
  const [update, setUpdate] = useState(0);
  const [inbox, setInbox] = useState(0);
  const [cart_total, setTotal] = useState(0);
  const [mentor_id, setMentorId] = useState(0);
  const [update_onload, toggleInbox] = useState(false);
  const [code, setCode] = useState("");
  const loadNotif = () => {
    callAction(call_action, "notif_items", {
      endpoint: "/v1/notif/items",
      data: {
        ts: localStorage.getItem("last_notify_ts")
          ? parseInt(localStorage.getItem("last_notify_ts"))
          : moment().subtract(1, "hours").unix(),
      },
    });
  }
  const updateCart = () => {
    if (cart !== null) {
      let total = 0;
      cart.map((item) => {
        let price = item.price ? parseFloat(item.price) : 0;
        total += price;
      });
      setTotalPrice(total);
    }
  };
  const onRemoveItem = (deletedItem) => {
    let c = cart;
    let refresh = [];
    c.map((item) => {
      if (item !== deletedItem) refresh.push(item);
    });
    localStorage.setItem("cart", JSON.stringify(refresh));
    setCart(refresh);
  };

  useEffect(() => {
    //setNotifCount(localStorage.getItem("recent_notif"));

    let _notifs = localStorage.getItem("notif_items");
    let notif_items = _notifs ? JSON.parse(_notifs) : [];
    let notif_counts = 0;
    notif_items.map((item)=>{
      if(item.is_new === 1) notif_counts++; 
    });
    setNotifCount(notif_counts);
    setUpdate(notif_items);

    window.addEventListener("storage", () => {
      // When local storage changes, dump the list to
      // the console.
      setCart(JSON.parse(localStorage.getItem("cart")) || []);
    });

    //load notifications
    /*callAction(call_action, "notifs", {
      endpoint: "/v1/notif",
      data: {
        ts: localStorage.getItem("last_notify_ts")
          ? parseInt(localStorage.getItem("last_notify_ts"))
          : moment().subtract(1, "hours").unix(),
      },
    });*/
  }, []);

  useEffect(() => {
    if(call_action.inbox_count){
      setInbox(call_action.inbox_count.meta.total_is_not_read)
    }
  }, [call_action.inbox_count]);

  useEffect(() => {
    updateCart();
    //setNotifCount(localStorage.getItem("recent_notif"));
  }, [refresh]);

  useEffect(() => {
    if(update_onload){
      loadNotif();
    }
  }, [update_onload]);

 useEffect(() => {
    if(update_onload && call_action.notif_items !== null){
      toggleInbox(false);
      setInbox(call_action.notif_items.data ? call_action.notif_items.data : []);
    }
  }, [call_action.notif_items]);

  useEffect(() => {
    let items = {};
    let bundle = [];
    cart.map((item) => {
      const { mentor,type } = item;
    
      if(type === 'subs'){
        if(!items[0]) items[0] = [];
        items[0].push(item);
      }else{
        if (typeof items[mentor.id] === "undefined") items[mentor.id] = [];
        items[mentor.id].push(item);
      }
     
    });
    for (let mentor_id in items) {
      bundle.push({
        mentor: items[mentor_id][0].mentor,
        items: items[mentor_id],
      });
    }
    setCartItems(bundle);
    //setNotifCount(localStorage.getItem("recent_notif"));
    updateCart();
  }, [cart]);

  useEffect(() => {
    if (onFilter)
      onFilter({
        code,
        mentor_id,
      });
  }, [mentor_id, code]);

  if (!location) return null;

  return (
    <header id="header" className="static-sticky">
      <Notification/>
      <div id="header-wrap">
        <div className="container">
          <div id="logo">
            <a href="/" className="standard-logo">
              <img src={Images.logo} alt="MentorBaik" />
            </a>
          </div>
          <div className="main-header-content">
            {isLogin ? (
              <ul className="main-header-items nobottommargin">
                <li className="header-item menu-home">
                  <Link to="/">
                    <img src={Images.icHome} alt="home" />
                  </Link>
                </li>
                <li className="header-item search-box">
                  {!landing && (
                    <SearchBox
                      {...props}
                      onPeople={(item) => {
                        setCode(item.code);
                      }}
                      onStock={(item) => {
                        setMentorId(item.mentor_id);
                      }}
                    />
                  )}
                </li>
              </ul>
            ) : (
              <></>
            )}
          </div>
          <div className="main-header-content">
            {isLogin ? (
              <ul className="main-header-items nobottommargin">
                <li className="header-item notification-wrapper">
                  <div className="notification-wrapper-inner dropdown">
                    
                  <Link to="/inbox">
                    {/* <a href="/inbox"> */}
                      <img className={location.pathname == "/inbox" ? "border-bottom-blue" : ""} src={location.pathname == "/inbox" ? Images.emailActive : Images.email } alt="images" />
                        <span className="counter-badge">{inbox}</span>
                    {/* </a> */}
                    </Link>
                  </div>
                </li>
                <li className="header-item notification-wrapper">
                  <div className="notification-wrapper-inner dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                      onClick={()=>{
                          toggleInbox(true);
                      }}
                    >
                      <img src={Images.icNotif} alt="images" />
                      {n_notif > 0 && (
                        <span className="counter-badge">{n_notif}</span>
                      )}
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenu1"
                    >
                      <div className="notification-items-wrapper has-scrollbar">
                        {update.map((item, index) => {
                         
                          let txt = (item.type === 'post') ? item.title : item.txt;
                          if(item.type === "comment" && item.title){
                            txt = item.title
                          }
                          const c = txt.split("</p>");
                          if(c.length > 0) c[0] = c[0].split("<p>").join("").substr(0,100);

                          return (
                            <div key={index} className="notification-item">
                              <div className="notification-user">
                                <Link to="#" className="user-img" onClick={()=>{
                                  document.location=`/mentor/${item.poster}`;
                                }}>
                                  <img src={item.poster_avatar_url} alt="images" />
                                </Link>
                                <div className="user-description">
                                  <Link to="#" className="username-wrap" onClick={()=>{
                                  document.location=`/mentor/${item.poster}`;
                                }}>
                                    {item.poster}
                                  </Link>
                                  <div className="timestap-wrap">
                                    {moment(item.ts*1000).fromNow()}
                                  </div>
                                </div>
                              </div>
                              <Link to={"#"} className="notification-text"
                              onClick={()=>{
                                document.location = `/posts/${item.post_id}`;
                              }}>
                                {c.length > 0 ? c[0] : ``}
                              </Link>
                            </div>
                          );
                        })}
                        {!update_onload && update.length === 0 && (
                          <span>Belum ada notifikasi.</span>
                        )}
                        
                      </div>
                    </ul>
                  </div>
                </li>
                <li className="header-item profile-wrapper">
                  <Link to="/profile">
                    <img src={localStorage.getItem("avatar")} alt="images" />
                  </Link>
                </li>
              </ul>
            ) : (
              <div className="login-menu-wrapper">
                    
                    {!isMobile && <Link
                      to="/login"
                      className={
                        location.pathname !== "/register"
                          ? `button`
                          : `button button-outline button-outline-grey`
                      }
                    >
                      Login
                    </Link>}
                 
                
                    {!isMobile &&  <Link
                      to="/register"
                      className={
                        location.pathname === "/register"
                          ? `button`
                          : `button button-outline button-outline-grey`
                      }
                    >
                      Sign Up
                    </Link>}
                 
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
