import logo from './images/logo-standard.png';
import googlePlay from './images/google-play-badge.png';
import appStore from './images/app-store-badge.png';
import heroBanner from './images/landing-hero-image.png';
import emailActive from './images/emailActive.png';
import icJujur from './images/ic-jujur-transparan.png';
import icUpdate from './images/ic-hot-update.png';
import icFocus from './images/ic-fokus.png';
import flatLower from './images/flat-browser.png';
import discussionActive from './images/discussionActive.png';
import discussion from './images/discussion.png';
import teacherActive from './images/teacherActive.png';
import teacher from './images/teacher.png';
import videoActive from './images/videoActive.png';
import video from './images/video.png';
import newsActive from './images/newsActive.png';
import news from './images/news.png';
import questionActive from './images/questionActive.png';
import question from './images/question.png';
import watchlistActive from './images/watchlistActive.png';
import watchlist from './images/watchlist.png';
import iphone from './images/iphone-12-pro.png';
import logoFooter from './images/logo-footer.png';
import googlePlayFooter from './images/google-play-badge-white.png';
import appStoreFooter from './images/app-store-badge-white.png';
import fbIcon from './images/ic-facebook.png';
import twitterIcon from './images/ic-twitter.png';
import icArrow from './images/ic-arrow-left.png';
import icHome from './images/ic-home.png';
import icSearch from './images/ic-search.png';
import satu from './images/1.jpg';
import empty from './images/emptyData.png';
import icCart from './images/ic-cart.png';
import icNotif from './images/ic-notification.png';
import delapan from './images/8.jpg';
import humanity from './images/t2_humanitary-aid_banner_032.jpg';
import icAdd from './images/ic-add.png';
import icOption from './images/ic-option.png';
import sampleVideo from './images/sample-video.jpg';
import icPlay from './images/ic-play.png';
import icFollow from './images/ic-follow.png';
import icShare from './images/ic-share.png';
import email from './images/email.png';
import icSubscribe from './images/ic-subscribe.png';
import icClose from './images/ic-close-blue.png';
import icCamera from './images/ic-camera-grey.png';
import icAttach from './images/ic-attach.png';
import BannerMain from "./images/banner-main.jpg";
import referral from "./images/bonus.png";
import coin from "./images/coin-1.png";
import people from "./images/people-1.png";
export const Images = {
    icAttach,
    icCamera,
    icClose,
    email,
    icSubscribe,
    icShare,
    icFollow,
    icPlay,
    sampleVideo,
    icOption,
    icAdd,
    humanity,
    delapan,
    icNotif,
    icCart,
    satu,
    icSearch,
    icHome,
    icArrow,
    logoFooter,
    twitterIcon,
    BannerMain,
    fbIcon,
    googlePlayFooter,
    appStoreFooter,
    iphone,
    flatLower,
    emailActive,
    icFocus,
    icUpdate,
    icJujur,
    heroBanner,
    appStore,
    googlePlay,
    referral,
    coin,
    people,
    logo,
    empty,
    discussionActive,
    discussion,
    teacherActive,
    teacher,
    videoActive,
    video,
    newsActive,
    news,
    questionActive,
    question,
    watchlistActive,
    watchlist,
}