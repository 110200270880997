import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Images } from "../Assets";
import LabelAndFilePicker from "../../widgets/LabelAndFilePicker";
import LabelAndImagePicker from "../../widgets/LabelAndImagePicker";
import MDEditor from "@uiw/react-md-editor";
import Select from 'react-dropdown-select';
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Swal from 'sweetalert2'

const TncThesis = (props) => {  
  const [seeAll, setSeeAll] = useState(false);

  
  return (
    <div className="mb-3">
      <ol start="1" className="tnc-thesis mb-0 ml-2">
        <li className="tnc-thesis">
          <p className="mb-2">
            Post THESIS adalah presentasi ide investasi, khusus mengenai SAHAM atau INDUSTRI tertentu. Tujuannya 
            adalah agar member MB dapat belajar untuk mengelola conviction dan bertukar pikiran dengan member lainnya.
          </p>
        </li>
        {seeAll && 
          <>
            <li className="tnc-thesis">
              <p className="mb-2">
                Kriteria pembahasan yang diharapkan:
                <ul>
                  <li className="tnc-thesis">
                    Pembahasan industri dengan menggunakan data dari sumber yang kredibel dan disebutkan 
                    dari mana sumber data tersebut.
                  </li>
                  <li className="tnc-thesis">
                    Pembahasan business model dari emiten/sektor. Sebagai referensi, bisa mengikuti Business 
                    Model Canvas nya Alexander Osterwalder. ( Pernah dijelaskan mas Rizza di https://mentorbaik.com/posts/4421 )
                  </li>
                  <li className="tnc-thesis">
                    Perhitungan valuasi dengan penjelasan detail dasar perhitungan tersebut.
                  </li>
                  <li className="tnc-thesis">
                    Pembuatan proyeksi laporan keuangan dengan melampirkan penjelasan detail perhitungan
                  </li>
                  <li className="tnc-thesis">
                    Update keterbukaan informasi emiten
                  </li>
                  <li className="tnc-thesis">
                    Pembahasan detail rencana aksi korporasi serta pembahasan analisanya
                  </li>
                  <li className="tnc-thesis">
                    Update rumour terkait aksi korporasi beserta sumber berita ataupun analisa pembahasan 
                    analisa kenapa menurut anda akan terjadi aksi korporasi tersebut walaupun belum ada keterbukaan informasi
                  </li>
                  <li className="tnc-thesis">
                    Pembahasan update data balance position emiten termasuk transaksi crossing di market 
                    non-reguler (pasar nego, pasar tunai)
                  </li>
                </ul>
              </p>
            </li>
            <li className="tnc-thesis">
              <p className="mb-2">
                Karena komunitas ini diakses oleh banyak pengguna dan untuk kenyamanan semua, diharapkan tidak ada ajakan jual 
                / beli / hold, baik dalam isi THESIS maupun komen. Investasi adalah seni, bisa benar atau salah dalam timeframe 
                masing-masing. 
              </p>
            </li>
            <li className="tnc-thesis">
              <p className="mb-2">
                MB berharap dapat menciptakan komunitas yang positif, kondusif dan selalu bertanggung jawab atas keputusan investasi 
                masing-masing.  Biasakan untuk berdiskusi dengan kata-kata yang netral dan tidak menyinggung siapa pun. 
                Komen atau isi post yang sekiranya memiliki potensi konflik, akan di-remove.
              </p>
            </li>
          </>
        }
      </ol>
      <a onClick={()=>setSeeAll(!seeAll)} className="see-more-tnc-thesis">
        {
          seeAll ? "See Less" : "Read More"
        }
      </a>
    </div>
  );
};

export default TncThesis;
