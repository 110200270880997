import React, { useState, useEffect } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import Loader from "react-loader-spinner";


export default function (props) {
    const { show, item, delete_status, onClose, callAction, call_action, onSaved } = props;
    const [folders, setFolders] = useState([]);
    const [toggle_new, setToggleNew] = useState(false);
    const [name, setName] = useState(null);
    const [progress, setProgress] = useState(false);
    const [add_progress,setAddProgress] = useState(false);
    const [update, setUpdate] = useState(0);
    const save = (folder, item) => {
        callAction(call_action,'add_folder_item',{
            endpoint:`/v1/folders/${folder.id}/save/${item.id}`,
            scenario:'post',
        });
        setAddProgress(true);
    }
    const load = () => {
        callAction(call_action,'folders',{
            endpoint:'/v1/folders',
          
        })
    }
    const add = () => {
        callAction(call_action,'add_folder',{
            endpoint:'/v1/folders',
            data:{name},
            scenario:'post'
        });
        
    }
    useEffect(()=>{
        if(call_action.add_folder && progress){
            setToggleNew(false);
            setProgress(false);
            load();
        }
       
    },[
        call_action.add_folder
    ]);

    useEffect(()=>{
        if(call_action.add_folder_item && add_progress){
            setAddProgress(false);
            onSaved();
        }
    },[
        call_action.add_folder_item
    ]);

    useEffect(()=>{
        if(call_action.folders){
            setFolders(call_action.folders.folders);
            setUpdate(update + 1);
        }
    },[
        call_action.folders
    ]);
    
    useEffect(()=>{
        load();
    },[]);

    if (item === null) return <></>
    
    return <Modal size="md" fullscreen={true} show={show} onHide={() => {
        onClose();
    }}>

        <Modal.Body>
            {!toggle_new && <div>
                <h4>{`Save Post`}</h4>
                <p>save `{item.title}` to : </p>
               
                {folders.map((folder)=>{
                    return <div><a href="#" onClick={()=>{
                        save(folder, item);
                    }}>{folder.name}</a></div>
                })}
             
               
                <div className={`pt-4`}>
                    <Button onClick={()=>{
                        setToggleNew(true);
                    }}>Create new folder</Button>
                </div>
                
            </div>}
            {toggle_new && <div>
                <h4>{`New Folder`}</h4>
                <div>
                    <input type="text" className={`form-control`} onBlur={(evt)=>{
                        setName(evt.target.value);
                    }}/>
                </div>
                <div className={`pt-4`}>
                {progress && <div style={{ textAlign: "center" }}>
                    <Loader type="Puff" color="#00BFFF" height={30} width={30} />
                </div>}
                 {!progress && <Button onClick={()=>{
                        setProgress(true);
                        add();
                    }}>Save Folder</Button>}
                </div>
                
            </div>}
        </Modal.Body>
        <Modal.Footer>
           <><Button onClick={() => {
                setProgress(false);
                setToggleNew(false);
                onClose()
            }}>Cancel</Button> </>
        </Modal.Footer>
    </Modal>
}