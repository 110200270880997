import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../Assets'

const ModalEvent = () => {
    return (
        <div className="modal fade modal-event" id="modalEvent" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
            <div className="modal-dialog" style={{ maxWidth: '576px' }}>
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" data-dismiss="modal" aria-hidden="true"><img src={Images.icClose} alt="close-icon" /></button>
                    </div>
                    <div className="modal-body">
                        <div className="event-box">
                            <img src={Images.sampleVideo} className="event-banner" alt="sample-video" />
                            <div className="event-description">
                                <div className="price">Rp 500.000</div>
                                <h4>Vestibulum tincidunt ligula tortor</h4>
                                <p>Aliquam elit mi, volutpat nec erat nec, dapibus laoreet lectus. Integer eget egestas ligula. Vivamus posuere volutpat elit in posuere. Proin tincidunt, justo aliquet tempor suscipit, enim est blandit lacus, at egestas justo</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Link to="#" className="button">Add to Cart</Link>
                        <Link to="#" className="button button-outline">Check Out</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalEvent
