import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Images } from "../Assets";
import LabelAndFilePicker from "../../widgets/LabelAndFilePicker";
import LabelAndImagePicker from "../../widgets/LabelAndImagePicker";
import MDEditor from "@uiw/react-md-editor";
import Select from 'react-dropdown-select';
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Swal from 'sweetalert2'
import { Editor } from 'react-draft-wysiwyg';
const API_URL = process.env.REACT_APP_API_URI;

const ModalEdit = (props) => {
  const { watchlist, callAction, call_action, open, onFinish, onClose, data, is_mentor } = props;

  const [is_open, setOpen] = useState(open || false);
  const [symbol, setSymbol] = useState("");
  const [code, setCode] = useState("");
  const [update, setUpdate] = useState(0);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [post_status, setStatus] = useState(0);
  const [excerpt, setExcerpt] = useState(null);
  const [attachment_url, setAttachment] = useState(null);
  const [image_url, setImageUrl] = useState(null);
  const [video_url, setVideoUrl] = useState(null);
  const [editorExcerptState, setEditorExcerptState] = useState(null);
  const [excerptHtml, setExcerptHtml] = useState(null);
  const [editorContentState, setEditorContentState] = useState(null);
  const [contentHtml, setContentHtml] = useState(null);
  const [attachment_type, setAttachmentType] = useState('image');  //image | video | file
  const [progress, setProgress] = useState(false);
  const [is_premium, setPremium] = useState(false);
  const [toggle_attachment, setToggleAttachment] = useState(false);
  const [toggle_image, setToggleImage] = useState(false);
  const [emiten, setEmiten] = useState([]);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("lorem ipsum");
  const [is_loading, setIsLoading] = useState(false);
  const [toggle_upload, setToggleUpload] = useState(false);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [imagesCounter, setImagesCounter] = useState(0);
  const [initialCounter, setInitialCounter] = useState(0);
  const [mentorList, setMentorList] = useState([{id: 1, nama: "Mentor 1"},{id: 2, nama: "Mentor 2"},{id: 3, nama: "Mentor 3"},
                                            {id: 4, nama: "Mentor 4"},{id: 5, nama: "Mentor 5"},{id: 6 , nama: "Mentor 6 "}])
  const [selectedMentor, setSelectedMentor] = useState([])
  const [selectedSaham, setSelectedSaham] = useState(null)
  const [category, setCategory] = useState([])
  const [is_loading_category, setIsLoadingCategory] = useState(false);
  const [selectedSector, setSelectedSector] = useState(null)
  const [sectorList, setSectorList] = useState([])
  const [is_loading_sectors, setIsLoadingSectors] = useState(false);
  const [isOnWatchlist, setIsOnWatchlist] = useState(false);
  const [selectedWatchlistStatus, setSelectedWatchlistStatus] = useState({id: "0", nama:"None (Default)"})
  const [watchlistStatus, setWatchlistStatus] = useState([
    {id: "0", nama:"None (Default)"},
    {id: "1", nama:"Start"},
    {id: "2", nama:"Exit"}
  ])

  useEffect(()=>{
  	if(open && data !== null){
      if(data.is_active_watchlist){
        setIsOnWatchlist(true)
      }else{
      }
      
      setTitle(data.title);      
      setContent(data.content);      
      setExcerpt(data.excerpt);      
      setContentHtml(data.styled_content ? data.styled_content : data.content);      
      setExcerptHtml(data.styled_excerpt ? data.styled_excerpt : (data.excerpt ? data.excerpt : "<p></p>"));      
      setStatus(data.post_status);      
      setAttachment(data.attachment_url);      
      setImageUrl(data.image_url);      
      setVideoUrl(data.video_url);      
      setSelectedSaham(data.emiten_code ? data.emiten_code : {})      
      setSelectedSector(data?.sector?.id)      
      setSymbol(is_mentor ? data.symbol : "Tanya");      
      setImagesUrl(data.images || [
        {order:0, image_url:''},
        {order:1, image_url:''},
        {order:2, image_url:''},
        {order:3, image_url:''},
        {order:4, image_url:''},
        {order:5, image_url:''},
        {order:6, image_url:''},
        {order:7, image_url:''},
        {order:8, image_url:''},
       ])     
      // if(data.mentor_id) handleMentors(data.mentor_id);      
      if(data.attachment_url) setAttachmentType('file');      
      if(data.image_url) setAttachmentType('image');      
      if(data.video_url) setAttachmentType('video');      
      setToggleAttachment(data.attachment_url ? true : false);      
      setToggleImage(data.image_url ? true : false);      
      setCode(data.symbol);      
      const contentBlock = htmlToDraft(data.styled_content || data.content);      
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks,contentBlock.entityMap);      
      const editorContentState = EditorState.createWithContent(contentState);      
      setEditorContentState(editorContentState)      
      
      const excerptBlock = htmlToDraft(data.styled_excerpt || "<p></p>");      
      const excerptState = ContentState.createFromBlockArray(excerptBlock.contentBlocks,  excerptBlock.entityMap);      
      const editorExcerptState = EditorState.createWithContent(excerptState);     
      setEditorExcerptState(editorExcerptState)
  	}

    setOpen(open);
    setUpdate(update+1);
  },[open]);
  
  const error = (message) => {
    setSnackbar(true);
    setSnackbarText(message);
  }

  const addPastedImgToServer = async(file) => {
    return new Promise( 
      (resolve, reject) => { 
        const xhr = new XMLHttpRequest(); 
        xhr.open('POST', API_URL+'/media/upload'); 
        xhr.setRequestHeader('Authorization', localStorage.getItem("token")); 
        xhr.setRequestHeader("Ngrok-Skip-Browser-Warning", true);
        let image = {
          'file': file
        }
        const data = new FormData(); 
        for (let k in image) {
          data.append(k, image[k]);
        }

        xhr.send(data); 
        xhr.addEventListener('load', () => { 
          const response = JSON.parse(xhr.responseText); 
          resolve({ data: {
            link: response.file_url
          }}); 
        }); 
        xhr.addEventListener('error', () => { 
          const error = JSON.parse(xhr.responseText); 
          reject(error); 
        }); 
      } 
    )
  }

  const handleMentors = (list) => {
    let result = []
    for( let selectedMentor of list ){
      for( let oneMentor of mentorList ){
        if( selectedMentor == oneMentor.profile_id ){
          result.push(oneMentor)
        }
      }
    }
    setSelectedMentor(result)
  }

  const handleChangePlain = (event, changes) => {
    let fullText = "";
    for( let i=0 ; i<event.blocks.length ; i++){
      fullText+= event.blocks[i].text
      fullText+= " "
    }
    changes == "content" ? setContent(fullText) : setExcerpt(fullText)
  }

  const handleCounterImage = () => {
    for( let i=0 ; i<imagesUrl.length ; i++){
      if(imagesUrl[i].image_url == ""){
        setImagesCounter(i)
        setInitialCounter(i)
        break;
      }
    }
  }

  const loadEmiten = () => {
    callAction(call_action,'load_emiten',{
      endpoint:'/v1/emiten',
      scenario:'get'
    });
    setIsLoading(true);
  }

  const loadSector = () => {
    callAction(call_action, "sectors",  {
      endpoint: "/v1/sectors",
      scenario: "get"
    })
    setIsLoadingSectors(true)
  }

  const loadCategory = () => {
    callAction(call_action,'load_category',{
      endpoint:'/v1/category',
      scenario:'get'
    });
    setIsLoadingCategory(true);
  }

  const handleSectors = (payload) => {
    if(!payload) return;
    const {status, sector_list} = payload
    if(!status) return;
    setSectorList(sector_list ? sector_list : [])
    setIsLoadingSectors(false)
    setUpdate(update + 1)
  }

  const handleCategory = (payload) => {
    if(!payload) return;
    const {status, data} = payload;
    if(!status) return;
    if(status === 0) return;
    
    setCategory(data ? data : []);
    setIsLoadingCategory(false);
  }

  const handleEmiten = (payload) => {
    if(!payload) return;
    const {status, data} = payload;
    if(!status) return;
    if(status === 0) return;
    data.map((item)=>{
    	if(item.code === code){
    		setSymbol(item);
    	}
    })
    setEmiten(data ? data : []);
    setIsLoading(false);
    setUpdate(update + 1);
  }

  const handleImages = ()  => {
    let images = []
    for( let i=0 ; i<imagesUrl.length ; i++ ){
      images.push(imagesUrl[i].image_url)
    }
    return images;
  }

  const loadMentor = () => {
    callAction(call_action,'load_mentors',{
      endpoint:'/v1/mentors',
      scenario:'get'
    });
    setIsLoading(true);
    setProgress(true)
  }

  useEffect(()=>{
      if(progress && call_action.load_mentors !== null){
          setProgress(false);
          setMentorList(call_action.load_mentors.data);
      }
  },[call_action.load_mentors]);

  const handleMentorId = (listMentor)  => {
    if( symbol == "Tanya" ) return ''
    let mentors = []
    for(let oneMentor of listMentor){
      mentors.push(oneMentor.profile_id)
    }
    return mentors;
  }

  const submit = async() => {
    if(selectedMentor.length > 3){
      Swal.fire({
        icon: 'error',
        text: 'Cannot choose more than 3 mentors',
        confirmButtonColor: '#00a8ff'
      })
    }else{
      let mentor_ids = handleMentorId(selectedMentor)
      const image_urls = await handleImages()
      const obj = {
        title,
        content,
        excerpt,
        mentor_ids,
        styled_content: contentHtml,
        styled_excerpt: excerptHtml,
        attachment_url,
        video_url,
        image_url,
        image_urls,
        symbol,
        post_status,
        watchlist_status: selectedWatchlistStatus.id,
      }
      
      if(symbol.includes("Industri")){ 
        obj.sector_id = selectedSector 
        obj.emiten_code = null
      }
      if(symbol.includes("Saham")) {
        obj.emiten_code = selectedSaham
        obj.sector_id = null
      }
      
      callAction(call_action,'update_post',{
          scenario:'put',
          endpoint:'/v2/post/' + data.id ,
          data: obj
      });
      setProgress(true);
    }
  };
  
  const handleSubmit = (payload) => {
      if(typeof payload === 'undefined') return;
      if(payload === null) return;
      if(payload.status === 1) setSnackbarText("Your Post is updated successfully!");
      if(payload.status === 0) setSnackbarText(payload.error);
      // comment out due to un predictable error from BE, will discuss with BE soon
      // if(payload.status === 0) setSnackbarText("Cannot update your post. Please try again later!");
      setSnackbar(true);
      setTimeout(()=>{
        //setOpen(false);
        onFinish();
        onClose();
      },3000);
  }

  useEffect(()=>{
      setOpen(open);
      loadEmiten();
      loadMentor();
      loadSector();
      loadCategory();
  },[]);

  useEffect(() => {
    if(is_loading_sectors && call_action.sectors) {
      handleSectors(call_action.sectors)
    }
  },[call_action.sectors])

  useEffect(()=>{
    if(is_loading_category && call_action.load_category !== null){
        handleCategory(call_action.load_category);  
    }
  },[call_action.load_category]);
  
  useEffect(()=>{
    if(progress && call_action.update_post !== null){
      setProgress(false);
      handleSubmit(call_action.update_post);
    }
  },[call_action.update_post]);

  useEffect(()=>{
    if(is_loading && call_action.load_emiten !== null){
      handleEmiten(call_action.load_emiten);
    }
  },[call_action.load_emiten]);

  useEffect(()=>{
    if(symbol){
      setCode(symbol);
    }
    handleCounterImage()
  },[symbol])

  const excerptChange = (state) => {
    let htmlFormat = draftToHtml(convertToRaw(editorExcerptState.getCurrentContent()));
    setEditorExcerptState(state)
    setExcerptHtml(htmlFormat)
  }

  const contentChange = (state) => {
    let htmlFormat = draftToHtml(convertToRaw(editorContentState.getCurrentContent()));
    setEditorContentState(state)
    setContentHtml(htmlFormat)
  }

  const setImagesFunc = (data, id) => {
    let datas = []
    for( let i=0 ; i<imagesUrl.length ; i++ ){
      if( i==id  ){
        datas.push({
          order: id,
          image_url: data
        })
      }else{
        datas.push(imagesUrl[i])
      }
    }
    setImagesUrl(datas)
    if( id >= imagesCounter ){
      let currCounter = imagesCounter + 1;
      setImagesCounter(currCounter);
    }
  }
  
  return (
    <>
    <div
      className={`modal fade modal-post ${is_open ? `show` : ``}`}
      id="modalPost"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ minWidth: "578px", maxWidth: "1000px" }}>
        <div className="modal-content pb-3">
          <div className="modal-header">
            <div className="modal-post-header" style={{width: '100%'}}>

              {emiten.length === 0 && <Loader
                   type="Puff"
                   color="#00BFFF"
                   height={30}
                   width={30}
                 />}
         
         
              {/* disable to makesure data not breaking
            {/* {is_mentor && emiten.length > 0 && <select
                className="form-control header-post"
                value={code}
                onChange={(evt) => {
                  if(category.map((item) => item.name).includes(evt.target.value)){
                    setSymbol(evt.target.value);
                    setUpdate(update + 1);
                  }
                }}
              >
                <option>Select Category</option>
                {category.map((item, idx) => (
                  <option key={idx} value={item.name}>{item.name}</option>
                ))}
              </select>} */}
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={()=>{
                setOpen(false);
                onClose();
              }}
            >
              <img src={Images.icClose} alt="close" />
            </button>
          </div>
          <div className="modal-body" style={{overflowY:'auto',height:window.innerHeight * 0.7}}>
            {
              (["Saham","Industri"].includes(data?.symbol) || ["Saham","Industri"].includes(symbol)) && is_mentor ?  
              <div className="form-group">
                <p className="postTitle">
                  Kode {symbol}
                  
                  { isOnWatchlist && <i className={"onWatchlistLabel"}> On Watchlist </i>   }
                </p>
                {/* disable to makesure data not breaking */}
                {/* <select className="dropdown-saham" value={symbol === "Industri" ? selectedSector : selectedSaham} onChange={(e) => {
                  if(symbol === "Industri") setSelectedSector(e.target.value)
                  else setSelectedSaham(e.target.value)
                  setUpdate(update + 1);
                }}>
                  <option> Choose {symbol} </option>
                  {symbol === "Industri" ? sectorList.map((item, idx) => {
                  return (
                    <option value={item.id}
                      key={idx}
                    >
                      {item.name} 
                    </option>
                  );
                  }) : emiten.map((item, idx) => {
                    return (
                      <option value={item.code}
                        key={idx}
                      >
                        {`${item.code} (${item.name})`}
                      </option>
                    );
                    })}
                </select> */}
              </div> 
              : !['Sektoral','Makroekonomi','Artikel','Action'].includes(symbol) && <div className="top-modal-post"><span>{code}</span> {symbol ? symbol : code}</div>
            }
            {
              (is_mentor && symbol === "Saham") &&
              <div className="col-md-12 pl-0 pr-0">
                <div className="form-group">
                  <p className="postTitle">
                  Watchlist
                  </p>
                  <select className="dropdown-saham" 
                    onChange={(e) => {
                      setSelectedWatchlistStatus({id: e.target.selectedIndex, nama: e.target.value});
                    }}
                  >
                    {watchlistStatus.map((item, idx) => {
                      return (
                        <option value={item.nama} key={idx}>
                          {item.nama}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            }
            
            {<form className="main-post-form">
              <div className="form-group">
                <p className="postTitle">
                  Title
                </p>
                <input
                  type="text"
                  className="form-control borderful postBox"
                  placeholder="Enter title here"
                  value={title}
                  onChange={(evt)=>{
                    setTitle(evt.target.value);
                  }}
                  onBlur={(evt) => {
                    setTitle(evt.target.value);
                  }}
                />
              </div>
              
              { symbol == "Tanya" &&
                <div className="form-group">
                  <p className="postTitle">
                    Mentor
                  </p>
                  <Select
                    className="select-dropdown2"
                    multi
                    valueField="id"
                    labelField="nama"
                    options={mentorList}
                    placeholder="Choose mentor"
                    values={selectedMentor}
                    onChange={(values) => setSelectedMentor(values)}
                  />
                </div>
              }
              {/* <div className="form-group">
                <p className="postTitle">
                  Saham (Opsional)
                </p>
                <select className="dropdown-saham">
                  <option> Choose Saham </option>
                  {emiten.map((item, idx) => {
                  return (
                    <option value={item.code}
                      key={idx}
                      onClick={() => {
                        setSelectedSaham(item.code);
                        setUpdate(update + 1);
                      }}
                    >
                      {item.code} - {item.name} 
                    </option>
                  );
                  })}
                </select>
                <p className="info-field-saham">Jika tidak dipilih, maka akan menjadi pertanyaan non-saham</p>
              </div> */}
              <div className="form-group">
                { excerptHtml && localStorage.getItem("toggle") == "Latest" && 
                  <>
                    <p className="postTitle">
                      Excerpt
                    </p>
                
                    <Editor
                      editorState={editorExcerptState}
                      wrapperClassName="demo-wrapper"
                      editorClassName="demo-editor"
                      handlePastedText={() => false}
                      placeholder="Excerpt"
                      editorStyle={{
                        "paddingLeft": "3%",
                        "height": "170px",
                        "fontSize": "12pt"
                      }}
                      toolbar={{
                        fontSize: {options: []},
                        fontFamily: {options: [],}
                      }}
                      onEditorStateChange={(state)=>{
                        excerptChange(state)
                      }}
                      onChange={(e)=>{
                        // for set plain text
                        handleChangePlain(e, "excerpt")
                      }}
                      />
                  </>
                  }
                {/* <input
                  type="text"
                  className="form-control borderful"
                  placeholder="Excerpt"
                  value={excerpt}
                  onChange={(evt)=>{
                    setExcerpt(evt.target.value);
                  }}
                  onBlur={(evt) => {
                    setExcerpt(evt.target.value);
                  }}
                  /> */}
               
              </div>
              
              { localStorage.getItem('toggle') == 'Thesis' ?
                  <>
                    { contentHtml && 
                      <div className="form-group">
                        <p className="postTitle">
                          Content
                        </p>
                        <Editor
                          className="testClass"
                          editorState={editorContentState}
                          wrapperClassName={"border padding"}
                          editorClassName="demo-editor"
                          handlePastedText={() => false}
                          placeholder="Enter your content here"
                          editorStyle={{
                            "paddingLeft": "3%",
                            "height": "170px",
                            "fontSize": "12pt"
                          }}
                          previewImage={true}
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'image', 'history'],
                            image: {
                              urlEnabled: true,
                              uploadEnabled: true,
                              previewImage: true,
                              alt: { present: true, mandatory: false },
                              uploadCallback: (e) => addPastedImgToServer(e),
                              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
                            },
                            fontSize: {options: []},
                            fontFamily: {options: [],}
                          }}
                          onEditorStateChange={(state)=>{
                            //for set html version text
                            contentChange(state)
                          }}
                          onChange={(e)=>{
                            // for set plain text
                            handleChangePlain(e, "content")
                          }}
                        />
                        {/* <MDEditor
                        value={content}
                        onChange={(value) => {
                          setContent(value);
                        }}
                        /> */}
                      </div>
                    }
                  </>
                :
                  <div className="form-group">
                    { contentHtml && 
                      <>
                        <p className="postTitle">
                          Content
                        </p>
                        <Editor
                          className="testClass"
                          editorState={editorContentState}
                          wrapperClassName={"border padding"}
                          editorClassName="demo-editor"
                          handlePastedText={() => false}
                          placeholder="Enter your content here"
                          editorStyle={{
                            "paddingLeft": "3%",
                            "height": "170px",
                            "fontSize": "12pt"
                          }}
                          toolbar={{
                            fontSize: {options: []},
                            fontFamily: {options: [],}
                          }}
                          onEditorStateChange={(state)=>{
                            //for set html version text
                            contentChange(state)
                          }}
                          onChange={(e)=>{
                            // for set plain text
                            handleChangePlain(e, "content")
                          }}
                        />
                      </>
                    }
                    {/* <MDEditor
                      preview={`edit`}
                      value={content}
                      onChange={(value) => {
                        setContent(value);
                      }}
                    /> */}
                  </div>
              }
             
              {toggle_attachment && (
                <div className={`form-group`} style={{display:attachment_type === 'image' ? 'block' : 'flex'}}>
                <div style={{flexGrow:1}}>
                <select className={`form-control`} 
                defaultValue={attachment_type}
                style={{height:'auto'}} onChange={(evt)=>{
                  setAttachmentType(evt.target.value);
                }}>
                  <option value="image">Image</option>
                  <option value="video">Video</option>
                  {/*<option value="file">Document</option>*/}
                </select>
                </div>
                <div style={{flexGrow:2}}>
                {attachment_type === 'video' && <input
                  type="text"
                  className="form-control"
                  placeholder="Youtube Video URL"
                  value={video_url}
                  onChange={(evt)=>{
                    setVideoUrl(evt.target.value);
                  }}
                  onBlur={(evt) => {
                    setVideoUrl(evt.target.value);
                  }}
                />}
                {attachment_type === 'image' && <div>
                {/* {!toggle_upload && <img src={image_url} style={{width:'100%',cursor:'pointer'}} onClick={()=>{
                  setToggleUpload(true);
                }}/>}
                {(toggle_upload || !image_url) && <LabelAndImagePicker
                label={`Upload new Photo`} onChange={(val)=>{
                  setImageUrl(val);
                  setToggleUpload(false);
                }}
                />} */}
                  <>
                    { 
                      imagesUrl.map((item)=>{
                        if( item.order <= imagesCounter){
                          return (<LabelAndImagePicker
                            label={``} key={item.order} onChange={(val)=>{
                              setImagesFunc(val, item.order)
                            }}
                            value={item.image_url}
                            />)
                          }
                        })
                    }
                  </>
                </div>}
                {attachment_type === 'file' && <input
                  type="text"
                  className="form-control"
                  placeholder="File URL"
                  value={attachment_url}
                  onChange={(evt)=>{
                    setAttachment(evt.target.value);
                  }}
                  onBlur={(evt) => {
                    setAttachment(evt.target.value);
                  }}
                />}
                   </div>
                 
                </div>
              )}

            {/*}  {toggle_image && (
                <div className={`form-group`}>
                 <input
                  type="text"
                  className="form-control"
                  placeholder="Photo URL"
                  value={image_url}
                  onChange={(evt)=>{
                    setImageUrl(evt.target.value);
                  }}
                  onBlur={(evt) => {
                    setImageUrl(evt.target.value);
                  }}
                />
                
                </div>
              )} */}
               <div className={`form-group`}>

                     <select className={`form-control`} 
                     style={{height:'auto'}} 
                     defaultValue={(post_status)} 
                     value={post_status} 
                     onChange={(evt)=>{
                        setStatus(evt.target.value);
                      }}>
                        <option value={''}>Publish Status</option>
                        <option value={"0"}>Draft</option>
                        <option value={"1"}>Published</option>
                      </select>
                </div>
               {snackbar && (
                <div style={{marginTop:20, marginBottom:20}}>
                     <SnackBar
                      show={snackbar}
                      text={snackbar_text}
                      onClose={() => {
                        setSnackbar(false);
                      }}
                    />
                    </div>
                   
                  )}
              <div className="form-group form-post-footer">

                <Link
                  to="#"
                  className="file-attach"
                  onClick={() => {
                    setToggleAttachment(!toggle_attachment);
                  }}
                >
                  <img src={Images.icAttach} alt="attach" /> Attachment
                </Link>
              

                {!progress && <Link
                  to="#"
                  className="button"
                  onClick={() => {
                    
                    if (symbol === null) return error('Harap pilih Kategori');
                    if (typeof symbol === 'undefined') return error('Harap pilih Kategori');
                    if(data.symbol === "Saham" && !selectedSaham) return error('Harap pilih Kode Saham');
                    if(data.symbol === "Industri" && !selectedSector) return error('Harap pilih Industri');
                    if (!title) return error('Harap masukkan TITLE');
                    if (title.length === 0) return error('Harap masukkan TITLE');
                    // if (content.length === 0) return error('Harap masukkan DESKRIPSI');
                    // if(content.length < 30) return error('DESKRIPSI minimal 30 karakter');
                    if (contentHtml.length === 0) return error('Harap masukkan DESKRIPSI');
                    if(contentHtml.length < 30) return error('DESKRIPSI minimal 30 karakter');
                    submit();
                  }}
                >
                  Post
                </Link>}
                {progress && <Loader
                   type="Puff"
                   color="#00BFFF"
                   height={30}
                   width={30}
                 />}
              </div>
             
            </form>}
            
            
          </div>
        </div>
      </div>
    </div>
     {is_open && <div className={`modal-backdrop fade show`}></div>}
    </>
  );
};

export default ModalEdit;