export function isStringLength(string, min, max) {
    return min <= string.length && max >= string.length;
}

export function isMobileValid(number) {
    const mobileRegexLimit = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

    const isMobileValid = mobileRegexLimit.test(number)

    return isMobileValid;
}