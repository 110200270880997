import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Images } from "../../parts";
import ProfileWidget from "../../parts/ProfileWidget";
import SidebarProfile from "../../parts/SidebarProfile";
import FormReferralInfo from "../../parts/FormReferralInfo";
import ListReferral from "../../parts/ListReferral";
import Mentor from "../../parts/FormMentor";
import Bank from "../../parts/FormAccountBank";
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import Toastify from 'toastify-js';

const MentorAccountReferralInfo = (props) => {
  const { callAction, call_action } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [progress, loading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [set_price, setPrice] = useState(false);
  const [data_ref, setDataRef] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("");

  const [data, setData] = useState(
    call_action.me
      ? call_action?.me?.data?.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );
  const load = (reload, selected_date) => {
    if (reload || call_action.referralInfo === null) {
      let month = 8;
      let year = 2023;

      if( selected_date ){
        month = selected_date.month
        year = selected_date.year
      }

      callAction(call_action, "referralInfo", {
        endpoint: `/v1/myself/refferalinfo?year=${year}&month=${month+1}`,
        scenario: 'getV2',
      });
      loading(true);
    }
  };
  const onRefferalLoaded = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setIsMentor((payload.data && payload.data.mentor && payload.data.mentor.id > 0) ? true : false);
    setData(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setUpdate(update + 1);
  };

  const save = (set_price) => {
    const {
      nama,
      email,
      no_telp,
      about_me,
      bank_account,
      bank_holder,
      bank_name,
    } = data;

    callAction(call_action, "save_profile", {
      scenario: "post",
      endpoint: "/v1/profile",
      data: !is_mentor
        ? {
            nama,
            email,
            no_telp,
          }
        : {
            nama,
            email,
            no_telp,
            about_me,
            bank_account,
            bank_holder,
            bank_name,
          },
    });
    loading(true);
    setPrice(set_price ? set_price : false);
  };

  const formProps = {
    data,
    setData,
    snackbar,
    snackbar_text,
    save,
    progress,
    setSnackbar
  };
  const ListRef = ListReferral;
  const FormReferral = FormReferralInfo;
  
  const searchRefHist = (e, selected_date) =>{
    e.preventDefault()
    load(true, selected_date)
  }

  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    // setIsMentor(
    //   parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    // );
    load(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (progress && call_action.referralInfo !== null) {
      loading(false);
      setDataRef(call_action.referralInfo);
    }
  }, [call_action.referralInfo]);

  useEffect(() => {
    if (progress && call_action.save_profile !== null) {
      loading(false);
      load();

      if (call_action.save_profile) {
        if (set_price === true) {
          document.location = "/set-price";
        } else {
          Toastify({
            text: call_action.save_profile.message,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "bottom", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "#B3E8D1",
              color: "#118B56",
            },
          }).showToast();
          // setSnackbar(true);
          // setSnackbarText(call_action.save_profile.message);
        }
      }
    }
  }, [call_action.save_profile]);

  if (data === null)
    return (
      <>
        <div style={{ textAlign: "center", padding: 30 }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      </>
    );
  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center pt-4 pb-4">
              <div className="col-md-3">
                <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={data ? data.avatar_url ? data.avatar_url : localStorage.getItem("avatar") : localStorage.getItem("avatar")}
                  callAction={callAction}
                  call_action={call_action}
                />
                <SidebarProfile is_mentor={is_mentor} current="referral" />
              </div>
              <div className="col-md-7">
                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-header">
                    <h4>Referral Info</h4>
                  </div>
                  <div className="widget-body">
                     <FormReferral data_ref={data_ref} searchRefHist={searchRefHist}/>
                  </div>
                </div>
                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-body">
                    <ListRef data_ref={data_ref}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorAccountReferralInfo;