const Button = ({children, style, onClick, ariaLabel, dataTarget = false, dataDismissed = false, dataToggled = false, color = "btn-white-mentorbaik"}) => {
    return (
        <button 
            type="button" 
            aria-label={ariaLabel}
            className={`btn ${color} ${style}`} 
            onClick={onClick} 
            data-dismiss={dataDismissed ? "modal": ""}
            data-toggle={dataToggled ? "modal" : ""}
            data-target={dataTarget ? dataTarget : ""}
        >
            {children}
        </button>
    )
}

export default Button