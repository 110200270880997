import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Images } from "./";
import moment from "moment";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';

import ReactPlayer from "react-player";
import Loader from "react-loader-spinner";

import Comment from "./Timeline/Comment";
import PopupShare from "../parts/PopupShare";
import { Alert, Col, Row } from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from "./ImageModal";
import DeleteModal from "./DeleteModal";
import ReactHlsPlayer from 'react-hls-player';
import FolderModal from "./FolderModal";
import renderHTML from "react-render-html";

const style = {};

const CDNPlayer = (props) => {
  const { item } = props;
  const [show, is_show] = useState(false);  

  return <>
    {!show && <><img src={item.image_url} style={{ width: '100%', cursor: 'pointer' }} alt="" onClick={() => {
      is_show(true);
    }} /><p>Click to play video</p></>}
    {show && <ReactHlsPlayer
      src={item.video_url}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
    />
    }
  </>
}

const FolderTimeline = (props) => {
  const {
    progress,
    posts,
    premium,
    events,
    isLogin,
    is_mentor,
    toggle,
    onToggle,
    onRefresh,
    callAction,
    call_action,
    can_post,
    on_toggle_modal,
    toolbar,
    onEdit,
    onReload
  } = props;

  const [follow, toggleFollow] = useState(false);
  const [items, setItems] = useState(posts ? posts : []);
  const [update, setUpdate] = useState(0);
  const [allUser, setAllUser] = useState([]);  
  const [update_in_progress, toggleProgress] = useState(false);
  const [toggleReply, setToggle] = useState(true);
  const [toggleShare, setToggleShare] = useState(false);
  const [shareItem, setShareItem] = useState(null);
  const [readmore, setReadmore] = useState({});
  const [sharelink, setShareLink] = useState(null);
  const [toggle_image, setToggleImage] = useState(false);
  const [selected_image, setSelectedImage] = useState(null);
  const [toggle_delete, setToggleDelete] = useState(false);
  const [deleted_item, setDeletedItem] = useState(null);
  const [delete_status, setDeleteStatus] = useState(0);
  const [selected_item, setSelectedItem] = useState(null);
  const [toggle_save, setToggleSave] = useState(false);
  const [on_create_folder, setCreateFolder] = useState(false);

  const onDelete = () => {

  }
  const follow_user = (item) => {
    try {
      callAction(call_action, "follow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/follow`,
      });
      toggleProgress(true);

      let rs = posts.map((p) => {
        if (p.mentor_id === item.mentor_id) {
          p.is_followed = 1;
        }
        return p;
      });
      setItems(rs);
    } catch (err) {
      console.log(err);
    }
  };
  const unfollow_user = (item) => {
    try {
      callAction(call_action, "unfollow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/unfollow`,
      });
      toggleProgress(true);

      let rs = posts.map((p) => {
        if (p.mentor_id === item.mentor_id) {
          p.is_followed = 0;
        }
        return p;
      });
      setItems(rs);
    } catch (err) {
      console.log(err);
    }
  };

  const onFollowClicked = (item) => {
    if (item.is_followed === 0) follow_user(item);
    else unfollow_user(item);
  };

  const toggleComments = (item) => {
    let updated_items = items.map((i) => {
      if (i.id === item.id) {
        i.toggle_comments = !i.toggle_comments;
      }
      return i;
    });
    setItems(updated_items);
    setUpdate(update + 1);
  };

  const onReadMore = (item, is_subscribed) => {

    let updated_items = items.map((i) => {
      if (!i.toggle_read) i.toggle_read = false;
      if (i.id === item.id) {
        i.toggle_read = !i.toggle_read;
      }
      return i;
    });
    setItems(updated_items);
    setUpdate(update + 1);
  }
  const isOwnPost = (item) => {
    if (parseInt(item.mentor_id) === parseInt(localStorage.getItem("mentor_id") && parseInt(item.mentor_id) > 0)) {
      return true;
    }
  }
  const deletePost = () => {
    callAction(call_action, "delete_post", {
      endpoint: `/v1/mentor/${deleted_item.mentor_id}/post/${deleted_item.id}/delete`,
    });
  }
  const like = (item) => {
    callAction(call_action, "like", {
      endpoint: `/v1/posts/${item.id}/like`,
    });
    item.is_liked = 1;
    item.total_likes++;
  }
  const unlike = (item) => {
    callAction(call_action, "unlike", {
      endpoint: `/v1/posts/${item.id}/unlike`,
    });
    item.is_liked = 0;
    item.total_likes--;
  }
  const saved = (item) => {
    //open folder popup
    setSelectedItem(item);
    setToggleSave(true);
  }

  const unsaved = (item) => {
    console.log('unsaved', item);
    callAction(call_action, "unsaved", {
      endpoint: `/v1/folders/remove/${item.id}`,
    });
    item.is_saved = 0;
  }

  const bookmark = (item, folder_id) => {
    callAction(call_action, "like", {
      endpoint: `/v1/post/${item.id}/save/${folder_id}`,
    });
  }

  const getAllUser = () => {
    callAction(call_action, "allUser", {
      endpoint: `/v1/authors`,
      data: {
        fetchType: "all"
      },
    });
  }

  useEffect(() => {
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(true);
    }
  }, [call_action.follow_mentor]);
  
  useEffect(() => {
    if (call_action.allUser) {
      setAllUser(call_action.allUser.data);
    }
  }, [call_action.allUser]);

  useEffect(() => {
    if (call_action.delete_post) {
      setDeleteStatus(0);
      setDeletedItem(null);
      setToggleDelete(false);
      if (onReload) onReload();

    }
  }, [call_action.delete_post]);

  useEffect(() => {
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(false);
    }
  }, [call_action.unfollow_mentor]);

  useEffect(() => {
    posts.map((p) => {
      p.toggle_comments = false;
    });
    premium.map((p) => {
      p.toggle_comments = false;
    });
    setItems(posts);
    setUpdate(update + 1);
    getAllUser()
  }, []);

  useEffect(() => {
    posts.map((p) => {
      p.toggle_comments = false;
    });
    premium.map((p) => {
      p.toggle_comments = false;
    });
    setItems(posts);
    setUpdate(update + 1);
  }, [posts, premium]);

  function addStyle(string) {
    return string.split("\n").join("\<br/>")
  }
  
  const renderImage = (imageUrl) => (
    <img src={imageUrl} style={{ width: '100%', cursor: 'pointer' }} alt="" onClick={() => {
      setSelectedImage(imageUrl);
      setToggleImage(true);
    }} />
  )

  return (
    <>

      {can_post === true && (<div className="widget-box mentor-timeline-widget">

        <div className="widget-user-header">
          <img
            src={localStorage.getItem("avatar")}
            className="user-image"
            alt="images"
          />
          <a
            className="whats-your-mind-button"
            onClick={() => {

              if (on_toggle_modal) on_toggle_modal();
            }}
          >
            {is_mentor ? `What’s on your mind?` : `Tanya Mentorbaik...`}
          </a>

        </div>


      </div>)}
      {toolbar && <div className="widget-user-body" style={{ marginBottom: 30 }}>
        <div className="homementor-top-action">
          <Link
            to="#"
            className={`button topbutton-timeline ${toggle !== "public" ? `button-outline button-outline-grey` : ""
              }`}
            style={{
              paddingLeft: 18,
              paddingRight: 18
            }}
            onClick={() => {
              onToggle("public");
            }}
          >
            Latest
          </Link>
          <Link
            to="#"
            className={`button topbutton-timeline ${toggle !== "Tanya" ? `button-outline button-outline-grey` : ""
              }`}
            style={{
              paddingLeft: 18,
              paddingRight: 18
            }}
            onClick={() => {
              onToggle("Tanya");
            }}
          >
            Tanya
          </Link>
          <Link
            to="#"
            className={`button topbutton-timeline ${toggle !== "my-qna" ? `button-outline button-outline-grey` : ""
              }`}
            style={{
              paddingLeft: 18,
              paddingRight: 18
            }}
            onClick={() => {
              onToggle("my-qna");
            }}
          >
            My QnA
          </Link>
          <Link
            to="#"

            style={{
              paddingLeft: 18,
              paddingRight: 18
            }}
            className={`button topbutton-timeline ${toggle !== "Article" ? `button-outline button-outline-grey` : ""
              }`}
            onClick={() => {
              onToggle("Article");
            }}
          >
            Artikel
          </Link>
          <Link
            to="#"
            className={`button topbutton-timeline ${toggle !== "Makro" ? `button-outline button-outline-grey` : ""
              }`}
            style={{
              paddingLeft: 18,
              paddingRight: 18
            }}
            onClick={() => {
              onToggle("Makro");
            }}
          >
            Makro
          </Link>
          <Link
            to="#"

            className={`button topbutton-timeline ${toggle !== "Emiten" ? `button-outline button-outline-grey` : ""
              }`}
            style={{
              paddingLeft: 18,
              paddingRight: 18
            }}
            onClick={() => {
              onToggle("Emiten");
            }}
          >
            Emiten
          </Link>
        </div>
      </div>
      }
     
        <>
          {(!progress && items.length === 0) && (
            <div className="widget-box mentor-timeline-widget">
              <p>Belum ada postingan.</p>
            </div>
          )}
          {items.map((item, index) => {

            const own_post = isOwnPost(item) ? true : false;
            //item.is_subscribed = 1; //override dulu
            if (!item.mentor && !item.profile) return null;
             return (
              <div key={index} className="widget-box mentor-timeline-widget">
                <div className="timeline-top-user">
                  <div className="user-profile-wrap">
                    <img
                      src={item.profile ? item.profile.avatar_url : item.mentor.avatar_url}
                      className="user-image"
                      alt="user"

                      onClick={() => {
                        //document.location="/mentor/" + item.mentor.username;
                      }}
                    />
                    <div className="user-description">
                      <div className="user-name" onClick={() => {
                        //document.location="/mentor/" + item.mentor.username;
                      }}> {item.mentor ? item.mentor.username :  item.profile.nama}</div>
                      <div className="user-timestamp">
                        {" "}
                        {moment(item.created_at).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                 
                </div>
                <div className="premium-box">
                  <h4 className={`mt-4 mb-1 clickable-title`} onClick={() => {
                    if (item.is_subscribed === 0 && !own_post) {
                      document.location = "/subscribe";
                    }
                  }}>{item.symbol}: {item.title}</h4>
                  {item.post_status === 0 && <span class="badge badge-pill badge-primary">Draft</span>}
                  {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
                    <ReactMarkdown>{(item.excerpt)}</ReactMarkdown>
                    <p>
                      Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
                  </div>}

                  {(item.is_subscribed === 1 || own_post) && <div className="thumbnail-video-wrap">
                    {(
                      <Row>
                      { item.images && item.images[0].image_url !== '' ?
                        <>
                          {item.images.filter(item => item.image_url).map((item, idx) => {
                            return (
                              <Col className="thumb-video-inner" md={ idx === 0 ? 12 : 4 } key={index + item.id}>
                                {renderImage(item.image_url)}
                              </Col>)
                          })}
                        </>
                        :
                        item.image_url && !item.images ?
                        <>
                          <Col className="thumb-video-inner">
                            {renderImage(item.image_url)}
                          </Col>
                        </>
                        :
                        item.image_url && item.images[0].image_url === '' &&
                        <>
                          <Col className="thumb-video-inner">
                            {renderImage(item.image_url)}
                          </Col>
                        </>
                      }
                    </Row>
                    )}
                    {item.video_url && (
                      <div className="thumb-video-inner">
                        {item.is_cdn === 1 && <CDNPlayer item={item} />}
                        {item.is_cdn === 0 && <ReactPlayer url={item.video_url} width={"100%"} />}
                      </div>
                    )}

                  </div>}


                  {((item.is_subscribed === 1 || is_mentor || own_post) && !item.toggle_read) && <div>
                    { 
                      item.styled_excerpt ? 
                        renderHTML(item.styled_excerpt )
                        :
                        item.excerpt 
                    }
                    <div className={`mb-4`}>
                      <a href="javascript:void(0);" onClick={() => {
                        onReadMore(item);
                      }}>Read More...</a>
                    </div>
                  </div>}


                  {((item.is_subscribed === 1 || is_mentor || own_post) && item.toggle_read) && 
                    <div>
                      {/* <ReactMarkdown>{(item.content)}</ReactMarkdown> */}
                      <div className="text-justify">
                        {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
                      </div>
                      <br/>
                      <div className="text-justify mb-4">
                        {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
                      </div>
                    </div>
                  }


                  {(item.is_subscribed === 1 || own_post || is_mentor) && <div className="mentor-comment-wrap">
                    <div class="comment-title">
                      
                          <Link
                            to={`#`}
                            className="iconbox-left linkable"
                            onClick={() => item.is_liked === 0 ? like(item) : unlike(item)}
                          >
                            <i
                              class={
                                item.is_liked === 1
                                  ? `fas fa-thumbs-up on`
                                  : `fas fa-thumbs-up off`
                              }
                            ></i>
                            <span>
                              {item?.total_likes > 0 ? `${item?.total_likes} Likes` : `Like`}
                            </span>
                          </Link>
                       
                      <Link onClick={() => {
                        navigator.clipboard.writeText(item.permalink
                          ? item.permalink
                          : "https://mentorbaik.com/posts/" + item.id);
                        item.copied = true;
                      }} class="iconbox-left linkable"><i className={`fas fa-share-alt`} /> <span>{item?.copied ? 'Copied' : 'Permalink'}</span></Link>

                     
                          <Link
                            to={`#`}
                            className="iconbox-left linkable"
                            onClick={() => item.is_saved === 0 ? saved(item) : unsaved(item)}
                          >
                            <i
                              class={
                                item.is_saved === 1
                                  ? `fas fa-bookmark on`
                                  : `fas fa-bookmark off`
                              }
                            ></i>
                            <span>
                              Save
                            </span>
                          </Link>
                      
                      <Link
                        to={`#`}
                        class="iconbox-left linkable"
                        onClick={() => {
                          toggleComments(item);
                        }}
                      >
                        <i class="far fa-comments"></i>
                        <span>{item.comments ? item.comments : 0}</span>
                      </Link>
                      {/*}
                      {parseInt(localStorage.getItem("mentor_id")) !==
                        item.mentor_id && (
                          <Link
                            to={`#`}
                            className="iconbox-left linkable"
                            onClick={() => {
                              onFollowClicked(item);
                              return;
                            }}
                          >
                            <i
                              class={
                                item.is_followed === 1
                                  ? `fas fa-user-check`
                                  : `fas fa-user-plus`
                              }
                            ></i>
                            <span>
                              {item.is_followed === 1 ? `Followed` : `Follow`}
                            </span>
                          </Link>
                        )}
                            {*/}
                      {/*}
                      <a href="#" onClick={() => {
                        setToggleShare(true);
                        setShareItem(item);
                        setShareLink(item.permalink
                          ? item.permalink
                          : "https://mentorbaik.com/posts/" + item.id);
                      }} class="iconbox-left linkable"><i className={`fas fa-share-alt`} /> <span>Share</span></a>
                    {*/}

                      {/*}
                      {(item.is_subscribed === 1 && !own_post) && (
                        <a className="button btn-subscribed">Subscribed</a>
                      )}*/}
                    </div>

                    {item.symbol !== 'Tanya' && <Comment
                      show={item.toggle_comments}
                      item={item}
                      can_reply={true}
                      allUser={allUser}
                      {...props}
                      post_id={item.id}
                      onReply={() => {
                        setToggle(false);
                      }}
                      onClose={() => {
                        setToggle(true);
                      }}
                      onReplyDone={() => {
                        toggleComments(item);
                      }}
                    />}
                    {(item.symbol === 'Tanya' && (item.is_subscribed === 1 || own_post || is_mentor)) && <Comment
                      show={item.toggle_comments}
                      item={item}
                      can_reply={is_mentor}
                      {...props}
                      allUser={allUser}
                      post_id={item.id}
                      onReply={() => {
                        setToggle(false);
                      }}
                      onClose={() => {
                        setToggle(true);
                      }}
                      onReplyDone={() => {
                        toggleComments(item);
                      }}
                    />}
                  </div>}
                </div>
              </div>
            );
          })}
          {progress && <div style={{ textAlign: "center" }}>
            <Loader type="Puff" color="#00BFFF" height={30} width={30} />
          </div>}
        </>
      <PopupShare
        show={toggleShare}
        sharelink={sharelink}
        shareItem={shareItem}

        onClose={() => {
          setToggleShare(false);
        }}
      />
      <ImageModal show={toggle_image} item={selected_image} onClose={() => {
        setToggleImage(false);
      }} />

      <DeleteModal
        delete_status={delete_status}
        item={deleted_item}
        show={toggle_delete}
        onClose={() => {
          setToggleDelete(false);
          setDeleteStatus(0);
        }} onConfirm={() => {
          //();
          setDeleteStatus(1);
          deletePost();
        }} />

      <FolderModal {...props}
        item={selected_item}

        show={toggle_save}
        onSaved={() => {
          setToggleSave(false);
          selected_item.is_saved = 1;
        }}
        onClose={() => {
          setToggleSave(false);

        }} />
    </>
  );
}

export default FolderTimeline;