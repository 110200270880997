import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Footer, Header, Images } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";

const MentorTerms = (props) => {
  const { callAction, call_action } = props;

  const style1 = {
    color: "#666",
    fontSize: "16px",
  };
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
  }, []);
  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center pt-4 pb-4">
              <div className="col-md-12">
                <div className="widget-box main-account-content account-privacy">
                  <div class="widget-body">
                    <h2>Terms of Use</h2>
                    <p>
                      Mentorbaik provides the Mentorbaik.com website and any
                      other websites or its affiliates (collectively, the "Site"
                      or the "Sites") and the products and services available at
                      the Sites (taken together with the Sites, the "Service")
                      to you, the user, subject to your compliance with all the
                      terms and conditions herein (the "Terms"), as well as any
                      other written agreement between us and you. In these
                      Terms, "you" or "your" means the person accepting these
                      Terms and the company (if any) on whose behalf he/she is
                      acting, and "we," "us," "our, "Mentorbaik," or the
                      "Company" means Mentorbaik. and its affiliates. BY USING
                      THIS SITE, YOU AGREE TO BE BOUND BY THESE TERMS OF USE.
                      PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN
                      IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS,
                      REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS
                      LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION
                      CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.
                    </p>
                    <p className="mb-0">MODIFICATIONS OF TERMS OF USE</p>
                    <p>
                      Mentorbaik reserves the right, at its sole discretion, to
                      modify or replace the Terms of Use at any time. You shall
                      be responsible for reviewing and becoming familiar with
                      any such modifications. Use of the Services by you
                      following such notification constitutes your acceptance of
                      the terms and conditions of the Terms of Use as modified.
                    </p>
                    <p className="mb-0">REGISTERED USERS & SUBCRIBERS</p>
                    <p>
                      In order to become a Registered User, you must create an
                      account using the Website interface, and click on the
                      activation link in the email that we will send to you.
                      Registered Users gain access to additional services and
                      restricted areas on the Website, which may include: (i)
                      the ability to read, like and comment PREMIUM POSTS in our
                      Website streams; (ii) the ability to post question related
                      to stock investing topics; (iii) the ability to read on
                      other member's question and answer; (iv) facility to
                      create a detailed profile which may be made available via
                      the Website.
                      <br />
                      <br />
                      Registered Users must not allow any other person to use
                      their user ID and password, and they must ensure that that
                      user ID and password are kept confidential. Registered
                      Users accept responsibility for all activities that occur
                      under or in relation to their user ID and password. Upon
                      our request, Registered Users and will provide to us proof
                      of their identity and/or age.
                      <br />
                      <br />
                      We may also be required by law or regulation to identify
                      Registered Users and disclose their details to a third
                      party including the relevant legal or regulatory authority
                      if requested by them to do so. We may alter or restrict
                      the services and areas that Registered Users have access
                      to, and/or delete any Registered User's account, in our
                      sole discretion without notice or explanation.
                      <br />
                      <br />
                      You may only Use the Website if you are at least 18 years
                      of age.
                    </p>
                    <p className="mb-0">COPYRIGHT AND LIMITATIONS ON USE</p>
                    <p>
                      The content available through Mentorbaik.com is the
                      property of Mentorbaik or its licensors and is protected
                      by copyright and other intellectual property laws. Content
                      includes for example, stories and other items displayed on
                      the site. Content received through Mentorbaik.com may be
                      displayed, reformatted, and printed for your personal,
                      non-commercial use only. You agree not to reproduce,
                      retransmit, distribute, disseminate, sell, publish,
                      broadcast or circulate the content received through
                      Mentorbaik.com to anyone, including but not limited to
                      others in the same company or organization, nor any use of
                      data mining, robots, spiders, or similar data gathering
                      and extraction tools for any purpose without the express
                      prior written consent of Mentorbaik, with this one
                      exception: You may, on an occasional and irregular basis,
                      disseminate an insubstantial portion of content from
                      Mentorbaik.com, for a noncommercial purpose, without
                      charge, and transmitted in non-electronic form, to a
                      limited number of individuals, provided you include all
                      copyright and other proprietary rights notices with such
                      portion of the content in
                      <br />
                      <br />
                      the same form in which the notices appear in
                      Mentorbaik.com, original source attribution, and the
                      phrase "Used with permission from Mentorbaik." You may not
                      post any content from Mentorbaik.com to newsgroups, mail
                      lists or electronic bulletin boards, without the prior
                      written consent of Mentorbaik. Mentorbaik.com includes
                      facts, views, opinions and recommendations of individuals
                      and organizations deemed of interest. Mentorbaik and its
                      content licensors do not guarantee the accuracy,
                      completeness or timeliness of, or otherwise endorse, these
                      views, opinions or recommendations, give tax or investment
                      advice, or advocate the purchase or sale of any security
                      or investment.
                      <br />
                      <br />
                      The Subscriber further agrees that only one user will log
                      in and use this subscription. Subscriptions are meant for
                      one user only and are not to be shared. Additional users
                      must have their own user id and password and their owner
                      subscription.
                    </p>
                    <p className="mb-0">SUBSCRIBER FEES</p>
                    <p>
                      Subscriber will pay the for the non-refundable, monthly
                      subscription with the option of one (1), three (3), six
                      (6) and twelve (12) months. All fees will be paid at the
                      beginning of service. Service of the subscription will not
                      begin until all charges have been processed.
                      <br />
                      <br />
                      Mentorbaik may offer, as indicated on the Order Form, the
                      Subscriber will be charged at the beginning of the initial
                      service period for services, which starts after the trial
                      period has concluded. Following any trial period, the
                      normal terms of this Agreement will remain in effect.
                    </p>
                    <p className="mb-0">PAYMENT METHOD</p>
                    <p>Mentorbaik provides the following payment methods:</p>
                    <ul className="ml-3">
                      <li>Credit Cards: Visa and Mastercard</li>
                      <li>Internet Banking</li>
                      <li>Virtual Account</li>
                    </ul>
                    <p>
                      Mentorbaik is not responsible for any misuse of credit
                      card or any payment methods that belongs to the user. The
                      onus is on the credit card holders to input the details
                      correctly when transacting and always safe-keep their
                      credit card information only to themselves. Mentorbaik is
                      not responsible for any transaction fraud arising from
                      credit card payments or any other payment methods. Users
                      will understand that any payment is assumed to be paid
                      from the credit card holders/ bank account holders and
                      will further understand that these fraud issues is beyond
                      Mentorbaik control and hence will indemnify Mentorbaik
                      from all liabilities arising from transactional fraud.
                      <br />
                      <br />
                      All transactions will be processed in Rupiah and/or
                      converted to the equivalent Rupiah value. All transaction
                      will be processed within 24 hours.
                      <br />
                      <br />
                      When you successfully complete your order for our
                      Subscription Service, you agree that the Product is
                      immediately available to you. As the services are provided
                      at your own request, we do not provide a refund after
                      completion of purchase.
                    </p>
                    <p className="mb-0">SUBSCRIBER TERM/TERMINATION</p>
                    <p>
                      Following the initial term this Agreement shall auto-renew
                      on one (1), three (3), six (6) and twelve (12) months with
                      all services re-activated for the respective account.
                      Further, Mentorbaik reserves the right to immediately
                      suspend performance or terminate this Agreement without
                      notice and without liability in the event that: (a)
                      Subscriber fails to pay any amount due to Mentorbaik or
                      charges back through the applicable credit card company
                      any amounts billed, (b) any Content that is necessary for
                      Mentorbaik to perform its obligations is suspended or
                      terminated and/ or (c) in the event of any conduct by
                      Subscriber which Mentorbaik, in its sole discretion,
                      considers to be unacceptable, or in the event of any
                      breach by Subscriber of this Agreement. Mentorbaik may
                      discontinue any of our subscription products at any time,
                      with or without notice and for any reason, including if it
                      believes that you are under 18. Any suspected fraudulent,
                      abusive or illegal activity may be grounds for terminating
                      your relationship and may be referred to appropriate law
                      enforcement authorities.
                      <br />
                      <br />
                      Without prejudice to our other rights under these Terms,
                      if you (including for the avoidance of doubt Registered
                      Users and Subscribers) breach any of these Terms in any
                      way, or if we reasonably suspect that you have breached
                      any of these Terms in any way, we may:
                    </p>
                    <p>(a) delete, move or edit any of your User Content;</p>
                    <p>(b) send you one or more formal warnings;</p>
                    <p>
                      (c) temporarily suspend your access to the Website and/or
                      the Subscription Service;
                    </p>
                    <p>(d) delete your account;</p>
                    <p>
                      (e) permanently prohibit you from using the Website and/or
                      the Subscription Service;
                    </p>
                    <p>
                      (f) block computers using your IP address from accessing
                      the Website;
                    </p>
                    <p>
                      (g) contact your internet services provider and request
                      that they block your access to the Website; and/or
                    </p>
                    <p>
                      (h) bring court proceeding against you for breach of
                      contract or otherwise.
                    </p>
                    <p>
                      Where we suspend or prohibit or block your access to the
                      Website, part of the Website, and/or the Subscription
                      Service, your right to use the services available on this
                      site immediately ceases. We shall not be liable to you or
                      any third party for any claims or damages arising out of
                      any termination or suspension or any other actions taken
                      by us in connection with such termination or suspension.
                      You must not take any action to circumvent such suspension
                      or prohibition or block (including without limitation
                      using a different account).
                    </p>
                    <p className="mb-0">CHANGED TERMS</p>
                    <p>
                      Mentorbaik.com shall have the right at any time to change
                      or modify the terms and conditions applicable to
                      Subscriber's use of Mentorbaik.com, or any part thereof,
                      or to impose new conditions, including (but not limited
                      to) adding fees and charges for use. Such changes,
                      modifications, additions or deletions shall be effective
                      immediately upon notice thereof, which may be given by
                      means including (but not limited to) posting on
                      Mentorbaik.com, or by electronic or conventional mail, or
                      by any other means by which Subscriber obtains notice
                      thereof. Any use of Mentorbaik.com by Subscriber after
                      such notice shall be deemed to constitute acceptance by
                      Subscriber of such changes, modifications or additions.
                    </p>
                    <p className="mb-0">USER CONTENT</p>
                    <p>
                      You are solely responsible for the Content that you post
                      on or through the Service, or otherwise transmit to or
                      share with other Users (collectively, the "User Content").
                      You may not post or share User Content on the Service that
                      you did not create or that you do not have permission to
                      post. You acknowledge that Mentorbaik does not pre-screen
                      or approve User Content, but that Mentorbaik shall have
                      the right (but not the obligation) in its sole discretion
                      to refuse, delete or remove any User Content that is
                      available via the Service, in connection with violations
                      of the letter or spirit of the Terms or for any other
                      reason. You are solely responsible at your sole cost and
                      expense for creating backup copies and replacing any User
                      Content you post or store on the Service or otherwise
                      provide to the Company.
                      <br />
                      <br />
                      All postings, messages, text, images, video or other
                      materials ("Content") posted or published on, uploaded to,
                      transmitted through, or linked from, the Service
                      (hereinafter, "post", "posted" or "published"), whether by
                      users of the Service ("Users") or Creators (as defined
                      below), are the sole responsibility of the person from
                      whom such Content originated. Site and Content available
                      through the Service may contain links to other websites
                      which are completely independent of Mentorbaik. Mentorbaik
                      makes no representation or warranty as to the accuracy,
                      completeness or authenticity of the information contained
                      in any such site. Your linking to any other websites is
                      done at your own risk. You agree that you must evaluate,
                      and bear all risks associated with, the use of any
                      Content, that you may not rely on said Content, and that
                      under no circumstances will Mentorbaik, its affiliates, or
                      partners be liable in any way for any Content or for any
                      loss or damage of any kind incurred as a result of the use
                      of any Content posted or otherwise made available via the
                      Service.
                      <br />
                      <br />
                      When you post User Content to the Service, you authorize
                      and direct us to make such copies thereof as we deem
                      necessary in order to facilitate the posting and storage
                      of the User Content on the Service. By posting User
                      Content to any part of the Service, you automatically
                      grant, and you represent and warrant that you have the
                      right to grant, to the Company an irrevocable, perpetual,
                      non-exclusive, transferable, fully paid, worldwide license
                      (with the right to sublicense) to use, copy, publicly
                      perform, publicly display, reformat, translate, excerpt
                      (in whole or in part) and distribute such User Content for
                      any purpose, commercial, advertising, or otherwise, on or
                      in connection with the Service or the promotion thereof,
                      to prepare derivative works of, or incorporate into other
                      works, such User Content, and to grant and authorize
                      sublicenses of the foregoing. Mentorbaik does not assert
                      any ownership over your User Content; rather, as between
                      us and you, subject to the rights granted to us in these
                      Terms, you retain full ownership of all of your User
                      Content and any intellectual property rights or other
                      proprietary rights associated with your User Content.
                      <br />
                      <br />
                      You must ensure that your User Content, material linked to
                      by the User Content, and the Use of User Content in
                      accordance with these Terms:
                    </p>
                    <p>
                      (a) does not infringe any third party's rights (including
                      without limitation Intellectual Property Rights,
                      reputational rights, rights of confidence and rights of
                      privacy);
                      <br />
                      <br />
                      (b) does not give rise to any cause of action in any
                      jurisdiction, whether against us, you or a third party;
                      <br />
                      <br />
                      (c) does not violate any law, statute, ordinance,
                      regulation, legally binding code or market conventions in
                      any jurisdiction (including, without limit, financial
                      services regulations) whether in the United Kingdom or
                      elsewhere;
                      <br />
                      <br />
                      (d) is not pornographic or sexually explicit;
                      <br />
                      <br />
                      (e) does not duplicate or substantially duplicate User
                      Content previously published on the Website;
                      <br />
                      <br />
                      (f) is not threatening, abusive, harassing, menacing or
                      contrary to the principles of internet etiquette;
                      <br />
                      <br />
                      (g) does not constitute spam or marketing material (any
                      omotional activity should only take place through your
                      Profile, as discussed here [insert link]); and;
                      <br />
                      <br />
                      (h) does not contain any viruses, Trojan horses, worms,
                      time bombs or other computer programming routines that are
                      intended to damage, detrimentally interfere with,
                      surreptitiously intercept or expropriate any system, data
                      or personal information.
                      <br />
                      <br />
                      You undertake to keep yourself informed as to the contents
                      of our House Rules and to ensure that your User Content
                      and your user behaviour is in line with the House Rules.
                      We do not undertake to monitor your use of the Website or
                      the posting of User Content by you or any third party on
                      the Website. Nor do we warrant that the Users of the
                      Website have adhered to these Terms, although we use
                      reasonable endeavours to remove User Content which
                      breaches these Terms after it has been brought to our
                      attention. If you become aware of any User Content on the
                      Website which breaches these Terms, you should notify us
                      of that content immediately. You acknowledge that we may
                      edit, delete and/or move User Content without notice or
                      explanation.
                      <br />
                      <br />
                      USER CONTENT DISCLAIMER
                      <br />
                      The Service is not intended to provide tax, legal or
                      investment advice, and nothing on the Service should be
                      construed as an offer to sell, a solicitation of an offer
                      to buy, or a recommendation for any security. Trading in
                      such securities can result in immediate and substantial
                      losses of the capital invested. You should only invest
                      risk capital, and not capital required for other purposes.
                      You alone are solely responsible for determining whether
                      any investment, security or strategy, or any other product
                      or service, is appropriate or suitable for you based on
                      your investment objectives and personal and financial
                      situation. You should also consult an attorney or tax
                      professional regarding your specific legal or tax
                      situation.
                      <br />
                      <br />
                      The Content is to be used for informational and
                      entertainment purposes only and the Service does not
                      provide investment advice for any individual. Mentorbaik,
                      its affiliates and partners specifically disclaim any and
                      all liability or loss arising out of any action taken in
                      reliance on Content, including but not limited to market
                      value or other loss on the sale or purchase of any
                      company, property, product, service, security, instrument,
                      or any other matter.
                      <br />
                      <br />
                      The creators of the Content may hold long or short
                      positions in the companies are free to buy or sell those
                      positions at will. The Creators may take positions
                      inconsistent with the views expressed. User Content
                      available on the Mentorbaik platform contain the Creators'
                      own opinions, and none of the information contained
                      therein constitutes a recommendation that any particular
                      security, portfolio of securities, transaction, or
                      investment strategy is suitable for any specific person.
                      You further understand that the Creators will not advise
                      you personally concerning the nature, potential, value or
                      suitability of any particular security, portfolio of
                      securities, transaction, investment strategy or other
                      matter.
                      <br />
                      <br />
                      You understand that an investment in any security is
                      subject to a number of risks, and that discussions of any
                      security published on the Service will not contain a list
                      or description of relevant risk factors. In addition,
                      please note that some of the stocks about which Content is
                      published on the Service have a low market capitalization
                      and/or insufficient public float. Such stocks are subject
                      to more risk than stocks of larger companies, including
                      greater volatility, lower liquidity and less publicly
                      available information.
                      <br />
                      <br />
                      WARRANTIES
                      <br />
                      You acknowledge that the Website and the material on the
                      Website may be changed from time to time without notice.
                      <br />
                      <br />
                      Notwithstanding the warranties above, Mentorbaik is not
                      authorised to give investment advice and nothing on the
                      Website should be interpreted as Mentorbaik seeking to
                      offer such advice. Mentorbaik Content, Mentorbaik Data,
                      User Content and/or relevant Third Party Content is
                      provided 'as is' and may contain errors or inaccuracies.
                      You should seek confirmation from an authoritative third
                      party source of any information found on the Website upon
                      which you may wish to rely. Use of Mentorbaik Content,
                      Mentorbaik Data, User Content and/or Third Party Content
                      is solely at the Subscriber's risk.
                      <br />
                      <br />
                      Subscriber acknowledges that the use and interpretation of
                      the Mentorbaik Data, and any data analysis tools provided
                      as part of the Subscription Service requires skill and
                      judgement. Subscribers shall at all times exercise their
                      own skill and judgement in the Use and interpretation of
                      the Subscription Service and shall be solely responsible
                      for the purposes for which it is used and for all
                      opinions, recommendations, forecasts and other comments
                      made or action taken by Subscribers based wholly or partly
                      on the Mentorbaik Data and/or data analysis tools.
                      <br />
                      <br />
                      Subject to the warranty given above in respect of the
                      paid-for Subscription Service:
                      <br />
                      <br />
                      (a) we do not warrant the completeness, veracity, bona
                      fides or accuracy of the material on the Website; nor do
                      we commit to ensuring that the Website remains available
                      or that the material on the Website is kept up-to-date;
                      <br />
                      <br />
                      (b) we do not grant to you any warranties or make any
                      representations relating to the Website or your Use of the
                      Website, and to the fullest extent permitted by applicable
                      law we exclude all warranties and representations.
                      <br />
                      <br />
                      You acknowledge that the material on the Website submitted
                      by third parties (including our Registered Users) from
                      time to time is the sole responsible of the third party
                      who has submitted that material. If you become aware of
                      any inaccuracy or error on the website, please let us
                      know.
                      <br />
                      <br />
                      INDEMNITY
                      <br />
                      You agree to indemnify and hold the Company, its
                      subsidiaries and affiliates, and each of their members,
                      managers, directors, officers, agents, contractors,
                      partners and employees, harmless from and against any
                      loss, liability, claim, demand, damages, costs and
                      expenses, including reasonable attorney's fees, arising
                      out of or in connection with your use of the Service,
                      Subscriber's Account, any Content you post or Content you
                      share on or through the Service or otherwise, your conduct
                      in connection with the Service or with other Users, or any
                      violation of these Terms or of any law, rule, regulation
                      or order, or the rights of any third party. This indemnity
                      shall survive any termination or cessation of use by you
                      of the Service.
                      <br />
                      <br />
                      FINANCIAL DISCLAIMER
                      <br />
                      Mentorbaik.com is a news portal, discussion forum and
                      content aggregator. Mentorbaik is not a broker/dealer, we
                      are not an investment advisor, we have no access to
                      non-public information about publicly traded companies,
                      and this is not a place for the giving or receiving of
                      financial advice, advice concerning investment decisions
                      or tax or legal advice. We are not regulated by the
                      Financial Services Authority.
                      <br />
                      <br />
                      Mentorbaik is an educational forum for analysing, learning
                      & discussing general and generic information related to
                      stocks, investments and strategies. No content on the site
                      constitutes - or should be understood as constituting - a
                      recommendation to enter in any securities transactions or
                      to engage in any of the investment strategies presented in
                      our site content. We do not provide personalised
                      recommendations or views as to whether a stock or
                      investment approach is suited to the financial needs of a
                      specific individual.
                      <br />
                      <br />
                      Much of the content on this site is user-generated and, as
                      such, impossible to monitor effectively. Excluding the
                      Mentorbaik Data, all of the information on our Website
                      about businesses, companies, investment strategies,
                      investments and investment opportunities is provided by
                      our Registered Users and other third parties. We act as a
                      mere conduit for this information published on our
                      Website, and we do not select, monitor, edit, modify
                      review, evaluate or otherwise oversee the information or
                      the publication of the information on our Website.
                      <br />
                      <br />
                      Where we present investment screens, the results should
                      only be treated as candidates for further research, not as
                      a buy list or set of recommendations. Screening may help
                      to narrow a search based on pre-defined criteria but it is
                      not a substitute for independent research reflecting your
                      individual criteria for investing/trading.
                      <br />
                      <br />
                      Mentorbaik Data is intended to be used and must be used
                      for informational purposes only. Mentorbaik Data is
                      provided 'as is' and may contain errors or inaccuracies.
                      It is very important to do your own analysis before making
                      any investment based on your own personal circumstances.
                      You should take professional financial advice in
                      connection with, or independently research and verify, any
                      information that you find on our Website and wish to rely
                      upon, whether for the purpose of making an investment
                      decision or otherwise. Any arrangements between you and
                      any third party contacted via the Website are at your sole
                      risk.
                      <br />
                      <br />
                      Accordingly, we will not be liable, whether in contract,
                      tort (including negligence) or otherwise, in respect of
                      any damage, expense or other loss you may suffer arising
                      out of such information or any reliance you may place upon
                      such information.
                      <br />
                      <br />
                      Our Website is directed only to Indonesian residents. For
                      the avoidance of doubt, the Website is not being offered
                      in the US or Canada or to US persons or Canadian persons.
                      Nothing on this Website or any part thereof is intended to
                      constitute an offer or solicitation to buy or sell
                      investments in any jurisdiction.
                      <br />
                      <br />
                      GENERAL DISCLAIMER
                      <br />
                      The Service may be temporarily unavailable from time to
                      time for maintenance or other reasons. The Company assumes
                      no responsibility for any error, omission, interruption,
                      deletion, defect, delay in operation or transmission,
                      communications line failure, theft or destruction or
                      unauthorized access to, or alteration of, User Content.
                      The Company is not responsible for any technical
                      malfunction or other problems of any telephone network or
                      service, computer systems, servers or providers, computer
                      or mobile phone equipment, or software on account of
                      technical problems or traffic congestion on the Internet
                      or at any site or with respect to the Service or
                      combination thereof, including injury or damage to User's
                      or to any other person's computer, mobile phone, or other
                      hardware or software, related to or resulting from using
                      or downloading Content in connection with the Web and/or
                      in connection with the Service. Under no circumstances
                      will the Company be responsible for any loss or damage,
                      including any loss or damage to any User Content or
                      personal injury or death, resulting from anyone's use of
                      the Service or any posted on or through the Service or
                      transmitted to or by Users, or any interactions between
                      Users, whether online or offline.
                      <br />
                      <br />
                      Mentorbaik is not a registered broker-dealer and does not
                      endorse or recommend the services of any brokerage
                      company. The brokerage company you select (and not
                      Mentorbaik) is solely responsible for its services to you.
                      MENTORBAIK, ITS AFFILIATES, AND PARTNERS SHALL NOT BE
                      LIABLE FOR ANY DAMAGES OR COSTS OF ANY TYPE ARISING OUT OF
                      OR IN ANY WAY CONNECTED WITH YOUR USE OF THE SERVICES OF
                      ANY BROKERAGE COMPANY. THE SERVICE AND THE CONTENT ARE
                      PROVIDED "AS-IS" AND THE COMPANY, ITS PARTNERS AND
                      AFFILIATES, DISCLAIM ANY AND ALL REPRESENTATIONS AND
                      WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED
                      WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A
                      PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE COMPANY CANNOT
                      GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM
                      USE OF THE SERVICE. THE COMPANY DOES NOT REPRESENT OR
                      WARRANT THAT SOFTWARE, CONTENT OR MATERIALS ON THE SERVICE
                      OR ELSEWHERE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR
                      ERROR-FREE OR THAT THE SERVICE OR ANY OF THE SERVERS USED
                      TO OPERATE THE SERVICE ARE FREE OF VIRUSES OR OTHER
                      HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE CAUTION
                      IN THE USE AND DOWNLOADING OF ANY CONTENT OR MATERIALS AND
                      USE INDUSTRY-RECOGNIZED SOFTWARE TO DETECT AND DISINFECT
                      VIRUSES. WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND
                      AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN CONTENT,
                      DATA OR OTHER MATERIAL FROM OR THROUGH THE SERVICE AT YOUR
                      OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
                      RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOUR
                      MOBILE DEVICE OR COMPUTER SYSTEM, LOSS OF DATA OR OTHER
                      HARM OF ANY KIND THAT MAY RESULT.
                      <br />
                      <br />
                      You agreed to the publication of feedback and comments
                      about you by others on the Website, you acknowledge that
                      such feedback and comments may be critical or defamatory,
                      and you agree that you will not hold us liable in respect
                      of any such feedback and comments, irrespective of whether
                      we are aware or ought to have been aware of such feedback
                      and comments. Nothing in these Terms will exclude or limit
                      our liability for fraud, for death or personal injury
                      caused by our negligence, or for any other liability which
                      cannot be excluded or limited under applicable law.
                      <br />
                      <br />
                      Mentorbaik reserves the right to change any and all
                      Content, software and other items used or contained in the
                      Service at any time without notice. Reference to any
                      products, services, processes or other information, by
                      trade name, trademark, manufacturer, supplier or otherwise
                      does not constitute or imply endorsement, sponsorship or
                      recommendation thereof, or any affiliation therewith, by
                      the Company.
                      <br />
                      <br />
                      THIRD PARTY CONTENT
                      <br />
                      Mentorbaik.com is a distributor (and not a publisher) of
                      content supplied by third parties and Subscribers. Any
                      opinions, advice, statements, services, offers, or other
                      information or content expressed or made available by
                      third parties, including information providers,
                      Subscribers or any other user of Mentorbaik.com, are those
                      of the respective author(s) or distributor(s) and not of
                      Mentorbaik.com. Neither Mentorbaik.com nor any third-party
                      provider of information guarantees the accuracy,
                      completeness, or usefulness of any content, nor its
                      merchantability or fitness for any particular purpose. In
                      many instances, the content available through
                      Mentorbaik.com represents the opinions and judgments of
                      the respective information provider, Subscriber, or other
                      user not under contract with Mentorbaik.com.
                      Mentorbaik.com neither endorses nor is responsible for the
                      accuracy or reliability of any opinion, advice or
                      statement made on Mentorbaik.com by anyone other than
                      authorized Mentorbaik.com employee spokespersons while
                      acting in their official capacities.
                      <br />
                      <br />
                      Under no circumstances will Mentorbaik.com be liable for
                      any loss or damage caused by a Subscriber's reliance on
                      information obtained through Mentorbaik.com. It is the
                      responsibility of Subscriber to evaluate the accuracy,
                      completeness or usefulness of any information, opinion,
                      advice or other content available through Mentorbaik.com.
                      Please seek the advice of professionals, as appropriate,
                      regarding the evaluation of any specific information,
                      opinion, advice or other content.
                      <br />
                      <br />
                      Users may find advertising or other content on our Site
                      that link to the sites and services of our partners,
                      suppliers, advertisers, sponsors, licensors and other
                      third parties. We do not control the content or links that
                      appear on these sites and are not responsible for the
                      practices employed by websites linked to or from our Site.
                      In addition, these sites or services, including their
                      content and links, may be constantly changing. These sites
                      and services may have their own privacy policies and
                      customer service policies. Browsing and interaction on any
                      other website, including websites which have a link to our
                      Site, is subject to that website's own terms and policies.
                      You agree to indemnify Mentorbaik for any losses, costs,
                      expenses or damages incurred by Mentorbaik due to your
                      violation of a third party's Terms of Service. You should
                      review the applicable terms and policies, including
                      privacy and data gathering practices, of any third party
                      services which you utilize or to any links to which you
                      navigate.
                      <br />
                      <br />
                      TRADEMARKS
                      <br />
                      Mentorbaik brand and other Mentorbaik graphics, logos,
                      designs, page headers, button icons, scripts, categories,
                      forum and service name are registered trademarks,
                      trademarks or trade dress of Mentorbaik in the Republic of
                      Indonesia and/or other countries. Mentorbaik's trademarks
                      and trade dress may not be used, including as part of
                      trademarks and/or as part of domain names, in connection
                      with any product or service in any manner that is likely
                      to cause confusion. Any intention to use the Mentorbaik
                      logo and other trademarks must be requested and agreed
                      upon in written by Mentorbaik.
                      <br />
                      <br />
                      CHARGES
                      <br />
                      Subject to the Trial Period provisions above, in
                      consideration for the provision of the Subscription
                      Service, Subscribers agree to pay the Charges as detailed
                      on the Website from time to time. Without prejudice to its
                      rights to recover the sums outstanding from the
                      Subscriber, should Mentorbaik not be able to withdraw any
                      part of the Charges from the Subscriber's account or
                      credit or debit card, Mentorbaik reserves the right to:
                      <br />
                      <br />
                      suspend access to the Subscription Service; or
                      <br />
                      <br />
                      terminate this agreement to access the Subscription
                      Service
                      <br />
                      <br />
                      REFUND
                      <br />
                      Mentorbaik generally do not provide refunds or
                      cancellation on its product after payment has been paid
                      unless a mistake is made/ proven to be made by Mentorbaik.
                      <br />
                      <br />
                      Any Billing Assistance will be conducted by email
                      (support@Mentorbaik.com). Our customer service centre will
                      be open business days and hours Monday to Friday and
                      closed on weekends.
                      <br />
                      <br />
                      PRIVACY
                      <br />
                      We care about the privacy of our Users. Click here to view
                      the Mentorbaik Privacy Policy. By using the Service, you
                      are consenting to have your personal data transferred to
                      and processed in the Republic of Indonesia. As part of
                      providing you the Service, we may need to provide you with
                      certain communications, such as service announcements and
                      administrative messages. These communications are
                      considered part of the Services and your Mentorbaik
                      account, which you may not be able to opt-out from
                      receiving.
                      <br />
                      <br />
                      GOVERNING LAW
                      <br />
                      This site (excluding any linked sites) is controlled by us
                      from our offices within the DKI Jakarta, Indonesia. It can
                      be accessed from Indonesia, as well as from other
                      countries around the world. As each of these places has
                      laws that may differ from those of Republic of Indonesia,
                      by accessing this site both of us agree that the statutes
                      and laws of the Republic of Indonesia. Each of us agrees
                      and hereby submits to the exclusive personal jurisdiction
                      and venue any court of competent jurisdiction within the
                      Republic of Indonesia with respect to such matters.
                      <br />
                      <br />
                      SUBMISSIONS
                      <br />
                      You acknowledge and agree that any questions, comments,
                      suggestions, ideas, feedback or other information about
                      the Service ("Submissions"), provided by you to the
                      Company are non-confidential and shall become the sole
                      property of the Company. The Company shall own exclusive
                      rights, including all intellectual property rights, and
                      shall be entitled to the unrestricted use and
                      dissemination of these Submissions for any purpose,
                      commercial or otherwise, without acknowledgment or
                      compensation to you.
                      <br />
                      <br />
                      GENERAL
                      <br />
                      These Terms constitute the entire agreement between you
                      and the Company regarding the use of the Service,
                      superseding any prior agreements between you and the
                      Company relating to your use of the Service. The failure
                      of the Company to exercise or enforce any right or
                      provision of these Terms shall not constitute a waiver of
                      such right or provision in that or any other instance. If
                      any provision of these Terms is held invalid, the
                      remainder of these Terms shall continue in full force and
                      effect. If any provision of these Terms shall be deemed
                      unlawful, void or for any reason unenforceable, then that
                      provision shall be deemed severable from these Terms and
                      shall not affect the validity and enforceability of any
                      remaining provisions.
                      <br />
                      <br />
                      If you have any questions about this Terms of Use, the
                      practices of this site, or your dealings with this site,
                      please contact us at:
                      <br />
                      <br />
                      <i>
                        Soho Capital, Central Park, lantai 32, nomor 7
                        <br />
                        Jl. Letjen S Parman, kavling 28 - Jakarta 11470
                        <br />
                        <a href="tel:622157906242">Phone : +62 21 57906242</a>
                        <br />
                        <a href="https://wa.me/6281317878717">
                          Whatsapp business : +6281317878717
                        </a>
                        <br />
                        <a href="mailto:cs@gmail.com?subject=Hello&body=Hello!">
                          Email : cs@gmail.com
                        </a>
                        <br />
                        Operational hours: 09:00 - 16.30
                      </i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MentorTerms;
