import React, {useState,useEffect} from "react";
import {Modal, Button, Alert} from "react-bootstrap";


export default function(props){
	const {show, item, onClose} = props;
	
	return <Modal size="lg" fullscreen={true} show={show} onHide={()=>{
		onClose();
	}}>
         
          {item !== null && <Modal.Body>
           
            		<img src={item} style={{
            			width: 1440,
            			height:'auto',
            		}}/>
           
          </Modal.Body>}
        </Modal>
}