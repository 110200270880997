import React from "react";
import { Images } from "../Assets";
import LabelAndImagePicker from "../LabelAndImagePicker";
const ModalAvatar = (props) => {
  const {open,onFinish} = props;
  return (
    <div
      className={`modal fade modal-history-subscription ${open ? `show`: ``}`}
      id="modalHistorySubscription"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ maxWidth: "620px" }}>
        <div className="modal-content">
          <div className="modal-header">
            <h4>Change Profile Picture</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={()=>{
                onFinish();
              }}
            >
              <img src={Images.icClose} alt="close" />
            </button>
          </div>
          <div className="modal-body">
            <div className="main-modal-history has-scrollbar">
              <div className="top-modal-history">
                <LabelAndImagePicker 
                label={`Upload Photo`} 
                onChange={(file_url, opts)=>{
                 
                  onFinish(file_url);
                }}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAvatar;
