const CheckBox = ({value, labelName, selected, labelId, defaultChecked, onChange, type = "checkbox", inputStyle = "mt-2"}) => {
    return (
        <div>
            <div class="form-check">
                <input className={`form-check-input ${inputStyle}`} type={type} value={value} id={labelId}
                    checked={type === "checkbox" ? defaultChecked : labelName === selected ? true : false}
                    onChange={onChange}
                />
                <label className="form-check-label" for={labelId}>
                    {labelName}
                </label>
            </div>
      </div>
    )
}

export default CheckBox