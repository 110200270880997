/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalPost,
  CardLeft,
  CardRight,
} from "../../parts";
import Timeline from "../../parts/Timeline";
import accounting from "accounting";
import { getSubsTypeName } from "../../libs/helper";
import OrderConfirmation from "../../parts/OrderConfirmation";
import Loader from "react-loader-spinner";
import qs from "qs";

//http://localhost:3000/checkout-mobile?type=subs&name=12+Months&price=600000&t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEyMDgsInJvbGVzIjpbInVzZXIiXSwiYXVkIjoibWVudG9yYmFpay5jb20iLCJleHAiOjE2NjA2MDQzNjQsImlhdCI6MTY2MDUxNzk2NH0.Cj4vw0jFoGOrUqKXuaWq5z0xH4GSpTxBgvMhWz235ts
const CheckoutMobile = (props) => {

  const { call_action, callAction, match } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [mentor, setMentor] = useState(null);
  const [update, setUpdate] = useState(0);
  const [fetching, loading] = useState(false);
  const [payment_page_url,setUrl] = useState(null);
  const [type, setType] = useState(null);
  const [name, setName] = useState(null);
  const [price, setPrice] = useState(null);
  const [txt, setText] = useState("");
  const location = useLocation();

  const load = () => {

    callAction(call_action,'checkout_mobile',{
      endpoint:'/v2/checkout',
      scenario:'post',
      data: {
        orders:[
        {
          type,
          name,
          price
        }]
      }
    });
    loading(true);
  }
  
  useEffect(() => {
    //load();
    setText("loading...");
     const {type,
          name,
          price,
          t}  = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    setType(type);
    setName(name);
    setPrice(price);
    localStorage.setItem("token",t);
    console.log({type,
          name,
          price,
          t} );

     setText(`${name} ${type} ${price}`);
  }, []);

useEffect(()=>{
  if(name && type && price) load();
  setText("loaded");
  
},[
  name,type,price])

  useEffect(() => {
    setText("on checkout" + JSON.stringify(call_action.checkout_mobile));
    if(fetching && call_action.checkout_mobile !== null){
      setText("on checkout" + JSON.stringify(call_action.checkout_mobile));
      handleSubmit(call_action.checkout_mobile);
    }
  }, [call_action.checkout_mobile]);


  const handleSubmit = (payload) => {
    const {status, payment_page_url} = payload;
    console.log(payment_page_url)
     setText(JSON.stringify(payload));
    loading(false);
    if(status === 1){
      setUrl(payment_page_url);
    }
    
  }
 
  return (
    <>
     
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="banner-ads">
             
            </div>
          </div>
          <div className="container">
            <div className="row">
            <div className={`col-md-3`}></div>
             
              <div className="col-md-6">
                  <h4>Payment</h4>
                  <p>Anda akan diarahkan ke Halaman Pembayaran</p>
                  <p>URL:{payment_page_url}</p>
                  <p>{txt}</p>
                  {fetching && <div style={{textAlign:'center'}}><Loader/></div>}
                  {!fetching && <a
                      href={`${payment_page_url}`}
                      class="button btn-block center"
                    >
                      GO TO PAYMENT
                    </a>}
              </div>
             <div className={`col-md-3`}></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CheckoutMobile;