import React, { useState, useEffect } from "react";
import styled from "styled-components";

const SnackBar = styled.div`
  padding: 10px;
  background-color: #ff7675;
  color: #ffffff;
  text-align: center;
`;
export default function (props) {
  const { show, text, onClose } = props;
  const [open, setOpen] = useState(false);
  useEffect(() => {
     
    if (show) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        onClose();
      }, 6000);
    }
  }, [show]);
  if (!open) return null;
  return <SnackBar>{text}</SnackBar>;
}
