import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { Images } from "../Assets";
import OrderSummary from "../OrderSummary";
import Videos from "../Videos";
const CardRight = (props) => {
  const { is_mentor, cart, subscription, onAddToCart,onRemoveFromCart } = props;
  const path = useRouteMatch("/saham");
  const [trendings, setTrendings] = useState([]);
  const [top_mentors, setTopMentors] = useState([]);
  useState(()=>{
    let top_emitens = localStorage.getItem("top_emiten");
    let top_mentors = localStorage.getItem("top_mentors");
    if(top_emitens) setTrendings(JSON.parse(top_emitens));
    if(top_mentors) setTopMentors(JSON.parse(top_mentors));
  },[]);
  return (
    <div className="col-md-3">
        { subscription && <OrderSummary cart={cart} onAddToCart={onAddToCart} onRemoveFromCart={onRemoveFromCart}/>}
        <Videos {...props}/>
        {/* {path === null && (
          <div className="widget-box mentor-trending-widget">
            <div className="widget-header">
              <h4>TRENDING</h4>
            </div>
            <div className="widget-body">
              <form className="form-horizontal">
                <div className="form-group">
                  <label>Select Market: </label>
                  <select className="form-control">
                    <option>IHSG</option>
                  </select>
                </div>
              </form>
              <ol className="trending-list">
                {trendings.map((item, index) => {
                  return <li key={index} style={{cursor:'pointer'}} onClick={()=>{
                    document.location="/symbol/" + item.symbol
                  }}>{item.symbol}</li>;
                })}
              </ol>
            </div>
          </div>
        )} */}
      {/*}
      <div className="widget-box mentor-topmentor-widget">
        <div className="widget-header">
          <h4>TOP MENTOR</h4>
        </div>
        <div className="widget-body">
          <form className="form-horizontal">
            <div className="form-group">
              <label>Select Market: </label>
              <select className="form-control">
                <option>IHSG</option>
              </select>
            </div>
          </form>
          <ol className="topmentor-user-list">
            {top_mentors.map((f, index) => (
              <li key={index}>
                <a href={`/mentor/${f.username}`}>
                  <img src={f.avatar_url} className="user-image" alt="user" />
                  <div className="user-description">
                    <div className="user-name">{f.username}</div>
                    {<div className="user-statistic">
                      Subscriber: {f.subs}, Follower: {f.followers}
            </div>}
                  </div>
                </a>
              </li>
            ))}
          </ol>
        </div>
      </div>*/}
    </div>
  );
};

export default CardRight;
