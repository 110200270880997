import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useHistory } from "react-router-dom";
import { googleAPI } from "../../global";

export function GoogleButton(props) {
    const navigate = useHistory()
    const {isRegister} = props
    const API_URL = process.env.REACT_APP_API_URI;
    const GOOGLE_API = googleAPI;

    async function fetchProcess(credentialResponse) {
        const code = credentialResponse.credential

        const payload = {
            code,
        }

        const response = await fetch(new URL("/v1/auth/google/login", API_URL), {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        })

        const googleResponse = await response.json();

        if (googleResponse.status === 1 && !googleResponse.is_registered) {
            navigate.push({
                pathname: "google-form",
                state: {
                    user: googleResponse.user
                }
            })
        }

        if (googleResponse.status === 1 && googleResponse.is_registered) {

            localStorage.setItem("token", googleResponse.access_token);
            localStorage.setItem("user_id", googleResponse.user.id);
            localStorage.setItem("name", googleResponse.user.name);
            localStorage.setItem("username", googleResponse.user.username);
            localStorage.setItem("is_profile", googleResponse.is_profile);
            localStorage.setItem("is_mentor", googleResponse.is_mentor);
            localStorage.setItem("mentor_id", googleResponse.mentor_id);
            localStorage.setItem("avatar", googleResponse.avatar_url);

            if (typeof googleResponse.roles === "string") {
                localStorage.setItem("roles", googleResponse.roles);
            } else {
                localStorage.setItem("roles", JSON.stringify(googleResponse.roles));
            }

            setTimeout(()=>{
                document.location = "/"
            }, 1000);
        }
    }

    return (
        <GoogleOAuthProvider clientId={GOOGLE_API}>
            <GoogleLogin width="400px" size="large" text={isRegister ? "signup_with" : "signin_with"}
                onSuccess={credentialResponse => {
                    fetchProcess(credentialResponse);
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
        </GoogleOAuthProvider>
        )
}