import React,{useEffect, useState} from "react";


export default function(props){
	const {callAction, call_action, onLoaded} = props;


	const initNotifs = () => {
		
	    callAction(call_action, "notifs", {
	      endpoint: "/v1/notif/latest",
	      data: {
	        since_id: 0,
	        comment_since_id: 0
	      },
	    });
	   	 
	};
	const handleNotifications = (payload) => {
		if(!payload) return;
		if(payload.status !== 1) return;
		
        const {data,meta, mentor_ids,emiten_ids, watchlists, since_id, comment_since_id} = payload;
        if(!data) return;
       	if(data.length > 0) localStorage.setItem('notif_items',JSON.stringify(data));
        //1. followed mentor latest posts
        let mentor_posts = {};
        if(mentor_ids){
        	for(let i=0; i < mentor_ids.length; i++){
	        	const mentor_id = mentor_ids[i];
	        	if(!mentor_posts[mentor_id]) mentor_posts[mentor_id] = [];
	        	data.map((item)=>{ if (item.mentor_id === mentor_id && item.type === 'post' ) mentor_posts[mentor_id].push(item)})
	        }
	       	if(mentor_posts.length > 0) localStorage.setItem('mentor_posts',JSON.stringify(mentor_posts));
	       

        }
        
       	//2. watchlist posts
       	let wl_posts = {};
       	let n_wl = 0;
       	
       	if(emiten_ids){
       		 for(let i=0; i < emiten_ids.length; i++){
		        	const emiten_id = emiten_ids[i];
		        	let symbol = '';
		        	watchlists.map((item)=>{ if(item.emiten_id === emiten_id) symbol = item.code});
		        	if(!wl_posts[symbol]) wl_posts[symbol] = [];
		        	data.map((item)=>{ if (item.emiten_id === emiten_id && item.type === 'post' ) wl_posts[symbol].push(item)})
		        	if(wl_posts[symbol].length > 0) n_wl++;
		        }
		       
       	}
       
       	if(n_wl > 0) localStorage.setItem('wl_posts',JSON.stringify(wl_posts));
       	
		return true;
	}

	useEffect(() => {
	    let ok = handleNotifications(call_action.notifs);
	    if (ok) {
	      onLoaded();
	    }
	  }, [call_action.notifs] );

	useEffect(()=>{
		initNotifs();
	},[]);

	return <></>
}