import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Images } from "../parts";
import ModalAvatar from "./ModalAvatar";
import Loader from "react-loader-spinner";
import moment from "moment";

export default function (props) {
  const { is_mentor, avatar, stats, callAction, call_action } = props;
  const [toggle, setToggle] = useState(false);
  const [progress, setProgress] = useState(false);
  const [current_avatar, setAvatar] = useState(avatar ? avatar : "");
  const save = (file_url) => {
    callAction(call_action, "save_avatar", {
      scenario: "post",
      endpoint: "/v1/profile/avatar",
      data: { file_url },
    });
    setProgress(true);
  };
  useEffect(() => {
    if (progress && call_action.save_avatar !== null) {
      localStorage.setItem("avatar",call_action.save_avatar.file_url);
      setAvatar(call_action.save_avatar.file_url);
      setProgress(false);
    }
  }, [call_action.save_avatar]);
  return (
    <div className="widget-box widget-profile-image">
      <div className="profile-image-wrape">
        {!progress && <img src={current_avatar} className="user-image" alt="imga-mentor" />}
        {progress && <Loader type="Puff" color="#00BFFF" height={100} width={100} />}
        <Link
          to="#"
          className="change-image"
          onClick={() => {
            setToggle(true);
          }}
        >
          <img src={Images.icCamera} alt="icon-camera" />
        </Link>
        {parseInt(stats?.expired) > 0 && <div style={{marginTop:10}}>You have active subscription until {moment(parseInt(stats?.expired*1000)).format("DD/MM/YYYY")}</div>}
      </div>
      {is_mentor && (
        <div className="mentor-statistic-profile">
          <div className="statistic-item">
            <h4>{stats ? stats.subscribers : 0}</h4>
            <p>Subscribers</p>
          </div>
          <div className="statistic-item">
            <h4>{stats ? stats.followers : 0}</h4>
            <p>Followers</p>
          </div>
          <div className="statistic-item">
            <h4>{stats ? stats.portfolio : 0}</h4>
            <p>Saham</p>
          </div>
        </div>
      )}
      {is_mentor && (
        <Link to="#" className="button disabled">
          As a Mentor
        </Link>
      )}
      {/*}
      {!is_mentor && (
        <Link to="/become-mentor" className="button">
         Become Mentor
        </Link>
      )} {*/}

      <ModalAvatar
        open={toggle}
        onFinish={(file_url) => {
          //saving avatar
          //setAvatar(file_url);
          if(file_url) save(file_url);
          setToggle(false);
        }}
      />
    </div>
  );
}
