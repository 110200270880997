/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Header, Images, CardLeft, CardRight } from '../../parts';
import { Link } from 'react-router-dom';

const Thankyou = props => {
  const { call_action, callAction, match } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [mentor, setMentor] = useState(null);
  const [update, setUpdate] = useState(0);
  const [fetching, loading] = useState(false);

  const [followings, setFollowings] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.followed_mentors
        : []
      : []
  );
  const [stats, setStats] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.stats
        : null
      : null
  );
  const [watchlist, setWatchlist] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.watchlists
        : []
      : []
  );
  const location = useLocation();

  useEffect(() => {
    setLoginStatus(localStorage.getItem('token') ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem('is_mentor')) === 1 ? true : false
    );
    localStorage.removeItem('cart');
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="banner-ads"></div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>

              <div className="col-md-6">
                <div class="widget-box widget-panel widget-panel-dark mentor-ordersummary-widget">
                  <div class="widget-header">
                    <h4>Thank You</h4>
                  </div>
                  <div class="widget-body ml-3 mr-3 pt-4">
                    <p>
                      Terima kasih telah subscribe di Mentorbaik. <br />
                      Semoga perjalanan investasi anda memberikan hasil yang
                      baik.
                      <br />
                      <br />
                      Jika ada pertanyaan, silakan email{' '}
                      <a href="mailto:mentorbaik.info@gmail.com">
                        mentorbaik.info@gmail.com
                      </a>
                      .
                    </p>
                  </div>
                  <div class="order-summary-action pb-4">
                    <Link to="/" class="button btn-block">
                      CONTINUE
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thankyou;
