import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Images } from "../../parts";
import ProfileWidget from "../../parts/ProfileWidget";
import SidebarProfile from "../../parts/SidebarProfile";
import Mentor from "../../parts/FormMentor";
import Bank from "../../parts/FormAccountBank";
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import Toastify from 'toastify-js';

const MentorAccountMember = (props) => {
  const { callAction, call_action } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [progress, loading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [set_price, setPrice] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("");
  const [original_npwp, setOriginalNpwp] = useState("");

  const [data, setData] = useState(
    call_action.me
      ? call_action?.me?.data?.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );
  const load = (reload) => {
    if (reload || call_action.me === null) {
      callAction(call_action, "me", {
        endpoint: "/v1/myself",
      });
      loading(true);
    }
  };
  const onProfileLoaded = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setIsMentor((payload.data && payload.data.mentor && payload.data.mentor.id > 0) ? true : false);
    setData(payload.data ? payload.data.profile : null);
    setOriginalNpwp(payload.data ? payload.data.profile.npwp : null)
    setStats(payload.data ? payload.data.stats : null);
    setUpdate(update + 1);
  };

  const save = (set_price, e) => {
    e.preventDefault();

    const {
      nama,
      email,
      no_telp,
      about_me,
      bank_account,
      bank_holder,
      bank_name,
      npwp,
    } = data;

    if( npwp.length < 15 || npwp.length > 15 ){
      Toastify({
        text: 'Harap masukkan 15 digit NPWP',
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "#ff9966",
          color: "black",
        },
      }).showToast();
    }else if( original_npwp && ( !npwp || npwp == " " ) ){
      Toastify({
        text: 'NPWP yang sudah terisi tidak bisa dikosongkan kembali',
        duration: 5000,
        newWindow: true,
        close: true,
        gravity: "bottom", // `top` or `bottom`
        position: "right", // `left`, `center` or `right`
        stopOnFocus: true, // Prevents dismissing of toast on hover
        style: {
          background: "#ff9966",
          color: "black",
        },
      }).showToast();
    }else{
      callAction(call_action, "save_profile", {
        scenario: "post",
        endpoint: "/v1/profile",
        data:{
              nama,
              email,
              no_telp,
              about_me,
              bank_account,
              bank_holder,
              bank_name,
              npwp,
            },
      });
      loading(true);
      setPrice(set_price ? set_price : false);
    }

  };

  const formProps = {
    data,
    setData,
    snackbar,
    snackbar_text,
    save,
    progress,
    setSnackbar
  };
  const FormBank = Bank;
  const FormMentor = Mentor;
  const FormMember = (props) => {
    return (
      <form className="form-member-profile">
        <div className="form-group">
          <label className="control-label"><strong>Name</strong></label>
          <input
            type="text"
            className="form-control"
            placeholder="input your Name here ..."
            name=""
            defaultValue={data?.nama}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.nama = txt;
              setData(d);
            }}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="control-label"><strong>Email</strong></label>
            <input
              type="email"
              className="form-control"
              placeholder="input your Email here ..."
              name=""
              defaultValue={data.email || ""}
              onBlur={(evt) => {
                const txt = evt.target.value;
                const d = data;
                d.email = txt;
                setData(d);
              }}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="control-label"><strong>Mobile</strong></label>
            <input
              type="text"
              className="form-control"
              placeholder="input your Mobile here ..."
              name=""
              defaultValue={data?.no_telp}
              onBlur={(evt) => {
                const txt = evt.target.value;
                const d = data;
                d.no_telp = txt;
                setData(d);
              }}
            />
          </div>
        </div>
        {progress && (
          <div style={{ textAlign: "center" }}>
            <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          </div>
        )}
        {!progress && (
          <button
            type="submit"
            className="button button-submit"
            onClick={() => {
              save();
            }}
          >
            SAVE
          </button>
        )}
        {snackbar && (
          <SnackBar
            show={snackbar}
            text={snackbar_text}
            onClose={() => {
              setSnackbar(false);
            }}
          />
        )}
      </form>
    );
  };
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    // setIsMentor(
    //   parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    // );
    load(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (progress && call_action.me !== null) {
      loading(false);
      onProfileLoaded(call_action.me);
    }
  }, [call_action.me]);

  useEffect(() => {
    if (progress && call_action.save_profile !== null) {
      loading(false);
      load();

      if (call_action.save_profile) {
        if (set_price === true) {
          document.location = "/set-price";
        } else {
          Toastify({
            text: call_action.save_profile.message,
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "bottom", // `top` or `bottom`
            position: "right", // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: "#B3E8D1",
              color: "#118B56",
            },
          }).showToast();
          // setSnackbar(true);
          // setSnackbarText(call_action.save_profile.message);
        }
      }
    }
  }, [call_action.save_profile]);

  if (data === null)
    return (
      <>
        <div style={{ textAlign: "center", padding: 30 }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      </>
    );
  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center pt-4 pb-4">
              <div className="col-md-3">
                <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={data ? data.avatar_url ? data.avatar_url : localStorage.getItem("avatar") : localStorage.getItem("avatar")}
                  callAction={callAction}
                  call_action={call_action}
                />
                <SidebarProfile is_mentor={is_mentor} current="profile" />
              </div>
              <div className="col-md-7">
                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-header">
                    <h4>Profile</h4>
                    <span>@{localStorage.getItem("username")}</span>
                  </div>
                  <div className="widget-body">
                    {is_mentor && <FormMentor {...formProps} />}
                    {!is_mentor && <FormMember />}
                  </div>
                </div>
                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-header">
                    <h4>BANK ACCOUNT</h4>
                  </div>
                  <div className="widget-body">
                    <FormBank {...formProps} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorAccountMember;