import React, {useState,useEffect} from "react";
import {Modal, Button, Alert} from "react-bootstrap";
import Loader from "react-loader-spinner";


export default function(props){
	const {show, item, delete_status, onClose, onConfirm} = props;
	if(item === null) return <></>
	return <Modal size="lg" fullscreen={true} show={show} onHide={()=>{
		onClose();
	}}>
         
        <Modal.Body>
           {delete_status === 0 && <><p>Are you sure to remove these post? </p>
		   <h4>{item.title}</h4>
		   </>}
		   {delete_status === 1 && <><div className={`center`}><p>removing the post... </p>
		   <Loader
                   type="Puff"
                   color="#00BFFF"
                   height={30}
                   width={30}
                 />
		   </div>
		  
		   </>}
		   
        </Modal.Body>
		<Modal.Footer>
		
			{delete_status === 0 && <><Button onClick={()=>{
				onClose()
			}}>Cancel</Button> <Button href="#" className={`btn btn-primary`} onClick={()=>{
				onConfirm();
			}}>Delete</Button> </>}
		</Modal.Footer>
        </Modal>
}