import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Footer, Header, Images } from "../../parts";

const MentorLending = (props) => {
  const [content] = React.useState([
    {
      id: 1,
      row: "row align-items-center",
      col: "col-md-6",
      classTitle: "section nobg nomargin landing-top-banner",
      title: "Belajar & Cuan Bersama \ndi MentorBaik",
      paragraphOne:
        "Dapatkan rekomendasi, analisa dan konsultasi dari ratusan mentor investasi terbaik.",
      paragraphTwo:
        "Tidak ada satu ahli investasi yang dapat menguasai semuanya. Di Mentorbaik, anda dapat belajar dan mengikuti cara berinvestasi dari mentor-mentor terbaik di bidangnya masing-masing.",
      imageOne: Images.googlePlay,
      imageTwo: Images.appStore,
      imageThree: Images.heroBanner,
    },
    {
      id: 2,
      row: "row",
      col: "col-md-4",
      classTitle: "section nobg nomargin",
      title: "Keuntungan Bergabung MentorBaik",
      subtitle: "Jujur & Transparan",
      subtitleTwo: "Jujur & Transparan",
      subtitleThree: "Fokus ke konten",
      paragraphOne:
        "Pilih mentor investasimu berdasarkan statistik dan track-record mereka",
      paragraphTwo:
        "Panduan dari mentor favoritmu: rekomendasi saham, kapan beli dan jual serta berita penting terkait investasimu.",
      paragraphThree: "Posting yang bermutu dan relevan tanpa drama forum.",
      imageOne: Images.icJujur,
      imageTwo: Images.icUpdate,
      imageThree: Images.icFocus,
    },
    {
      id: 3,
      row: "row small-gutters align-items-center",
      col: "col-md-7",
      colTwo: "col-md-5",
      colThree: "left-col",
      classTitle: "section nobg nomargin landing-bottom-section",
      title: "Semua panduan investasi anda dalam 1 aplikasi",
      paragraphOne:
        "Investasi adalah seni yang unik. MentorBaik percaya bahwa cara terbaik adalah belajar langsung dari yang sukses di bidangnya. Dengan dipandu mentor yang berpengalaman, anda dapat meningkatkan keamanan, kualitas dan return investasi serta tidak mengulangi kesalahan yang tidak perlu.",
      imageOne: Images.flatLower,
      imageTwo: Images.iphone,
    },
  ]);

  React.useEffect(() => {
    document.title = "Landing | Mentor Baik";
  }, []);

  return (
    <>
      <Header isLogin={false} {...props} onFilter={(input) => {}} />
      <section id="content">
        <div className="content-wrap">
          {content.map((c, index) => (
            <div className={`${c.classTitle}`} key={index}>
              {c.id === 1 && (
                <Container>
                  <div className={c.row}>
                    <div className={c.col}>
                      <h1>{c.title}</h1>
                      <p>{c.paragraphOne}</p>
                      <p>{c.paragraphTwo}</p>
                      <div className="download-badge">
                        <Link to="#">
                          <img src={c.imageOne} alt="landing" />
                        </Link>
                        <Link to="#">
                          <img src={c.imageTwo} alt="landing" />
                        </Link>
                      </div>
                    </div>
                    <div className={c.col6}>
                      <div className="top-banner-image">
                        <img
                          src={c.imageThree}
                          className="img-fluid"
                          alt="landing"
                        />
                      </div>
                    </div>
                  </div>
                </Container>
              )}
              {c.id === 2 && (
                <Container>
                  <div className="heading-block title-center">
                    <h2>{c.title}</h2>
                  </div>
                  <div className={c.row}>
                    <div className={c.col}>
                      <div className="benefit-item-wrap">
                        <img src={c.imageOne} alt={c.imageOne} />
                        <h4>{c.subtitle}</h4>
                        <p>{c.paragraphOne}</p>
                      </div>
                    </div>
                    <div className={c.col}>
                      <div className="benefit-item-wrap">
                        <img src={c.imageTwo} alt={c.imageTwo} />
                        <h4>{c.subtitleTwo}</h4>
                        <p>{c.paragraphTwo}</p>
                      </div>
                    </div>
                    <div className={c.col}>
                      <div className="benefit-item-wrap">
                        <img src={c.imageThree} alt={c.imageThree} />
                        <h4>{c.subtitleThree}</h4>
                        <p>{c.paragraphThree}</p>
                      </div>
                    </div>
                  </div>
                </Container>
              )}
              {c.id === 3 && (
                <Container>
                  <div className={c.row}>
                    <div className={c.col}>
                      <div className="left-col">
                        <img
                          src={c.imageOne}
                          className="flat-browser"
                          alt={c.imageOne}
                        />
                        <img
                          src={c.imageTwo}
                          className="iphone-pro"
                          alt={c.imageTwo}
                        />
                      </div>
                    </div>
                    <div className={c.colTwo}>
                      <div className="right-col">
                        <h2>{c.title}</h2>
                        <p>{c.paragraphOne}</p>
                      </div>
                    </div>
                  </div>
                </Container>
              )}
            </div>
          ))}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MentorLending;
