import Select from "react-dropdown-select"

const Dropdown = ({placeholder, options, value, valueField, labelField, onChange, searchable = true}) => {
    return (
        <Select
            className="select-dropdown"
            multi
            searchable={searchable}
            valueField={valueField}
            labelField={labelField}
            options={options}
            placeholder={placeholder}
            values={value}
            onChange={onChange}
        />
    )
}

export default Dropdown