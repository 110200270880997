import Api from "../../Services/Api";

const api = new Api();
let busy = false;
let q = [];
//this would be a single action but can be called to many components
//so the payload must be splitted by the component's name
export default (state, name, opts) => (dispatch) => {
 
  const queue = ({ state, name, opts }) => {

    let _state = {
      ...state,
      type: "CALL_ACTION",
      data: opts,
      fetching: true,
    };
    // comment for a while
    // _state[name] = null;

    dispatch(_state);
    q.push({ state, name, opts });

  };

  const proceed = () => {
    if (q.length === 0){
      busy = false;
      return;
    }
    busy = true;
    const { state, name, opts } = q.shift();

    

    api.call(opts, true).then((response) => {
      if (response.status === 1) {
        let new_state = {
          ...state,
          type: "CALL_ACTION_SUCCESS",
        };
        new_state[name] = response;
        dispatch(new_state);
      } else {
        if (
          typeof response.error !== "undefined" &&
          response.error === "access_denied"
        ) {
          localStorage.removeItem("token");
          document.location = "/landing";
        }
        let new_state = {
          ...state,
          type: "CALL_ACTION_ERROR",
        };
        new_state[name] = response;
        dispatch(new_state);
      }
      proceed();
    });
  };


  queue({ state, name, opts });
  if(!busy) proceed();
  
};