import React from "react";
import Loader from "react-loader-spinner";
import SnackBar from "./Snackbar";
const FormAccountBank = (props) => {
  const { data, setData, setSnackbar, progress, snackbar, snackbar_text, save } = props;
  return (
    <form className="form-member-profile">
      <div className="form-row">
        <div className="form-group col-md-12">
          <label className="control-label"><strong>Bank Name</strong></label>
          <input
            type="text"
            className="form-control"
            placeholder="input your Bank Name here ..."
            defaultValue={data.bank_name}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.bank_name = txt;
              setData(d);
            }}
          />
        </div>
        <div className="form-group col-md-12">
          <label className="control-label"><strong>Account Number</strong></label>
          <input
            type="text"
            className="form-control"
            placeholder="input your Accout Number here ..."
            defaultValue={data.bank_account}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.bank_account = txt;
              setData(d);
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="control-label"><strong>Account Name</strong></label>
        <input
          type="text"
          className="form-control"
          placeholder="input your Accout Name here ..."
          defaultValue={data.bank_holder}
          onBlur={(evt) => {
            const txt = evt.target.value;
            const d = data;
            d.bank_holder = txt;
            setData(d);
          }}
        />
      </div>
      <div className="form-group">
        <label className="control-label"><strong>NPWP</strong></label>
        <input
          type="number"
          className="form-control"
          placeholder="input your NPWP here ..."
          defaultValue={data.npwp}
          onBlur={(evt) => {
            const txt = evt.target.value;
            const d = data;
            d.npwp = txt;
            setData(d);
          }}
        />
      </div>
      {progress && (
        <div style={{ textAlign: "center" }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      )}
      {!progress && (
        <button
          type="submit"
          className="button button-submit"
          onClick={(e) => {
            save(false, e);
          }}
        >
          SAVE
        </button>
      )}
     {/*} {!progress && (
        <button
          type="submit"
          className="button button-outline"
          onClick={() => {
            save(true);
          }}
        >
          SAVE and SET PRICE
        </button>
      )} */}
      {snackbar && (
        <SnackBar
          show={snackbar}
          text={snackbar_text}
          onClose={() => {
            setSnackbar(false);
          }}
        />
      )}
    </form>
  );
};
export default FormAccountBank;