import React, { useState, useEffect } from "react";
import toast, {Toaster} from "react-hot-toast"
import { onMessageListener, requestPermission} from "../firebase"


const Notification = (props) => {
  const [notif, setNotif] = useState({title: "", body: ""});
  
  useEffect(() => {
    // requestPermission()

  //   const unsubscribe = onMessageListener().then(payload=>{
  //     setNotif({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     })

  //   toast.success(
  //     `${payload?.notification?.title}: ${payload?.notification?.body}`,
  //     {
  //       duration: 60000,
  //       position: "top-right"
  //     }
  //   )
  // })

  //   return () => {
  //     unsubscribe.catch(err=> console.log("failed: ", err))
  //   }
  // Listen for incoming messages
    // const unsubscribe = () => {
    //   onMessageListener().then(payload => {
    //     setNotif({
    //       title: payload?.notification?.title,
    //       body: payload?.notification?.body,
    //     });

    //     toast.success(
    //       `${payload?.notification?.title}: ${payload?.notification?.body}`,
    //       {
    //         duration: 60000,
    //         position: "top-right"
    //       }
    //     );
    //   }).catch(err => {
    //     console.error("Error listening for messages:", err);
    //   });
    // }
    
    // Unsubscribe when component unmounts
    // return () => {
    //   unsubscribe();
    // };
  }, [])

  return (
    <div>
      <Toaster/>
    </div>
  );
};

export default Notification;
