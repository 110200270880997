/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalPost,
  ModalFollowers,
  ModalSubscribers,
  CardLeft,
  CardRight,
} from "../../parts";
import Timeline from "../../parts/Timeline";
import TimelineItem from "../../parts/TimelineItem";

import accounting from "accounting";
import { getSubsTypeName } from "../../libs/helper";
import moment from "moment";
import { setupNotification } from "../../libs/helper";
import Loader from "react-loader-spinner";
import Notifications from "../../parts/Notifications";

const Article = (props) => {
  const { call_action, callAction, match } = props;
  const [post_id, setPostId] = useState(0);
  const [ready, setReady] = useState(false);
  const [username, setUsername] = useState(null);
  const [toggle, setToggle] = useState("public");
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [load_mentor, isLoadMentor] = useState(false);
  const [is_post, togglePostModal] = useState(false);

  const [progress, setProgress] = useState(true);
  const [follow_progress, toggleFollow] = useState(false);
  const [profile, setProfile] = useState(null);
  const [load_profile, isLoadProfile] = useState(false);
  const [mentor, setMentor] = useState(null);
  const [posts, setPosts] = useState([]);
  const [premium, setPremium] = useState([]);
  const [since_id, setSince_id] = useState(0);
  const [fetching, loading] = useState(false);
  const [load_packages, setLoadingPackage] = useState(false);
  const [update, setUpdate] = useState(0);
  const [all, setAll] = useState(0);
  const [m1, setM1] = useState(0);
  const [m3, setM3] = useState(0);
  const [m6, setM6] = useState(0);
  const [m12, setM12] = useState(0);
  const [plan1, setPlan1] = useState(null); //plan all in
  const [plan2, setPlan2] = useState([]); //plan per emiten -> isinya adalah kode emiten
  const [emiten, setEmiten] = useState([]);
  const [choosedEmiten, setChoosed] = useState([]);
  const [popup_mentor_id, setPopupMentorId] = useState(0);
  const [toggle_popup, setTogglePopup] = useState(false);
  const [popup_reply_symbol, setPopupReplySymbol] = useState("");
  const [popup_reply_limit, setPopupReplyLimit] = useState(0);
  const [popup_symbol, setPopupSymbol] = useState("");
  const [popup_limit, setPopupLimit] = useState(0);
  const [toggle_content, setContent] = useState(false);
  const [toggle_messages, setToggleMessage] = useState(false);
  const [ts, setTimestamp] = useState(localStorage.getItem("last_ts") || 0);
  const [notif_loading, setLoadNotif] = useState(false);
  const [isChecked, toggleChecked] = useState(null);
  const [allUser, setAllUser] = useState([]);    
  const [postMentor, setPostMentor] = useState(null);
  
  const [followings, setFollowings] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.followed_mentors
        : []
      : []
  );
  const [subscriptions, setSubscriptions] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.mentorsubs
        : []
      : []
  );
  const [stats, setStats] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.stats
        : null
      : null
  );
  const [watchlist, setWatchlist] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.watchlists
        : []
      : []
  );

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [toggle_subscriptions, setToggleSubscriptions] = useState(false);
  const [toggle_followers, setToggleFollowers] = useState(false);



  const pricing = {
    m1,
    m3,
    m6,
    m12,
    all,
  };
  const location = useLocation();

  const loadProfile = () => {
    callAction(call_action, "me", {
      endpoint: "/v1/myself",
    });
    isLoadProfile(true);
  };

 
  const onProfileLoaded = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    // setProfile(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setWatchlist(payload.data ? payload.data.watchlists : []);
    setFollowings(payload.data ? payload.data.followed_mentors : []);
    setSubscriptions(payload.data ? payload.data.mentorsubs : []);
    setUpdate(update + 1);
    return true;
  };
  const loadPosts = () => {
  	console.log({match})
  	let id = match.params.id || 0;
  	callAction(call_action, "article", {
        endpoint: `/v1/posts/${id}`,
        scenario: "get",
        data: {
          since_id,
        },
       });
  
    loading(true);
  };
  

  useEffect(() => {
    let ok = onMentorTimeline(call_action.article);
    if (ok) {
      loading(false);
    }
  }, [call_action.article]);


  //FOLLOW / UNFOLLOW USER
  const toggle_followed_status = () => {
    console.log("toggle_followed_status",m);
    let m = mentor;
    m.is_followed = m.is_followed === 1 ? 0 : 1;
    setMentor(m);
  };
  const follow_user = (item) => {
    try {
      callAction(call_action, "follow_mentor", {
        endpoint: `/v1/mentor/${item.id}/follow`,
      });
      toggleFollow(true);
      toggle_followed_status();
    } catch (err) {
      console.log(err);
    }
  };

  const unfollow_user = (item) => {
    try {
      callAction(call_action, "unfollow_mentor", {
        endpoint: `/v1/mentor/${item.id}/unfollow`,
      });
      toggleFollow(true);
      toggle_followed_status();
    } catch (err) {
      console.log(err);
    }
  };

  const onFollowClicked = (item) => {
    if (item.is_followed === 0) follow_user(item);
    else unfollow_user(item);
  };

  const onMentorDetail = (payload) => {
    if(!payload) return;
    setMentor(
          payload ? payload.data : null
        );
    
    return true;
  }

  const getAllUser = () => {
    callAction(call_action, "allUser", {
      endpoint: `/v1/authors`,
    });
  }

  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
   
 
    getAllUser()
    loadProfile();
    loadPosts();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let ok = onMentorDetail(call_action.mentor_detail);
    if (ok) {
       // loadPosts();
        isLoadMentor(false);
        setUpdate(update + 1);
      
    }
  }, [call_action.mentor_detail]);
  
  useEffect(() => {
    if (call_action.allUser) {
      setAllUser(call_action.allUser.data);
    }
  }, [call_action.allUser]);

  useEffect(() => {
    if (mentor) {
      //loadSettings(username);
    }
  }, [mentor]);

  const handleTimeline = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
   
    let { status, data, r } = payload;

    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
    	data.mentor = r.mentors[0];
    	
    }
    setPostMentor(payload.data ? payload.data.mentor : null)
    setPosts(payload.data ? [data] : []);
    setUpdate(update + 1);
  };
  const onMentorTimeline = (payload) => {
    if(!payload) return;
    handleTimeline(payload);
    return true;
  }

  useEffect(() => {
    const ok =  onProfileLoaded(call_action.me);
    if (ok) {
      isLoadProfile(false);
      setReady(true);
    }
  }, [call_action.me]);


  useEffect(() => {
    if(ready){
      setSince_id(0);
      //load(username);
     }
  }, [toggle]);

  
  
  useEffect(() => {
    if(call_action.follow_mentor){
       loadProfile();
      // load(username);
    }
   
  }, [call_action.follow_mentor]);

   useEffect(() => {
   
    if(call_action.unfollow_mentor){
       loadProfile();
      // load(username);
    }
  }, [call_action.unfollow_mentor]);

   console.log({posts});
  return (
    <>
    <Notifications {...props} onLoaded={()=>{
        console.log("notif loaded");
      }}/>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
        refresh={update}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container mt-3">
            <div className="row">
              {fetching && (
                <>
                  <div className="col-md-3"></div>
                  <div
                    className="col-md-6"
                    style={{ textAlign: "center", marginTop: 30 }}
                  >
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={75}
                      width={75}
                    />
                  </div>
                </>
              )}
              <div style={{width: '100%'}}>
                {(!fetching && posts.length > 0) && (
                  <TimelineItem
                  is_subscribed={posts[0].is_subscribed  ? true : false}
                    callAction={callAction}
                    call_action={call_action}
                    progress={fetching}
                    toggle={'public'}
                    allUser={allUser}
                    isLogin={isLogin}
                    is_mentor={is_mentor}
                    readMoreToggle={true}
                    can_post={
                      parseInt(mentor ? mentor.account_id : 0) ===
                      parseInt(localStorage.getItem("user_id"))
                    }
                    posts={posts}
                    data={{...posts[0], mentor: postMentor }}
                    premium={premium}
                    events={[]}
                    onRefresh={() => {
                     // load(username);
                    }}
                    onToggle={(input) => {
                      setToggle(input);
                    }}
                    on_toggle_modal={()=>{
                      togglePostModal(true);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <ModalEvent />
      {(watchlist.length > 0) && (
         <ModalPost
        watchlist={watchlist}
        {...props}
        open={is_post}
        onClose={()=>{
          togglePostModal(false);
        }}
        onFinish={() => {
          //load();
           togglePostModal(false);
        }}
      />
      )}

      <ModalHistory subscriptions={[]} {...props} />
     
      <ModalFollowers {...props} open={toggle_followers} username={mentor ? mentor.username : ''} mentor_id={mentor ? mentor.id : 0} onClose={()=>{
        setToggleFollowers(false);
      }}/> 

      <ModalSubscribers {...props}  open={toggle_subscriptions} username={mentor ? mentor.username : ''} mentor_id={mentor ? mentor.id : 0}  onClose={()=>{
        setToggleSubscriptions(false);
      }}/> 
     
    </>
  );
};

export default Article;