import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../parts";
import Loader from "react-loader-spinner";
import validator from "validator";
import SnackBar from "../../parts/Snackbar";
import {Alert} from "react-bootstrap";
import { GoogleButton } from "../../parts/Google/GoogleButton";
const Login = (props) => {
  const { location, match, call_action, callAction } = props;
  const [progress, setProgress] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password_visible, setPasswordVisible] = useState(false);
  const [username, setUsername] = useState(null);
  const [phone, setPhone] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirm_password, setConfirm] = useState(null);
  const [isRegister, toggleRegister] = useState(false);
  const [isRegisterMethod, toggleRegisterMethod] = useState(false);
  const [snackbar_type, setSnackbarType] = useState('alert');
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("lorem ipsum");
  const [errors, setError] = useState({});
  const setErrors = (name, value) => {
    let _e = errors;
    _e[name] = value;
    setError(_e);
  }
  const auth = () => {
    if(!username || !password) return;
    callAction(call_action, "login", {
      endpoint: "/login",
      data: {
        username,
        password,
      },
      scenario: "post",
    });
    setUsername(null);
    setPassword(null);
    setSnackbar(false);
    // document.getElementById('username').value="";
    // document.getElementById('password').value="";
    setProgress(true);
    
   
  };
  const register = () => {
    callAction(call_action, "register", {
      endpoint: "/register",
      data: {
        username,
        password,
        name,
        phone,
        confirm_password,
        email
      },
      scenario: "post",
    });
    setProgress(true);
  };
  const onAuth = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    const { status, user, access_token, roles, mentor_id,is_profile, is_mentor,avatar_url, error } =
      payload;

    if (status === 1) {
      localStorage.setItem("token", access_token);
      localStorage.setItem("user_id", user.id);
      localStorage.setItem("name", user.name);
      localStorage.setItem("username", user.username);
      localStorage.setItem("is_profile", is_profile);
      localStorage.setItem("is_mentor", is_mentor);
      localStorage.setItem("mentor_id", mentor_id);
      localStorage.setItem("avatar", avatar_url);
      localStorage.setItem("roles", JSON.stringify(roles));
      setTimeout(()=>{
        setProgress(false);
        document.location = "/";

      },1000);
      
    } else {
      setSnackbar(true);
      setSnackbarType('danger');
      setSnackbarText(error);
      setProgress(false);
    }
  };
  const onRegister = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    const { status, error } =
      payload;

    if (status === 1) {
        toggleRegister(false);
        setTimeout(()=>{
          setSnackbar(true);
          setSnackbarType('success');
          setSnackbarText("Registrasi sukses. Silahkan periksa email anda untuk aktifasi.");
        },300);
        
       
        
    } else {
      setSnackbar(true);
      setSnackbarType('danger');
      setSnackbarText(error);
    }
    setProgress(false);
  };

  useEffect(() => {
    if (location.pathname === "/login") {
      toggleRegister(false);
    } else {
      toggleRegister(true);
    }
  }, []);

  useEffect(() => {
    if (progress && call_action.login !== null) {
      onAuth(call_action.login);
      
    }
  }, [call_action.login]);

  useEffect(() => {
    if (progress && call_action.register !== null) {
      onRegister(call_action.register);
    }
  }, [call_action.register]);

  useEffect(()=>{
    setSnackbar(false);
  },[isRegister])
  
  return (
    <>
      <Header isLogin={false} {...props} />
      <section id="content">
        <div className="content-wrap">
          <div className="section nobg nomargin">
            <div className="login-card-wrapper">
              <div className="tabs-outer">
                <ul className="tab-nav clearfix ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header">
                  <li className={"ui-tabs-tab ui-corner-top ui-state-default ui-tab " + (!isRegister ? " ui-tabs-active ui-state-active" : "")} style={{width:265}}>
                    <a
                     className={`ui-tabs-anchor`}
                      href="#"
                      onClick={() => {
                        toggleRegister(false);
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li className={"ui-tabs-tab ui-corner-top ui-state-default ui-tab" + (isRegister ? " ui-tabs-active ui-state-active" : "")} style={{width:285}}>
                    <a
                    className={`ui-tabs-anchor`}
                      href="#"
                      onClick={() => {
                        toggleRegister(true);
                      }}
                    >
                      Register as a new user
                    </a>
                  </li>
                </ul>
                {snackbar && (
                  <div style={{marginTop:15,marginBottom:-15}}>
                     <Alert variant={snackbar_type ? snackbar_type : `danger`}>
                      {snackbar_text}
                        </Alert>
                      </div>
                )}
                <div className="tab-container">
                  {!isRegister && (
                    <div className="tab-content clearfix" id="tab-login">
                      <form className="form-login-wrapper text-center">
                       {!progress && <div className="form-group">
                          <input

                            type="text"
                            className="sm-form-control"
                            placeholder="Username"
                            name="username"
                            onChange={(evt) => {
                              setUsername(evt.target.value);
                            }}
                          />
                        </div>}
                       {!progress &&  <div className="form-group">
                          <div className="password-group">
                            <input
                              type={password_visible ? "text" : "password"}
                              className="sm-form-control password-box"
                              placeholder="Password"
                              aria-label="password"
                              name="password"
                              id="your-password"
                              onChange={(evt) => {
                                setPassword(evt.target.value);
                              }}
                            />
                            <Link
                              to="#"
                              className="password-visibility"
                              onClick={() => {
                                setPasswordVisible(!password_visible);
                              }}
                            >
                              <i className="eye-close"></i>
                            </Link>
                          </div>
                          <div className="forgot-pass-option">
                           
                            <a
                              href="/forgot-password"
                              className="text-forgot-pass"
                            >
                              Forget Password?
                            </a>
                          </div>
                        </div>}
                        <div className="form-button">
                          {!progress && <a
                            href="#"
                            className="button btn-block"
                            onClick={() => {
                              auth();
                            }}
                          >
                            Login
                          </a>}
                          {/* <p>or</p>
                                                <Link to="#" className="button btn-block button-google"><i className="icon-google"></i>Login with Google</Link>
                                                <Link to="mentor-login-facebook.html" className="button btn-block button-facebook"><i className="icon-facebook"></i>Login with Facebook</Link>*/}
                        </div>
                        {
                          !progress && 
                          <div className="d-flex flex-column align-items-center">
                            <span className="mb-2">or</span>
                            <GoogleButton isRegister={false} />
                          </div>
                        }
                      </form>
                    </div>
                  )}

                  {isRegister && (
                    <div className="tab-content clearfix" id="tab-register"> {
                      !isRegisterMethod ? 
                      <div className="mt-4">
                          <div className="form-button text-center" onClick={() => toggleRegisterMethod(true)}>
                            <div className="button btn-block">
                              Register Manually
                            </div>
                          </div>
                          <div className="w-auto d-flex flex-column align-items-center">
                            <span className="mb-2">or</span>
                            <GoogleButton isRegister={true} />
                          </div>
                        </div> : 
                        <form className="form-login-wrapper text-center">
                        <div className="form-group">
                            <input
                              type="text"
                              className="sm-form-control"
                              placeholder="Your Full Name"
                              name=""
                              onChange={(evt)=>{
                                  setName(evt.target.value)
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="text"
                              className="sm-form-control"
                              placeholder="User Name"
                              name=""
                              onChange={(evt)=>{
                                  if (validator.isEmpty(evt.target.value)) {
                                      setErrors('username','harap isi username');
                                  } else if (!validator.matches(evt.target.value, '^[a-zA-Z0-9_.-]*$')) {
                                      setErrors('username','Username harus terdiri dari huruf, angka!');
                                  } else {
                                      setErrors('username',null);
                                  }
  
                                  setUsername(evt.target.value)
                              }}
                            />
                            {errors['username'] && <Alert variant={`danger`}>
                          {errors['username']}
                          </Alert>}
                          </div>
                          <div className="form-group">
                            <input
                              type="email"
                              className="sm-form-control"
                              placeholder="Email"
                              name=""
                              onChange={(evt)=>{
                                  if (validator.isEmpty(evt.target.value)) {
                                      setErrors('email','mohon isi Email');
                                  } else if (!validator.isEmail(evt.target.value)) {
                                      setErrors('email','Format Email salah!');
                                  } else {
                                      setErrors('email',null);
                                  }
  
                                  setEmail(evt.target.value)
                              }}
                            />
                             {errors['email'] && <Alert variant={`danger`}>
                              {errors['email']}
                              </Alert>}
                          </div>
                          <div className="form-group">
                            <input
                              type="phone"
                              className="sm-form-control"
                              placeholder="Phone Number"
                              name=""
                              onChange={(evt)=>{
                                  setPhone(evt.target.value)
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <div className="password-group">
                              <input
                                type={password_visible ? "text" : "password"}
                                className="sm-form-control password-box"
                                placeholder="Password"
                                aria-label="password"
                                name="your-password"
                                id="your-password"
                                onChange={(evt)=>{
                                  setPassword(evt.target.value)
                              }}
                              />
                              <Link to="#" onClick={() => {
                                  setPasswordVisible(!password_visible);
                                }} className="password-visibility">
                                <i className="eye-close"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="password-group">
                              <input
                                type={password_visible ? "text" : "password"}
                                className="sm-form-control password-box"
                                placeholder="Confirm Password"
                                aria-label="password"
                                name="your-password"
                                id="your-password"
                                onChange={(evt)=>{
                                  setConfirm(evt.target.value)
                              }}
                              />
                              <Link to="#" onClick={() => {
                                  setPasswordVisible(!password_visible);
                                }} className="password-visibility">
                                <i className="eye-close"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="form-button">
                            <a
                              href="#"
                              className="button btn-block"
                              onClick={()=>{
                                  register();
                               
                              }}
                            >
                              Register
                            </a>
                          </div>
                          <div className="term-text">
                            By signing up you agree with our{" "}
                            <Link to="#">Terms & Conditions</Link>.
                          </div>
                          <div className="term-text">
                            <Link to="#" onClick={(evt) => {
                              evt.preventDefault()
                              toggleRegisterMethod(false)}
                            }
                              >See all sign up options</Link>
                          </div>
                        </form>
                    }
                    </div>
                  )}
                 

                  {progress && (
                    <div style={{ textAlign: "center" }}>
                      <Loader
                        type="Puff"
                        color="#00BFFF"
                        height={100}
                        width={100}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
