import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

export default function withSession(ComponentToProtect) {
  return class extends PureComponent {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
        isLogin: false,
      };
    }

    redirectToHome = () => {
      const { history } = this.props;
      if(history) history.push('/landing');
    }

    componentDidMount() {
      let token = localStorage.getItem("token");
      let isLogin = token ? true : false;
      this.setState({
        isLogin,
      });
      isLogin == false ? this.redirectToHome() : console.log("ADA TOKEN")
    }
    render() {
      const { history } = this.props;
      console.log("withSession",{...this.props});
      return (
        <ComponentToProtect {...this.props} isLogin={this.state.isLogin} />
      );
    }
  };
}
