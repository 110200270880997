import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Images } from "../Assets";
import LabelAndFilePicker from "../../widgets/LabelAndFilePicker";
import LabelAndImagePicker from "../../widgets/LabelAndImagePicker";
import MDEditor from "@uiw/react-md-editor";
import Select from 'react-dropdown-select';
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TncThesis from './TncThesis'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URI;

const ModalPost = (props) => {
  const { watchlist, callAction, call_action, open, onFinish, onClose, is_mentor, toggle } = props;
  const [is_open, setOpen] = useState(open || false);
  const [symbol, setSymbol] = useState({});
  const [code, setCode] = useState("");
  const [mentionSection, setMentionSection] = useState("");
  const [update, setUpdate] = useState(0);
  const [title, setTitle] = useState(null);
  const [content, setContent] = useState(null);
  const [post_status, setStatus] = useState(0);
  const [excerpt, setExcerpt] = useState(null);
  const [editorExcerptState, setEditorExcerptState] = useState(null);
  const [excerptHtml, setExcerptHtml] = useState(null);
  const [editorContentState, setEditorContentState] = useState(null);
  const [contentHtml, setContentHtml] = useState("");
  const [attachment_url, setAttachment] = useState(null);
  const [image_url, setImageUrl] = useState(null);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [imagesCounter, setImagesCounter] = useState(0);
  const [video_url, setVideoUrl] = useState(null);
  const [attachment_type, setAttachmentType] = useState('image');  //image | video | file
  const [progress, setProgress] = useState(false);
  const [is_premium, setPremium] = useState(false);
  const [toggle_attachment, setToggleAttachment] = useState(false);
  const [toggle_image, setToggleImage] = useState(false);
  const [emiten, setEmiten] = useState([]);
  const [category, setCategory] = useState([]);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("lorem ipsum");
  const [is_loading, setIsLoading] = useState(false);
  const [is_loading_category, setIsLoadingCategory] = useState(false);
  const [mentorList, setMentorList] = useState([])
  const [selectedMentor, setSelectedMentor] = useState([])
  const [selectedSaham, setSelectedSaham] = useState(null)
  const [is_loading_sectors, setIsLoadingSectors] = useState(false);
  const [selectedSector, setSelectedSector] = useState(null)
  const [sectorList, setSectorList] = useState([])
  const [selectedIndustry, setSelectedIndustry] = useState(null)
  const [selectedcategoryThesis, setSelectedcategoryThesis] = useState("")
  const [selectedWatchlistStatus, setSelectedWatchlistStatus] = useState({id: "0", nama:"None (Default)"})
  const [industryThesis, setIndustryThesis] = useState([])
  const [categoryThesis, setCategoryThesis] = useState([
    {id: "0", nama:"Saham"},
    {id: "1", nama:"Industri"}
  ])
  const [watchlistStatus, setWatchlistStatus] = useState([
    {id: "0", nama:"None (Default)"},
    {id: "1", nama:"Start"},
    {id: "2", nama:"Exit"}
  ])

  useEffect(()=>{
    loadMentor()
    setCode(is_mentor ? toggle : "Tanya");
    setTitle("");
    setContent("");
    setExcerpt("");
    setStatus(1);
    setAttachment(null);
    setImageUrl(null);
    setVideoUrl("");
    setSymbol(checkSymbol());
    setToggleAttachment(false);
    setToggleImage(false);
    setOpen(open);
    setImgsDefault()
    
    const html = '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setEditorExcerptState(editorState)
      setEditorContentState(editorState)
    }

  },[open]);

  const checkSymbol = () => {
    if( localStorage.getItem('toggle') === 'Thesis' ){
      return { name:"Thesis", code:"Thesis"}
    }else if(is_mentor){
      return {name:"Artikel", code:"Artikel"}
    }else{
      return { name:"Tanya", code:"Tanya"}
    }
  }

  const error = (message) => {
    setSnackbar(true);
    setSnackbarText(message);
  }

  const loadSectors = () =>{
    callAction(call_action,'load_sectors_full',{
      endpoint:'/v1/sectors',
      scenario:'get'
    });
    setIsLoading(true);
  }

  const loadEmiten = () => {
    callAction(call_action,'load_emiten',{
      endpoint:'/v1/emiten',
      scenario:'get'
    });
    setIsLoading(true);
  }

  const loadCategory = () => {
    callAction(call_action,'load_category',{
      endpoint:'/v1/category',
      scenario:'get'
    });
    setIsLoadingCategory(true);
  }
  
  const loadSector = () => {
    callAction(call_action, "sectors",  {
      endpoint: "/v1/sectors",
      scenario: "get"
    })
    setIsLoadingSectors(true)
  }
  
  const handleSectors = (payload) => {
    if(!payload) return;
    const {status, sector_list} = payload
    if(!status) return;
    setSectorList(sector_list ? sector_list : [])
    setIsLoadingSectors(false)
    setUpdate(update + 1)
  }


  const handleEmiten = (payload) => {
    if(!payload) return;
    const {status, data} = payload;
    if(!status) return;
    if(status === 0) return;
    
    setEmiten(data ? data : []);
    setIsLoading(false);
    setUpdate(update + 1);
  }

  const addPastedImgToServer = async(file) => {
    return new Promise( 
      (resolve, reject) => { 
        const xhr = new XMLHttpRequest(); 
        xhr.open('POST', API_URL+'/media/upload'); 
        xhr.setRequestHeader('Authorization', localStorage.getItem("token")); 
        xhr.setRequestHeader("Ngrok-Skip-Browser-Warning", true);
        let image = {
          'file': file
        }
        const data = new FormData(); 
        for (let k in image) {
          data.append(k, image[k]);
        }

        xhr.send(data); 
        xhr.addEventListener('load', () => { 
          const response = JSON.parse(xhr.responseText); 
          resolve({ data: {
            link: response.file_url
          }}); 
        }); 
        xhr.addEventListener('error', () => { 
          const error = JSON.parse(xhr.responseText); 
          reject(error); 
        }); 
      } 
    )
  }

  const handleCategory = (payload) => {
    if(!payload) return;
    const {status, data} = payload;
    if(!status) return;
    if(status === 0) return;
    
    setCategory(data ? data : []);
    setIsLoadingCategory(false);
  }

  const handleImages = ()  => {
    let images = []
    for( let i=0 ; i<imagesUrl.length ; i++ ){
      images.push(imagesUrl[i].image_url)
    }
    return images;
  }

  const checkCategorySection = () => {
    if( localStorage.getItem('toggle') === 'Thesis' ){
      return (
        <h4 className="mb-0">
          THESIS
        </h4>
      )
    }else if(is_mentor){
      return (
        <select
          className="form-control header-post"
          value={code}
          onChange={(evt) => {
            if(category.map((item) => item.name).includes(evt.target.value)){
              setSymbol({
                name:evt.target.value,
                code:evt.target.value
              });
              setUpdate(update + 1);
            }
          }}
        >
          <option value={"category"}>Select Category</option>
          {category.map((item, idx) => (
            <option key={idx} value={item.name}>{item.name}</option>
          ))}
        </select>
      )
    }else{
      return (
        <h4 className="mb-0">
          Tanya
        </h4>
      )
    }
  }
  const popupOnReview = () =>{
    Swal.fire({
      icon: 'success',
      title: 'Terima kasih untuk posting Thesis Anda.',
      text: `Saat ini Thesis anda sedang ditinjau oleh 
      para mentor (untuk subscriber) dan akan ditampilkan jika sudah di-approve.`,
      confirmButtonColor: '#00a8ff'
    })
  }

  const handleMentorId = (listMentor)  => {
    if(symbol.code != "Tanya" ) return ''
    let mentors = ''
    for(let idx in listMentor){
      mentors += listMentor[idx].profile_id

      if( idx != listMentor.length-1 ){
        mentors += ','
      }
    }
    return mentors;
  }

  const submit = async() => {
    if(selectedMentor.length > 3){
      Swal.fire({
        icon: 'error',
        text: 'Cannot choose more than 3 mentors',
        confirmButtonColor: '#00a8ff'
      })
    }else{
      // handle thesis
      if( localStorage.getItem('toggle') == 'Thesis' ){
        let mentor_ids = handleMentorId(selectedMentor)
        const image_urls = await handleImages()
         callAction(call_action,'submit_post',{
            scenario:'post',
            endpoint:'/v2/post',
            data:{
              is_premium,
              title,
              content,
              excerpt,
              mentor_ids,
              emiten_code: selectedSaham ? selectedSaham : null,
              styled_content: contentHtml,
              styled_excerpt: excerptHtml,
              attachment_url,
              video_url,
              image_url,
              image_urls,
              symbol: selectedcategoryThesis,
              post_status,
              sector_id:  selectedIndustry ? selectedIndustry : null,
              watchlist_status: selectedWatchlistStatus.id,
            }
          });
          setProgress(true);
          setSelectedMentor([])
          popupOnReview()
          setSelectedSaham(null)
          setSelectedIndustry(null)
          setSelectedWatchlistStatus({id: "0", nama:"None (Default)"})
          setSelectedcategoryThesis("")
      }else{
        // handle non thesis post
        console.log(selectedWatchlistStatus,"<<selectedWatchlistStatus")
        let mentor_ids = handleMentorId(selectedMentor)
        const image_urls = await handleImages()
        const objPost = {
          is_premium,
          title,
          content,
          excerpt,
          mentor_ids,
          styled_content: contentHtml,
          styled_excerpt: excerptHtml,
          attachment_url,
          video_url,
          image_url,
          image_urls,
          symbol: symbol.code,
          post_status,
          watchlist_status: selectedWatchlistStatus.id,
        }
        if( localStorage.getItem('toggle') === 'Thesis' ){
          objPost.symbol = selectedcategoryThesis
        }
        if(objPost.symbol === "Saham" || objPost.symbol === "Tanya") objPost.emiten_code = selectedSaham
        else if(objPost.symbol === "Industri") objPost.sector_id = selectedSector
        setSelectedWatchlistStatus({id: "0", nama:"None (Default)"})

        callAction(call_action,'submit_post',{
            scenario:'post',
            endpoint:'/v2/post',
            data: objPost
          });
          setProgress(true);
          setSelectedMentor([])
        }
    }
  };

  const loadMentor = () => {
    callAction(call_action,'load_mentors',{
      endpoint:'/v1/mentors',
      scenario:'get'
    });
    setIsLoading(true);
    setProgress(true)
  }
  
  useEffect(()=>{
    if(progress && call_action.load_mentors !== null){
      setProgress(false);
      setMentorList(call_action.load_mentors.data);
    }
  },[call_action.load_mentors]);
  
  useEffect(()=>{
    if(call_action.load_sectors_full){
      setIndustryThesis(call_action.load_sectors_full?.sector_list);
    }
  },[call_action.load_sectors_full]);

  const handleSubmit = (payload) => {
      if(typeof payload === 'undefined') return;
      if(payload === null) return;
      if(payload.status === 1) setSnackbarText("Your Post is sent successfully!");
      if(payload.status === 0) setSnackbarText(payload.error);
      // comment out due to un predictable error from BE, will discuss with BE soon
      // if(payload.status === 0) setSnackbarText("Cannot update your post. Please try again later!");
      setSnackbar(true);
      
      //only close when succeeded
      if(payload.status === 1){
        setTimeout(()=>{
          //setOpen(false);
          onFinish();
          onClose();
          setImgsDefault()
        },3000);
      }   
  }

  const handleChangePlain = (event, changes) => {
    let fullText = "";
    for( let i=0 ; i<event.blocks.length ; i++){
      fullText+= event.blocks[i].text
      fullText+= " "
    }
    changes == "content" ? setContent(fullText) : setExcerpt(fullText)
  }

  useEffect(()=>{
      setOpen(open);
      loadEmiten();
      loadSector();
      loadCategory();
      loadSectors()
  },[]);

  useEffect(()=>{
      if(progress && call_action.submit_post !== null){
          setProgress(false);
          handleSubmit(call_action.submit_post);
      }
  },[call_action.submit_post]);

  useEffect(()=>{
      if(is_loading && call_action.load_emiten !== null){
          handleEmiten(call_action.load_emiten);
         
      }
  },[call_action.load_emiten]);

  useEffect(() => {
    if(is_loading_sectors && call_action.sectors) {
      handleSectors(call_action.sectors)
    }
  },[call_action.sectors])

  useEffect(()=>{
    if(is_loading_category && call_action.load_category !== null){
        handleCategory(call_action.load_category);
       
    }
},[call_action.load_category]);

  useEffect(()=>{
    if(symbol){
      setCode(symbol.code);
    }
  },[symbol])

  const excerptChange = (state) => {
    let htmlFormat = draftToHtml(convertToRaw(editorExcerptState.getCurrentContent()));
    setEditorExcerptState(state)
    setExcerptHtml(htmlFormat)
  }

  const contentChange = (state) => {
    let htmlFormat = draftToHtml(convertToRaw(editorContentState.getCurrentContent()));
    setEditorContentState(state)
    setContentHtml(htmlFormat)
  }
  
  const setImgsDefault = () => {
    setImagesUrl([
      {order:0, image_url:''},
      {order:1, image_url:''},
      {order:2, image_url:''},
      {order:3, image_url:''},
      {order:4, image_url:''},
      {order:5, image_url:''},
      {order:6, image_url:''},
      {order:7, image_url:''},
      {order:8, image_url:''},
    ])
    setImagesCounter(0)
  }

  const setImagesFunc = (data, id) => {
    let datas = []
    for( let i=0 ; i<imagesUrl.length ; i++ ){
      if( i==id  ){
        datas.push({
          order: id,
          image_url: data
        })
      }else{
        datas.push(imagesUrl[i])
      }
    }
    setImagesUrl(datas)
    if( id >= imagesCounter ){
      let currCounter = imagesCounter + 1;
      setImagesCounter(currCounter);
    }
  }
  
  return (
    <>
    <div
      className={`modal fade modal-post ${is_open ? `show` : ``}`}
      id="modalPost"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" style={{ minWidth: "578px", maxWidth: "1000px" }}>
        <div className="modal-content pb-3">
          <div className="modal-header">
            <div className="modal-post-header" style={{width: '100%'}}>
              {emiten.length === 0 && <Loader
                   type="Puff"
                   color="#00BFFF"
                   height={30}
                   width={30}
                 />}
            
            { emiten.length > 0 && checkCategorySection() }
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={()=>{
                setOpen(false);
                onClose();
                setImgsDefault()
              }}
            >
              <img src={Images.icClose} alt="close" />
            </button>
          </div>
          <div className="modal-body" style={{overflowY:'auto',height:window.innerHeight * 0.7}}>
            {/* {(typeof symbol.code !== 'undefined' && !['Sektoral','Admin','Artikel','Action','Event','Tanya'].includes(code))
             &&
              <div className="top-modal-post">
               <div><span>{code}</span>{symbol.name}</div> 
              </div>
            } */}
            {
              localStorage.getItem('toggle') === 'Thesis' ?
              (<>
                <p className="postTitle">
                  Terms & Conditions:
                </p>
                <TncThesis/>
              </>) : null
            }
            {
              <form className="main-post-form">
                { symbol.code === "Tanya" &&
                  <div className="form-group">
                    <p className="postTitle">
                      Mentor
                    </p>
                    <Select
                      className="select-dropdown2"
                      multi
                      valueField="id"
                      labelField="nama"
                      options={mentorList}
                      placeholder="Choose mentor"
                      values={selectedMentor}
                      onChange={(values) => setSelectedMentor(values)}
                    />
                  </div>
                }
                { (symbol.code === "Tanya" || (is_mentor && symbol.code === "Saham")) &&
                  <div className="form-group">
                    <p className="postTitle">
                      Kode Saham {symbol.code == "Tanya" ? "(Optional)" : ""}
                    </p>
                    <select className="dropdown-saham" onChange={(e) => {
                      setSelectedSaham(e.target.value)
                      setUpdate(update + 1);
                    }}>
                      <option> Choose Saham </option>
                      {emiten.map((item, idx) => {
                      return (
                        <option value={item.code}
                          key={idx}
                        >
                          {item.code} - {item.name} 
                        </option>
                      );
                      })}
                    </select>
                    { symbol.code == "Tanya" ? <p className="info-field-saham">Jika tidak dipilih, maka akan menjadi pertanyaan non-saham</p> : null}
                  </div>
                }

                { (symbol.code == "Industri" && is_mentor) &&
                  <div className="form-group">
                    <p className="postTitle">
                      Kode Industri
                    </p>
                    <select className="dropdown-saham" onChange={(e) => {
                      setSelectedSector(e.target.value)
                      setUpdate(update + 1);
                    }}>
                      <option> Choose Industri </option>
                      {sectorList.map((item, idx) => {
                      return (
                        <option value={item.id}
                          key={idx}
                        >
                          {item.name} 
                        </option>
                      );
                      })}
                    </select>
                  </div>
                }
                
                {
                  localStorage.getItem('toggle') === 'Thesis' &&
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <p className="postTitle">
                          Kategori
                        </p>
                        <select className="dropdown-saham" 
                          onChange={(e) => {
                            setSelectedcategoryThesis(e.target.value);
                          }}
                        >
                          <option> Choose Kategori </option>
                          {categoryThesis.map((item, idx) => {
                            return (
                              <option value={item.nama} key={idx}>
                                {item.nama}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        { selectedcategoryThesis == "Saham" &&
                          <>
                            <p className="postTitle">
                              Saham
                            </p>
                            <select className="dropdown-saham"
                              onChange={(e) => {
                                setSelectedSaham(e.target.value)}}
                            >
                              <option> Choose Saham </option>
                              {emiten.map((item, idx) => {
                              return (
                                <option value={item.code} key={idx} >
                                  {item.code} - {item.name} 
                                </option>
                              );
                              })}
                            </select>
                          </>
                        }
                        { selectedcategoryThesis == "Industri" &&
                          <>
                            <p className="postTitle">
                              Industri
                            </p>
                            <select className="dropdown-saham"
                              onChange={(e) => {
                                setSelectedSector(e.target.value)}}
                            >
                              <option> Choose Industri </option>
                              {industryThesis.map((item, idx) => {
                              return (
                                <option value={item.id} key={idx}>
                                  {item.name} 
                                </option>
                              );
                              })}
                            </select>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                }
                {
                  (is_mentor && symbol.code === "Saham") && ( localStorage.getItem('toggle') === 'Latest' || localStorage.getItem('toggle') === 'Watchlist' ) &&
                  <div className="col-md-6 pl-0">
                    <div className="form-group">
                      <p className="postTitle">
                      Watchlist
                      </p>
                      <select className="dropdown-saham" 
                        onChange={(e) => {
                          setSelectedWatchlistStatus({id: e.target.selectedIndex, nama: e.target.value});
                        }}
                      >
                        {watchlistStatus.map((item, idx) => {
                          return (
                            <option value={item.nama} key={idx}>
                              {item.nama}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                }

                
                <div className="form-group">
                  <p className="postTitle">
                    Title
                  </p>
                  <input
                    type="text"
                    className="form-control borderful postBox"
                    placeholder="Enter title here"
                    value={title}
                    onChange={(evt)=>{
                      setTitle(evt.target.value);
                    }}
                    onBlur={(evt) => {
                      setTitle(evt.target.value);
                    }}
                  />
                </div>
                {((localStorage.getItem("toggle") === "Thesis") || (is_mentor && localStorage.getItem("toggle") === "Latest")) && 
                  
                  <div className="form-group">  
                    <div className="form-group">
                      <p className="postTitle">
                        Excerpt
                      </p>
                      <Editor
                        editorState={editorExcerptState}
                        mention={{
                          separator: ' ',
                          trigger: '@',
                          suggestions: [
                            { text: 'APPLE', value: 'apple', url: 'apple' },
                            { text: 'BANLE', value: 'BANle', url: 'BANle' },
                            { text: 'BANANA', value: 'banana', url: 'banana' },
                            { text: 'CHERRY', value: 'cherry', url: 'cherry' },
                            { text: 'DURIAN', value: 'durian', url: 'durian' },
                            { text: 'EGGFRUIT', value: 'eggfruit', url: 'eggfruit' },
                            { text: 'FIG', value: 'fig', url: 'fig' },
                            { text: 'GRAPEFRUIT', value: 'grapefruit', url: 'grapefruit' },
                            { text: 'HONEYDEW', value: 'honeydew', url: 'honeydew' },
                          ],
                        }}
                        wrapperClassName={"border padding"}
                        editorClassName="demo-editor"
                        editorStyle={{
                          "paddingLeft": "3%",
                          "height": "170px",
                          "fontSize": "12pt"
                        }}
                        handlePastedText={() => false}
                        placeholder="Enter your summary, your posting preview before user clicks 'Read more..'"
                        toolbar={{
                          fontSize: {options: []},
                          fontFamily: {options: [],}
                        }}
                        onEditorStateChange={(state)=>{
                          excerptChange(state)
                        }}
                        onChange={(e)=>{
                          // for set plain text
                          handleChangePlain(e, "excerpt")
                        }}
                      />
                    </div>
                    <hr/>
                  </div>
                }

                {
                  localStorage.getItem('toggle') === 'Thesis' ?
                    <>
                      <div className="form-group">
                        <p className="postTitle">
                          Content
                        </p>
                        <Editor
                          className="testClass"
                          editorState={editorContentState}
                          wrapperClassName={"border padding"}
                          editorClassName="demo-editor"
                          handlePastedText={() => false}
                          placeholder="Enter your full post here.."
                          editorStyle={{
                            "paddingLeft": "3%",
                            "height": "170px",
                            "fontSize": "12pt"
                          }}
                          previewImage={true}
                          toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'image', 'history'],
                            image: {
                              urlEnabled: true,
                              uploadEnabled: true,
                              previewImage: true,
                              alt: { present: true, mandatory: false },
                              uploadCallback: (e) => addPastedImgToServer(e),
                              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
                            },
                            fontSize: {options: []},
                            fontFamily: {options: [],}
                          }}
                          onEditorStateChange={(state)=>{
                            //for set html version text
                            contentChange(state)
                          }}
                          onChange={(e)=>{
                            // for set plain text
                            handleChangePlain(e, "content")
                          }}
                        />
                        {/* <MDEditor
                        value={content}
                        onChange={(value) => {
                          setContent(value);
                        }}
                        /> */}
                      </div>
                    </>
                  :
                    <div className="form-group">
                      <p className="postTitle">
                        Content
                      </p>
                      <Editor
                        className="testClass"
                        editorState={editorContentState}
                        wrapperClassName={"border padding"}
                        editorClassName="demo-editor"
                        handlePastedText={() => false}
                        placeholder="Enter your full post here.."
                        editorStyle={{
                          "paddingLeft": "3%",
                          "height": "170px",
                          "fontSize": "12pt"
                        }}
                        toolbar={{
                          fontSize: {options: []},
                          fontFamily: {options: [],}
                        }}
                        onEditorStateChange={(state)=>{
                          //for set html version text
                          contentChange(state)
                        }}
                        onChange={(e)=>{
                          // for set plain text
                          handleChangePlain(e, "content")
                        }}
                      />
                      {/* <MDEditor
                      value={content}
                      onChange={(value) => {
                        setContent(value);
                      }}
                      /> */}
                    </div>
                }
                <br/>
                {toggle_attachment && (
                  <div className={`form-group`} style={{display:attachment_type === 'image' ? 'block' : 'flex'}}>
                  <div style={{flexGrow:1}}>
                  <select className={`form-control`} style={{height:'auto'}} onChange={(evt)=>{
                    setAttachmentType(evt.target.value);
                  }}>
                    <option value="image">Image</option>
                    <option value="video">Video</option>
                  {is_mentor && <option value="vcdn">Video CDN</option>}
                  </select>
                  </div>
                  <div style={{flexGrow:2}}>
                  {attachment_type === 'video' && <input
                    type="text"
                    className="form-control"
                    placeholder="Youtube Video URL"
                    value={video_url}
                    onChange={(evt)=>{
                      setVideoUrl(evt.target.value);
                    }}
                    onBlur={(evt) => {
                      setVideoUrl(evt.target.value);
                    }}
                  />}

                  {attachment_type === 'vcdn' && <input
                    type="text"
                    className="form-control"
                    placeholder="CDN Url"
                    value={video_url}
                    onChange={(evt)=>{
                      setVideoUrl(evt.target.value);
                    }}
                    onBlur={(evt) => {
                      setVideoUrl(evt.target.value);
                    }}
                  />}

                  {attachment_type === 'image' && <div>
                    <>
                      { 
                        imagesUrl.map((item)=>{
                        if(item.order <= imagesCounter){
                          return (<LabelAndImagePicker
                            label={``} key={item.order} onChange={(val)=>{
                              setImagesFunc(val, item.order)
                            }}
                            />)
                          }
                        })
                      }
                    </></div>}
                        {/* setImageUrl(val); */}
                  {attachment_type === 'file' && <input
                    type="text"
                    className="form-control"
                    placeholder="File URL"
                    value={attachment_url}
                    onChange={(evt)=>{
                      setAttachment(evt.target.value);
                    }}
                    onBlur={(evt) => {
                      setAttachment(evt.target.value);
                    }}
                  />}
                    </div>
                  
                  </div>
                )}
                {/* <div className={`form-group`}>
                    <select className={`form-control`} style={{height:'auto'}} onChange={(evt)=>{
                        setStatus(evt.target.value);
                      }}>
                        <option value="0">Publish Status</option>
                        <option value="0">Draft</option>
                        <option value="1">Published</option>
                      </select>
                </div> */}
                {snackbar && (
                  <div style={{marginTop:20, marginBottom:20}}>
                      <SnackBar
                        show={snackbar}
                        text={snackbar_text}
                        onClose={() => {
                          setSnackbar(false);
                        }}
                      />
                      </div>
                    
                    )}
                <div className="form-group form-post-footer">

                  <Link
                    to="#"
                    className="file-attach"
                    onClick={() => {
                      setToggleAttachment(!toggle_attachment);
                    }}
                  >
                    <img src={Images.icAttach} alt="attach" /> Attachment
                  </Link>
                

                  {!progress && <Link
                    to="#"
                    className="button"
                    onClick={() => {
                      if (symbol === null) return error('Harap pilih CATEGORY');
                      if (typeof symbol.code === 'undefined') return error('Harap pilih CATEGORY');
                      if(symbol.code === "Saham" && !selectedSaham) return error('Harap pilih Kode Saham');
                      if(symbol.code === "Industri" && !selectedSector) return error('Harap pilih Industri');
                      if (!title) return error('Harap masukkan TITLE');
                      if (title.length === 0) return error('Harap masukkan TITLE');
                      // if (content.length === 0) return error('Harap masukkan DESKRIPSI');
                      // if(content.length < 30) return error('DESKRIPSI minimal 30 karakter');
                      if (contentHtml.length === 0) return error('Harap masukkan CONTENT');
                      if(contentHtml.length < 30) return error('DESKRIPSI minimal 30 karakter');
                      if(selectedcategoryThesis == "Saham" && !selectedSaham) return error('Harap pilih Saham Thesis anda');
                      if(selectedcategoryThesis == "Industri"&& !selectedSector) return error('Harap pilih Jenis Industri Thesis anda');
                      if(!selectedcategoryThesis && symbol.code === "Thesis") return error('Harap pilih CATEGORY');
                      submit();
                    }}
                  >
                    Post
                  </Link>}
                  {progress && <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={30}
                    width={30}
                  />}
                </div>
              
              </form>
            }
            
            
          </div>
        </div>
      </div>
    </div>
     {is_open && <div className={`modal-backdrop fade show`}></div>}
    </>
  );
};

export default ModalPost;
