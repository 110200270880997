/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Timeline from "../../parts/Timeline";
import TimelineItem from "../../parts/TimelineItem";
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from "react-loading";
import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalEdit,
  ModalPost,
  CardLeft,
} from "../../parts";
import { load_notif } from "../../libs/helper";
import PopupNewPosts from "../../parts/PopupNewPosts";
import PopupNewComments from "../../parts/PopupNewComments";
import Notifications from "../../parts/Notifications";

const HomeThesis = (props) => {
  const { callAction, call_action } = props;
  const location = useLocation()
  const [ready, setReady] = useState(false);
  const [fetching, loading] = useState(false);
  const [load_recent, setLoadingRecent] = useState(false);
  const [progress, setProgress] = useState(false);
  const [is_post, togglePostModal] = useState(false);
  const [isLogin, setLoginStatus] = useState(false);
  const [is_edit, toggleEditModal] = useState(false);
  const [edit_item, setEditItem] = useState(null);
  const [posts, setPosts] = useState([]);
  const [premium, setPremium] = useState([]);
  const [events, setEvents] = useState([]);
  const [watchlist, setWatchlist] = useState([]);
  const [since_id, setSince_id] = useState(0);
  const [next_id, setNextId] = useState(0);
  const [toggle, setToggle] = useState('Latest');
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [profile, setProfile] = useState(null);
  const [followings, setFollowings] = useState([]);
  const [allSaham, setAllSaham] = useState([]);
  const [mentors, setMentors] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [popup_mentor_id, setPopupMentorId] = useState(0);
  const [toggle_popup, setTogglePopup] = useState(false);
  const [popup_reply_symbol, setPopupReplySymbol] = useState("");
  const [popup_reply_limit, setPopupReplyLimit] = useState(0);
  const [popup_symbol, setPopupSymbol] = useState("");
  const [popup_limit, setPopupLimit] = useState(0);
  const [toggle_content, setContent] = useState(false);
  const [toggle_messages, setToggleMessage] = useState(false);
  const [post_item, setPostItem] = useState(null);
  const [sort, setSort] = useState('desc');
  const [mentor_ids, setMentorIds] = useState(null);
  const [is_subscribed, setIsSubscribed] = useState(false);
  const [ts, setTimestamp] = useState(localStorage.getItem("last_ts") || 0);
  const scroll = useRef();
  const [filters, setFilters] = useState({
    code: "",
    mentor_id: 0,
  });
  const [update, setUpdate] = useState(0);
  const [filterTanya, setFilterTanya] = useState(false)
  const [filterThesis, setFilterThesis] = useState(false)
  const [filterLatest, setFilterLatest] = useState(false)
  const [triggerFilter, setTriggerFilter] = useState(false)
  const [selSaham, setSelSaham] = useState(null)
  // const [selSector, setSelSector] = useState(null)
  const [selMentor, setSelMentor] = useState(null)
  // const [selCategories, setSelCategories] = useState(null)
  const [account_id, setAccountId] = useState("")
  const [allSectors, setAllSectors] = useState([]);
 
  const onUserQuestions = (id) => {
    setAccountId(id)
    setFilterTanya(true)
    setSince_id(0)
  }

  const  onUserCategoryPosts = (id, category, code) => {
    setSince_id(0)
    setAccountId(id)
    const obj = {}
    if(category === 'Saham') obj.sahamCheck = code
    else if(category === 'Industri') obj.sectorCheck = code
    setFilters(obj)
    setTriggerFilter(!triggerFilter)
    setFilterThesis(true)
  }
 
  const applyFilterTimeline = (filters ,section, sorting) => {
    if(section === "Thesis" ){
      setAccountId("")
      setTriggerFilter(!triggerFilter)
      setFilterThesis(true)
      setFilters(filters)
    }
    setSort(sorting)
  }

  const categoriesCheck = () => {
    if( filters.sahamCheck && filters.sectorCheck ){
      return `Saham,Industri`
    } else if( filters.sahamCheck ){
      return `Saham,`
    }else{
      return `Industri`
    }
  }

  const load = () => {
    // load total unread inbox msg
    callAction(call_action, "inbox_count", {
      endpoint: "/v2/inbox",
      scenario: "get",
      data: {
        since_id,
        sort,
        mentor_ids,
        account_tanya: account_id
      },
    });
    if(filterThesis){
      callAction(call_action, "timelineThesis", {
        endpoint: "/v1/recent",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids: selMentor,
          accountId: account_id,
          emitens: filters.sahamCheck,
          includeSubs: true,
          categories: categoriesCheck(),
          sectors: filters.sectorCheck,
        },
      });
    }else{
      callAction(call_action, "timelineThesis", {
        endpoint: "/v1/recent",
        scenario: "get",
        data: {
          since_id,
          sort,
          mentor_ids: selMentor,
          emitens: selSaham,
          includeSubs: true,
          categories: `Saham,Industri`,
          isThesis: true,
        },
      });
    }
  };
  
  const loadMentors = () => {
    callAction(call_action, "load_mentors", {
      endpoint: "/v1/mentors",
    });
    setProgress(true);
  };
  
  const loadListSaham = () => {
    callAction(call_action, "load_saham", {
      endpoint: "/v1/emiten",
      data: {
        isFilter: true
      }
    });
    setProgress(true);
  };

  const loadSectors = () => {
    callAction(call_action, "load_sectors", {
      endpoint: "/v1/sectors",
      data: {
        isFilter: true
      }
    });
    setProgress(true);
  }

  const loadProfile = () => {
    callAction(call_action, "me", {
      endpoint: "/v1/myself",
    });
    setProgress(true);
  };

  const handleTimeline = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
  
    const { status, data, r,next_id } = payload;
    
    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    if(since_id === 0 && payload.data && payload.data.length === 0) {
      setPosts([])

    };
    if(since_id === 0 && payload.data && payload.data.length > 0) {
      setPosts(payload.data);
      localStorage.removeItem("prevPosts");
      if(payload.data.length > 0){
        localStorage.setItem("prevPosts", payload.data[0].title);
      }
    }
    else if(payload.data && payload.data.length > 0) {
      setPosts(payload.data ? [...posts,...payload.data] : posts);
      localStorage.removeItem("prevPosts");
      if(payload.data.length > 0){
        localStorage.setItem("prevPosts", payload.data ? [...posts,...payload.data[0].title] : posts[0].title);
      }
    }
    setNextId(next_id);
    setUpdate(update + 1);
   
    return true;
  };

  const handlePremium = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    const { status, data, r } = payload;
    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    setPremium(payload.data ? payload.data : []);
    setUpdate(update + 1);
  };
  const onProfileLoaded = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setIsMentor((payload.data && payload.data.mentor && payload.data.mentor.id > 0) ? true : false)
    setProfile(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setWatchlist(payload.data ? payload.data.watchlists : []);
    setFollowings(payload.data ? payload.data.followed_mentors : []);
    setSubscriptions(payload.data ? payload.data.mentorsubs : []);
    setIsSubscribed(payload.data ? payload.data.is_subscribed : false);
    setAccountId(payload.data.profile.account_id)
    return true;
  };

  useEffect(() => {
    setPosts([])
    localStorage.removeItem("prevPosts")
    localStorage.removeItem("toggle")
    
    localStorage.setItem('toggle','Thesis')
    setToggle('Thesis')
    setTimeout(() => {
      load_notif();
      setLoginStatus(localStorage.getItem("token") ? true : false);
      loadProfile();
      window.scrollTo(0, 0);
    }, 500);
    loadMentors()
    loadListSaham()
    loadSectors()
  }, []);
  
  useEffect(() => {
    if( call_action.me ){
      let ok = onProfileLoaded(call_action.me);
      if(ok){
         setProgress(false);
      }
    }
  }, [call_action.me]);

  useEffect(() => {
    if( call_action.timelineThesis) {
      if( call_action.timelineThesis.data.length > 0 ){
          let ok = handleTimeline(call_action.timelineThesis);
       
          if(ok) loading(false);
          if(!ready) setReady(true);
      }else{
        loading(false);
        if(!ready) setReady(true);
      }
    }else if(since_id != 0){
      let ok = handleTimeline(call_action.timelineThesis);
       
      if(ok) loading(false);
      if(!ready) setReady(true);
    }
  }, [call_action.timelineThesis]);

  useEffect(() => {
    setReady(false)
    if(ready) {
      setPosts([])
      if(since_id === 0) load();
      else{
        setSince_id(0);
        setNextId(0);
      }
    }
  }, [localStorage.getItem('toggle'), triggerFilter]);

  useEffect(() => {
    if (fetching && call_action.premium ) {
      handlePremium(call_action.premium);
      loading(false);
    }
  }, [call_action.premium]);

  useEffect(() => {
    if (!fetching && call_action.load_mentors) {
      setMentors(call_action.load_mentors.data);
      loading(false);
    }
  }, [call_action.load_mentors]);

  useEffect(() => {
    if (!fetching && call_action.load_saham) {
      setAllSaham(call_action.load_saham.data);
      loading(false);
    }
  }, [call_action.load_saham]);

  useEffect(() => {
    if (!fetching && call_action.load_sectors) {
      setAllSectors(call_action.load_sectors.sector_list);
      loading(false);
    }
  }, [call_action.load_sectors]);

  useEffect(()=>{
    setSince_id(0);
    load();
  },[sort]);

  useEffect(()=>{
    if( mentor_ids ){
      setSince_id(0);
      load();
    }
  },[mentor_ids]);
  
  useEffect(()=>{
    if(since_id){
      load();
    }
  },[since_id]);

  // useEffect(() =>{
  //   load()
  // },[account_id, filterTanya])

  useEffect(() =>{
    if(filterLatest){
      setReady(false)
      setPosts([])
      load()
      setFilterLatest(false)
    }
  },[filterLatest])

  return (
    <>
      <Notifications {...props} onLoaded={()=>{
      
      }}/>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {
          setFilters(input);
          setUpdate(update + 1);
        }}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-md-3 p-0">
                {ready && <CardLeft
                  {...props}
                  withFilter={true}
                  is_mentor={is_mentor}
                  stats={stats}
                  watchlist={watchlist}
                  followings={followings}
                  subs={subscriptions}
                  onProjectClicked={(input) => {
                    const { symbol, limit } = input;
                    setPopupReplySymbol(symbol);
                    setPopupReplyLimit(limit);
                    setToggleMessage(true);
                  }}
                  onFolderClicked={(folder)=>{
                    localStorage.setItem("folder_id",folder.id);
                    localStorage.setItem("folder_name",folder.name);
                    document.location="/my-notes";
                  }}
                  onFilter={(filter)=>{
                  
                    const {sort, mentor_ids} = filter;
                    setSort(sort);
                    if(mentor_ids.length > 0) setMentorIds(mentor_ids.join(","));
                    else setMentorIds(null);
                  
                  }}
                  onSubsClicked={(input) => {
                    const { mentor_id, symbol, limit } = input;
                    setPopupMentorId(mentor_id || 0);
                    setPopupSymbol(symbol || "");
                    setTimestamp(localStorage.getItem("last_ts") || 0);
                    setTogglePopup(true);
                    setPopupLimit(limit);
                  }}
                  onWatchlist={() => {
                    loadProfile();
                  }}
                />}
              </div>
              <div className="col-md-9">
                {(ready && !toggle_content && localStorage.getItem('toggle') === "Thesis") && (
                  <InfiniteScroll
                    ref={scroll}
                    dataLength={posts.length} //This is important field to render the next data
                    next={()=>{
                      setSince_id(next_id);
                    }}
                    hasMore={since_id !== next_id ? true : false}
                    
                    endMessage={``}
                    refreshFunction={()=>{
                      setSince_id(0);
                    }}
                  >
                    <Timeline
                      toolbar={true}
                      progress={fetching}
                      isLogin={isLogin}
                      onUserCategoryPosts={onUserCategoryPosts}
                      is_mentor={is_mentor}
                      mentors={mentors}
                      ready={ready}
                      allSaham={allSaham}
                      allSectors={allSectors}
                      applyFilterTimeline={applyFilterTimeline}
                      can_post={is_mentor || (is_subscribed === 1)}
                      posts={posts}
                      premium={premium}
                      events={[]}
                      toggle={toggle}
                      onToggle={(input) => {
                        setToggle(input);
                        setFilterTanya(false);
                        setAccountId("");
                        setSince_id(0);
                      }}
                      callAction={callAction}
                      call_action={call_action}
                      onRefresh={() => {
                        loadProfile();
                      }}
                      onReload={()=>{
                        setSince_id(0);
                        setFilterTanya(false);
                        setFilterThesis(false);
                        setFilters("")
                        setAccountId("");
                      }}
                      on_toggle_modal={()=>{
                        togglePostModal(true);
                      }}
                        onEdit={(item)=>{
                        setEditItem(item);
                        toggleEditModal(true);
                      }}
                      onNext={()=>{
                        setSince_id(next_id);
                      }}
                      onUserQuestions={onUserQuestions}
                    />
                  </InfiniteScroll>
                )}
                {toggle_content && (
                  <TimelineItem
                    isLogin={isLogin}
                    item={post_item}
                    toggle={toggle}
                    onToggle={(input) => {
                      setToggle(input);
                      setFilterTanya(false);
                      setAccountId("");
                      setSince_id(0);
                    }}
                    callAction={callAction}
                    call_action={call_action}
                    onRefresh={() => {
                      loadProfile();
                    }}
                  />
                )}
                { !ready && (
                  <div style={{ textAlign: "center", margin: 300 }}>
                    <ReactLoading type={"bubbles"} color="#85909ecc" height={80} width={80}/>
                  </div>
                )}
              </div>
             {/* {ready && <CardRight {...props}/>} */}
            </div>
          </div>
        </div>
      </section>
      <ModalEvent />
      <ModalPost
        watchlist={watchlist}
        {...props}
        toggle={toggle}
        open={is_post}
        onClose={()=>{
          togglePostModal(false);
        }}
        onFinish={() => {
          load();
          togglePostModal(false);
        }}
        is_mentor={is_mentor}
      />
      
      {(
        <ModalEdit
          watchlist={watchlist}
          {...props}
          data={edit_item}
          open={is_edit}
          is_mentor={is_mentor}
          onClose={()=>{
            load();
            toggleEditModal(false);
          }}
          onFinish={() => {
            load();
            toggleEditModal(false);
          }}
        />
      )}

      <ModalHistory subscriptions={[]} {...props} />
      {toggle_popup && (
        <PopupNewPosts
          mentor_id={popup_mentor_id}
          symbol={popup_symbol}
          ts={ts}
          limit={popup_limit || 10}
          fetching={load_recent}
          {...props}
        />
      )}
      {toggle_messages && (
        <PopupNewComments
          symbol={popup_reply_symbol}
          ts={ts}
          limit={popup_reply_limit}
          fetching={false}
          {...props}
        />
      )}
    </>
  );
};

export default HomeThesis;
