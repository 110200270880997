import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Footer, Header, Images } from "../../parts";

const MentorLending = (props) => {
 
  React.useEffect(() => {
    document.title = "Landing | Mentor Baik";
  }, []);

  return (
    <>
      <Header isLogin={false} {...props} onFilter={(input) => {}} />
      <section id="content">
			<div class="content-wrap">
				<div class="section nobg nomargin landing-top-banner">
					<div class="container">
						<div class="row align-items-center">
							<div class="col-md-6">
								<h1>Learn to Earn</h1>
								<p>Apabila kamu ingin mendapatkan rekomendasi saham apa yang besok akan naik, maka MentorBaik bukanlah pilihan yang tepat. Tapi jika kamu ingin mengenal prinsip investasi yang mengedepankan analisa bisnis dan kondisi detil keuangan perusahaan, MentorBaik dapat membantu kamu.</p>
								<p>Dengan bergabung di platform MentorBaik, kamu akan mendapatkan insight dari 6 value investor berpengalaman. Silahkan download aplikasi MentorBaik untuk mendapatkan insight terupdate dari kami.</p>
								<div class="download-badge">
                    <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.mentorbaik">
                      <img src="images/badge-playstore.svg" />
                    </a>
                    <a target={'_blank'} href="https://apps.apple.com/id/app/mentorbaik/id6444364874">
                      <img src="images/badge-appstore.svg" />
                    </a>
								</div>
							</div>
							<div class="col-md-6">
								<div class="top-banner-image">
									<img src="images/landing-hero-image.png" class="img-fluid"/>
									<img src="images/phone-mockup.png" class="top-phone-mockup"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="section section-teams nomargin">
					<div class="container">
						<div class="heading-block title-center">
							<h2>Co- Founders <br/>Berinvestasi dengan Akal Sehat</h2>
						</div>
						<div class="row">
							<div class="col-md-4">
								<div class="team-item-box">
									<img src="images/team-1.png" class="profile-photo"/>
									<div class="profile-description">
										<h4>Ricky Hopmans</h4>
										<p>"Find the story, find the WHY"</p>
									</div>
									<div class="profile-socials">
										<a href="https://www.instagram.com/ricky.a.h_2212/" target="_blank"><img src="images/ic-instagram.svg"/></a>
										<a href="#"><img src="images/ic-twitter.svg"/></a>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="team-item-box">
									<img src="images/team-2.png" class="profile-photo"/>
									<div class="profile-description">
										<h4>Rizza Adillah</h4>
										<p>"Cycle as momentum. Industry as direction. Company as vehicle."</p>
									</div>
									<div class="profile-socials">
										<a href="#"><img src="images/ic-instagram.svg"/></a>
										<a href="#"><img src="images/ic-twitter.svg"/></a>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="team-item-box">
									<img src="images/team-3.png" class="profile-photo"/>
									<div class="profile-description">
										<h4>Robert Widjaja</h4>
										<p>"Berapa banyak…? Berapa lama …?"</p>
									</div>
									<div class="profile-socials">
										<a href="https://www.instagram.com/kis.bear/" target="_blank"><img src="images/ic-instagram.svg"/></a>
										<a href="#"><img src="images/ic-twitter.svg"/></a>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="team-item-box">
									<img src="images/team-4.png" class="profile-photo"/>
									<div class="profile-description">
										<h4>Steven Rin</h4>
										<p>"Risk first, then reward"</p>
									</div>
									<div class="profile-socials">
										<a href="#"><img src="images/ic-instagram.svg"/></a>
										<a href="#"><img src="images/ic-twitter.svg"/></a>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="team-item-box">
									<img src="images/team-5.png" class="profile-photo"/>
									<div class="profile-description">
										<h4>Thomas William Simardjo</h4>
										<p>"3K, Kinerja, Kinerja, Kinerja"</p>
									</div>
									<div class="profile-socials">
										<a href="https://www.instagram.com/investorsaham.id/" target="_blank"><img src="images/ic-instagram.svg"/></a>
										<a href="https://twitter.com/investorsahamid?" target="_blank"><img src="images/ic-twitter.svg"/></a>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="team-item-box">
									<img src="images/team-6.png" class="profile-photo"/>
									<div class="profile-description">
										<h4>Tom Hardi</h4>
										<p>"BMCP: Business Mindset Courage Persistence"</p>
									</div>
									<div class="profile-socials">
										<a href="https://www.instagram.com/tomhardi.investing/" target="_blank"><img src="images/ic-instagram.svg"/></a>
										<a href="#"><img src="images/ic-twitter.svg"/></a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="section nobg nomargin">
					<div class="container">
						<div class="heading-block title-center">
							<h2>Mentorbaik Value</h2>
						</div>
						<div class="row">
							<div class="col-md-4">
								<div class="benefit-item-wrap">
									<img src="images/ic-waras.svg"/>
									<h4>Akal Sehat</h4>
									<p>Berinvestasi dengan memahami resiko, story dan analisa perusahaan..</p>
								</div>
							</div>
							<div class="col-md-4">
								<div class="benefit-item-wrap">
									<img src="images/ic-open.svg"/>
									<h4>Mentalitas</h4>
									<p>Tidak hanya saham dan harga saja tetapi pendekatan emosi yang benar.</p>
								</div>
							</div>
							<div class="col-md-4">
								<div class="benefit-item-wrap">
									<img src="images/ic-komunitas.svg"/>
									<h4>Independen</h4>
									<p>Belajar untuk berani mengambil keputusan dan berpikir sendiri.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="section nobg nomargin landing-bottom-section">
					<div class="container">
						<div class="row small-gutters align-items-center">
							<div class="col-md-7">
								<div class="left-col">
									<img src="images/flat-browser-2.png" class="flat-browser"/>
									<img src="images/phone-mock-2.png" class="iphone-pro"/>
								</div>
							</div>
							<div class="col-md-5">
								<div class="right-col">
								
									<p>1 Aplikasi untuk Komunitas Investasi yang lebih menekankan Sudut Pandang Bisnis,
agar setiap investor dapat menemukan karakter investasi yang profitable dan sustain.</p>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		</section>
      <Footer />
    </>
  );
};

export default MentorLending;