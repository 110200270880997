import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Images } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";

const MentorAccountSetPrice = (props) => {
  const { callAction, call_action } = props;

  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [setups, setSetups] = useState(null);
  const [progress, setProgress] = useState(false);
  const [stats, setStats] = useState(null);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState(null);
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );

  const load = () => {
    callAction(call_action, "subscription_setup", {
      endpoint: "/v1/mentor/setup",
      scenario: "get",
    });
    setProgress(true);
  };

  const onLoadCompleted = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (!payload.data) return;
    setSetups(payload.data);
    setProgress(false);
  };

  const save = () => {
    const { ma, m1, m3, m6, m12 } = setups;

    callAction(call_action, "save_setup", {
      endpoint: "/v1/mentor/setup",
      scenario: "post",
      data: {
        ma,
        m1,
        m3,
        m6,
        m12,
      },
    });
    setProgress(true);
  };
  const onSaved = (payload) => {
    setProgress(false);
  };

  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
    load();
  }, []);

  useEffect(() => {
    if (progress && call_action.subscription_setup !== null) {
      onLoadCompleted(call_action.subscription_setup);
    }
  }, [call_action.subscription_setup]);

  useEffect(() => {
    if (progress && call_action.save_setup !== null) {
      onSaved(call_action.save_setup);
      setSnackbar(true);
      setSnackbarText("Konfigurasi anda telah berhasil disimpan.");
    }
  }, [call_action.save_setup]);

  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center pt-4 pb-4">
              <div className="col-md-3">
                <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={
                    data ? data.avatar_url : localStorage.getItem("avatar")
                  }
                  callAction={callAction}
                  call_action={call_action}
                />

                <SidebarProfile is_mentor={is_mentor} />
              </div>
              <div className="col-md-7">
                {progress && (
                  <div style={{ textAlign: "center", padding: 30 }}>
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                    />
                  </div>
                )}
                {!progress && (
                  <div className="widget-box widget-panel main-account-content account-member account-mentor">
                    <div className="widget-header">
                      <h4>Set Price</h4>
                    </div>
                    <div className="widget-body">
                      <form className="form-member-profile">
                        <div className="form-group">
                          <label className="control-label">
                            Price Per Project Monthly
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name=""
                              defaultValue={
                                setups ? parseFloat(setups.ma).toFixed(0) : 0
                              }
                              onBlur={(evt) => {
                                let s = setups;
                                s.ma = evt.target.value;
                                setSetups(s);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Price All Project / Mentor 1 Month
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name=""
                              defaultValue={
                                setups ? parseFloat(setups.m1).toFixed(0) : 0
                              }
                              onBlur={(evt) => {
                                let s = setups;
                                s.m1 = evt.target.value;
                                setSetups(s);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Price All Project / Mentor 3 Month
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name=""
                              defaultValue={
                                setups ? parseFloat(setups.m3).toFixed(0) : 0
                              }
                              onBlur={(evt) => {
                                let s = setups;
                                s.m3 = evt.target.value;
                                setSetups(s);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Price All Project / Mentor 6 Month
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name=""
                              defaultValue={
                                setups ? parseFloat(setups.m6).toFixed(0) : 0
                              }
                              onBlur={(evt) => {
                                let s = setups;
                                s.m6 = evt.target.value;
                                setSetups(s);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label className="control-label">
                            Price All Project / Mentor 12 Month
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text">Rp</span>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              name=""
                              defaultValue={
                                setups ? parseFloat(setups.m12).toFixed(0) : 0
                              }
                              onBlur={(evt) => {
                                let s = setups;
                                s.m12 = evt.target.value;
                                setSetups(s);
                              }}
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="button button-submit"
                          onClick={() => {
                            save();
                          }}
                        >
                          SAVE
                        </button>
                        {snackbar && (
                          <SnackBar
                            show={snackbar}
                            text={snackbar_text}
                            onClose={() => {
                              setSnackbar(false);
                            }}
                          />
                        )}
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorAccountSetPrice;
