import React, { useEffect, useState } from 'react';
import accounting from 'accounting';
import { getSubsTypeName } from '../libs/helper';
import { Link } from 'react-router-dom';
import * as Unicons from '@iconscout/react-unicons';

import Loader from 'react-loader-spinner';

export default function (props) {
  const { call_action, callAction, onRemoveFromCart, onConfirm, progress } = props;

  const [cart, setCart] = useState(
    localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : []
  );
  const [total_price, setTotalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [update, setUpdate] = useState(0);
  const [referral_code_found, setReferralCodeFound] = useState(false);
  const [error_code, set_error_code] = useState("");
  const [button_clicked, setButtonClicked] = useState(false);
  const [referral_code, setReferralCode] = useState("");
  const [fetching, loading] = useState(false);

  const updateCart = () => {
    if (cart !== null) {
      let total = 0;
      cart.map(item => {
        let price = item.price ? parseFloat(item.price) : 0;
        total += price;
      });
      setTotalPrice(total);
    }
  };

  const onRemoveItem = deletedItem => {
    let c = cart;
    let refresh = [];
    c.map(item => {
      if (item !== deletedItem) refresh.push(item);
    });
    localStorage.setItem('cart', JSON.stringify(refresh));
    setCart(refresh);
    onRemoveFromCart();
  };

  const searchReferral = (e) => {
    e.preventDefault()
    
    const cart = localStorage.getItem('cart');
    if (cart === null) loading(false);

    const orders = JSON.parse(cart) || [];
    callAction(call_action, 'search_referal', {
      endpoint: `/v1/plans/${orders[0].id}?refferal_code=${referral_code}`,
      scenario: 'getV2'
    });
    loading(true);
  }

  const doTypeCode = (code) => {
    setReferralCode(code)
    setButtonClicked(false)
    setReferralCodeFound(false)
  }

  useEffect(() => {
    if (fetching && call_action.search_referal !== null) {
      let dataCart = call_action.search_referal;
      loading(false);
      if ( dataCart.status == 0 && dataCart.error == "Only available for new user."){
        setDiscount(0)
        setReferralCodeFound(false)
        set_error_code(dataCart.error)
      } else if(dataCart.status == 0){
        setDiscount(0)
        setReferralCodeFound(false)
        set_error_code("Referal not found")
      }else if( dataCart.data.discount > 0 ){
        setReferralCodeFound(true)
        setDiscount(dataCart.data.discount)
        set_error_code("")
      
        let cart = [];
        cart.push({
          type: 'subs',
          id: dataCart.data.id,
          name: dataCart.data.name,
          price: dataCart.data.total_price,
          subs_length: dataCart.data.subs_length
        });
        localStorage.setItem('cart', JSON.stringify(cart));
      }else{
        setDiscount(0)
        setReferralCodeFound(false)
      }

      setButtonClicked(true)
    }
  }, [call_action.search_referal]);

  useEffect(() => {
    updateCart();
  }, [cart]);

  return (
    <div class="widget-box widget-panel widget-panel-dark mentor-ordersummary-widget">
      <div class="widget-header">
        <h4>Check Out</h4>
      </div>
      <div class="widget-body">
        {cart.length > 0 && (
          <div class="table-order-summary">
            <table class="table">
              <tbody>
                {cart.map((item, index) => {
                  let title = item.mentor ? item.mentor.username : item.name;
                  return (
                    <tr key={index}>
                      <td>
                        {title}
                        <br />
                        {item.type === 'ma'
                          ? item.emiten
                          : getSubsTypeName(item.type)}
                      </td>
                      <td class="tright">
                        <div className={`cart-action`}>
                          <span className={`price`}>
                            Rp. {accounting.formatNumber(item.price, 0, '.')}
                          </span>
                          <span className={`delete-action`}>
                            <a
                              onClick={() => {
                                if (onRemoveItem) onRemoveItem(item);
                              }}
                            >
                              <i className={`fa fa-trash`}></i>
                            </a>
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
                <tr className='mt-1'>
                  <td colSpan="2"> 
                    <p class='referralTitle mb-0 text-left'>
                      Referral Code
                    </p>
                    <div class="input-group mb-3">
                      <input type="text" class="form-control inputReferral" placeholder="input referral code here..." onChange={(e)=> doTypeCode(e.target.value)} value={referral_code}/>
                      <span class="input-group-text" id="basic-addon2" onClick={(e)=>searchReferral(e)}> <Unicons.UilSearch size={18}/></span>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tfoot className='tfootBorder'>
                { referral_code_found ? 
                  <>
                    <tr>
                      <td class="noBold"> Price </td>
                      <td class="tright noBold">
                        Rp. {accounting.formatNumber(total_price, 0, '.')}
                      </td>
                    </tr>
                    <tr>
                      <td class="noBold italic"> Discount Using Referral </td>
                      <td class="tright discountReferral noBold italic">
                        - Rp. {accounting.formatNumber(discount, 0, '.')}
                      </td>
                    </tr>
                    <tr>
                      <td> TOTAL </td>
                      <td class="tright">
                        Rp. {accounting.formatNumber(total_price-discount, 0, '.')}
                      </td>
                    </tr>
                  </>
                : 
                  <>
                    { button_clicked && referral_code &&
                      <tr>
                        <td class="noBold italic"> Discount Using Referral </td>
                        <td class="tright discountReferral noBold italic notFound">
                          {error_code}
                        </td>
                      </tr>
                    }
                    <tr>
                      <td>TOTAL</td>
                      <td class="tright">
                        Rp. {accounting.formatNumber(total_price-discount, 0, '.')}
                      </td>
                    </tr>
                  </>
                }
              </tfoot>
            </table>
          </div>
        )}
        {cart.length === 0 && (
          <div className={`pt-4 pb-4 center`}>You have no order(s) yet.</div>
        )}
        {progress && (
          <div style={{ textAlign: 'center' }}>
            <Loader type="Puff" color="#00BFFF" height={60} width={60} />
            <div>
              Please wait a moment, you will be redirected to our payment
              processor.
            </div>
          </div>
        )}
        <div class="order-summary-action">
          {!progress && cart.length > 0 && (
            <Link
              onClick={() => {
                onConfirm(referral_code);
              }}
              class="button btn-block"
            >
              {fetching ? "SEARCHING . . ." : "GO TO PAYMENT"}
            </Link>
          )}
          {cart.length === 0 && (
            <Link to="/" class="button btn-block">
              CONTINUE
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}