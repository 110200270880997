import { load } from "dotenv";

const moment = require("moment");
const text = (name, content) => {
  if (typeof content === "undefined") return;
  if (typeof content.payload === "undefined") return;
  if (content.payload === null) return;
  if (typeof content.payload.data === "undefined") return;

  let data = content.payload.data;

  for (let k in data) {
    if (data[k].name.localeCompare(name) === 0) return data[k].txt;
  }
};

const getValues = (props, key) => {
  if (typeof props === "undefined") return [];
  if (typeof props.payload === "undefined") return [];
  if (props.payload === null) return [];
  if (props.payload[key] === null) return [];
  return props.payload[key];
};

const getPayload = (props) => {
  if (typeof props === "undefined") return;
  if (typeof props.payload === "undefined") return;
  if (props.payload === null) return;

  return props.payload;
};

const userHasAccess = (access) => {
  let roles = localStorage.getItem("roles");
  roles = roles !== null ? roles.split(",") : ["user", "guest"];

  //console.log("roles",roles);
  if (!roles) return;
  if (roles.length === 0) return;
  for (let k in access) {
    if (roles.includes(access[k])) return true;
  }

  return false;
};

const session = () => {
  let user_id = localStorage.getItem("user_id");
  let agent_id = localStorage.getItem("agent_id");

  let roles = localStorage.getItem("roles");
  return {
    agent_id,
    user_id,
    roles,
  };
};

const getSubsTypeName = (type) => {
  const str = {
    m1: "1 Month",
    m3: "3 Months",
    m6: "6 Months",
    m12: "1 Year",
    all: "Individual Project Monthly Subscription",
    ma: "Individual Project Monthly Subscription",
  };

  return str[type];
};

//load notification
const load_notif = () => {
  let now = moment().unix();
  let last = localStorage.getItem("last_notify_ts") || 0;
  let recent = localStorage.getItem("recent_notif");
  if(recent === null) localStorage.setItem("recent_notif",0);
  if (last + 600000 < now) {
    localStorage.setItem("last_notify_ts", now);
  }

};

const setupNotification = (notifs)=>{
  if(!notifs) return;
  if (notifs.data) {
    if (notifs.data.subscribed_posts)
      localStorage.setItem(
        "sp",
        JSON.stringify(notifs.data.subscribed_posts)
      );
    if (notifs.data.emiten_posts)
      localStorage.setItem(
        "ep",
        JSON.stringify(notifs.data.emiten_posts)
      );
    if (notifs.data.followed_posts)
      localStorage.setItem(
        "fp",
        JSON.stringify(notifs.data.followed_posts)
      );
    if (notifs.data.project_replies)
      localStorage.setItem(
        "pr",
        JSON.stringify(notifs.data.project_replies)
      );

      if (notifs.data.top_mentors)
      localStorage.setItem(
        "top_mentors",
        JSON.stringify(notifs.data.top_mentors)
      );

      if (notifs.data.top_emiten)
      localStorage.setItem(
        "top_emiten",
        JSON.stringify(notifs.data.top_emiten)
      );

    localStorage.setItem(
      "recent_notif",
      parseInt(notifs.data.new_posts) || 0
    );
    localStorage.setItem("last_ts", notifs.ts);
  
  }
  
}
export {
  text,
  getValues,
  getPayload,
  userHasAccess,
  session,
  getSubsTypeName,
  load_notif,
  setupNotification
};

export default {
  text,
  getValues,
  getPayload,
  userHasAccess,
  getSubsTypeName,
  load_notif,
  setupNotification
};
