import React, {useState,useEffect} from "react";
import {Modal, Button, Alert} from "react-bootstrap";

import {
  FacebookIcon,
  FacebookShareButton,
  InstapaperShareButton,
  TelegramShareButton,
  TwitterIcon,
  TelegramIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  InstapaperIcon,


} from "react-share";


export default function(props){
	const {show, shareItem, sharelink, onClose, onCopy} = props;
	const [snackbar,setSnackbar] = useState(false);
  	const [snackbar_text, setSnackbarText] = useState(null);
	return <Modal show={show} onHide={()=>{
		onClose();
	}}>
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          {shareItem !== null && <Modal.Body>
            <div className={`share-modal`}>
            <div><h4>Share to</h4></div>
            	 
              <div className={`share-icons mr-2`}>
                <FacebookShareButton
                    url={sharelink}
                    quote={shareItem.title}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={64} round />
                  </FacebookShareButton>
              </div>
                 <div className={`share-icons mr-2`}>
                 <TwitterShareButton
                    url={sharelink}
                    title={shareItem.title}
                    className="Demo__some-network__share-button"
                  >
                    <TwitterIcon size={64} round />
                  </TwitterShareButton>
              </div>
                <div className={`share-icons mr-2`}>
                 <TelegramShareButton
                    url={sharelink}
                    title={shareItem.title}
                    className="Demo__some-network__share-button"
                  >
                    <TelegramIcon size={64} round />
                  </TelegramShareButton>
              </div>
                 <div className={`share-icons`}>
                <WhatsappShareButton
                    url={sharelink}
                    title={shareItem.title}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={64} round />
                  </WhatsappShareButton>
                 
              </div>
                {snackbar && (
                  <div style={{marginTop:15,marginBottom:15}}>
                     <Alert variant={`info`}>
                      {snackbar_text}
                        </Alert>
                      </div>
                )}
              	
            </div>
          </Modal.Body>}
          

          <Modal.Footer>
          
          <div className={`permalink-share`}>
          	<i className={`fas fa-link mr-1`}/> <span>{sharelink}</span>
          </div>
           <Button variant="primary" className={`btn btn-primary`} onClick={()=>{
              navigator.clipboard.writeText(sharelink);
	          setSnackbar(true);
	          setSnackbarText("The Post's URL has been copied to your clipboard.");
	         
            }}><span>Copy Link</span></Button>
           
          
          </Modal.Footer>

        </Modal>
}