import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Footer, Header, Images } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const MentorAccountPrivacy = (props) => {
  const { callAction, call_action } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const style1 = {
    color: "#666",
    fontSize: "16px",
  };
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
  }, []);

  return (
    <>
      <Header
        landing={false}
        isLogin={false}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center pt-4 pb-4">
              <div className="col-md-12">
                <div className="widget-box main-account-content account-privacy">
                  <div class="widget-body">
                    <h2>Privacy Policy</h2>
                    <p>
                      This Privacy Policy governs the manner in which Mentorbaik
                      collects, uses, maintains and discloses personally
                      identifiable information collected from users (each, a
                      "User") of the Mentorbaik website located at
                      www.Mentorbaik.com ("Site") including Mentorbaik Mobile
                      App that are registered on the App Store and Google Play
                      Store, or collected through our social networking service
                      (together with the Site, the “Service”).
                    </p>
                    <p>
                      By accessing and / or using the Mentorbaik service, the
                      user declares that each user's data is valid and valid
                      data, and the User agrees to Mentorbaik to obtain,
                      collect, store, manage and use the data as stated in the
                      Privacy Policy and Mentorbaik Terms and Conditions.
                    </p>
                    <p>
                      Mentorbaik from time to time has the right to renew this
                      Privacy Policy so that it remains relevant and up-to-date
                      with changing technology, applicable laws, Mentorbaik
                      innovations that continue to evolve and user needs, and
                      you should check this page occasionally to ensure you are
                      familiar with any changes.
                    </p>
                    <p>
                      Mentorbaik protects all information provided by the user
                      when the user uses all Mentorbaik services, including
                      asking for confidentiality, integrity, and requires the
                      personal data it manages.
                    </p>
                    <p className="mb-0">INFORMATION COLLECTION</p>
                    <p>
                      Mentorbaik collects user data with the aim of managing and
                      expediting the process of using the site, as well as other
                      purposes as long as permitted by applicable laws and
                      regulations. The User data collected is as follows:
                    </p>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>1.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>Information about user, such as :</a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 11, offset: 1 }} className="pr-0">
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>a.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a> Name </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>b.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a> E-mail </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>c.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>Phone number</a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>d.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a> Contacts phone </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>e.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a> Address and/or location </a>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>2.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Data submitted independently by the User, User must
                            submitted the accurate user's data, including but
                            not limited to data submitted when the user:
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 11, offset: 1 }} className="pr-0">
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>a.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                Create or renew a Mentorbaik account, including
                                a username, email address, phone number,
                                password, address, photo, etc;
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>b.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                {" "}
                                Contact Mentorbaik, including through customer
                                service;{" "}
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>c.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                {" "}
                                Interacting with other users through messaging,
                                stream discussion, reviews, ratings and so on;{" "}
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>d.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                Use services on Mentorbaik, including detailed
                                transaction data and user transactions,
                                including types, payment channels used, date and
                                time of the transaction, as well as other
                                transaction details;
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>e.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                {" "}
                                Use features that require permission to access
                                the user's device.{" "}
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>f.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                Information that you provide to us in the course
                                of using Mentorbaik (including information
                                contained in any media uploaded for publication
                                on our site or App); and
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>g.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                {" "}
                                any other information that you choose to send to
                                us.{" "}
                              </a>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>3.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a> Data obtained from other sources, including :</a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={{ span: 11, offset: 1 }} className="pr-0">
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>a.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a>
                                {" "}
                                Marketing and notification service providers;{" "}
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={1} className="pr-0">
                              <a>b.</a>
                            </Col>
                            <Col xs={10} className="pl-0">
                              <a> Publicly available sources. </a>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>4.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Information about your computer/mobile and about
                            your visits to and use of Mentorbaik (such as your
                            IP address, geographical location, browser type,
                            mobile type, referral source, length of visit and
                            number of page views).
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <p className="mb-0">ACCOUNT DELETION</p>
                    <p>
                      You can request to delete your account at any time. When
                      your Mentorbaik account is deleted, your profile, photos,
                      posts, and everything else you've added will be deleted.
                      You won't be able to retrieve anything you've added. Keep
                      in mind that information that others have shared about you
                      is not part of your account and will not be deleted when
                      you delete your account.
                    </p>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>1.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>You won't be able to reactivate your account</a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>2.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Users can delete their Mentorbaik account by
                            accessing the menu: Account {">"} Public Profile{" "}
                            {">"} Delete Account.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>3.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Deleting a Mentorbaik account will not delete the
                            information listed on search engines including but
                            not limited to Google or Bing because Mentorbaik has
                            no control over these websites.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>4.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            When your Mentorbaik account has been deleted, posts
                            tagged by other users on your profile will still
                            appear, but your profile cannot be accessed by other
                            users.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>5.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            When you choose to delete your account, we'll remove
                            your account from the site. Some of this information
                            is permanently deleted from our servers.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>6.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Some information, such as messaging history, isn't
                            stored in your account. This means that friends may
                            still have access to messages that you sent after
                            your account has been deleted.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>7.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Your email address and phone number can no longer be
                            used for future registration processes.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>8.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Your account username can no longer be used for
                            registration on Mentorbaik in the future.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>9.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            If your account is verified or has Admin status,
                            when your profile is deleted, the status will be
                            dismissed.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>10.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            All of your subscription activity on Mentorbaik Pro
                            will be lost upon account deletion.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>11.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Copies of some material may remain in our database
                            as Mentorbaik is required to keep information and
                            data related to your account.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>12.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Contact our customer support team to request for
                            permanent data deletion from our system.
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <p className="mb-0">USER CHOICE AND TRANSPARENCY</p>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>1.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Mobile devices in general (iOS, Android, etc.) have
                            settings so that the Mentorbaik application cannot
                            access certain data without the consent of the user.
                            The iOS device will give a notification to the user
                            when the Mentorbaik application first requests
                            access to the data, while the Android device will
                            give a notification to the user when the Mentorbaik
                            application is first loaded. By using the
                            application and giving access to the application,
                            the user has considered giving consent to the
                            Privacy Policy.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>2.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Users can access and change information in the form
                            of e-mail address, telephone number, date of birth,
                            gender, profile photo and other information through
                            the "settings" feature.
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <p className="mb-0">NUMBER AND CONTACTS PHONE</p>
                    <p>
                      We will never scan or import your contacts unless you ask
                      us to. If you choose to do so, we will only use your
                      contact information to help you find friends or contacts
                      who use Mentorbaik.
                    </p>
                    <br />
                    <p className="mb-0">INFORMATION AND DISCLOSURE</p>
                    <p>
                      In addition to the disclosures reasonably necessary for
                      the purposes identified elsewhere in this privacy policy,
                      we may disclose information about you:
                    </p>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>1.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            to the extent that we are required to do so by law;
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>2.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            in connection with any legal proceedings or
                            prospective legal proceedings (including proceedings
                            contemplated by a third party, whether against us,
                            you or another third party);
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>3.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            in order to establish, exercise or defend our legal
                            rights (including providing information to others
                            for the purposes of fraud prevention and reducing
                            credit risk); and
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>4.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            to the purchaser (or prospective purchaser) of any
                            business or asset which we are (or are
                            contemplating) selling.
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <p className="mb-0">SECURITY</p>
                    <p>
                      We will take reasonable precautions to prevent the loss,
                      misuse or alteration of your personal information.
                    </p>
                    <p>
                      If you choose to publish information about yourself in
                      your public profile or in articles you choose to publish
                      on the website (both optional), this information will, of
                      course, be publicly available on the internet.
                    </p>
                    <p>
                      While all payment information is sent over a secure
                      protocol, other data transmission over the internet is
                      inherently insecure, and we cannot guarantee the security
                      of data sent over the internet.
                    </p>
                    <p>
                      You are responsible for keeping your password and user
                      details confidential. We will not ask you for your
                      password (except when you log in).
                    </p>
                    <br />
                    <p className="mb-0">THIRD PARTY SERVICES & WEBSITES</p>
                    <p>
                      Users may find advertising or other content on our Site
                      that link to the sites and services of our partners,
                      suppliers, advertisers, sponsors, licensors and other
                      third parties. We do not control the content or links that
                      appear on these sites and are not responsible for the
                      practices employed by websites linked to or from our Site.
                    </p>
                    <p>
                      In addition, these sites or services, including their
                      content and links, may be constantly changing. These sites
                      and services may have their own privacy policies and
                      customer service policies. Browsing and interaction on any
                      other website, including websites which have a link to our
                      Site, is subject to that website's own terms and policies.
                    </p>
                    <p>
                      In using any Third-Party Services made available via the
                      Service, you must agree to comply with and be bound by the
                      Terms of Service, Privacy Policy, or other terms and
                      conditions of the third-party service providers.
                    </p>
                    <p className="mb-0">COOKIES</p>
                    <div style={style1}>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>1.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Cookies are small files that will automatically take
                            place inside the user device that runs the device in
                            the form of preferentially managing the user during
                            the site.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>2.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            A cookie consists of information sent by a web
                            server to a web browser, and stored by the browser.
                            The information is then sent back to the server each
                            time the browser requests a page from the server.
                            This enables the web server to identify and track
                            the web browser.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>3.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            These cookies are not intended to be used when
                            accessing other data that the User has on the User's
                            computer device, other than what the User has agreed
                            to submit.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>4.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            We may use both "session" cookies and "persistent"
                            cookies on the website. The session cookies enable
                            our website to keep track of you whilst you navigate
                            the website. The persistent cookies will enable our
                            website to recognise your computer when you visit
                            our website.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>5.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Whilst session cookies will be deleted from your
                            computer when you close your browser, persistent
                            cookies will remain stored on your computer until
                            deleted, or until they reach a specified expiry
                            date.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>6.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Most browsers allow you to refuse to accept cookies.
                            (For example, in Internet Explorer you can refuse
                            all cookies by clicking "Tools", "Internet Options",
                            "Privacy", and selecting "Block all cookies" using
                            the sliding selector.) This will, however, have a
                            negative impact upon the usability of many websites,
                            including ours.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>7.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Mentorbaik uses the Google Analytics Demographics
                            and Interest feature. The data we obtain from these
                            features will be used for the development of the
                            Site and Mentorbaik content. If you don't want your
                            data to be tracked by Google Analytics, you can use
                            the Google Analytics Add-On Browser Opt-Out.
                          </a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1} className="pr-0">
                          <a>8.</a>
                        </Col>
                        <Col xs={11} className="pl-0">
                          <a>
                            Mentorbaik may use features provided by third
                            parties in order to improve the services and content
                            of Mentorbaik, including the adjustment and
                            presentation of advertisements to each User based on
                            interest or visit history. If you don't want your ad
                            to be displayed based on these adjustments, then you
                            can set it through a browser.
                          </a>
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <p className="mb-0">POLICY AMENDMENTS</p>
                    <p>
                      Mentorbaik from time to time has the right to renew this
                      Privacy Policy so that it remains relevant and up-to-date
                      with changing technology, applicable laws, Mentorbaik
                      innovations that continue to evolve and user needs, and
                      you should check this page occasionally to ensure you are
                      familiar with any changes.
                    </p>
                    <p className="mb-0">CONTACT</p>
                    <p>
                      If you have any questions about this Privacy Policy, the
                      practices of this site, or your dealings with this site,
                      please contact us at:
                    </p>
                    <p className="pl-3">
                      <i>
                        Soho Capital, Central Park, lantai 32, nomor 7
                        <br />
                        Jl. Letjen S Parman, kavling 28 - Jakarta 11470
                        <br />
                        <a href="tel:622157906242">Phone : +62 21 57906242</a>
                        <br />
                        <a href="https://wa.me/6281317878717">
                          Whatsapp business : +6281317878717
                        </a>
                        <br />
                        <a href="mailto:cs@gmail.com?subject=Hello&body=Hello!">
                          Email : cs@gmail.com
                        </a>
                        <br />
                        Operational hours: 09:00 - 16.30
                      </i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default MentorAccountPrivacy;
