import React from "react";
import Loader from "react-loader-spinner";
import SnackBar from "./Snackbar";
const FormMentor = (props) => {
  const { data, setData, setSnackbar, progress, snackbar, snackbar_text, save } = props;
  return (
    <form className="form-member-profile">
      <div className="form-group">
        <label className="control-label">Name</label>
        <input
          type="text"
          className="form-control"
          defaultValue={data.nama}
          onBlur={(evt) => {
            const txt = evt.target.value;
            const d = data;
            d.nama = txt;
            setData(d);
          }}
        />
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label className="control-label">Email</label>
          <input
            type="email"
            className="form-control"
            defaultValue={data.email}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.email = txt;
              setData(d);
            }}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Mobile</label>
          <input
            type="text"
            className="form-control"
            defaultValue={data.no_telp}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.no_telp = txt;
              setData(d);
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="control-label">About Me</label>
        <textarea
          className="form-control"
          rows="5"
          onBlur={(evt) => {
            const txt = evt.target.value;
            const d = data;
            d.about_me = txt;
            setData(d);
          }}
          defaultValue={data.about_me}
        />
      </div>
      {/* <div className="form-row">
        <div className="form-group col-md-6">
          <label className="control-label">Bank</label>
          <input
            type="email"
            className="form-control"
            defaultValue={data.bank_name}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.bank_name = txt;
              setData(d);
            }}
          />
        </div>
        <div className="form-group col-md-6">
          <label className="control-label">Bank Account</label>
          <input
            type="text"
            className="form-control"
            defaultValue={data.bank_account}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.bank_account = txt;
              setData(d);
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label className="control-label">Account Holder Name</label>
        <input
          type="text"
          className="form-control"
          defaultValue={data.bank_holder}
          onBlur={(evt) => {
            const txt = evt.target.value;
            const d = data;
            d.bank_holder = txt;
            setData(d);
          }}
        />
      </div> */}
      {progress && (
        <div style={{ textAlign: "center" }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      )}
      {!progress && (
        <button
          type="submit"
          className="button button-submit"
          onClick={() => {
            save(false);
          }}
        >
          SAVE
        </button>
      )}
     {/*} {!progress && (
        <button
          type="submit"
          className="button button-outline"
          onClick={() => {
            save(true);
          }}
        >
          SAVE and SET PRICE
        </button>
      )} */}
      {snackbar && (
        <SnackBar
          show={snackbar}
          text={snackbar_text}
          onClose={() => {
            setSnackbar(false);
          }}
        />
      )}
    </form>
  );
};
export default FormMentor;