import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../Assets';

const Footer = () => {
    const style = {
        "color":"white",
        "fontSize": "14px"
    }
    return (
        <footer id="footer" className="dark">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <img src={Images.logoFooter} className="footer-logo" alt="logo-footer.png" />
                        <a style={style} href="/termsOfUse" target="_blank">
                            Terms of Use
                        </a>
                        <br/>
                        <a style={style} href="/privacyPolicy" target="_blank">
                            Privacy Policy
                        </a>
                        <br/>
                        <a style={style} href="/contact" target="_blank">
                            Contact Us
                        </a>
                        <div className="copyright mt-3">© Mentorbaik 2021</div>
                    </div>
                    <div className="col-md-4">
                        <h4 className="footer-title">Get the App</h4>
                        <div className="download-badge nomargin">
                            <a target={'_blank'} href="https://play.google.com/store/apps/details?id=com.mentorbaik">
                              <img
                                src={Images.googlePlayFooter}
                                alt="google-play-badge-white.png"
                              />
                            </a>
                            <a target={'_blank'} href="https://apps.apple.com/id/app/mentorbaik/id6444364874">
                              <img
                                src={Images.appStoreFooter}
                                alt="images/app-store-badge-white.png"
                              />
                            </a>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <h4 className="footer-title">Follow</h4>
                        <div className="footer-social-wrap">
                            <Link to="#"><img src={Images.fbIcon} alt="ic-facebook.png" /></Link>
                            <Link to="#"><img src={Images.twitterIcon} alt="ic-twitter.png" /></Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
