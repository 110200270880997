import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header, Images } from "../../parts";
import ProfileWidget from "../../parts/ProfileWidget";
import SidebarProfile from "../../parts/SidebarProfile";
import Mentor from "../../parts/FormMentor";
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import Toastify from 'toastify-js';

const InviteMember = (props) => {
  const { callAction, call_action } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [stats, setStats] = useState(null);
  const [progress, loading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [set_price, setPrice] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("");
  const [referral_code, setReferralCode] = useState("");
  const [bonus, setBonus] = useState(26000);
  const [bonus_accumulation, setBonusAccumulation] = useState(880000);
  const [frineds_joined, setFriendsJoined] = useState(3);

  const [data, setData] = useState(
    call_action.me
      ? call_action?.me?.data?.profile
      : {
          nama: null,
          email: null,
          no_telp: null,
        }
  );

  const copyToast = () => {
    Toastify({
      text: "Kode berhasil disalin.",
      duration: 3000,
      newWindow: true,
      close: true,
      gravity: "bottom", // `top` or `bottom`
      position: "right", // `left`, `center` or `right`
      stopOnFocus: true, // Prevents dismissing of toast on hover
      style: {
        background: "#B3E8D1",
        color: "#118B56",
      },
    }).showToast();
  }
  
  const load = (reload) => {
    if (reload || call_action.me === null) {
      callAction(call_action, "me", {
        endpoint: "/v1/myself",
      });
      loading(true);
    }
  };
  const onProfileLoaded = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setIsMentor((payload.data && payload.data.mentor && payload.data.mentor.id > 0) ? true : false);
    setData(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setReferralCode(payload.data ? payload.data.profile.my_referral_code : null)
    setUpdate(update + 1);
  };

  const save = (set_price) => {
    const {
      nama,
      email,
      no_telp,
      about_me,
      bank_account,
      bank_holder,
      bank_name,
    } = data;

    callAction(call_action, "save_profile", {
      scenario: "post",
      endpoint: "/v1/profile",
      data: !is_mentor
        ? {
            nama,
            email,
            no_telp,
          }
        : {
            nama,
            email,
            no_telp,
            about_me,
            bank_account,
            bank_holder,
            bank_name,
          },
    });
    loading(true);
    setPrice(set_price ? set_price : false);
  };

  const formProps = {
    data,
    setData,
    snackbar,
    snackbar_text,
    save,
    progress,
    setSnackbar
  };
  const FormMentor = Mentor;
  const FormMember = (props) => {
    return (
      <form className="form-member-profile">
        <div className="form-group">
          <label className="control-label">Name</label>
          <input
            type="text"
            className="form-control"
            name=""
            defaultValue={data?.nama}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.nama = txt;
              setData(d);
            }}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label className="control-label">Email</label>
            <input
              type="email"
              className="form-control"
              name=""
              defaultValue={data.email || ""}
              onBlur={(evt) => {
                const txt = evt.target.value;
                const d = data;
                d.email = txt;
                setData(d);
              }}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="control-label">Mobile</label>
            <input
              type="text"
              className="form-control"
              name=""
              defaultValue={data?.no_telp}
              onBlur={(evt) => {
                const txt = evt.target.value;
                const d = data;
                d.no_telp = txt;
                setData(d);
              }}
            />
          </div>
        </div>
        {progress && (
          <div style={{ textAlign: "center" }}>
            <Loader type="Puff" color="#00BFFF" height={100} width={100} />
          </div>
        )}
        {!progress && (
          <button
            type="submit"
            className="button button-submit"
            onClick={() => {
              save();
            }}
          >
            SAVE
          </button>
        )}
        {snackbar && (
          <SnackBar
            show={snackbar}
            text={snackbar_text}
            onClose={() => {
              setSnackbar(false);
            }}
          />
        )}
      </form>
    );
  };
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    // setIsMentor(
    //   parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    // );
    load(true);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (progress && call_action.me !== null) {
      loading(false);
      onProfileLoaded(call_action.me);
    }
  }, [call_action.me]);

  useEffect(() => {
    if (progress && call_action.save_profile !== null) {
      loading(false);
      load();

      if (call_action.save_profile) {
        if (set_price === true) {
          document.location = "/set-price";
        } else {
          setSnackbar(true);
          setSnackbarText(call_action.save_profile.message);
        }
      }
    }
  }, [call_action.save_profile]);

  if (data === null)
    return (
      <>
        <div style={{ textAlign: "center", padding: 30 }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      </>
    );
  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center pt-4 pb-4">
              <div className="col-md-3">
                <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={data ? data.avatar_url ? data.avatar_url : localStorage.getItem("avatar") : localStorage.getItem("avatar")}
                  callAction={callAction}
                  call_action={call_action}
                />
                <SidebarProfile is_mentor={is_mentor} current="invite" />
              </div>
              <div className="col-md-7">
                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-header">
                    <h4>INVITE FRIEND</h4>
                    <Link to="/referral-info">
                      <span>{"MY REFERRAL INFO >"}</span>
                    </Link>
                  </div>
                  <div className="widget-body">
                    <div className="row">
                      <div className="col-12 text-center">
                        <img src={Images.referral} alt="close" />
                        <p className="referalProfileHeader">
                        Share kode referal dibawah ini, dapatkan komisi referal 10% untuk kamu dan diskon 10% untuk temanmu yang melakukan subscription
                        </p>
                        {/* <p className="referalProfileDesc">
                          Share your referral code
                        </p> */}
                        { (data.bank_account && data.bank_holder && data.bank_name ) ?
                            <div class="input-group mb-3 sectionReferral">
                              <input type="text" class="form-control boxReferral" value={referral_code}/>
                              <span class="input-group-text copyReferral" id="basic-addon2" 
                                onClick={() => {
                                  navigator.clipboard.writeText(referral_code)
                                  copyToast()
                                }}
                              > SALIN </span>
                            </div>
                          :
                            <>
                              <p className="referalProfileHeader2">
                                <i><strong> Please complete your Bank Information before share referral </strong></i>
                              </p>
                              <Link to={'/profile'}>
                                <span class="input-group-text completeProfile" id="basic-addon2"> 
                                  Complete Profile
                                </span>
                              </Link>
                            </>
                          
                        }
                        
                        {/* what is this for? */}
                        {/* <div className="form-button">
                          <a href="#" className="button btn-block shareButton">
                            Share
                          </a>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="widget-box widget-panel main-account-content account-history">
                  <div className="widget-header">
                    <h4>HOW TO GET BONUS</h4>
                  </div>
                  <div className="widget-body">
                    <div className="row">
                      <div className="col-12 text-left">
                        <div className="row w-8 getBonusText">
                          <ol type="1">
                            <li>
                              Salin dan bagikan kode referral di atas kepada temanmu.
                            </li>
                            <li>
                              Temanmu akan mendapatkan diskon 10% bila berlangganan menggunakan kode referal tersebut, dan kamu akan mendapatkan 10% komisi referal dari nilai transaksi berupa cash yang akan di transfer setiap tanggal 15 di bulan selanjutnya.
                            </li>
                            <li>
                              Untuk mendapatkan komisi referal, kamu wajib mencantumkan nomor rekening bank dan NPWP di bagian profile anda.
                            </li>
                            <li>
                              Info Bonus Referal serta detail pembayaran referalmu dapat dilihat pada Referral Info di bagian Profile.
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InviteMember;