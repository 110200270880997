import Login from './Login'
import { connect } from "react-redux";
import CallAction from "../../redux/actions/CallAction";


const mapStateToProps = (state) => {
    const { call_action } = state;
    return {
      call_action,
    };
  };
  
  const mapDispatchToProps = (dispatch) => ({
    callAction: (state, name, opts) => dispatch(CallAction(state, name, opts)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Login);