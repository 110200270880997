import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Header } from "../../parts";
import Loader from "react-loader-spinner";
import SnackBar from "../../parts/Snackbar";
import {Alert} from "react-bootstrap";
import validator from "validator";
const Login = (props) => {
  const { location, match, call_action, callAction } = props;
  const [progress, setProgress] = useState(true);
  const [token, setToken] = useState(null);
  
  const [snackbar_type, setSnackbarType] = useState('alert');
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("lorem ipsum");
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);

  const [errors, setError] = useState({});
  const setErrors = (name, value) => {
    let _e = errors;
    _e[name] = value;
    setError(_e);
  }
  const activate = () => {
    callAction(call_action, "activation", {
      endpoint: "/activation",
      data: {
       	token
      },
      scenario: "post",
    });
    setProgress(true);
  };
  
  const onActivate = (payload) => {
    if (typeof payload === "undefined") return;
    if(!progress) return;
    if (payload === null) return;
    if(payload.status === 1) setSuccess(true);
    else setFailed(true);
    return true;

  };
  
  useEffect(()=>{
  	if(token) activate();
  },[token]);

  useEffect(() => {
  	let chunks = location.search.split("?token=");
  	setToken(chunks[1]);
    
  }, []);

  useEffect(() => {
  		let ok = onActivate(call_action.activation);
  		if(ok){
  			setProgress(false);
  		}
  }, [call_action.activation]);

  return (
    <>
      <Header isLogin={false} {...props} />
      <section id="content">
        <div className="content-wrap">
          <div className="section nobg nomargin">
          {progress  && <div className="login-card-wrapper">
            	<h4>Aktivasi Akun</h4>
            	<Loader
                        type="Puff"
                        color="#00BFFF"
                        height={40}
                        width={40}
                      />
            	<p>Mohon tunggu sebentar...</p>
            </div>}
            {success && <div className="login-card-wrapper">
            	<h4>Selamat!</h4>
            	<p>Akun anda telah aktif. Silahkan <a href="/login">Login</a> menggunakan kredensial anda!</p>
            </div>}
            {failed && <div className="login-card-wrapper">
            	<h4>Mohon maaf</h4>
            	<p>Link yang anda kirimkan sudah tidak berlaku lagi.</p>
            </div>}
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;