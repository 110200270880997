import Api from "../../Services/Api";

const api = new Api();

//this would be a single action but can be called to many components
//so the payload must be splitted by the component's name
export default (state, name) => (dispatch) => {
  let _state = {
    ...state
  };
  _state[name] = null;

  dispatch(_state);

};