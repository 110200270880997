import React from "react";
import { Link } from "react-router-dom";

export default function (props) {
    const {is_mentor, current} = props;
  return (
    <div className="widget-box sidebar-menu">
      <ul className="sidebar-menu-inner">
        <li className={current === "profile" ? "active" : null}>
          <Link to="/profile">Profile</Link>
        </li>
        <li className={current === "password" ? "active" : null}>
          <Link to="/password">Password</Link>
        </li>
       {/*} <li className={current === "subscription" ? "active" : null}>
          <Link to="/subscribers">Subscription Status</Link>
        </li>
        {is_mentor &&  <li className={current === "price" ? "active" : null}>
          <Link to="/set-price">Set Subscription Price</Link>
        </li>}
       
        {is_mentor &&   <li className={current === "transactions" ? "active" : null}>
          <Link to="/transactions">History Transactions</Link>
        </li>} */}     
        <li className={current === "invite" ? "active" : null}>
          <Link to="/invite-friend">Invite Friend</Link>
        </li>
         <li className={current === "referral" ? "active" : null}>
          <Link to="/referral-info">Referral Info</Link>
        </li>
        <li className={current === "privacy" ? "active" : null}>
          <Link to="/privacy-policy">Privacy</Link>
        </li>
        <li className={current === "terms" ? "active" : null}>
          <Link to="/terms-of-use">Terms of Use</Link>
        </li>
        <li className="logout-button">
          <Link to="/logout">Log out</Link>
        </li>
      </ul>
    </div>
  );
}