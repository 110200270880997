import React, { useState, useEffect } from "react";
import { Header } from "../../parts";
import SidebarProfile from "../../parts/SidebarProfile";
import ProfileWidget from "../../parts/ProfileWidget";
import FormMentor from "../../parts/FormMentor";

const MentorAccountMentor = (props) => {
  const { callAction, call_action } = props;
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [progress, loading] = useState(false);
  const [update, setUpdate] = useState(0);
  const [set_price, setPrice] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [snackbar_text, setSnackbarText] = useState("lorem ipsum");
  const [stats, setStats] = useState(null);
  const [data, setData] = useState(
    call_action.me
      ? call_action.me.data.profile
      : {
          nama: null,
          email: null,
          no_telp: '',
          avatar_url:'https://ui-avatars.com/api/?name=User',
        }
  );
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (progress && call_action.save_profile !== null) {
      localStorage.setItem("is_mentor", 1);
      localStorage.setItem("mentor_id", call_action.save_profile.mentor_id);
      loading(false);

      if (call_action.save_profile) {
        if (set_price === true) {
          document.location = "/set-price";
        } else {
          document.location = "/profile";
        }
      }
    }
  }, [call_action.save_profile]);

  const save = (set_price) => {
    const {
      nama,
      email,
      no_telp,
      about_me,
      bank_account,
      bank_holder,
      bank_name,
    
    } = data;

    callAction(call_action, "save_profile", {
      scenario: "post",
      endpoint: "/v1/profile",
      data: {
        nama,
        email,
        no_telp,
        about_me,
        bank_account,
        bank_holder,
        bank_name,
        is_mentor: 1,
      },
    });

    loading(true);
    setPrice(set_price ? set_price : false);
  };
  const formProps = {
    data,
    setData,
    snackbar,
    snackbar_text,
    save,
    progress,
    setSnackbar,
  };
  console.log({data});
  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="row justify-content-center  pt-4 pb-4">
              <div className="col-md-3">
                <ProfileWidget
                  is_mentor={is_mentor}
                  stats={stats}
                  avatar={
                    data ? data.avatar_url : localStorage.getItem("avatar")
                  }
                  callAction={callAction}
                  call_action={call_action}
                />
                <SidebarProfile is_mentor={is_mentor} />
              </div>
              <div className="col-md-7">
                <div className="widget-box widget-panel main-account-content account-member account-mentor">
                  <div className="widget-header">
                    <h4>Profile</h4>
                    <span>@{localStorage.getItem("username")}</span>
                  </div>
                  <div className="widget-body">
                    <FormMentor {...formProps} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MentorAccountMentor;
