import React,{useEffect,useState} from "react";
import { Button } from "react-bootstrap";
import Loader from "react-loader-spinner";

export default function(props){
    const { callAction, call_action, onFolderClicked } = props;

    const [folders, setFolders] = useState([]);
    const [toggle_new, setToggleNew] = useState(false);
    const [name, setName] = useState(null);
    const [progress, setProgress] = useState(false);
    const [add_progress,setAddProgress] = useState(false);
    const [update, setUpdate] = useState(0);

    const delete_folder = (item) => {
        callAction(call_action,'delete_folder',{
            endpoint:'/v1/folders/' + item.id + '/remove',
        });
    }
   

    const load = () => {
        callAction(call_action,'folders',{
            endpoint:'/v1/folders',
          
        })
    }
    const add = () => {
        callAction(call_action,'add_folder',{
            endpoint:'/v1/folders',
            data:{name},
            scenario:'post'
        });
        
    }
    useEffect(()=>{
        if(call_action.add_folder && progress){
            setToggleNew(false);
            setProgress(false);
            load();
        }
       
    },[
        call_action.add_folder
    ]);

    useEffect(()=>{
        if(call_action.folders){
            setFolders(call_action.folders.folders);
            setUpdate(update + 1);
        }
    },[
        call_action.folders
    ]);
    useEffect(()=>{
        if(call_action.delete_folder){
            load();
        }
    },[
        call_action.delete_folder
    ]);
    useEffect(()=>{
        load();
    },[]);
    return <div className="widget-box mentor-follow-widget">
    <div className="widget-header">
      <h4>My Notes</h4>
    </div>
    <div className="widget-body">
    {folders && folders.length > 0 && <div className="table-follow has-scrollbar folders">
        <ul className="folders">
        {folders.map((item)=>{
            return <li><a href="javascript:void();" className={`folder`} onClick={(evt)=>{
               
                onFolderClicked(item);
            }}><span>{item.name}</span></a> <a href="javascript:void();" onClick={(evt)=>{
                evt.preventDefault();
                delete_folder(item);
            }}><i className={`fa fa-trash trash`}/></a></li>
        })}
         </ul>
      </div>}
      {toggle_new &&  <div className={`pt-4`}>
                <h5 className={`mb-0 mt-0`}>{`New Folder`}</h5>
                <div>
                    <input type="text" className={`form-control`} onBlur={(evt)=>{
                        setName(evt.target.value);
                    }}/>
                </div>
                <div className={`pt-4`}>
                {progress && <div style={{ textAlign: "center" }}>
                    <Loader type="Puff" color="#00BFFF" height={30} width={30} />
                </div>}
                 {!progress && <Button onClick={()=>{
                        setProgress(true);
                        add();
                    }}>Save Folder</Button>}
                </div>
                
            </div>}
            {!toggle_new &&  <div className={`pt-4`}>
        
        <Button 
          className="btn-blue-mentorbaik1"
          onClick={()=>{
            setToggleNew(true);
        }}>New Folder</Button>
      </div>}
    </div>
  </div>
}