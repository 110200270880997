/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalPost,
  ModalFollowers,
  ModalSubscribers,
  CardLeft,
  CardRight
} from '../../parts';
import Timeline from '../../parts/Timeline';
import accounting from 'accounting';
import { getSubsTypeName } from '../../libs/helper';
import moment from 'moment';
import { setupNotification } from '../../libs/helper';
import Loader from 'react-loader-spinner';
import OrderSummary from '../../parts/OrderSummary';

const Subscribe = props => {
  const { call_action, callAction, match } = props;
  const [ready, setReady] = useState(false);
  const [username, setUsername] = useState(null);
  const [toggle, setToggle] = useState('public');
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [load_mentor, isLoadMentor] = useState(false);
  const [is_post, togglePostModal] = useState(false);

  const [progress, setProgress] = useState(true);
  const [follow_progress, toggleFollow] = useState(false);
  const [profile, setProfile] = useState(null);
  const [load_profile, isLoadProfile] = useState(false);
  const [mentor, setMentor] = useState(null);
  const [posts, setPosts] = useState([]);
  const [premium, setPremium] = useState([]);
  const [since_id, setSince_id] = useState(0);
  const [fetching, loading] = useState(false);
  const [load_packages, setLoadingPackage] = useState(false);
  const [update, setUpdate] = useState(0);
  const [all, setAll] = useState(0);
  const [m1, setM1] = useState(0);
  const [m3, setM3] = useState(0);
  const [m6, setM6] = useState(0);
  const [m12, setM12] = useState(0);
  const [plan1, setPlan1] = useState(null); //plan all in
  const [plan2, setPlan2] = useState([]); //plan per emiten -> isinya adalah kode emiten
  const [emiten, setEmiten] = useState([]);
  const [choosedEmiten, setChoosed] = useState([]);
  const [popup_mentor_id, setPopupMentorId] = useState(0);
  const [toggle_popup, setTogglePopup] = useState(false);
  const [popup_reply_symbol, setPopupReplySymbol] = useState('');
  const [popup_reply_limit, setPopupReplyLimit] = useState(0);
  const [popup_symbol, setPopupSymbol] = useState('');
  const [popup_limit, setPopupLimit] = useState(0);
  const [toggle_content, setContent] = useState(false);
  const [toggle_messages, setToggleMessage] = useState(false);
  const [ts, setTimestamp] = useState(localStorage.getItem('last_ts') || 0);
  const [notif_loading, setLoadNotif] = useState(false);
  const [isChecked, toggleChecked] = useState(null);
  const [plan, setPlan] = useState([]);
  const [plan_id, setPlanId] = useState(0);
  const [selected_plan, setSelectedPlan] = useState(null);
  const [cart, setCart] = useState([]);
  const [followings, setFollowings] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.followed_mentors
        : []
      : []
  );
  const [subscriptions, setSubscriptions] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.mentorsubs
        : []
      : []
  );
  const [stats, setStats] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.stats
        : null
      : null
  );
  const [watchlist, setWatchlist] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.watchlists
        : []
      : []
  );

  const [data, setData] = useState({
    name: '',
    email: '',
    phone: ''
  });

  const [toggle_subscriptions, setToggleSubscriptions] = useState(false);
  const [toggle_followers, setToggleFollowers] = useState(false);

  const pricing = {
    m1,
    m3,
    m6,
    m12,
    all
  };
  const location = useLocation();
  const oneYearFromNow = moment().add(10, 'years').unix();

  const initNotifs = () => {
    callAction(call_action, 'notifs', {
      endpoint: '/v1/notif',
      data: {
        ts: localStorage.getItem('last_notify_ts')
          ? parseInt(localStorage.getItem('last_notify_ts'))
          : moment().subtract(1, 'hours').unix()
      }
    });
    setLoadNotif(true);
  };

  const handleSettings = payload => {
    if (typeof payload === 'undefined') return;
    if (payload === null) return;
    if (!payload.data) return;
    const { data } = payload;
    data.sort((a, b) => b.subs_length - a.subs_length);
    setPlan(data);
    setUpdate(update + 1);
  };
  
  const load = () => {
    callAction(call_action, 'subs_setting', {
      endpoint: `/v1/plans`,
      scenario: 'get'
    });

    setLoadingPackage(true);
  };

  const isExistInCart = (item, cart) => {
    let exist = false;
    cart.map(t => {
      if (t.id === item.id) exist = true;
    });

    return exist;
  };

  const loadProfile = () => {
    callAction(call_action, 'me', {
      endpoint: '/v1/myself'
    });
    isLoadProfile(true);
  };

  const addToCart = () => {
    if (!selected_plan) return;
    //if (plan1 === null || plan2 === null) return;

    let cart = [];
    cart.push({
      type: 'subs',
      id: selected_plan.id,
      name: selected_plan.name,
      price: selected_plan.price,
      subs_length: selected_plan.subs_length
    });
    localStorage.setItem('cart', JSON.stringify(cart));
    setUpdate(update + 1);
  };

  const onProfileLoaded = payload => {
    if (typeof payload === 'undefined') return;
    if (payload === null) return;

    setProfile(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setWatchlist(payload.data ? payload.data.watchlists : []);
    setFollowings(payload.data ? payload.data.followed_mentors : []);
    setSubscriptions(payload.data ? payload.data.mentorsubs : []);
    setUpdate(update + 1);
  };

  useEffect(() => {
    setLoginStatus(localStorage.getItem('token') ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem('is_mentor')) === 1 ? true : false
    );
    setUsername(match.params.username);
    initNotifs();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (notif_loading && call_action.notifs !== null) {
      // localStorage.setItem("last_notify_ts",moment().unix()); //di reset jika user sudah klik tombol notif
      setLoadNotif(false);
      setupNotification(call_action.notifs);
      setReady(true);
      try {
        load(match.params.username);
      } catch (err) {
        console.log(err);
      }
    }
  }, [call_action.notifs]);

  useEffect(() => {
    if (load_profile && call_action.me !== null) {
      isLoadProfile(false);
      onProfileLoaded(call_action.me);
    }
  }, [call_action.me]);

  useEffect(() => {
    if (load_packages && call_action.subs_setting) {
      handleSettings(call_action.subs_setting);
      setLoadingPackage(false);
      setUpdate(update + 1);
    }
  }, [call_action.subs_setting]);

  useEffect(() => {
    if (ready) {
      setSince_id(0);
      load(username);
    }
  }, [toggle]);

  useEffect(() => {
    loadProfile();
  }, [call_action.follow_mentor, call_action.unfollow_mentor]);

  useEffect(() => {
    if (selected_plan) {
      console.log({ selected_plan });
      //addToCart();
    }
  }, [selected_plan]);
  return (
    <>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={input => {}}
        refresh={update}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="banner-ads"></div>
          </div>
          <div className="container">
            <div className="row">
                {ready && (
                  <div className="col-md-3">
                    <CardLeft
                      {...props}
                      stats={stats}
                      watchlist={watchlist}
                      followings={followings}
                      subs={subscriptions}
                      onWatchlist={() => {
                        loadProfile();
                      }}
                      onProjectClicked={input => {
                        const { symbol, limit } = input;
                        setPopupReplySymbol(symbol);
                        setPopupReplyLimit(limit);
                        setToggleMessage(true);
                      }}
                      onSubsClicked={input => {
                        const { mentor_id, symbol, limit } = input;
                        setPopupMentorId(mentor_id || 0);
                        setPopupSymbol(symbol || '');
                        setTimestamp(localStorage.getItem('last_ts') || 0);
                        setTogglePopup(true);
                        setPopupLimit(limit);
                      }}
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <div className="mentor-topmain-widget">
                    <div className="mentor-top-subscription">
                      <a className={`collapsed`}>
                        <span>
                          {call_action?.me?.data?.is_subscribed &&
                          call_action?.me?.data?.subs_expired < oneYearFromNow
                            ? 'EXTEND SUBSCRIPTION'
                            : 'SUBSCRIBE NOW'}
                        </span>
                        <i className="icon-angle-down"></i>
                      </a>
                      {!load_packages && (
                        <div className="mentor-top-subscription-inner">
                          <div className="radio-group-select mentor-group-select">
                            <div className="mentor-radio-group white">
                              {plan.map((item, idx) => {
                                return (
                                  <div key={idx}>
                                    <input
                                      id={`radio-${idx}`}
                                      className="radio-style"
                                      name={idx}
                                      type="radio"
                                      checked={plan_id === item.id}
                                      onClick={() => {
                                        setPlanId(item.id);
                                        setSelectedPlan(item);
                                      }}
                                    />
                                    <label
                                      htmlFor={`radio-${idx}`}
                                      className="radio-style-1-label radio-label"
                                    >
                                      <span className={`title`}>{item.name}</span>
                                      <span>
                                        Rp.{' '}
                                        {accounting.formatNumber(
                                          item.price,
                                          0,
                                          '.'
                                        )}
                                      </span>
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                      {selected_plan && (
                        <Link
                          class="button btn-block button-outline"
                          onClick={() => {
                            addToCart();
                            document.location = '/checkout';
                          }}
                        >
                          Check Out
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
                {ready && (
                  <CardRight
                    {...props}
                    orders={[1, 2, 3]}
                    is_mentor={false}
                    subscription={false}
                    onAddToCart={() => {
                      addToCart();
                    }}
                    onRemoveFromCart={() => {
                      setUpdate(update + 1);
                    }}
                  />
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Subscribe;