const ReplyFrom = (props) => {

  //NOT IN USE !!
  const { comment, onClick } = props;

  return (
    <>
     
      <div class="style-msg reply-box" onClick={()=>{
          onClick(comment.id);
      }}>
        <h4>
          Replying to <span>{comment.nama}</span>
        </h4>
        <p>{comment.txt}</p>
      </div>
    </>
  );
};

export default ReplyFrom;
