/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Header,
  Images,
  ModalEvent,
  ModalHistory,
  ModalPost,
  ModalEdit,
  ModalFollowers,
  ModalSubscribers,
  CardLeft,
  CardRight,
} from "../../parts";
import Timeline from "../../parts/Timeline";
import accounting from "accounting";
import { getSubsTypeName } from "../../libs/helper";
import moment from "moment";
import { setupNotification } from "../../libs/helper";
import Loader from "react-loader-spinner";
import Notifications from "../../parts/Notifications";

const MentorMentor = (props) => {
  const { call_action, callAction, match } = props;
  const [ready, setReady] = useState(false);
  const [username, setUsername] = useState(null);
  const [toggle, setToggle] = useState("public");
  const [isLogin, setLoginStatus] = useState(false);
  const [is_mentor, setIsMentor] = useState(false);
  const [load_mentor, isLoadMentor] = useState(false);
  const [is_post, togglePostModal] = useState(false);
  const [is_edit, toggleEditModal] = useState(false);
  const [edit_item, setEditItem] = useState(null);
  const [progress, setProgress] = useState(true);
  const [follow_progress, toggleFollow] = useState(false);
  const [profile, setProfile] = useState(null);
  const [load_profile, isLoadProfile] = useState(false);
  const [sort, setSort] = useState('desc');
  const [mentor_ids, setMentorIds] = useState(null);
  const [mentor, setMentor] = useState(null);
  const [posts, setPosts] = useState([]);
  const [premium, setPremium] = useState([]);
  const [since_id, setSince_id] = useState(0);
  const [fetching, loading] = useState(false);
  const [load_packages, setLoadingPackage] = useState(false);
  const [update, setUpdate] = useState(0);
  const [all, setAll] = useState(0);
  const [m1, setM1] = useState(0);
  const [m3, setM3] = useState(0);
  const [m6, setM6] = useState(0);
  const [m12, setM12] = useState(0);
  const [plan1, setPlan1] = useState(null); //plan all in
  const [plan2, setPlan2] = useState([]); //plan per emiten -> isinya adalah kode emiten
  const [emiten, setEmiten] = useState([]);
  const [choosedEmiten, setChoosed] = useState([]);
  const [popup_mentor_id, setPopupMentorId] = useState(0);
  const [toggle_popup, setTogglePopup] = useState(false);
  const [popup_reply_symbol, setPopupReplySymbol] = useState("");
  const [popup_reply_limit, setPopupReplyLimit] = useState(0);
  const [popup_symbol, setPopupSymbol] = useState("");
  const [popup_limit, setPopupLimit] = useState(0);
  const [toggle_content, setContent] = useState(false);
  const [toggle_messages, setToggleMessage] = useState(false);
  const [ts, setTimestamp] = useState(localStorage.getItem("last_ts") || 0);
  const [notif_loading, setLoadNotif] = useState(false);
  const [isChecked, toggleChecked] = useState(null);

  
  const [followings, setFollowings] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.followed_mentors
        : []
      : []
  );
  const [subscriptions, setSubscriptions] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.mentorsubs
        : []
      : []
  );
  const [stats, setStats] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.stats
        : null
      : null
  );
  const [watchlist, setWatchlist] = useState(
    call_action.me
      ? call_action.me.data
        ? call_action.me.data.watchlists
        : []
      : []
  );

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [toggle_subscriptions, setToggleSubscriptions] = useState(false);
  const [toggle_followers, setToggleFollowers] = useState(false);



  const pricing = {
    m1,
    m3,
    m6,
    m12,
    all,
  };
  const location = useLocation();

  const initNotifs = () => {
    callAction(call_action, "notifs", {
      endpoint: "/v1/notif",
      data: {
        ts: localStorage.getItem("last_notify_ts")
          ? parseInt(localStorage.getItem("last_notify_ts"))
          : moment().subtract(1, "hours").unix(),
      },
    });
    setLoadNotif(true);
  };
  const handleTimeline = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    console.log('timeline',{payload});
    const { status, data, r } = payload;

    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    console.log('timeline',{data});
    setPosts(payload.data ? data : []);
    // setUpdate(update + 1);
  };
  const handlePremium = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    const { status, data, r } = payload;
    if (status === 0) return;
    if (typeof r.mentors !== "undefined") {
      data.map((d) => {
        r.mentors.map((m) => {
          if (m.id === d.mentor_id) d.mentor = m;
        });
      });
    }
    setPremium(payload.data ? data : []);
    // setUpdate(update + 1);
  };
  const handleSettings = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    if (!payload.data) return;
    const { status, ma, m1, m3, m6, m12 } = payload.data;
    const { emiten } = payload;
    if (status === 0) return;
    setAll(parseInt(ma));
    setM1(parseInt(m1));
    setM3(parseInt(m3));
    setM6(parseInt(m6));
    setM12(parseInt(m12));
    setEmiten(emiten ? emiten : []);
  };
  const load = (username) => {
    if (!username) return;
    callAction(call_action, "mentor_detail", {
      endpoint: `/v1/mentor/${username}`,
      scenario: "get",
    });

    isLoadMentor(true);
  };
  const loadSettings = (username) => {
    if (!username) return;

    callAction(call_action, "subs_setting", {
      endpoint: `/v1/mentor/${username}/packages`,
      scenario: "get",
    });

    setLoadingPackage(true);
  };
  const togglePlan2 = (idx) => {
    let checked = isChecked;
    if (checked === null) checked = {};
    if (typeof checked[idx] === "undefined") checked[idx] = true;
    else checked[idx] = !checked[idx];
    toggleChecked(checked);
  };

  const isExistInCart = (item, cart) => {
    let exist = false;
    cart.map((t) => {
      if (t.type === "ma") {
        if (item.emiten === t.emiten && t.mentor.id === mentor.id) exist = true;
      } else {
        if (item.type === t.type && t.mentor.id === mentor.id) exist = true;
      }
    });

    return exist;
  };
  const removePreviousSubs = (item, cart) => {
    let refresh = [];
    cart.map((t) => {
      if (t.type !== "ma") {
        if (t.mentor.id !== item.id) refresh.push(t);
      } else {
        refresh.push(t);
      }
    });
    return refresh;
  };
  const loadProfile = () => {
    callAction(call_action, "me", {
      endpoint: "/v1/myself",
    });
    isLoadProfile(true);
  };

  const addToCart = () => {
    if (!mentor) return;
    //if (plan1 === null || plan2 === null) return;
    let cart = localStorage.getItem("cart");
    if (!cart) cart = [];
    else cart = JSON.parse(cart);
    if (plan1 !== null) {
      let item1 = {
        mentor,
        type: plan1,
        price: pricing[plan1],
      };
      cart = removePreviousSubs(mentor, cart);
      cart.push(item1);
    }
    if (plan2 !== null) {
      if (plan2.length > 0) {
        plan2.map((symbol) => {
          let item2 = {
            mentor,
            type: "ma",
            emiten: symbol,
            price: pricing.all,
          };
          if (!isExistInCart(item2, cart)) cart.push(item2);
        });
      }
    }
    localStorage.setItem("cart", JSON.stringify(cart));
    setUpdate(update + 1);
  };

  const onProfileLoaded = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    setProfile(payload.data ? payload.data.profile : null);
    setStats(payload.data ? payload.data.stats : null);
    setWatchlist(payload.data ? payload.data.watchlists : []);
    setFollowings(payload.data ? payload.data.followed_mentors : []);
    setSubscriptions(payload.data ? payload.data.mentorsubs : []);
    setUpdate(update + 1);
    return true;
  };
  const loadPosts = () => {
    if (toggle === "public") {
      callAction(call_action, "mentor_timeline", {
        endpoint: `/v1/mentor/${username}/timeline`,
        scenario: "get",
        data: {
          since_id,
        },
      });
    }
    /*if (toggle === "premium") {
      callAction(call_action, "mentor_premium", {
        endpoint: `/v1/mentor/${username}/premium`,
        scenario: "get",
        data: {
          since_id,
        },
      });
    }*/
    loading(true);
  };

  //FOLLOW / UNFOLLOW USER
  const toggle_followed_status = () => {
    console.log("toggle_followed_status",m);
    let m = mentor;
    m.is_followed = m.is_followed === 1 ? 0 : 1;
    setMentor(m);
  };
  const follow_user = (item) => {
    try {
      callAction(call_action, "follow_mentor", {
        endpoint: `/v1/mentor/${item.id}/follow`,
      });
      toggleFollow(true);
      toggle_followed_status();
    } catch (err) {
      console.log(err);
    }
  };
  const unfollow_user = (item) => {
    try {
      callAction(call_action, "unfollow_mentor", {
        endpoint: `/v1/mentor/${item.id}/unfollow`,
      });
      toggleFollow(true);
      toggle_followed_status();
    } catch (err) {
      console.log(err);
    }
  };

  const onFollowClicked = (item) => {
    if (item.is_followed === 0) follow_user(item);
    else unfollow_user(item);
  };

  const onMentorDetail = (payload) => {
    if(!payload) return;
    setMentor(
          payload ? payload.data : null
        );
    
    return true;
  }
  useEffect(() => {
    setLoginStatus(localStorage.getItem("token") ? true : false);
    setIsMentor(
      parseInt(localStorage.getItem("is_mentor")) === 1 ? true : false
    );
    setUsername(match.params.username);
   // initNotifs();
   loadProfile();
   
  
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let ok = onMentorDetail(call_action.mentor_detail);
    if (ok) {
       // loadPosts();
        isLoadMentor(false);
        setUpdate(update + 1);
      
    }
  }, [call_action.mentor_detail]);

  useEffect(() => {
    if (mentor) {
      //loadSettings(username);
    }
  }, [mentor]);

  const onNotifLoaded = (payload) => {
    if(!payload) return;
    //setupNotification(payload);
    return true;
  }
  useEffect(() => {
    let ok = onNotifLoaded(call_action.notifs);
    if (ok) {
      // localStorage.setItem("last_notify_ts",moment().unix()); //di reset jika user sudah klik tombol notif
      setLoadNotif(false);
      
      try {
        //load(match.params.username);
      } catch (err) {
        console.log(err);
      }
    }
  }, [call_action.notifs]);

  const onMentorTimeline = (payload) => {
    if(!payload) return;
    handleTimeline(payload);
    return true;
  }

  useEffect(() => {
    let ok = onMentorTimeline(call_action.mentor_timeline);
    if (ok) {
      loading(false);
    }
  }, [call_action.mentor_timeline]);

/*
  useEffect(() => {
    if (fetching && call_action.mentor_premium !== null) {
      handlePremium(call_action.mentor_premium);
      loading(false);
    }
  }, [call_action.mentor_premium]);
*/

  
  useEffect(() => {
    const ok =  onProfileLoaded(call_action.me);
    if (ok) {
      isLoadProfile(false);
      setReady(true);
    }
  }, [call_action.me]);

  useEffect(() => {
    if (load_packages && call_action.subs_setting) {
      handleSettings(call_action.subs_setting);
      setLoadingPackage(false);
      setUpdate(update + 1);
    }
  }, [call_action.subs_setting]);

  useEffect(() => {
    if(ready){
      setSince_id(0);
      load(username);
     }
  }, [toggle]);

  useEffect(() => {
    if(username){
      setSince_id(0);
      loadPosts();
      load(username);
     }
  }, [username]);
  
  useEffect(() => {
    if(call_action.follow_mentor){
       loadProfile();
       load(username);
    }
   
  }, [call_action.follow_mentor]);

  useEffect(()=>{
    setSince_id(0);
    load();
  },[sort]);
  useEffect(()=>{
    setSince_id(0);
    load();
  },[mentor_ids]);

   useEffect(() => {
   
    if(call_action.unfollow_mentor){
       loadProfile();
       load(username);
    }
  }, [call_action.unfollow_mentor]);

  return (
    <>
    <Notifications {...props} onLoaded={()=>{
        console.log("notif loaded");
      }}/>
      <Header
        landing={false}
        isLogin={isLogin}
        {...props}
        onFilter={(input) => {}}
        refresh={update}
      />
      <section id="content">
        <div className="content-wrap">
          <div className="container">
            <div className="banner-ads"></div>
          </div>
          <div className="container">
            <div className="row">
              {ready && <CardLeft
                {...props}
                stats={stats}
                watchlist={watchlist}
                followings={followings}
                subs={subscriptions}
                onWatchlist={() => {
                  loadProfile();
                }}
                onFolderClicked={(folder)=>{
                  localStorage.setItem("folder_id",folder.id);
                  localStorage.setItem("folder_name",folder.name);
                  document.location="/my-notes";
                }}
                onFilter={(filter)=>{
                 
                  const {sort, mentor_ids} = filter;
                  setSort(sort);
                  if(mentor_ids.length > 0) setMentorIds(mentor_ids.join(","));
                  else setMentorIds(null);
                  console.log('refreshByFilter',{filter});
                 
                }}
                onProjectClicked={(input) => {
                  const { symbol, limit } = input;
                  setPopupReplySymbol(symbol);
                  setPopupReplyLimit(limit);
                  setToggleMessage(true);
                }}
                onSubsClicked={(input) => {
                  const { mentor_id, symbol, limit } = input;
                  setPopupMentorId(mentor_id || 0);
                  setPopupSymbol(symbol || "");
                  setTimestamp(localStorage.getItem("last_ts") || 0);
                  setTogglePopup(true);
                  setPopupLimit(limit);
                }}
              />}
              <div className="col-md-6">
                {mentor && (
                  <div className="mentor-topmain-widget">
                    <div className="mentor-topmain-widget-user">
                      {mentor !== null && (
                        <div className="top-mentor-main">
                          <img
                            src={mentor.avatar_url}
                            className="user-image"
                            alt="images"
                          />
                          <div className="user-description">
                            <div className="user-profile-name">
                              <h4 className="user-fullname">{mentor.nama}</h4>
                              <span>@{mentor.username}</span>
                            </div>
                            <p>{mentor.about_me}</p>
                          </div>
                        </div>
                      )}
                      <div className="mentor-topmain-statistic">
                        
                        <Link onClick={()=>{
                          console.log("click",toggle_subscriptions);
                          setToggleSubscriptions(true);
                        }}><span><strong>{mentor.subscribers}</strong> Subscribers</span></Link>

                        <Link onClick={()=>{
                          setToggleFollowers(true);
                        }}><span><strong>{mentor.followers}</strong> Followers</span></Link>
                       
                        <span>
                          <strong>{mentor.portfolio}</strong> Saham
                        </span>
                        {mentor.is_followed === 1 && (
                          <button
                            className="button "
                            onClick={() => {
                              onFollowClicked(mentor);
                            }}
                          >
                            Followed
                          </button>
                        )}
                        {mentor.id !==
                          parseInt(localStorage.getItem("mentor_id")) &&
                          mentor.is_followed === 0 && (
                            <button
                              className="button button-outline button-outline-grey"
                              onClick={() => {
                                onFollowClicked(mentor);
                              }}
                            >
                              Follow
                            </button>
                          )}
                      </div>
                    </div>
                    <div className="mentor-top-subscription">
                      <a
                        data-toggle="collapse"
                        href="#toggle-subscription"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                        className={`collapsed`}
                        onClick={()=>{
                          document.location="/subscribe";
                        }}
                      >
                        <span>Subscribe Now</span>
                        <i className="icon-angle-down"></i>
                      </a>
                     {/*} {!load_packages && (
                        <div
                          className="mentor-top-subscription-inner collapse "
                          id="toggle-subscription"
                        >
                          <p>
                            Layanan rekomendasi, price action buy-sell-hold,
                            analisa dan konsultasi.
                          </p>
                          <div className="radio-group-select mentor-group-select">
                            <h4>Mentor Subscription (all project)</h4>
                            <div className="mentor-radio-group">
                              {m1 > 0 && (
                                <div>
                                  <input
                                    id="radio-1"
                                    className="radio-style"
                                    name="radio-group-1"
                                    type="radio"
                                    checked={plan1 === "m1"}
                                    onClick={() => {
                                      setPlan1("m1");
                                    }}
                                  />
                                  <label
                                    htmlFor="radio-1"
                                    className="radio-style-1-label"
                                  >
                                    <span>1 month</span>
                                    <span>
                                      Rp. {accounting.formatNumber(m1, 0, ".")}
                                    </span>
                                  </label>
                                </div>
                              )}
                              {m3 > 0 && (
                                <div>
                                  <input
                                    id="radio-2"
                                    className="radio-style"
                                    name="radio-group-1"
                                    type="radio"
                                    checked={plan1 === "m3"}
                                    onClick={() => {
                                      setPlan1("m3");
                                    }}
                                  />
                                  <label
                                    htmlFor="radio-2"
                                    className="radio-style-1-label"
                                  >
                                    <span>3 month</span>
                                    <span>
                                      Rp {accounting.formatNumber(m3, 0, ".")}
                                    </span>
                                  </label>
                                </div>
                              )}
                              {m6 > 0 && (
                                <div>
                                  <input
                                    id="radio-3"
                                    className="radio-style"
                                    name="radio-group-1"
                                    type="radio"
                                    checked={plan1 === "m6"}
                                    onClick={() => {
                                      setPlan1("m6");
                                    }}
                                  />
                                  <label
                                    htmlFor="radio-3"
                                    className="radio-style-1-label"
                                  >
                                    <span>6 month</span>
                                    <span>
                                      Rp {accounting.formatNumber(m6, 0, ".")}
                                    </span>
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                         
                        </div>
                      )} */}
                    </div>
                  </div>
                )}
                {fetching && (
                  <div style={{ textAlign: "center",marginTop:30 }}>
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={75}
                      width={75}
                    />
                  </div>
                )}
                {(ready && !fetching) && (
                  <Timeline
                    callAction={callAction}
                    call_action={call_action}
                    progress={fetching}
                    toggle={toggle}
                    isLogin={isLogin}
                    is_mentor={is_mentor}
                    can_post={
                      parseInt(mentor ? mentor.account_id : 0) ===
                      parseInt(localStorage.getItem("user_id"))
                    }
                    posts={posts}
                    premium={premium}
                    events={[]}
                    onRefresh={() => {
                      load(username);
                    }}
                    onToggle={(input) => {
                      setToggle(input);
                    }}
                    on_toggle_modal={()=>{
                      togglePostModal(true);
                    }}
                    onReload={()=>{
                      setSince_id(0);
                     
                    }}
                    onEdit={(item)=>{
                      setEditItem(item);
                      toggleEditModal(true);
                    }}
                  />
                )}
              </div>
              {ready && <CardRight
                orders={[1, 2, 3]}
                {...props}
                is_mentor={false}
                subscription={true}
                onAddToCart={() => {
                  addToCart();
                }}
                onRemoveFromCart={() => {
                  setUpdate(update + 1);
                }}
              />}
            </div>
          </div>
        </div>
      </section>
      <ModalEvent />
      { (
         <ModalPost
        watchlist={watchlist}
        {...props}
        open={is_post}
        onClose={()=>{
          togglePostModal(false);
        }}
        onFinish={() => {
         loadPosts();
           togglePostModal(false);
        }}
      />
      )}

      { (
         <ModalEdit
        watchlist={watchlist}
        {...props}
        data={edit_item}
        open={is_edit}
        onClose={()=>{
          load();
          toggleEditModal(false);
        }}
        onFinish={() => {
          loadPosts();
           toggleEditModal(false);
        }}
      />
      )}

      <ModalHistory subscriptions={[]} {...props} />
     
      <ModalFollowers {...props} open={toggle_followers} username={mentor ? mentor.username : ''} mentor_id={mentor ? mentor.id : 0} onClose={()=>{
        setToggleFollowers(false);
      }}/> 

      <ModalSubscribers {...props}  open={toggle_subscriptions} username={mentor ? mentor.username : ''} mentor_id={mentor ? mentor.id : 0}  onClose={()=>{
        setToggleSubscriptions(false);
      }}/> 
     
    </>
  );
};

export default MentorMentor;
