import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import SnackBar from "./Snackbar";
import { DropdownDate, YearPicker, MonthPicker } from "react-dropdown-date";
import currencyFormatter from "currency-formatter";
import * as Unicons from '@iconscout/react-unicons';

const FormReferralInfo = (props) => {
  const { data, setData, setSnackbar, progress, snackbar, snackbar_text, save, data_ref, searchRefHist } = props;
  const [selected_date, setSelectedDate] = useState({ month: null, year: '2023' });
  const [total_bonus, setTotalBonus] = useState(0);
  const [total_bonus_after_tax, setTotalBonusAfterTax] = useState(0);
  const [total_tax, setTotalTax] = useState(0);
  const [friends_joined, setFriendsJoined] = useState(0);
  const [payment_status, setPaymentStatus] = useState("");
  const [is_tax_npwp, setIsTaxNpwp] = useState(false);
  const [payment_date, setPaymentDate] = useState("17 July 2023");

  useEffect(() => {
    if(data_ref){
      setIsTaxNpwp(data_ref.data.is_tax_npwp)
      setTotalBonus(data_ref.data.total_bonus)
      setTotalBonusAfterTax(data_ref.data.total_bonus_after_tax)
      setTotalTax(data_ref.data.total_tax)
      setFriendsJoined(data_ref.data.total_users)
      setPaymentStatus(data_ref.data.payment_status)
      setPaymentDate(data_ref.data.payment_date)
    }
  }, [data_ref]);

  const formatDate = (date) => {
    // formats a JS date to 'yyyy-mm-dd'
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [year, month, day].join("-");
  };

  return (
    <form className="form-member-profile">
      <div className="form-row">
        <div className="form-group col-md-5">
          <MonthPicker
            defaultValue={'select month'}
            endYearGiven             
            year={2023}   
            required={true}
            disabled={false}
            value={selected_date.month} 
            onChange={(month) => {   
              setSelectedDate({...selected_date, month: Number(month)})
            }}
            id={'month'}
            name={'month'}
            classes={'referralDate'}
            optionClasses={'option classes'}
          />
        </div>
        <div className="form-group col-md-5">
          <YearPicker
            defaultValue={'select year'}
            start={'2023'} 
            end={'2030'} 
            required={true}
            disabled={false}
            value={selected_date.year}    
            onChange={(year) => {      
              setSelectedDate({...selected_date, year: year})
            }}
            id={'year'}
            name={'year'}
            classes={'referralDate'}
            optionClasses={'option classes'}
          />
        </div> 
        <div className="form-group col-md-2">
          <button className="button button-submit button-search" onClick={(e)=>searchRefHist(e, selected_date)}> 
            <Unicons.UilSearch size={18}/>
          </button>
        </div>
      </div>
      { is_tax_npwp ? 
        <div className="form-row">
          <div className="form-group col-md-12 text-left mb-0">
            <label className="control-label mb-0"><strong> With NPWP </strong></label>
          </div>
          <div className="form-group col-md-6 text-left mb-0 pl-4">
            <label className="control-label mb-0">Bonus This Month</label>
          </div>
          <div className="form-group col-md-6 text-right mb-0">
            <label className="control-label mb-0">
              {currencyFormatter.format(total_bonus, { code: 'IDR' })}
            </label>
          </div>
          <div className="form-group col-md-6 text-left mb-0 pl-4">
            <label className="control-label mb-0"><i> Pph21 (2.5%) </i></label>
          </div>
          <div className="form-group col-md-6 text-right mb-0">
            <label className="control-label mb-0 red">
              {`-( ${currencyFormatter.format(total_tax, { code: 'IDR' })} )`}
            </label>
          </div>
          <div className="form-group col-md-6 text-left mb-0 pl-4">
            <label className="control-label mb-0"><strong> Outstanding </strong></label>
          </div>
          <div className="form-group col-md-6 text-right mb-0">
            <label className="control-label mb-0">
              <strong>{currencyFormatter.format(total_bonus_after_tax, { code: 'IDR' })}</strong>
            </label>
          </div>
        </div>
      :
        <div className="form-row">
          <div className="form-group col-md-12 text-left mb-0">
            <label  data-toggle="tooltip" data-placement="top" title="inputed NPWP (if any) will reflect on next month Bonus" className="control-label mb-0">
            <strong> Without NPWP </strong>  &nbsp;
              <Unicons.UilInfoCircle color="background: #4BA5F8;" size="14"/>
            </label>
          </div>
          <div className="form-group col-md-6 text-left mb-0 pl-4">
            <label className="control-label mb-0">Bonus This Month</label>
          </div>
          <div className="form-group col-md-6 text-right mb-0">
            <label className="control-label mb-0">
              {currencyFormatter.format(total_bonus, { code: 'IDR' })}
            </label>
          </div>
          <div className="form-group col-md-6 text-left mb-0 pl-4">
            <label className="control-label mb-0"><i> Pph21 (3%) </i></label>
          </div>
          <div className="form-group col-md-6 text-right mb-0">
            <label className="control-label mb-0 red">
              {`-( ${currencyFormatter.format(total_tax, { code: 'IDR' })} )`}
            </label>
          </div>
          <div className="form-group col-md-6 text-left mb-0 pl-4">
            <label className="control-label mb-0"><strong> Outstanding </strong></label>
          </div>
          <div className="form-group col-md-6 text-right mb-0">
            <label className="control-label mb-0">
              <strong>{currencyFormatter.format(total_bonus_after_tax, { code: 'IDR' })}</strong>
            </label>
          </div>
        </div>
      }
      <hr/>
      <div className="form-row">
        <div className="form-group col-md-6 text-left mb-2">
          <label className="control-label">Friends Joined This Month</label>
        </div>
        <div className="form-group col-md-6 text-right mb-2">
          <label className="control-label">
            <strong> {friends_joined} </strong>
          </label>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6 text-left mb-2">
          <label className="control-label">Status</label>
        </div>
        <div className="form-group col-md-6 text-right mb-2">
          <label className="control-label">
            <strong> {payment_status ? 
                <font className="font-green"> Paid </font>
              : 
                <font className="font-red"> Unpaid </font>
            } </strong>
          </label>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6 text-left mb-2">
          <label  data-toggle="tooltip" data-placement="top" title="Your bonus will be paid every on Date 15th " className="control-label">
            Payment Date &nbsp;
            <Unicons.UilInfoCircle color="background: #4BA5F8;" size="14"/>
          </label>
        </div>
        <div className="form-group col-md-6 text-right mb-2">
          <label className="control-label">
            <strong> {payment_date} </strong>
          </label>
        </div>
      </div>
      {progress && (
        <div style={{ textAlign: "center" }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      )}
     {/*} {!progress && (
        <button
          type="submit"
          className="button button-outline"
          onClick={() => {
            save(true);
          }}
        >
          SAVE and SET PRICE
        </button>
      )} */}
      {snackbar && (
        <SnackBar
          show={snackbar}
          text={snackbar_text}
          onClose={() => {
            setSnackbar(false);
          }}
        />
      )}
    </form>
  );
};
export default FormReferralInfo;